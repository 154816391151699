import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import useDateField from "@/composables/dateField";
import { getElementReport } from "@/composables/elementsHierarchy";
import { styles } from "@/reports/reportStyles";

function setTunnelsDocDefinition(structure) {
  const configStore = useConfigStore();
  const { getFormattedDateStringNoTime } = useDateField();
  const content = getElementReport(structure);

  let docDefinition = {
    header: function () {
      return getReportHeader("Tunnels Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Tunnels Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Tunnel Details",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 10, 0, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Structure Type and Material Items",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [20, 10, 20, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["32%", "20%", "28%", "*"],
          body: [
            [
              { text: "S.1 Number of Bores: ", bold: true },
              {
                text: structure?.T_Tunnel_Structmat?.NUM_BORES,
              },
              { text: "S.4 Ground Condition: ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.GROUND_CONDITION,
                  structure?.T_Tunnel_Structmat?.GROUND_CONDTN
                ),
              },
            ],
            [
              { text: "S.2 Tunnel Shape: ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.TUNNEL_SHAPE,
                  structure?.T_Tunnel_Structmat?.TUNNEL_SHAPE
                ),
              },
              { text: "S.5 Complex: ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.COMPLEX,
                  structure?.T_Tunnel_Structmat?.COMPLEX
                ),
              },
            ],
            [
              { text: "S.3 Portal Shape: ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.PORTAL_SHAPE,
                  structure?.T_Tunnel_Structmat?.PORTAL_SHAPE
                ),
              },
              "",
              "",
            ],
          ],
        },
        margin: [20, 10, 20, 0],
        layout: "noBorders",
      },

      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Age and Service Items",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [20, 10, 20, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["32%", "20%", "28%", "*"],
          body: [
            [
              { text: "A.8 Service in Tunnel: ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.SERVICE_IN_TUNNEL,
                  structure?.T_Tunnel_Service?.SVC_IN_TUNNEL
                ),
              },
              "",
              "",
            ],
          ],
        },
        layout: "noBorders",
        margin: [20, 10, 20, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Classification Items",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [20, 10, 20, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["32%", "20%", "28%", "*"],
          body: [
            [
              { text: "C.3 Direction of Traffic: ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.DIRECTION_OF_TRAVEL,
                  structure?.T_Tunnel_Service?.DIR_OF_TRAFFIC
                ),
              },
              { text: "C.7 Functional Classification: ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.TUNNEL_FUNCTIONAL_CLASSIFICATION,
                  structure?.T_Tunnel_Service?.FUNCTIONAL_CLASS
                ),
              },
            ],
            [
              { text: "C.4 Toll: ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.TOLL,
                  structure?.T_Tunnel_Service?.TOLL
                ),
              },
              { text: "C.8 Urban Code: ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.URBAN_CODE,
                  structure?.T_Tunnel_Service?.URBAN_CODE
                ),
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [20, 10, 20, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Inspection Items",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [20, 10, 20, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["32%", "20%", "28%", "*"],
          body: [
            [
              { text: "D.1 Routine Insp Target Date: ", bold: true },
              {
                text: getFormattedDateStringNoTime(
                  structure?.T_Tunnel_Service?.INSP_TARGET_DATE
                ),
              },
              { text: "D.4 In-Depth Insp Schedule? ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.IN_DEPTH,
                  structure?.T_Tunnel_Service?.IN_DEPTH_INSP_SCHEDULED
                ),
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [20, 10, 20, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Posting Restriction Items",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [20, 10, 20, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["32%", "20%", "28%", "*"],
          body: [
            [
              { text: "L.10 Height Restriction: ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.YES_NO,
                  structure?.T_Tunnel_Load_Rating?.HEIGHT_RESTR
                ),
              },
              { text: "L.12 Other Restrictions: ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.YES_NO,
                  structure?.T_Tunnel_Load_Rating?.OTHER_RESTR
                ),
              },
            ],
            [
              { text: "L.11 Hazardous Mat'l Restriction: ", bold: true },
              {
                text: configStore.getReferenceValue(
                  REFERENCE_TABLE.YES_NO,
                  structure?.T_Tunnel_Load_Rating?.HAZMAT_RESTR
                ),
              },
              "",
              "",
            ],
          ],
        },
        layout: "noBorders",
        margin: [20, 10, 20, 0],
      },

      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Elements",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 10, 0, 0],
      },
      content.map((a) => a),
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}

export { setTunnelsDocDefinition };
