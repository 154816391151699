<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="6" class="pa-0">
      <LabelSelect
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :selectXl="6"
        :selectLg="6"
        :selectMd="6"
        :selectSm="6"
        :id="`label_Railroad_On/Under_${rowId}`"
        label="5C03 On/Under"
        :refTable="REFERENCE_TABLE.ON_UNDER"
        v-model="selectedRow.ON_UNDER"
      >
      </LabelSelect>
      <LabelSelect
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :selectXl="6"
        :selectLg="6"
        :selectMd="6"
        :selectSm="6"
        :id="`label_Highway_Feature_Type_${rowId}`"
        label="6C34 Feature Type"
        :refTable="REFERENCE_TABLE.FEATURE_TYPE"
        v-if="$vuetify.display.mdAndDown"
        v-model="selectedRow.FEATURE_TYPE"
      >
      </LabelSelect>

      <LabelSelect
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :selectXl="6"
        :selectLg="6"
        :selectMd="6"
        :selectSm="6"
        :id="`label_RR_Name_${rowId}`"
        label="FR01 RR Name"
        :refTable="REFERENCE_TABLE.RAILROAD_NAME"
        v-model="selectedRow.RR_NAME"
      >
      </LabelSelect>

      <LabelSelect
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :selectXl="6"
        :selectLg="6"
        :selectMd="6"
        :selectSm="6"
        :id="`label_Service_Status_${rowId}`"
        label="FR03 Service Status"
        :refTable="REFERENCE_TABLE.SERVICE_STATUS"
        v-model="selectedRow.RR_SERV_STATUS"
      >
      </LabelSelect>

      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_RR_Milepost_${rowId}`"
        label="FR04 RR Milepost"
        v-model="selectedRow.RR_MILEPOST"
        maxlength="10"
      >
      </LabelText>

      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_AAR_DOT_Number_${rowId}`"
        label="FR05 AAR DOT Number"
        v-model="selectedRow.AAR_DOT_NUM"
        maxlength="7"
      >
      </LabelText>

      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Number_of_Electrified_Tracks_${rowId}`"
        label="FR06 Number of Electrified Tracks"
        v-model="selectedRow.ELEC_TRACK_QTY"
      >
      </LabelText>

      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Total_Number_of_Tracks_${rowId}`"
        label="FR07 Total Number of Tracks"
        v-model="selectedRow.LANES"
      >
      </LabelText>
    </v-col>
    <v-col class="pa-0">
      <LabelSelect
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :selectXl="6"
        :selectLg="6"
        :selectMd="6"
        :selectSm="6"
        :id="`label_Railroad_Feature_Type_${rowId}`"
        label="6C34 Feature Type"
        v-if="$vuetify.display.lgAndUp"
        :refTable="REFERENCE_TABLE.FEATURE_TYPE"
        v-model="selectedRow.FEATURE_TYPE"
      >
      </LabelSelect>

      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Minimum_Lateral_Under_Right_${rowId}`"
        label="4A19 Minimum Lateral Under (Right)"
        :decimalPlaces="1"
        type="number"
        appendWith="ft"
        v-model="structure.Bridge.HCLRURT"
        :pairCode="PAIR_CODE.METERS_TO_FEET"
        :conversionTypes="[METRIC_CONVERSION_TYPES.L4]"
      >
      </LabelText>
      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Minimum_Lateral_Under_Left_${rowId}`"
        label="4A20 Minimum Lateral Under (Left)"
        :decimalPlaces="1"
        type="number"
        appendWith="ft"
        v-model="structure.Bridge.HCLRULT"
        :pairCode="PAIR_CODE.METERS_TO_FEET"
        :conversionTypes="[
          METRIC_CONVERSION_TYPES.L3,
          METRIC_CONVERSION_TYPES.L5,
        ]"
      >
      </LabelText>
      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Minimum_Over_Vertical_Right_${rowId}`"
        label="FR11 Minimum Over Vertical (Right)"
        appendWith=" ft"
        v-model="selectedRow.MIN_OVER_VERT_CLEAR_RIGHT"
        :conversionTypes="[METRIC_CONVERSION_TYPES.L3]"
        :pairCode="PAIR_CODE.METERS_TO_FEET"
        type="number"
        :decimalPlaces="2"
      >
      </LabelText>
      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Minimum_Over_Vertical_Left_${rowId}`"
        label="FR13 Minimum Over Vertical (Left)"
        appendWith=" ft"
        type="number"
        :pairCode="PAIR_CODE.METERS_TO_FEET"
        :roundingFactor="ROUNDING_FACTOR.TENTH"
        :decimalPlaces="1"
        v-model="selectedRow.TOT_HOR_CLEAR_RIGHT"
      >
      </LabelText>
    </v-col>
  </v-row>
  <v-row>
    <v-col sm="12" class="pa-0">
      <LabelText
        labelXl="3"
        labelLg="3"
        labelMd="3"
        labelSm="3"
        textXl="9"
        textLg="9"
        textMd="9"
        textSm="9"
        :id="`txt_Span_Description_${rowId}`"
        label="FR08 Span Description"
        v-model="selectedRow.SPAN_DESC"
        maxlength="200"
        type="textarea"
        counter
        persistentCounter
        class="pb-0 customLabelAlignment"
      >
      </LabelText>

      <LabelText
        labelXl="3"
        labelLg="3"
        labelMd="3"
        labelSm="3"
        textXl="9"
        textLg="9"
        textMd="9"
        textSm="9"
        :id="`txt_Additional_Operator_${rowId}`"
        label="FR09 Additional Operator"
        v-model="selectedRow.ADD_OPER_DESC"
        maxlength="200"
        type="textarea"
        counter
        persistentCounter
        class="pb-0 customLabelAlignment"
      >
      </LabelText>

      <LabelText
        labelXl="3"
        labelLg="3"
        labelMd="3"
        labelSm="3"
        textXl="9"
        textLg="9"
        textMd="9"
        textSm="9"
        :id="`txt_FR16_Notes_${rowId}`"
        label="FR16 Notes"
        v-model="selectedRow.NOTES"
        maxlength="2000"
        type="textarea"
        counter
        persistentCounter
        class="pb-0 customLabelAlignment"
      >
      </LabelText>
    </v-col>
  </v-row>
</template>

<script>
import { computed } from "vue";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useInspectionStore } from "@/stores/inspection";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelText from "@/components/common/LabelText.vue";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { METRIC_CONVERSION_TYPES } from "@/constants/InspectionConstants";

export default {
  name: "InspectionFeaturesRailroad",
  props: {
    rowId: { default: 0 },
  },
  setup(props) {
    const inspectionStore = useInspectionStore();
    let selectedRow = computed(() =>
      inspectionStore.getFeatureIntersectionSelectedRow(props.rowId)
    );

    let structure = computed(() => inspectionStore.selectedInspection);

    return {
      REFERENCE_TABLE,
      selectedRow,
      structure,
      PAIR_CODE,
      ROUNDING_FACTOR,
      METRIC_CONVERSION_TYPES,
    };
  },
  components: {
    LabelSelect,
    LabelText,
  },
};
</script>
