<template>
  <v-row>
    <ExpandCollapseToggle
      id="approachPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panel"
    />
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel style="margin-top: 20px">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
          <h3>Approach Conditions</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-form v-model="valid" ref="modifyApproachValidation">
            <v-row>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  id="sel_Approach_Alignment"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.APPRALIGN_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.APPRALIGN_DURATION_CHANGES
                    )
                  "
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  label="4A02 Approach Alignment"
                  :refTable="
                    REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL
                  "
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      approachAlignmentUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="structure.InspEvnt.APPRALIGN"
                ></LabelSelect>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col sm="12" class="pt-4">
                <CommentTypeTextField
                  label="Alignment Notes"
                  id="field_Inspection_Notes"
                  :inspectionCommentType="
                    INSPECTION_COMMENTS.APPROACH_ALIGNMENT
                  "
                  :readonly="!isEditable"
                  :counter="commentsMaxLength"
                  :maxlength="commentsMaxLength"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  id="sel_Approach_Roadway"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.APPR_ROADWAY_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.APPR_ROADWAY_DURATION_CHANGES
                    )
                  "
                  label="6B39 Approach Roadway"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      approachRoadwayUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="structure.UserInsp.APPR_ROAD_COND_RATE"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                ></LabelSelect>
              </v-col>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  id="sel_Approach_Slab"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.APPR_SLAB_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.APPR_SLAB_DURATION_CHANGES
                    )
                  "
                  label="6B38 Approach Slab"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      approachSlabUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="structure.UserInsp.APPR_SLAB_COND_RATE"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                ></LabelSelect
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  id="sel_Railing"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.RAILRATING_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.RAILRATING_DURATION_CHANGES
                    )
                  "
                  label="IA02 Railing"
                  :refTable="REFERENCE_TABLE.RAILING_RATING"
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      approachRailingUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="structure.InspEvnt.RAILRATING"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                ></LabelSelect>
              </v-col>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  id="sel_Transition"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.TRANSRATIN_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.TRANSRATIN_DURATION_CHANGES
                    )
                  "
                  label="IA02 Transition"
                  :refTable="REFERENCE_TABLE.TRANSITION_RATING"
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      approachTransitionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="structure.InspEvnt.TRANSRATIN"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                ></LabelSelect
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="6" xl="6">
                <LabelSelect
                  id="sel_Guide_Rail"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.ARAILRATIN_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.ARAILRATIN_DURATION_CHANGES
                    )
                  "
                  label="IA02 Guide Rail"
                  :refTable="REFERENCE_TABLE.APPROACH_GUIDE_RAIL_RATING"
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      approachGuideRailUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="structure.InspEvnt.ARAILRATIN"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                ></LabelSelect>
              </v-col>
              <v-col cols="12" lg="6" xl="6"
                ><LabelSelect
                  id="sel_Rail_End"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.AEBDRATING_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.AEBDRATING_DURATION_CHANGES
                    )
                  "
                  label="IA02 Rail End"
                  :refTable="REFERENCE_TABLE.APPROACH_RAIL_END_RATING"
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      approachRailEndUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="structure.InspEvnt.AENDRATING"
                  labelXl="5"
                  labelLg="5"
                  labelMd="6"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                ></LabelSelect>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(1)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(1)" icon="fa-solid fa-minus" />
          <h3>Approach Details and Inspection Notes</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <LabelText
            id="field_Pavement_Width"
            label="6A43 Approach Pavement Width"
            v-model="structure.UserBrdg.APPR_PAVEMENT_WIDTH"
            appendWith=" ft"
            type="number"
            :pairCode="PAIR_CODE.METERS_TO_FEET"
            :roundingFactor="ROUNDING_FACTOR.ROUND"
            noGrid
            class="pt-4"
          >
          </LabelText>
          <div>
            <CommentTypeTextField
              label="Pavement"
              id="field_Pavement"
              :inspectionCommentType="INSPECTION_COMMENTS.APPROACH_PAVEMENT"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
            />
          </div>

          <div>
            <CommentTypeTextField
              label="Drainage"
              id="field_Drainage"
              :inspectionCommentType="INSPECTION_COMMENTS.APPROACH_DRAINAGE"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
            />
          </div>

          <div>
            <CommentTypeTextField
              label="Shoulders"
              id="field_Shoulders"
              :inspectionCommentType="INSPECTION_COMMENTS.APPROACH_SHOULDERS"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
            />
          </div>

          <div>
            <CommentTypeTextField
              label="Approach Slab"
              id="field_Approach_Slab"
              :inspectionCommentType="INSPECTION_COMMENTS.APPROACH_SLAB"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
            />
          </div>
          <v-row
            ><v-col sm="6">
              <LabelCheckbox
                id="checkbox_Bump_At_Bridge"
                label="6B04 Bump at Bridge?"
                :showColon="false"
                class="noGridCheckbox"
                :isEditable="isEditable"
                noGrid
                v-model="structure.UserInsp.APPR_BUMP"
              >
              </LabelCheckbox></v-col
          ></v-row>
          <v-row
            ><v-col sm="12">
              <CommentTypeTextField
                id="field_Bump_At_Bridge"
                :inspectionCommentType="
                  INSPECTION_COMMENTS.BUMP_AT_BRIDGE_OBJECT
                "
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              /> </v-col
          ></v-row>
          <div>
            <CommentTypeTextField
              label="6A39 Pavement Relief Joints Present?"
              :showColon="false"
              :labelValue="pavementReliefJointsPresent"
              id="field_Pavement_Relief_Joints_Present"
              :inspectionCommentType="
                INSPECTION_COMMENTS.PAVEMENT_RELIEF_JOINTS
              "
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
            />
          </div>
          <div>
            <v-label class="bold">Bridge Railing:</v-label>
            <div class="pl-6">
              <LabelText
                labelXl="1"
                labelLg="2"
                labelMd="2"
                labelSm="3"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="text_Bridge_Railing_desc"
                label="Description"
                :isEditable="isEditable"
                v-model="bridgeRailingDesc"
                maxlength="50"
                type="textarea"
                counter
                persistentCounter
                class="pb-0 customLabelAlignment"
              >
              </LabelText>

              <CommentTypeTextField
                label="Notes"
                id="field_Bridge_Railing_Notes"
                :inspectionCommentType="INSPECTION_COMMENTS.BRIDGE_RAILING"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </div>
          </div>
          <div>
            <v-label class="bold">Transition:</v-label>
            <div class="pl-6">
              <LabelText
                labelXl="1"
                labelLg="2"
                labelMd="2"
                labelSm="3"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="text_Transition_desc"
                label="Description"
                :isEditable="isEditable"
                v-model="transitionDesc"
                maxlength="50"
                type="textarea"
                counter
                persistentCounter
                class="pb-0 customLabelAlignment"
              >
              </LabelText>

              <CommentTypeTextField
                label="Notes"
                id="field_Transition_Notes"
                :inspectionCommentType="INSPECTION_COMMENTS.TRANSITION"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </div>
          </div>
          <div>
            <v-label class="bold">Guiderail:</v-label>
            <div class="pl-6">
              <LabelText
                labelXl="1"
                labelLg="2"
                labelMd="2"
                labelSm="3"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="text_Guiderail_desc"
                label="Description"
                :isEditable="isEditable"
                v-model="guiderailDesc"
                maxlength="50"
                type="textarea"
                counter
                persistentCounter
                class="pb-0 customLabelAlignment"
              >
              </LabelText>

              <CommentTypeTextField
                label="Notes"
                id="field_Guide_Rail_Notes"
                :inspectionCommentType="INSPECTION_COMMENTS.GUIDE_RAIL"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </div>
          </div>
          <div>
            <v-label class="bold">Rail End:</v-label>
            <div class="pl-6">
              <LabelText
                labelXl="1"
                labelLg="2"
                labelMd="2"
                labelSm="3"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="text_Rail_End_desc"
                label="Description"
                :isEditable="isEditable"
                v-model="railEndDesc"
                maxlength="50"
                type="textarea"
                counter
                persistentCounter
                class="pb-0 customLabelAlignment"
              >
              </LabelText>

              <CommentTypeTextField
                label="Notes"
                id="field_Rail_End_Notes"
                :inspectionCommentType="INSPECTION_COMMENTS.RAIL_END"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </div>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel @group:selected="checkValidation">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(2)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(2)" icon="fa-solid fa-minus" />
          <h3>Signs</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-row>
            <v-col>
              <v-row class="d-flex align-center mx-0 mt-2 mb-4"
                ><v-btn
                  v-if="isEditable"
                  id="btn_addSign"
                  variant="outlined"
                  @click="addNewSign"
                  :disabled="
                    !isEditable ||
                    expandedRows?.length > 0 ||
                    availableSignTypes?.length <= 0
                  "
                  >Add Sign</v-btn
                >
                <IconTooltip
                  id="icon_noSignAvailable"
                  icon="fas fa-circle-info"
                  iconclass="infoIcon"
                  :tooltip="INSPECTION_MESSAGES.NO_SIGN_TYPES_AVAILABLE_TO_ADD"
                  v-if="isEditable && availableSignTypes?.length == 0"
              /></v-row>

              <v-data-table
                id="approach-data-table"
                v-model:expanded="expandedRows"
                :headers="headers"
                :items="signs"
                class="compactTable lessPaddingTableColumns"
                v-model:sort-by="sortBy"
                hide-default-footer
                must-sort
                show-expand
                return-object
              >
                <template v-slot:[`column.data-table-expand`]="{ column }">
                  {{ column.title }}
                </template>
                <template v-slot:[`column.SIGN_TYPE`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortBy"
                    :lines="2"
                  />
                </template>
                <template v-slot:[`column.SIGN_MESSAGE`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortBy"
                    :lines="2"
                  />
                </template>
                <template v-slot:[`column.NEAR_ADVANCE_COND`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortBy"
                    :lines="2"
                  />
                </template>
                <template v-slot:[`column.FAR_ADVANCE_COND`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortBy"
                    :lines="2"
                  />
                </template>
                <template v-slot:[`column.NEAR_SITE_COND`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortBy"
                    :lines="2"
                  />
                </template>
                <template v-slot:[`column.FAR_SITE_COND`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortBy"
                    :lines="2"
                  />
                </template>
                <template v-slot:[`column.action`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortBy"
                    :lines="2"
                  />
                </template>

                <template v-slot:expanded-row="{ item }">
                  <td :colspan="headers.length">
                    <v-form
                      ref="signDetailForm"
                      v-model="validDetails"
                      class="mt-2"
                      @change="changesDetected = true"
                    >
                      <v-row>
                        <v-col sm="6" xs="12" class="pa-0">
                          <LabelSelect
                            labelXl="4"
                            labelLg="5"
                            labelMd="6"
                            labelSm="6"
                            selectXl="6"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="sel_sign_type"
                            label="ID01 Type of Sign"
                            :options="
                              item.raw?.isAdd
                                ? availableSignTypes
                                : allSignTypes
                            "
                            :isEditable="isEditable && item.raw.isAdd"
                            isRequired
                            v-model="item.raw.SIGN_TYPE"
                            @update:modelValue="changesDetected = true"
                          ></LabelSelect>

                          <LabelText
                            labelXl="4"
                            labelLg="5"
                            labelMd="6"
                            labelSm="6"
                            textXl="6"
                            textLg="6"
                            textMd="6"
                            textSm="6"
                            id="sel_sign_message"
                            :isEditable="isEditable && !item.raw.readOnly"
                            label="ID03 Sign Message"
                            v-model="item.raw.SIGN_MESSAGE"
                            maxlength="10"
                            counter="10"
                          >
                          </LabelText>

                          <LabelSelect
                            labelXl="4"
                            labelLg="5"
                            labelMd="6"
                            labelSm="6"
                            selectXl="6"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="sel_near_advance"
                            label="ID04 Near Advance"
                            :refTable="REFERENCE_TABLE.SIGN_CONDITION_RAITING"
                            :isEditable="isEditable && !item.raw.readOnly"
                            isRequired
                            v-model="item.raw.NEAR_ADVANCE_COND"
                            @update:modelValue="changesDetected = true"
                          ></LabelSelect>
                        </v-col>

                        <v-col sm="6" xs="12" class="pa-0">
                          <LabelSelect
                            labelXl="4"
                            labelLg="5"
                            labelMd="6"
                            labelSm="6"
                            selectXl="6"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="sel_far_advance"
                            label="ID05 Far Advance"
                            :refTable="REFERENCE_TABLE.SIGN_CONDITION_RAITING"
                            :isEditable="isEditable && !item.raw.readOnly"
                            isRequired
                            v-model="item.raw.FAR_ADVANCE_COND"
                            @update:modelValue="changesDetected = true"
                          ></LabelSelect>

                          <LabelSelect
                            labelXl="4"
                            labelLg="5"
                            labelMd="6"
                            labelSm="6"
                            selectXl="6"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="sel_near_bridge_site"
                            label="ID06 Near Bridge Site"
                            :refTable="REFERENCE_TABLE.SIGN_CONDITION_RAITING"
                            :isEditable="isEditable && !item.raw.readOnly"
                            isRequired
                            v-model="item.raw.NEAR_SITE_COND"
                            @update:modelValue="changesDetected = true"
                          ></LabelSelect>

                          <LabelSelect
                            labelXl="4"
                            labelLg="5"
                            labelMd="6"
                            labelSm="6"
                            selectXl="6"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="sel_far_bridge_site"
                            label="ID07 Far Bridge Site"
                            :refTable="REFERENCE_TABLE.SIGN_CONDITION_RAITING"
                            :isEditable="isEditable && !item.raw.readOnly"
                            isRequired
                            v-model="item.raw.FAR_SITE_COND"
                            @update:modelValue="changesDetected = true"
                          ></LabelSelect>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <LabelText
                            labelXl="12"
                            labelLg="12"
                            labelMd="12"
                            labelSm="12"
                            textXl="12"
                            textLg="12"
                            textMd="12"
                            textSm="12"
                            id="sel_signing_notes"
                            type="textarea"
                            :isEditable="isEditable && !item.raw.readOnly"
                            label="ID08 Signing Notes"
                            v-model="item.raw.NOTES"
                            maxlength="255"
                          >
                          </LabelText>
                        </v-col>
                      </v-row>
                    </v-form>
                  </td>
                </template>

                <template v-slot:item="{ item }">
                  <tr :class="rowClass(item.raw)">
                    <td class="alignCenter">
                      <template
                        v-if="
                          expandedRows?.length == 0 || !anySignDetailEditable
                        "
                      >
                        <button
                          v-if="!expandedRows.includes(item.raw)"
                          @click="toggleRow(item.raw)"
                          aria-label="toggleRowButtonOpen"
                        >
                          <v-icon
                            :id="`icon_Chevron_Right_${item.raw.SIGN_TYPE}`"
                            icon="fa-solid fa-chevron-right"
                          ></v-icon>
                        </button>

                        <button
                          v-if="expandedRows.includes(item.raw)"
                          @click="toggleRow(item.raw)"
                          aria-label="toggleRowButtonClosed"
                        >
                          <v-icon
                            :id="`icon_Chevron_Down_${item.raw.SIGN_TYPE}`"
                            icon="fa-solid fa-chevron-down"
                          ></v-icon>
                        </button>
                      </template>
                    </td>
                    <td class="alignCenter">
                      {{
                        item.columns.SIGN_TYPE
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.SIGN_TYPE,
                              item.columns.SIGN_TYPE
                            )
                          : ""
                      }}
                    </td>
                    <td class="alignCenter">{{ item.columns.SIGN_MESSAGE }}</td>
                    <td class="alignCenter">
                      {{
                        item.columns.SIGN_TYPE
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.SIGN_CONDITION_RAITING,
                              item.columns.NEAR_ADVANCE_COND
                            )
                          : ""
                      }}
                    </td>

                    <td class="alignCenter">
                      {{
                        item.columns.SIGN_TYPE
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.SIGN_CONDITION_RAITING,
                              item.columns.FAR_ADVANCE_COND
                            )
                          : ""
                      }}
                    </td>

                    <td class="alignCenter">
                      {{
                        item.columns.SIGN_TYPE
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.SIGN_CONDITION_RAITING,
                              item.columns.NEAR_SITE_COND
                            )
                          : ""
                      }}
                    </td>

                    <td class="alignCenter">
                      {{
                        item.columns.SIGN_TYPE
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.SIGN_CONDITION_RAITING,
                              item.columns.FAR_SITE_COND
                            )
                          : ""
                      }}
                    </td>
                    <td class="alignCenter">
                      <div
                        style="white-space: nowrap"
                        v-if="!item.raw.readOnly && isEditable"
                      >
                        <IconTooltip
                          id="icon_add"
                          icon="fa-solid fa-check"
                          :tooltip="
                            item.raw?.isAdd ? 'Add Sign' : 'Update Sign'
                          "
                          @click="saveDetail(item.raw)"
                        />
                        <IconTooltip
                          id="icon_close"
                          icon="fa-solid fa-xmark"
                          tooltip="Close"
                          @click="closeDetail"
                        />
                      </div>
                      <div
                        style="white-space: nowrap"
                        v-else-if="
                          isEditable &&
                          (expandedRows?.length == 0 || !anySignDetailEditable)
                        "
                      >
                        <IconTooltip
                          id="icon_edit"
                          icon="fa-solid fa-pencil"
                          tooltip="Edit"
                          @click="editDetail(item.raw)"
                        />
                        <IconTooltip
                          id="icon_delete"
                          icon="fa-solid fa-trash"
                          tooltip="Delete"
                          @click="deleteDetail(item.raw)"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-slot:bottom> </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
  <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />
  <v-snackbar
    v-model="inspectionStore.addingOrEditing"
    color="warning"
    position="sticky"
    location="top"
    timeout="-1"
  >
    {{ INSPECTION_MESSAGES.SIGNS_DATATABLE }}
  </v-snackbar>
</template>

<script>
import { computed, ref, watch, nextTick, onMounted } from "vue";
import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import { getHistDuration } from "@/composables/util";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";
import LabelText from "@/components/common/LabelText.vue";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { clone } from "@/util/clone";
import IconTooltip from "@/components/common/IconTooltip.vue";
import sign from "@/data/sign";
import { LOGGER } from "@/util/logger";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import { INSPECTION_MESSAGES } from "@/constants/InspectionConstants";
import { BUTTONS } from "@/constants/CommonWebConstants";
import safetyFeature from "@/data/safetyFeature";
import DataTableHeader from "@/components/common/DataTableHeader.vue";
import { scrollToDataTable } from "@/util/scrollToElement";
import useApproachData from "@/composables/approachData";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";

export default {
  name: "InspectionApproach",
  data() {
    return {
      headers: [
        {
          key: "data-table-expand",
        },
        {
          line1: "ID01",
          line2: "Type of Sign",
          line3: null,
          key: "SIGN_TYPE",
          sortable: true,
        },
        {
          line1: "ID03",
          line2: "Sign Message",
          line3: null,
          key: "SIGN_MESSAGE",
          sortable: true,
        },
        {
          line1: "ID04",
          line2: "Near Advance",
          line3: null,
          key: "NEAR_ADVANCE_COND",
          sortable: true,
        },
        {
          line1: "ID05",
          line2: "Far Advance",
          line3: null,
          key: "FAR_ADVANCE_COND",
          sortable: true,
        },
        {
          line1: "ID06",
          line2: "Bridge Site Near",
          line3: null,
          key: "NEAR_SITE_COND",
          sortable: true,
        },
        {
          line1: "ID07",
          line2: "Bridge Site Far",
          line3: null,
          key: "FAR_SITE_COND",
          sortable: true,
        },
        {
          line1: null,
          line2: "Action",
          line3: null,
          key: "action",
          sortable: false,
        },
      ],
    };
  },
  setup() {
    const configStore = useConfigStore();
    const inspectionStore = useInspectionStore();
    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });
    let structure = computed(() => inspectionStore.selectedInspection);
    const allPanels = [0, 1, 2];
    const panel = ref(allPanels);
    const {
      pavementReliefJointsPresent,
      bridgeRailingDesc,
      transitionDesc,
      guiderailDesc,
      railEndDesc,
    } = useApproachData();
    onMounted(async () => {
      await validate();
    });

    let valid = ref(true);
    let modifyApproachValidation = ref(null);
    async function validate() {
      await modifyApproachValidation.value.validate();
    }
    let approachAlignmentUpdated = ref(false);
    let conditionUpdated = ref(false);
    let approachRoadwayUpdated = ref(false);
    let approachSlabUpdated = ref(false);
    let approachRailingUpdated = ref(false);
    let approachTransitionUpdated = ref(false);
    let approachGuideRailUpdated = ref(false);
    let approachRailEndUpdated = ref(false);
    let previousSort = ref([{ key: "SIGN_TYPE", order: "asc" }]);
    let sortBy = ref([{ key: "SIGN_TYPE", order: "asc" }]);
    const updateDuration = () => {
      if (!structure.value.T_Insp_Condition_Hist) {
        structure.value.T_Insp_Condition_Hist = {};
      }
      if (approachAlignmentUpdated.value) {
        structure.value.T_Insp_Condition_Hist.APPRALIGN_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.APPRALIGN_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.APPRALIGN_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.SUB_DURATION,
            structure.value.T_Insp_Condition_Hist.APPRALIGN_DURATION_CHANGES
          );
      }
      if (approachRoadwayUpdated.value) {
        structure.value.T_Insp_Condition_Hist.APPR_ROADWAY_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.APPR_ROADWAY_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.APPR_ROADWAY_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.APPR_ROADWAY_DURATION,
            structure.value.T_Insp_Condition_Hist.APPR_ROADWAY_DURATION_CHANGES
          );
      }
      if (approachSlabUpdated.value) {
        structure.value.T_Insp_Condition_Hist.APPR_SLAB_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.APPR_SLAB_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.APPR_SLAB_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.APPR_SLAB_DURATION,
            structure.value.T_Insp_Condition_Hist.APPR_SLAB_DURATION_CHANGES
          );
      }
      if (approachRailingUpdated.value) {
        structure.value.T_Insp_Condition_Hist.RAILRATING_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.RAILRATING_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.RAILRATING_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.RAILRATING_DURATION,
            structure.value.T_Insp_Condition_Hist.RAILRATING_DURATION_CHANGES
          );
      }
      if (approachTransitionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.TRANSRATIN_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.TRANSRATIN_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.TRANSRATIN_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.TRANSRATIN_DURATION,
            structure.value.T_Insp_Condition_Hist.TRANSRATIN_DURATION_CHANGES
          );
      }
      if (approachGuideRailUpdated.value) {
        structure.value.T_Insp_Condition_Hist.ARAILRATIN_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.ARAILRATIN_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.ARAILRATIN_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.ARAILRATIN_DURATION,
            structure.value.T_Insp_Condition_Hist.ARAILRATIN_DURATION_CHANGES
          );
      }
      if (approachRailEndUpdated.value) {
        structure.value.T_Insp_Condition_Hist.AEBDRATING_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.AEBDRATING_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.AEBDRATING_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.AEBDRATING_DURATION,
            structure.value.T_Insp_Condition_Hist.AEBDRATING_DURATION_CHANGES
          );
      }

      approachRailEndUpdated.value = false;
      approachGuideRailUpdated.value = false;
      approachTransitionUpdated.value = false;
      approachAlignmentUpdated.value = false;
      approachRailingUpdated.value = false;
      approachRoadwayUpdated.value = false;
      approachSlabUpdated.value = false;
      conditionUpdated.value = false;
    };
    let signDetailForm = ref(null);
    let validDetails = ref(false);
    let changesDetected = ref(false);
    const closeConfirm = ref(null);
    let availableSignTypes = ref([]);
    const allSignTypes = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.SIGN_TYPE)
    );

    if (!isEditable.value) {
      panel.value = allPanels;
    }

    const commentsMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });

    let expandedRows = ref([]);
    let signs = ref([]);

    const getNewSign = () => {
      return clone(sign);
    };

    const sort = (arr) => {
      arr.value = arr.value?.sort(function (a, b) {
        return a.SIGN_TYPE - b.SIGN_TYPE;
      });
    };

    const setAvailableSignTypes = () => {
      availableSignTypes.value = allSignTypes.value.filter(
        (t) =>
          !structure.value?.T_Signing?.map((s) => s.SIGN_TYPE).includes(
            t.value.toString()
          )
      );
    };

    const getSignDetails = () => {
      expandedRows.value = [];
      if (!structure.value?.T_Signing?.[0]) {
        //remove null values from array(if any)
        structure.value.T_Signing = structure.value.T_Signing?.filter(
          (a) => !!a
        );
      }

      signs.value = clone(structure.value?.T_Signing);
      //set unique key for toggle expansion
      if (signs.value) {
        for (const element of signs.value) {
          element.UNIQUE_KEY = element.SIGN_TYPE;
          element.readOnly = true;
        }
      }

      sort(signs);
      setAvailableSignTypes();
    };
    getSignDetails();

    const closeDetail = () => {
      if (isEditable.value && changesDetected.value) {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (!response) {
              getSignDetails();
              expandedRows.value = [];
              inspectionStore.setAddingOrEditing(false);
              changesDetected.value = false;
              sortBy.value = previousSort.value?.map((x) => x);
            }
          });
      } else {
        getSignDetails();
        expandedRows.value = [];
        inspectionStore.setAddingOrEditing(false);
        sortBy.value = previousSort.value?.map((x) => x);
      }
    };

    const saveDetail = (detail) => {
      signDetailForm.value
        .validate()
        .then(() => {
          if (validDetails.value) {
            inspectionStore.setShowValidationSnackbar(false);
            if (detail.isAdd) {
              structure.value.T_Signing.push(
                convertToSignDetailsObject(detail)
              );
            } else {
              const updateIndex = structure.value.T_Signing.findIndex(
                (object) => {
                  return object.SIGN_TYPE === detail.SIGN_TYPE;
                }
              );
              structure.value.T_Signing.splice(
                updateIndex,
                1,
                convertToSignDetailsObject(detail)
              );
            }
            //if all required fields entered - close the expanded row
            expandedRows.value = [];
            getSignDetails();
            changesDetected.value = false;
            inspectionStore.setAddingOrEditing(false);
            sortBy.value = previousSort.value?.map((x) => x);
          } else {
            inspectionStore.setShowValidationSnackbar(true);
          }
        })
        .catch((err) => LOGGER.logException(err));
    };

    const convertToSignDetailsObject = (details) => {
      let signDetail = getNewSign();
      signDetail.BRKEY = details.BRKEY;
      signDetail.INSPKEY = details.INSPKEY;

      signDetail.SIGN_TYPE = details.SIGN_TYPE;
      signDetail.SIGN_MESSAGE = details.SIGN_MESSAGE;
      signDetail.NEAR_ADVANCE_COND = details.NEAR_ADVANCE_COND;
      signDetail.FAR_ADVANCE_COND = details.FAR_ADVANCE_COND;
      signDetail.NEAR_SITE_COND = details.NEAR_SITE_COND;
      signDetail.FAR_SITE_COND = details.FAR_SITE_COND;
      signDetail.USERKEY = details.USERKEY;
      signDetail.NOTES = details.NOTES;
      signDetail.SIGN_NEEDED = "1";

      return signDetail;
    };

    const editDetail = (detail) => {
      previousSort.value = sortBy.value.map((x) => x);
      inspectionStore.setAddingOrEditing(true);
      detail.readOnly = false;
      detail.isAdd = false;
      expandedRows.value.push(detail);
      changesDetected.value = false;
      sortBy.value = [];
      nextTick(() => {
        signDetailForm.value?.validate();
      });
    };

    const toggleRow = (detail) => {
      if (expandedRows.value.includes(detail)) {
        const index = expandedRows.value.findIndex(
          (d) => d.SIGN_TYPE == detail.SIGN_TYPE
        );
        expandedRows.value.splice(index, 1);
      } else {
        expandedRows.value.push(detail);
      }
    };

    const deleteDetail = (detail) => {
      const deleteIndex = structure.value.T_Signing.findIndex(
        (a) => a?.SIGN_TYPE === detail?.SIGN_TYPE
      );
      if (deleteIndex > -1) {
        structure.value.T_Signing.splice(deleteIndex, 1);
        expandedRows.value = [];
        getSignDetails();
      }
    };

    const rowClass = (detail) => {
      return expandedRows.value?.includes(detail) ? "rowSelect" : "rowUnselect";
    };

    const anySignDetailEditable = computed(() => {
      return expandedRows.value?.find((element) => !element.readOnly);
    });

    const addNewSign = () => {
      inspectionStore.setAddingOrEditing(true);
      let newSign = getNewSign();
      newSign.isAdd = true;
      newSign.BRKEY = structure.value.Bridge.BRKEY;
      newSign.INSPKEY = structure.value.InspEvnt.INSPKEY;
      newSign.UNIQUE_KEY = newSign.SIGN_TYPE;
      newSign.readOnly = false;
      signs.value.unshift(newSign);
      previousSort.value = sortBy.value.map((x) => x);
      sortBy.value = [];
      expandedRows.value = [newSign];
      scrollToDataTable("approach-data-table");

      changesDetected.value = false;
      nextTick(() => {
        signDetailForm.value.validate();
      });
    };

    function defaultEmptySafetyFeature() {
      if (!structure.value?.T_Safety_Feature) {
        safetyFeature.map((item) => {
          item.BRKEY = structure.value.Bridge.BRKEY;
          item.INSPKEY = structure.value.InspEvnt.INSPKEY;
          item.MODTIME = new Date();
          item.USERKEY = structure.value.UserInsp.USERKEY;
        });
        structure.value.T_Safety_Feature = safetyFeature;
      }
    }
    defaultEmptySafetyFeature();

    // watch for dirty flag
    watch(
      () => [
        structure.value.InspEvnt,
        structure.value.UserInsp,
        structure.value.T_Signing,
        structure.value.T_Insp_Comment,
        structure.value.UserBrdg.APPR_PAVEMENT_WIDTH,
        structure.value.T_Safety_Feature,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );
    // chunks of watch ends

    const checkValidation = (value) => {
      //add validation only when the expansion panel is expanded(value = true)
      if (value) {
        nextTick(() => {
          signDetailForm.value?.validate();
        });
      }
    };
    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    return {
      updateDuration,
      approachSlabUpdated,
      conditionUpdated,
      approachRailEndUpdated,
      approachGuideRailUpdated,
      approachTransitionUpdated,
      approachRailingUpdated,
      approachRoadwayUpdated,
      approachAlignmentUpdated,
      REFERENCE_TABLE,
      configStore,
      pavementReliefJointsPresent,
      commentsMaxLength,
      structure,
      getHistDuration,
      panel,
      isEditable,
      INSPECTION_COMMENTS,
      changesDetected,
      PAIR_CODE,
      ROUNDING_FACTOR,
      signs,
      validDetails,
      anySignDetailEditable,
      closeDetail,
      saveDetail,
      editDetail,
      deleteDetail,
      rowClass,
      addNewSign,
      expandedRows,
      signDetailForm,
      closeConfirm,
      BUTTONS,
      availableSignTypes,
      INSPECTION_MESSAGES,
      allSignTypes,
      getSignDetails,
      bridgeRailingDesc,
      transitionDesc,
      guiderailDesc,
      railEndDesc,
      inspectionStore,
      toggleRow,
      checkValidation,
      valid,
      modifyApproachValidation,
      toggleAllPanels,
      sortBy,
    };
  },
  components: {
    DataTableHeader,
    CommentTypeTextField,
    LabelSelect,
    LabelCheckbox,
    LabelText,
    IconTooltip,
    ConfirmDialog,
    ExpandCollapseToggle,
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/colors" as c;

h3 {
  order: 1;
  color: c.$p-white !important;
}

.v-expansion-panel-title__icon {
  order: 0;
}

.v-label.v-field-label {
  color: c.$p-black !important;
  opacity: 1 !important;
  font-weight: bold !important;
}
.v-input--density-default {
  --v-input-control-height: 25px !important;
}

.v-checkbox {
  flex: inherit !important;
}

.customLabelAlignment :deep(.v-label) {
  padding-top: 4px;
}

.signTable :deep(button:hover) {
  background-color: inherit !important;
  color: inherit !important;
}

.signTable :deep(button:focus) {
  outline: none !important;
}
.alignCenter {
  text-align: center;
}
.v-data-table :deep(button:hover) {
  background-color: inherit !important;
  color: inherit !important;
}

.v-data-table :deep(button:focus) {
  outline: none !important;
}
.tableHeader {
  text-align: center !important;
  background: c.$p-pa-light-blue !important;
}
</style>
