<template>
  <v-dialog v-model="dialog" :width="800">
    <v-card class="pa-3">
      <p class="h3 mb-6"><strong>&nbsp;</strong></p>

      <p class="mb-2">
        {{ message }}
      </p>
      <div class="d-flex justify-center">
        <v-btn id="confirmDialogSubmit" variant="outlined" @click="agree"
          >{{ okButtonText }}
        </v-btn>
        <v-btn variant="outlined" class="ml-4" @click="cancel">{{
          cancelButtonText
        }}</v-btn>
      </div>
      <button
        @click="close"
        class="d-flex align-end flex-column align-content-center close-button"
      >
        <v-icon class="no-outline" size="x-large" icon="fas fa-xmark mx-2" />
        <small>CLOSE</small>
      </button>
    </v-card>
  </v-dialog>
</template>

<script>
import { reactive, toRefs, nextTick } from "vue";
export default {
  name: "ConfirmDialog",
  props: {
    placeButtons: { default: "justify-end" },
    cancelButtonText: { default: "Cancel" },
  },

  setup() {
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      okButtonText: "OK",
    });

    const open = (message, okButtonText) => {
      state.dialog = true;
      state.message = message;
      state.okButtonText = okButtonText;
      nextTick(() => document.getElementById("confirmDialogSubmit")?.focus());
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };

    const agree = () => {
      state.resolve(true);
      state.dialog = false;
    };

    const cancel = () => {
      state.resolve(false);
      state.dialog = false;
    };

    const close = () => {
      state.resolve("close");
      state.dialog = false;
    };

    return {
      ...toRefs(state),
      open,
      agree,
      cancel,
      close,
    };
  },
};
</script>
