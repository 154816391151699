import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { styles } from "@/reports/reportStyles";

function setSignsLightsDocDefinition(structure) {
  const configStore = useConfigStore();
  let docDefinition = {
    header: function () {
      return getReportHeader("Signs & Lights Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Signs & Lights Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Sign & Light Conditions",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        columns: [
          {
            width: "25%",
            text: "IS10 Overall Condition Rating: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING,
              structure.T_Sign_Light_Insp.STRRATING
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        text: "Overall Sign & Light Notes: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) => element?.COMMENT_TYPE == INSPECTION_COMMENTS.OVERALL
        )?.NOTES,
      },

      {
        columns: [
          {
            width: "25%",
            text: "IS02 Foundation: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING,
              structure.T_Sign_Light_Insp.BASE_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "IS07 Lights: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING,
              structure.T_Sign_Light_Insp.LIGHT_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 5, 0, 0],
            width: "25%",
            text: "IS03 Guide Rail: ",
            bold: true,
          },
          {
            margin: [0, 5, 0, 0],
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING,
              structure.T_Sign_Light_Insp.GRAIL_COND_RATE
            ),
          },
          {
            margin: [0, 5, 0, 0],
            width: "25%",
            text: "IS08 Surface/Paint: ",
            bold: true,
          },
          {
            margin: [0, 5, 0, 0],
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING,
              structure.T_Sign_Light_Insp.SURFACE_COND_RATE
            ),
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 5, 0, 0],
            width: "25%",
            text: "IS04 Column: ",
            bold: true,
          },
          {
            margin: [0, 5, 0, 0],
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING,
              structure.T_Sign_Light_Insp.COLUMN_COND_RATE
            ),
          },
          {
            width: "25%",
            text: "IS09 Horiz. Member/Frame: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            margin: [0, 5, 0, 0],
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING,
              structure.T_Sign_Light_Insp.HOR_COND_RATE
            ),
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IS05 Method of Access: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            margin: [0, 5, 0, 0],
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING,
              structure.T_Sign_Light_Insp.ACCESS_COND_RATE
            ),
          },
          {
            width: "25%",
            text: "IS15 Sign Asset Tag Condition: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            margin: [0, 5, 0, 0],
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SIGN_ASSET_TAG,
              structure.T_Sign_Light_Insp.SIGN_ASSET_TAG
            ),
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IS06 Sign: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            margin: [0, 5, 0, 0],
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING,
              structure.T_Sign_Light_Insp.SIGN_COND_RATE
            ),
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Sign & Light Inspection Notes",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        text: "Foundation: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SIGN_COLUMN_BASE
        )?.NOTES,
      },
      {
        text: "Guide Rail: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SIGN_GUIDE_RAIL
        )?.NOTES,
      },
      {
        text: "Column: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) => element?.COMMENT_TYPE == INSPECTION_COMMENTS.SIGN_COLUMN
        )?.NOTES,
      },
      {
        text: "Method of Access: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SIGN_ACCESS_METH
        )?.NOTES,
      },
      {
        text: "Sign: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) => element?.COMMENT_TYPE == INSPECTION_COMMENTS.SIGN_SIGN
        )?.NOTES,
      },
      {
        text: "Lights: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) => element?.COMMENT_TYPE == INSPECTION_COMMENTS.SIGN_LIGHTS
        )?.NOTES,
      },
      {
        text: "Surface/Paint: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SIGN_SURFACE_PAINT
        )?.NOTES,
      },
      {
        text: "Horizontal Member/Frame: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SIGN_FRAMEWORK
        )?.NOTES,
      },
      {
        text: "Sign Asset Tags - Exist/Condition: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SIGN_ASSET_TAGS
        )?.NOTES,
      },
    ],
    styles: styles,

    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}

export { setSignsLightsDocDefinition };
