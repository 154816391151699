import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import {
  INSPECTION_COMMENTS,
  STRUCTURE_UNIT_TYPE,
} from "@/constants/Inspections";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { styles } from "@/reports/reportStyles";
import { clone } from "@/util/clone";
import { convertToInt } from "@/composables/util";
import { CUSTOM_ICONS } from "@/constants/Unicode";

function setJointsDocDefinition(structure) {
  const configStore = useConfigStore();
  const jointDetailsOne = [];
  jointDetailsOne.push(getJointsOneHeader());
  getJointOne(jointDetailsOne, structure);

  let docDefinition = {
    header: function () {
      return getReportHeader("Joints Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Joints Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Joint Conditions",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        columns: [
          {
            width: "30%",
            text: "IJ01 Overall Joint Condition Rating: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.JOINT_CONDITION_RATING,
              structure?.T_Joints?.[0]?.OVERALL_COND_RATING
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        text: "Overall Joint Notes: ",
        bold: true,
        margin: [0, 3, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.JOINTS_TINSP_COMMENTS
        )?.NOTES,
        margin: [0, 0, 0, 5],
      },
      {
        table: {
          widths: ["100%"],
          headerRows: 0,
          body: [
            ["Legend:"],
            [
              {
                table: {
                  headerRows: 0,
                  widths: ["25%", "25%", "25%", "25%"],
                  body: [
                    [
                      "IJ02 Joint Key",
                      "IJ05 Joint Location",
                      "IJ09 Debris Impaction?",
                      "IJ12 Covered?",
                    ],
                    [
                      "IJ03 Record Key",
                      "IJ06 Joint Movement",
                      "IJ10 Leaking?",
                      "IJ13 Condition Rating",
                    ],
                    [
                      "IJ04 Joint Type",
                      "IJ08 Joint Length (ft)",
                      "IJ11 Damage?",
                      "",
                    ],
                  ],
                },
                layout: "noBorders",
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 1 : 0;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 1 : 0;
          },
          hLineColor: function () {
            return "black";
          },
          vLineColor: function () {
            return "black";
          },
        },
        unbreakable: true,
      },
      {
        table: {
          headerRows: 1,
          widths: [
            "5%",
            "auto",
            "auto",
            "*",
            "5%",
            "auto",
            "auto",
            "auto",
            "auto",
            "auto",
          ],
          body: jointDetailsOne,
        },
        margin: [0, 5, 0, 0],
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}

function getJointsOneHeader() {
  return [
    {
      text: "IJ02-IJ03",
      style: ["tableHeader"],
    },
    {
      text: "IJ04",
      style: ["tableHeader"],
    },
    {
      text: "IJ05",
      style: ["tableHeader"],
    },
    {
      text: "IJ06",
      style: ["tableHeader"],
    },
    {
      text: "IJ08",
      style: ["tableHeader"],
    },
    {
      text: "IJ09",
      style: ["tableHeader"],
    },
    {
      text: "IJ10",
      style: ["tableHeader"],
    },
    {
      text: "IJ11",
      style: ["tableHeader"],
    },
    {
      text: "IJ12",
      style: ["tableHeader"],
    },
    {
      text: "IJ13",
      style: ["tableHeader"],
    },
  ];
}
function sort(arr) {
  return arr?.sort(function (a, b) {
    if (a?.JOINT_KEY == b?.JOINT_KEY) {
      return convertToInt(b?.RECORD_KEY) - convertToInt(a?.RECORD_KEY);
    } else {
      return a?.JOINT_KEY - b?.JOINT_KEY;
    }
  });
}
function cloneCurrentInspectionJoints(structure) {
  let jointsClone = clone(structure?.T_Joints_Details) || [];

  if (!jointsClone) {
    //remove null values from array(if any)
    jointsClone = jointsClone?.filter((a) => !!a);
  }

  const currentInspectionJointIndex = structure?.T_Joints?.findIndex(
    (a) => a?.INSPKEY == structure?.InspEvnt?.INSPKEY
  );

  jointsClone = jointsClone?.filter(
    (a) => a?.ID == structure?.T_Joints?.[currentInspectionJointIndex]?.ID
  );

  return jointsClone;
}
function getJointLocations() {
  const configStore = useConfigStore();
  const inspectionStore = useInspectionStore();
  return inspectionStore
    .getFilteredStructureUnits([STRUCTURE_UNIT_TYPE.APRAS_SPAN], false)
    ?.map((b) => ({
      title: b?.STRUNITDESCRIPTION
        ? `${b?.STRUNITDESCRIPTION}-${b?.STRUNITLABEL}`
        : `${configStore.getReferenceValue(
            REFERENCE_TABLE.STRUCTURE_UNIT_TYPE,
            b?.STRUNITTYPE,
            true
          )}-${b?.STRUNITLABEL}`,
      value: b?.STRUNITKEY.toString(),
    }))
    .sort((a, b) => a?.title?.localeCompare(b?.title));
}
function loadJoints(structure) {
  let joints = cloneCurrentInspectionJoints(structure);
  //set unique key for toggle expansion
  for (const element of joints) {
    element.UNIQUE_KEY = element?.JOINT_KEY + "-" + element?.RECORD_KEY;
  }
  return sort(joints);
}

function getJointOne(jointDetailsOne, structure) {
  const jointLocations = getJointLocations();
  const configStore = useConfigStore();
  const joints = loadJoints(structure) || [];
  for (const joint of joints) {
    jointDetailsOne.push([
      {
        text: joint?.JOINT_KEY + "-" + joint?.RECORD_KEY,
        style: ["tableBody"],
      },
      {
        text: configStore.getReferenceValue(
          REFERENCE_TABLE.JOINT_TYPE,
          joint?.JOINT_TYPE
        ),
        style: ["tableBody"],
      },
      {
        text: jointLocations?.find((a) => a.value === joint?.JOINT_LOCATION)
          ?.title,
        style: ["tableBody"],
      },
      {
        text: configStore.getReferenceValue(
          REFERENCE_TABLE.JOINT_MOVEMENT,
          joint?.JOINT_MOVEMENT
        ),
        style: ["tableBody"],
      },
      {
        text:
          joint?.JOINT_LENGTH || joint?.JOINT_LENGTH == 0
            ? parseInt(joint?.JOINT_LENGTH) + " ft"
            : "",
        style: ["tableBody"],
      },
      {
        text:
          joint?.DEBRIS_IMPACT == "1"
            ? CUSTOM_ICONS.SQUARE_CHECK
            : CUSTOM_ICONS.SQUARE,
        style: ["customFontAwesomeTableBody"],
      },
      {
        text:
          joint?.LEAKING == "1"
            ? CUSTOM_ICONS.SQUARE_CHECK
            : CUSTOM_ICONS.SQUARE,
        style: ["customFontAwesomeTableBody"],
      },
      {
        text:
          joint?.DAMAGE == "1"
            ? CUSTOM_ICONS.SQUARE_CHECK
            : CUSTOM_ICONS.SQUARE,
        style: ["customFontAwesomeTableBody"],
      },
      {
        text:
          joint?.COVERED == "1"
            ? CUSTOM_ICONS.SQUARE_CHECK
            : CUSTOM_ICONS.SQUARE,
        style: ["customFontAwesomeTableBody"],
      },
      {
        text: joint?.CONDITION_RATING,
        style: ["tableBody"],
      },
    ]);
    //Joint notes
    jointDetailsOne.push([
      {
        colSpan: "10",
        text: [
          { text: "IJ19 Joint Notes: ", bold: true },
          joint?.JOINT_COMMENT,
        ],
      },
    ]);
  }
}

export { setJointsDocDefinition };
