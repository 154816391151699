import useDateField from "@/composables/dateField.js";
import { convertToFloat } from "@/composables/util";

const { checkValidDate, getFormattedDateStringNoTime } = useDateField();
export const requiredRule = (value) => {
  if (!value || value.length == 0) {
    return "Required";
  }

  return true;
};

export const secondCharacterCountRule = (value) => {
  if (value && value.length < 50) {
    return value.length + " of 50 Characters Required";
  }
  return true;
};

export const greaterThanZeroRule = (value) =>
  (parseFloat(value?.replaceAll(",", "")) || 0) >= 0 || "Cannot be less than 0";

export const lessThanOneRule = (value) =>
  (parseFloat(value?.replaceAll(",", "")) || 0) <= 1 ||
  "Cannot be greater than 1";

export const lessThanValueRule = (value, maxValue) => {
  if (typeof value === "string") {
    value = value?.toString().replaceAll(",", "");
  }
  if (parseFloat(value) > maxValue) {
    return `Cannot be greater than ${maxValue}`;
  } else {
    return true;
  }
};

export const validDateFormatRule = (value) => {
  if (value && !checkValidDate(value)) {
    return "Date must follow format MM/DD/YYYY";
  } else {
    return true;
  }
};
export const validDateRangeRule = (value, max, min) => {
  if (
    value &&
    (new Date(value) > new Date(max) || new Date(value) < new Date(min))
  ) {
    return `Valid range ${getFormattedDateStringNoTime(
      min
    )} to ${getFormattedDateStringNoTime(max)}`;
  } else {
    return true;
  }
};

export const valueInRangeRule = (value, minValue, maxValue) => {
  if (value) {
    if (typeof value === "string") {
      value = value?.toString().replaceAll(",", "");
    }
    const floatValue = parseFloat(value) || 0;

    if (floatValue > maxValue || floatValue < minValue) {
      return `Valid range  ${minValue} to ${maxValue}`;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const valueLengthInRangeRule = (value, minValue) => {
  if (value.length < minValue) {
    return `Must be at least ${minValue} characters`;
  } else {
    return true;
  }
};

export const valueCannotBeRule = (value, restrictedValue) => {
  if (value == restrictedValue) {
    return `Cannot be ${restrictedValue}`;
  } else {
    return true;
  }
};

export const shouldbeEqual = (value, matchValue) => {
  if (typeof value === "string") {
    value = value?.toString().replaceAll(",", "");
  }
  if (convertToFloat(value, 0) != matchValue) {
    return `must be ${matchValue}`;
  } else {
    return true;
  }
};
