import { useConfigStore } from "@/stores/config";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { METRIC_CONVERSION_TYPES } from "@/constants/InspectionConstants";
import { CUSTOM_ICONS } from "@/constants/Unicode";

export default function getFeaturesWaterwayData(detail) {
  const configStore = useConfigStore();

  return {
    table: {
      headerRows: 0,
      widths: ["24%", "26%", "26%", "*"],
      body: [
        [
          { text: "5C03 On/Under: ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.ON_UNDER,
              detail?.ON_UNDER
            ),
          },
          { text: "6C34 Feature Type: ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.FEATURE_TYPE,
              detail?.FEATURE_TYPE
            ),
          },
        ],
        [
          { text: "FW01 Stream Name: ", bold: true },
          {
            text: detail?.ROADWAY_NAME,
          },
          {
            text: "FW10 Primary Waterway: ",
            bold: true,
          },
          {
            text:
              detail?.PRIMARY_WATERWAY == "1"
                ? CUSTOM_ICONS.SQUARE_CHECK
                : CUSTOM_ICONS.SQUARE,
            style: "customFontAwesome",
          },
        ],
        [
          { text: "FW02 Stream Class 1: ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.STREAM_CLASSIFICATION,
              detail?.DEP_CLASS_1
            ),
          },
          { text: "FW11 Vert Clr: ", bold: true },
          {
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                detail?.MIN_OVER_VERT_CLEAR_RIGHT,
                ROUNDING_FACTOR.TENTH,
                null,
                [METRIC_CONVERSION_TYPES.L3]
              ) + " ft",
          },
        ],
        [
          { text: "FW03 Stream Class 2: ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.STREAM_CLASSIFICATION,
              detail?.DEP_CLASS_2
            ),
          },
          { text: "FW12 Max W.S. Elev: ", bold: true },
          {
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                detail?.MAX_WATER_SURF_ELEV,
                ROUNDING_FACTOR.TENTH
              ) + " ft",
          },
        ],
        [
          { text: "FW04 Timeframe: ", bold: true },
          {
            text: detail?.DEP_TIMEFRAME,
          },
          { text: "FW13 Max W.S. Elev Year: ", bold: true },
          {
            text: detail?.MAX_WATER_SURF_YEAR,
          },
        ],
        [
          { text: "FW05 Stream Class 3: ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.STREAM_CLASSIFICATION,
              detail?.DEP_CLASS_3
            ),
          },
          { text: "FW14 Design Flood Magn: ", bold: true },
          {
            text:
              configStore.getReferenceValue(
                PAIR_CODE.CUBICMETERSPERSECOND_TO_CUBICFEETPERSECOND,
                detail?.DESIGN_FLOOD_MAGNITUDE
              ) + " cf/s",
          },
        ],
        [
          { text: "FW06 Permit Type: ", bold: true },
          {
            text: detail?.DEP_PERMIT_TYPE,
          },
          { text: "FW15 Design Flood Elev: ", bold: true },
          {
            text:
              configStore.getReferenceValue(
                PAIR_CODE.METERS_TO_FEET,
                detail?.DESIGN_FLOOD_ELEV,
                ROUNDING_FACTOR.TENTH
              ) + " ft",
          },
        ],
        [
          { text: "FW07 Drainage Area: ", bold: true },
          {
            text:
              configStore.getReferenceValue(
                PAIR_CODE.SQUAREKILOMETERS_TO_SQUAREMILES,
                detail?.STREAM_DRAIN_AREA,
                ROUNDING_FACTOR.TENTH
              ) + " sq mi.",
          },
          { text: "FW16 Design Flood Freq: ", bold: true },
          {
            text: detail?.DESIGN_FLOOD_FREQ + " yrs",
          },
        ],
        [
          {
            text: "FW08 Fishable: ",
            bold: true,
          },
          {
            text:
              detail?.FISHABLE == "1"
                ? CUSTOM_ICONS.SQUARE_CHECK
                : CUSTOM_ICONS.SQUARE,
            style: "customFontAwesome",
          },
          {
            text: "FW17 Design Flood Velocity: ",
            bold: true,
          },
          {
            text:
              configStore.getReferenceValue(
                PAIR_CODE.METERSPERSECOND_TO_FEETPERSECOND,
                detail?.DESIGN_FLOOD_VEL,
                ROUNDING_FACTOR.TENTH
              ) + " fps",
          },
        ],
        [
          { text: "FW09 Waterflow Direction: ", bold: true },
          {
            colSpan: 3,
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.WATERFLOW_DIRECTION,
              detail?.WATERFLOW_DIR
            ),
          },
        ],
        [
          { text: "FW18 Pollutant Descr: ", bold: true },
          {
            colSpan: 3,
            text: detail?.POLLUTANT_DESC,
            alignment: "left",
          },
        ],
        [
          { text: "FW19 Stream Restrict Descr: ", bold: true },
          {
            colSpan: 3,
            text: detail?.STREAM_RESTRICT_DESC,
            alignment: "left",
          },
        ],
        [
          { text: "FW20 Notes: ", bold: true },
          {
            colSpan: 3,
            text: detail?.NOTES,
            alignment: "left",
          },
        ],
      ],
    },
    layout: {
      hLineWidth: function (i, node) {
        return i === 0 || i === node.table.body.length ? 2 : 0;
      },
      vLineWidth: function (i, node) {
        return i === 0 || i === node.table.widths.length ? 1 : 0;
      },
      hLineColor: function () {
        return "black";
      },
      vLineColor: function () {
        return "gray";
      },
    },
    unbreakable: true,
    margin: [10, 0, 10, 0],
  };
}
