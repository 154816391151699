import { defineStore } from "pinia";
import { LOGGER } from "@/util/logger";
import {
  INSPECTION_ENDPOINT,
  SUBMIT_PDF_REPORT_ENDPOINT,
} from "@/constants/Endpoints";
import axios from "axios";
import { INSPECTION_STATUSES } from "@/constants/InspectionStatuses";
import { useStructureSearchStore } from "@/stores/structureSearch";
import { HTTP_STATUS_CODE } from "@/constants/CommonWebCodes";
import { RECORD_MANAGEMENT_TYPES } from "@/constants/StructureSearches";
import {
  INSPECTION_PAGES,
  BRIDGE_PAGES,
  WALL_PAGES,
  SIGN_PAGES,
  TUNNEL_PAGES,
  EDOCS_PAGES,
  STATUSES_CAN_SUBMIT,
} from "@/constants/Inspections";
import { clone } from "@/util/clone";
import { CUSTOM_ICONS } from "@/constants/Unicode";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { useConfigStore } from "@/stores/config";

export const useInspectionStore = defineStore("inspection", {
  state: () => ({
    inspections: {},
    selectedInspection: {},
    isDirty: false,
    page: INSPECTION_PAGES.GENERAL,
    nextPage: "",
    nextRoute: "",
    unsavedChangesWarning: false,
    abortController: null,
    addingOrEditing: false,
    latestInspKey: "",
    userAuthenticationErrors: {},
    featureIntersectionTableData: [],
    inspectionArray: [],
    submissionErrors: new Map(),
    overallScourHolesChangedByUser: false,
    pageReference: null,
    showCalculate: false,
    savingSnackbar: false,
    saved: false,
    showSubmitLaterMessage: false,
    reportToSubmit: null,
    seismicVulnerabilityChangedByUser: false,
    nstmInspectionConditionChangedByUser: false,
    showValidationSnackbar: false,
  }),
  getters: {
    getSelectedInspection() {
      return this.selectedInspection;
    },
    isEditable() {
      if (this.latestInspKey == this.selectedInspection?.InspEvnt?.INSPKEY) {
        return Boolean(
          STATUSES_CAN_SUBMIT.includes(
            this.selectedInspection?.InspEvnt?.INSPSTAT
          )
        );
      } else {
        return false;
      }
    },
    getDirtyFlag() {
      return this.isDirty;
    },
    getShowValidationSnackbar() {
      return this.showValidationSnackbar;
    },
    getNstmInspectionConditionChangedByUser() {
      return this.nstmInspectionConditionChangedByUser;
    },
    getSeismicVulnerabilityChangedByUser() {
      return this.seismicVulnerabilityChangedByUser;
    },
    getShowCalculate() {
      return this.showCalculate;
    },
    getOverallScourHolesChangedByUser() {
      return this.overallScourHolesChangedByUser;
    },
    getReportToSubmit() {
      return this.reportToSubmit;
    },
    getSavingSnackbar() {
      return this.savingSnackbar;
    },
    getSaved() {
      return this.saved;
    },
    getActiveNavigation() {
      const configStore = useConfigStore();

      const activeInspPages = configStore
        .getEnvConfigValue(ENV_CONFIG_PROPERTY.ACTIVE_INSPECTION_PAGES)
        .split(",");

      return this.getNavigation.filter((p) => activeInspPages.includes(p));
    },
    getNavigation() {
      let navigationReturn = null;
      switch (this.selectedInspection?.Bridge?.SERVTYPON) {
        case "0":
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
        case "X":
          navigationReturn = BRIDGE_PAGES;
          break;
        case "H":
        case "S":
          navigationReturn = SIGN_PAGES;
          break;
        case "N":
        case "R":
          navigationReturn = WALL_PAGES;
          break;
        case "T":
          navigationReturn = TUNNEL_PAGES;
          break;
      }
      if (
        this.selectedInspection?.recordType ===
        RECORD_MANAGEMENT_TYPES.DOCUMENTS
      ) {
        navigationReturn = EDOCS_PAGES;
      }
      return navigationReturn;
    },
    getUserAuthenticationErrors() {
      return this.userAuthenticationErrors;
    },
    getSelectedPage() {
      return this.page;
    },
    getPageReference() {
      return this.pageReference;
    },
    getNextPage() {
      return this.nextPage;
    },
    getNextRoute() {
      return this.nextRoute;
    },
    getUnsavedChangesWarning() {
      return this.unsavedChangesWarning;
    },
    getInspectionArray() {
      return this.inspectionArray;
    },
    getAddingOrEditing() {
      return this.addingOrEditing;
    },
    getSubStructureUnitKey: (state) => (structureType) => {
      return state.selectedInspection?.Structure_Unit?.find(
        (unit) => unit.STRUNITLABEL == structureType
      )?.STRUNITKEY;
    },
    getUnderwaterInspForPier: (state) => (StructureUnitKey) => {
      return state.selectedInspection?.T_Underwater_Insp?.find(
        (unit) => unit.STRUNITKEY == StructureUnitKey
      );
    },
    getPiers: (state) => () => {
      let returnValue = state.selectedInspection?.Structure_Unit?.filter(
        (unit) => unit.STRUNITTYPE == "P"
      );
      returnValue?.sort(function (a, b) {
        let splitA = a.STRUNITLABEL.split("P");
        let splitB = b.STRUNITLABEL.split("P");
        let aPlaceholder;
        let bPlaceholder;
        if (splitA[1].charAt(0) == "0") {
          aPlaceholder = parseInt(splitA[1].charAt(1));
        } else {
          aPlaceholder = parseInt(splitA[1]);
        }
        if (splitB[1].charAt(0) == "0") {
          bPlaceholder = parseInt(splitB[1].charAt(1));
        } else {
          bPlaceholder = parseInt(splitB[1]);
        }
        return aPlaceholder - bPlaceholder;
      });

      return returnValue || [];
    },
    getSubUnits: (state) => () => {
      let units = state.selectedInspection?.Structure_Unit?.filter((unit) => {
        if (
          unit.STRUNITTYPE == "P" ||
          unit.STRUNITTYPE == "B" ||
          unit.STRUNITTYPE == "C"
        ) {
          return true;
        }
      });
      let returnObject = [];
      for (let unit of units) {
        returnObject.push({
          value: unit.STRUNITKEY,
          title: `${unit.STRUNITKEY} - ${unit.STRUNITLABEL}`,
        });
      }
      return returnObject;
    },
    getAllSubUnits: (state) => () => {
      let returnValue = [];
      state.selectedInspection?.Structure_Unit?.forEach((unit) => {
        returnValue.push({
          value: unit.STRUNITKEY,
          title: `${unit.STRUNITKEY} - ${unit.STRUNITLABEL}`,
        });
      });
      return returnValue;
    },
    getStructureUnitLabel: (state) => (unitKey) => {
      return state.selectedInspection?.Structure_Unit?.find(
        (unit) => unit.STRUNITKEY == unitKey
      )?.STRUNITLABEL;
    },
    getSubStructure: (state) => (unitKey) => {
      return state.selectedInspection?.Structure_Unit?.find(
        (unit) => unit.STRUNITKEY == unitKey
      );
    },
    getIDsNotInUnderwaterInsp: (state) => () => {
      let idsNotInUnderwater = [];

      for (let idx in state.selectedInspection.Structure_Unit) {
        if (
          !state.selectedInspection?.T_Underwater_Insp?.find(
            (unit) =>
              state.selectedInspection.Structure_Unit[idx].STRUNITKEY ==
              unit.STRUNITKEY
          ) &&
          (state.selectedInspection.Structure_Unit[idx].STRUNITTYPE == "B" ||
            state.selectedInspection.Structure_Unit[idx].STRUNITTYPE == "P" ||
            state.selectedInspection.Structure_Unit[idx].STRUNITTYPE == "C")
        ) {
          idsNotInUnderwater.push({
            value: state.selectedInspection.Structure_Unit[idx].STRUNITKEY,
            title: state.selectedInspection.Structure_Unit[idx].STRUNITLABEL,
          });
        }
      }

      return idsNotInUnderwater;
    },
    getStructureUnitFromLabel: (state) => (unitLabel) => {
      return state.selectedInspection?.Structure_Unit?.find(
        (unit) => unit.STRUNITLABEL == unitLabel
      );
    },
    getFilteredStructureUnits: (state) => (unitTypes, toInclude) => {
      if (toInclude) {
        return state.selectedInspection?.Structure_Unit?.filter((unit) =>
          unitTypes.includes(unit.STRUNITTYPE)
        );
      } else {
        //exclude the unit types
        return state.selectedInspection?.Structure_Unit?.filter(
          (unit) => !unitTypes.includes(unit.STRUNITTYPE)
        );
      }
    },
    getFeatureIntersectionTableData: (state) => () => {
      state.featureIntersectionTableData = [];

      for (let i = 0; i < state.selectedInspection?.Roadway?.length; i++) {
        state.featureIntersectionTableData.push({
          rowId: i + 1,
          ...state.selectedInspection?.Roadway[i],
          ...state.selectedInspection?.UserRWay.find(
            (itmInner) =>
              itmInner.ON_UNDER ===
              state.selectedInspection?.Roadway[i].ON_UNDER
          ),
        });
      }
      return state.featureIntersectionTableData;
    },
    getFeatureIntersectionSelectedRow: (state) => (rowId) => {
      return state.featureIntersectionTableData?.find(
        (item) => item.rowId == rowId
      );
    },
    getsubmissionErrors: () => () => {
      return this.submissionErrors;
    },
    getShowSubmitLaterMessage: () => () => {
      return this.showSubmitLaterMessage;
    },
    getBrKey: (state) => {
      return state.selectedInspection.Bridge.BRKEY;
    },
  },
  actions: {
    setDuration(duration, durationChanges) {
      if (durationChanges == 1) {
        duration = 0.0;
      }
      return duration;
    },
    setDurationChanges(durationChanges = 0) {
      if (durationChanges > 0) {
        durationChanges = durationChanges + 1;
      } else {
        durationChanges = 1;
      }
      return durationChanges;
    },
    getRatingMin(ratings) {
      const validRating = (rating) => rating !== "_" && !!rating;
      const isN = (rating) => rating === "N";
      return ratings.sort((a, b) => {
        if (validRating(a) && !validRating(b)) {
          return 1;
        } else if (!validRating(a) && validRating(b)) {
          return -1;
        } else if (!validRating(a) && !validRating(b)) {
          return 0;
        } else if (isN(a) && !isN(b)) {
          return 1;
        } else if (!isN(a) && isN(b)) {
          return -1;
        } else if (isN(a) && isN(b)) {
          return 0;
        } else {
          return parseInt(a) - parseInt(b);
        }
      });
    },
    async getLowestConditionRating() {
      if (
        this.selectedInspection?.Bridge?.SERVTYPON >= 0 &&
        this.selectedInspection?.Bridge?.SERVTYPON <= 9
      ) {
        this.selectedInspection.UserInsp.LOWEST_CONDITION = this.getRatingMin([
          this.selectedInspection.InspEvnt.DKRATING,
          this.selectedInspection.InspEvnt.SUBRATING,
          this.selectedInspection.InspEvnt.CULVRATING,
          this.selectedInspection.InspEvnt.SUPRATING,
        ])[0];
      } else {
        this.selectedInspection.UserInsp.LOWEST_CONDITION = "N";
        this.selectedInspection.InspEvnt.CONDITION = "N";
      }
    },
    async setSelectedInspection(inspection) {
      this.selectedInspection = inspection;
    },
    setLatestInspKey(inspkey) {
      this.latestInspKey = inspkey;
    },
    setInspectionArray(inspectionArray) {
      this.inspectionArray = inspectionArray;
    },
    setAddingOrEditing(value) {
      this.addingOrEditing = value;
    },
    setPageReference(value) {
      this.pageReference = value;
    },
    setNstmInspectionConditionChangedByUser(value) {
      this.nstmInspectionConditionChangedByUser = value;
    },
    setSeismicVulnerabilityChangedByUser(value) {
      this.seismicVulnerabilityChangedByUser = value;
    },
    async loadInspection(brkey, inspkey) {
      const result = await this.getInspection(brkey, inspkey);
      this.setSelectedInspection(result);
      await this.updateKeyFields(brkey);
      this.isDirty = false;
    },
    async getInspection(brkey, inspkey) {
      let url = INSPECTION_ENDPOINT.LOAD_INSPECTION;
      url = url.replace("{brkey}", brkey).replace("{inspkey}", inspkey);
      const insp = await axios.get(url).catch((e) => {
        let error = Object.assign(e, {
          method: "getInspection",
          brkey,
          inspkey,
        });
        LOGGER.logException(error);
      });
      return insp?.data?.data;
    },
    async getInspectionKeyFields(brkey, inspkey) {
      if (brkey && inspkey) {
        let url = INSPECTION_ENDPOINT.GET_INSP_KEYFIELDS;
        url = url.replace("{brkey}", brkey).replace("{inspkey}", inspkey);
        const insp = await axios.get(url).catch((e) => {
          let error = Object.assign(e, {
            method: "getInspectionKeyFields",
            brkey,
            inspkey,
          });
          LOGGER.logException(error);
        });
        return insp?.data?.data;
      }
      return {};
    },
    async updateKeyFields(brkey) {
      if (this.inspections[brkey]) {
        const selectedIndex = this.inspections[brkey]?.findIndex(
          (a) => a.inspectionId == this.selectedInspection?.InspEvnt?.INSPKEY
        );
        let inspections = clone(this.inspections[brkey]);
        let prevAcceptedInspkey = inspections
          .splice(selectedIndex + 1)
          .find(
            (a) => a.inspectionStatus == INSPECTION_STATUSES.ACCEPTED
          )?.inspectionId;
        this.selectedInspection.keyFields = await this.getInspectionKeyFields(
          brkey,
          prevAcceptedInspkey
        );
      }
    },
    async loadLatestInspection(brkey, userName, password, abortController) {
      const structureSearchStore = useStructureSearchStore();
      //if there is atleast one with 401=Unauthorized - cancel subsequent requests
      if (
        structureSearchStore?.userAuthenticationErrors[
          HTTP_STATUS_CODE.NOT_AUTHORIZED
        ] &&
        structureSearchStore?.userAuthenticationErrors[
          HTTP_STATUS_CODE.NOT_AUTHORIZED
        ]?.length > 0
      ) {
        abortController.abort();
      }

      let url = INSPECTION_ENDPOINT.LOAD_LATEST_INSPECTION;
      url = url.replace("{brkey}", brkey);
      await axios
        .get(url, {
          headers: {
            userid: userName,
            password: password,
          },
          signal: abortController.signal,
        })
        .then(async (result) => {
          const currentInspKey = result.data.data.InspEvnt.INSPKEY;
          result.data.data.T_Bearings = result.data.data.T_Bearings.filter(
            (a) => a.INSPKEY == currentInspKey
          );
          result.data.data.T_Joints = result.data.data.T_Joints.filter(
            (a) => a.INSPKEY == currentInspKey
          );
          result.data.data.T_Bearings_Details =
            result.data.data.T_Bearings_Details.filter(
              (a) =>
                result.data.data.T_Bearings.findIndex((d) => a.ID == d.ID) != -1
            );
          result.data.data.T_Joints_Details =
            result.data.data.T_Joints_Details.filter(
              (a) =>
                result.data.data.T_Joints.findIndex((d) => a.ID == d.ID) != -1
            );
          this.selectedInspection = result.data.data;
          structureSearchStore.isUserAuthenticated = true;
        })
        .then(async () => {
          await this.getInspections(brkey);
          await this.updateKeyFields(brkey);
        })
        .catch((e) => {
          if (e?.response?.status && e.response.status != 200) {
            if (
              structureSearchStore.userAuthenticationErrors[e.response.status]
            ) {
              structureSearchStore.userAuthenticationErrors[
                e.response.status
              ].push(brkey);
            } else {
              structureSearchStore.userAuthenticationErrors[e.response.status] =
                [brkey];
            }
          }

          let error = Object.assign(e, {
            method: "loadLatestInspection",
            brkey,
          });
          LOGGER.logException(error);
        });
    },
    cleanObject(object) {
      Object.entries(object).forEach(([k, v]) => {
        if (v && typeof v === "object") this.cleanObject(v);
        if (
          (v && typeof v === "object" && !Object.keys(v).length) ||
          v === null ||
          v === undefined ||
          v.length === 0
        ) {
          if (Array.isArray(object)) object.splice(+k, 1);
          else if (!(v instanceof Date)) delete object[k];
        }
      });
      return object;
    },
    getReportCheckbox(value) {
      if (value === "1") {
        return CUSTOM_ICONS.SQUARE_CHECK;
      } else {
        return CUSTOM_ICONS.SQUARE;
      }
    },
    getPrevOverallScourHoles(prevAcceptedInsp) {
      if (!prevAcceptedInsp?.scourCondRating) {
        if (prevAcceptedInsp?.overallScourHoles?.[0] != null) {
          let overallScourHoles = prevAcceptedInsp?.overallScourHoles?.[0];
          for (let idx in prevAcceptedInsp?.overallScourHoles) {
            if (
              parseInt(prevAcceptedInsp?.overallScourHoles?.[idx]) <
              parseInt(overallScourHoles)
            ) {
              overallScourHoles = prevAcceptedInsp?.overallScourHoles?.[idx];
            }
          }
          return overallScourHoles;
        } else {
          return "";
        }
      } else {
        return prevAcceptedInsp?.scourCondRating;
      }
    },
    getOverallScourHoles(structure) {
      if (
        !structure?.UserInsp?.SCOUR_COND_RATING &&
        !this.overallScourHolesChangedByUser
      ) {
        if (structure?.T_Underwater_Insp?.[0] != null) {
          let overallScourHoles = structure?.T_Underwater_Insp?.[0]?.SCOUR_HOLE;
          for (let idx in structure?.T_Underwater_Insp) {
            if (
              parseInt(structure?.T_Underwater_Insp?.[idx]?.SCOUR_HOLE) <
              parseInt(overallScourHoles)
            ) {
              overallScourHoles =
                structure?.T_Underwater_Insp?.[idx]?.SCOUR_HOLE;
            }
          }
          return overallScourHoles;
        } else {
          return "";
        }
      } else {
        return structure?.UserInsp?.SCOUR_COND_RATING;
      }
    },
    getCommentIndex(structureUnit, commentType) {
      return this.selectedInspection?.T_Struc_Unit_Insp_Comment.findIndex(
        (element) =>
          element?.STRUNITKEY == structureUnit?.STRUNITKEY &&
          element?.COMMENT_TYPE == commentType
      );
    },
    prepareObjectForSubmission(inspection) {
      const keysToInclude = [
        "Bridge",
        "Insp_WCand",
        "InspEvnt",
        "Pon_Elem_Insp",
        "Roadway",
        "T_Bearings",
        "T_Bearings_Details",
        "T_FC_Insp",
        "T_Insp_Comment",
        "T_Insp_Defect",
        "T_Joints",
        "T_Joints_Details",
        "T_Safety_Feature",
        "T_Scour_CMeasure",
        "T_Scour_Comp",
        "T_Scour_Poss_CMeasure",
        "T_Sign_Light",
        "T_Sign_Light_Insp",
        "T_Signing",
        "T_Struc_Unit_Insp_Comment",
        "T_Underwater_Insp",
        "T_UserInsp_WCand",
        "T_UW_Underclear",
        "T_Wall",
        "T_Wall_Insp",
        "UserBrdg",
        "UserInsp",
        "UserRWay",
        "Users",
        "Snbi_Insp_Event",
        "Snbi_Insp_Schedule",
      ];

      const submissionInspection = keysToInclude.reduce((obj, key) => {
        if (key in inspection) {
          if (key === "Snbi_Insp_Schedule") {
            //only the first element from the schedule array needs to be submitted
            obj[key] = [inspection[key][0]];
          } else {
            obj[key] = inspection[key];
          }
        }
        return obj;
      }, {});
      return submissionInspection;
    },
    async submitInspection(inspection, userName, password, isValidate) {
      LOGGER.logEvent(
        LOGGER.EventAction.Submit,
        LOGGER.EventCat.Button,
        "Inspection",
        inspection
      );
      this.submissionErrors.set(inspection.Bridge.BRKEY, null);
      inspection = this.cleanObject(
        this.prepareObjectForSubmission(inspection)
      );
      let endpointURL = isValidate
        ? INSPECTION_ENDPOINT.VALIDATE_INSPECTION
        : INSPECTION_ENDPOINT.SUBMIT_INSPECTION;
      await axios
        .post(endpointURL, inspection, {
          headers: {
            userid: userName,
            password: password,
          },
        })
        .then((response) => {
          if (response?.data?.data?.validateMessages?.length > 0) {
            this.setSubmissionErrors(
              inspection.Bridge.BRKEY,
              response?.data?.data?.validateMessages
            );
          }
          return response;
        })
        .catch((e) => {
          let error = Object.assign(e, {
            method: isValidate ? "submitInspection" : "validateInspection",
            inspection,
          });
          LOGGER.logException(error);
          if (
            e?.response?.status &&
            e.response.status == HTTP_STATUS_CODE.NOT_AUTHORIZED
          ) {
            this.userAuthenticationErrors[e.response.status] = [
              inspection.Bridge.BRKEY,
            ];
          } else if (
            e?.response?.status &&
            e.response.status != HTTP_STATUS_CODE.SUCCESS
          ) {
            if (this.submissionErrors.get(inspection.Bridge.BRKEY)) {
              this.submissionErrors
                .get(inspection.Bridge.BRKEY)
                .push(e.response.status);
            } else {
              this.submissionErrors.set(inspection.Bridge.BRKEY, [
                e.response.status,
              ]);
            }
          } else {
            throw e;
          }
        });
    },
    async submitReport(inspection, file, userName, password) {
      LOGGER.logEvent(
        LOGGER.EventAction.SubmitReport,
        LOGGER.EventCat.Button,
        "Report",
        file.name
      );
      const formData = new FormData();
      let url = SUBMIT_PDF_REPORT_ENDPOINT;
      url = url.replace("{brkey}", inspection.Bridge.BRKEY);
      url = url.replace("{inspkey}", inspection.InspEvnt.INSPKEY);
      url = url.replace("{doclabel}", file.name);
      formData.append("file", file);
      await axios
        .post(url, formData, {
          headers: {
            userid: userName,
            password: password,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.setSubmissionErrors(inspection.Bridge.BRKEY, [response]);
        })
        .catch((e) => {
          let error = Object.assign(e, {
            method: "submitReport",
            file,
          });
          LOGGER.logException(error);
          if (
            e?.response?.status &&
            e.response.status == HTTP_STATUS_CODE.NOT_AUTHORIZED
          ) {
            this.userAuthenticationErrors[e.response.status] = [
              inspection.Bridge.BRKEY,
            ];
          } else if (
            e?.response?.status &&
            e.response.status != HTTP_STATUS_CODE.SUCCESS
          ) {
            this.submissionErrors.set(inspection.Bridge.BRKEY, [
              e.response.status,
            ]);
          }
        });
    },
    setSubmissionErrors(brkey, validationMsgs) {
      if (this.submissionErrors.get(brkey)) {
        this.submissionErrors.get(brkey).concat(validationMsgs);
      } else {
        this.submissionErrors.set(brkey, validationMsgs);
      }
    },
    setDirtyFlag(value) {
      this.isDirty = value;
    },
    setShowValidationSnackbar(value) {
      this.showValidationSnackbar = value;
    },
    setShowCalculate(value) {
      this.showCalculate = value;
    },
    setSavingSnackbar(value) {
      this.savingSnackbar = value;
    },
    setSaved(value) {
      this.saved = value;
    },
    setSelectedPage(value) {
      this.page = value;
    },
    setNextPage(value) {
      this.nextPage = value;
    },
    setNextRoute(value) {
      this.nextRoute = value;
    },
    setOverallScourHolesChangedByUser(value) {
      this.overallScourHolesChangedByUser = value;
    },
    setUnsavedChangesWarning(value) {
      this.unsavedChangesWarning = value;
    },
    setUserAuthenticationErrors(value) {
      this.userAuthenticationErrors = value;
    },
    setReportToSubmit(value) {
      this.reportToSubmit = value;
    },
    async getInspections(brkey) {
      let url = INSPECTION_ENDPOINT.GET_INSPECTIONS;
      url = url.replace("{brkey}", brkey);
      await axios
        .get(url)
        .then((result) => {
          this.inspections[brkey] = result.data?.data?.sort(
            (a, b) => new Date(b.inspectionDate) - new Date(a.inspectionDate)
          );
        })
        .catch((e) => {
          let error = Object.assign(e, {
            method: "getInspections",
            brkey,
          });
          LOGGER.logException(error);
        });
    },
    saveFeatureInspectionDetails() {
      this.featureIntersectionTableData.forEach((m, i) => {
        Object.keys(m).forEach((k) => {
          this.selectedInspection?.Roadway.map((r, j) => {
            Object.keys(r).forEach((key) => {
              if (key === k && i === j) {
                r[(j, key)] = m[(j, k)];
              }
            });
          });
          this.selectedInspection?.UserRWay.map((u, j) => {
            Object.keys(u).forEach((key) => {
              if (key === k && i === j) {
                u[(j, key)] = m[(i, k)];
              }
            });
          });
        });
      });
    },
    setShowSubmitLaterMessage(value) {
      this.showSubmitLaterMessage = value;
    },
  },
});
