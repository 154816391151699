export default () => {
  function getStructureType(serviceTypeOn) {
    if (
      serviceTypeOn == "0" ||
      serviceTypeOn == "1" ||
      serviceTypeOn == "2" ||
      serviceTypeOn == "3" ||
      serviceTypeOn == "4" ||
      serviceTypeOn == "5" ||
      serviceTypeOn == "6" ||
      serviceTypeOn == "7" ||
      serviceTypeOn == "8" ||
      serviceTypeOn == "9" ||
      serviceTypeOn == "X"
    ) {
      return "B";
    } else if (serviceTypeOn == "N" || serviceTypeOn == "R") {
      return "W";
    } else if (serviceTypeOn == "S" || serviceTypeOn == "H") {
      return "S";
    } else {
      return serviceTypeOn;
    }
  }

  return { getStructureType };
};
