const objTWalls = {
  BRKEY: "",
  ARCH_FORMS: "",
  BACKFILL_MATERIAL1: "",
  BACKFILL_MATERIAL2: "",
  BACKFILL_SLOPE: null,
  BEGIN_COUNTY: "",
  BEGIN_OFFSET: "",
  BEGIN_ROUTENUM: "",
  BEGIN_SEG_NUM: "",
  END_COUNTY: "",
  END_OFFSET: "",
  END_ROUTENUM: "",
  END_SEG_NUM: "",
  FCI: null,
  FOUND_TYPE: "",
  HIST_ELIG: "",
  INSTALL_ROADWAY_TYPE: "",
  MANUFACTURER: "",
  MAX_HEIGHT: null,
  MIN_CLEARANCE: null,
  MIN_HEIGHT: null,
  MODTIME: "",
  MOUNT_TYPE: "",
  POST_TYPE: "",
  REBAR_TYPE: "",
  ROAD_DISTANCE: null,
  ROAD_SIDE: "",
  SUPPORT_DESC: "",
  USERKEY: "",
  WALL_SURFACE_AREA: null,
  WALL_USE: "",
};

const objTWallsInsp = {
  BRKEY: "",
  INSPKEY: "",
  ANCHORAGE_COND_RATE: "",
  BACKFILL_COND_RATE: "",
  DRAINAGE_COND_RATE: "",
  FOUND_COND_RATE: "",
  MODTIME: "",
  NEXT_WALL_INSPTYPE: "",
  PANEL_COND_RATE: "",
  PARAPETS_COND_RATE: "",
  POST_COND_RATE: "",
  STRRATING: "",
  USERKEY: "",
  WALL_COND_RATE: "",
  WALL_INSPTYPE: "",
};

export { objTWalls, objTWallsInsp };
