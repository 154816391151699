<template>
  <v-banner class="align-center error" v-if="!isOfflinePasswordSet">
    <v-banner-text>
      Setup offline password in your User Profile, before downloading
      structures.
    </v-banner-text>
  </v-banner>
  <v-container fluid>
    <h3 class="withBlueBackground px-2 py-1">Structures</h3>

    <h3 class="withBackground px-2 py-1">Search to Download</h3>

    <v-col sm="12">
      <div style="font-size: 16px">
        Select one of the options below to search for structures
      </div>
    </v-col>
    <v-row>
      <v-col sm="12">
        <v-radio-group
          id="radio_structureSearch"
          aria-describedby="radio_structureSearch"
          v-model="searchBy"
          inline
          hide-details
          mandatory
        >
          <v-radio
            id="radio_structureSearchBrKey"
            color="primary"
            :value="STRUCTURE_SEARCH.BRKEY"
            class="mr-5"
          >
            <template v-slot:label>
              <div class="mt-2">BRKEY</div>
            </template>
          </v-radio>
          <v-radio
            id="radio_structureSearchBmsId"
            color="primary"
            :value="STRUCTURE_SEARCH.BMSID"
            class="mr-5"
          >
            <template v-slot:label>
              <div class="mt-2">BMS ID</div>
            </template>
          </v-radio>
          <v-radio
            id="radio_structureSearchUploadBrKey"
            color="primary"
            :value="STRUCTURE_SEARCH.UPLOAD_BRKEY"
            class="mr-5"
          >
            <template v-slot:label>
              <div class="mt-2">Upload BRKEY List</div>
            </template>
          </v-radio>
          <v-radio
            id="radio_structureSearchParamSearch"
            color="primary"
            :value="STRUCTURE_SEARCH.PARAMETER_SEARCH"
            class="mr-5"
          >
            <template v-slot:label>
              <div class="mt-2">Search By Parameters</div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-dialog
      v-model="message.show"
      :width="message.type === 'warning-dialog' ? 800 : 400"
    >
      <v-card class="pa-3" :class="message.type">
        <p class="h3 mb-6"><strong>&nbsp;</strong></p>
        <p class="mb-2">{{ message.value }}</p>

        <v-table
          v-if="message.type === 'warning-dialog'"
          class="message-table-responsive"
        >
          <thead>
            <tr>
              <th id="missingResults" class="text-left">
                {{ message.header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in message?.missingResults" :key="item">
              <td>{{ item }}</td>
            </tr>
          </tbody>
        </v-table>

        <button
          @click="hideMsg"
          class="d-flex align-end flex-column align-content-center close-button"
        >
          <v-icon size="x-large" icon="fas fa-xmark mx-2" />
          <small>CLOSE</small>
        </button>
      </v-card>
    </v-dialog>

    <div v-if="searchBy === STRUCTURE_SEARCH.BRKEY">
      <BRKEY @show-message="showMsg" />
    </div>
    <div v-if="searchBy === STRUCTURE_SEARCH.BMSID">
      <BMSID @show-message="showMsg" />
    </div>
    <div v-if="searchBy === STRUCTURE_SEARCH.UPLOAD_BRKEY">
      <UploadBRKeyList @show-message="showMsg" />
    </div>
    <div v-if="searchBy === STRUCTURE_SEARCH.PARAMETER_SEARCH">
      <SearchByParameters @show-message="showMsg" />
    </div>
    <StructureSearchResults
      ref="searchResultsTable"
      :isOfflinePasswordSet="isOfflinePasswordSet"
    />
  </v-container>
</template>

<script>
import BRKEY from "@/components/structureSearch/BRKEY.vue";
import BMSID from "@/components/structureSearch/BMSID.vue";
import UploadBRKeyList from "@/components/structureSearch/UploadBRKeyList.vue";
import StructureSearchResults from "@/components/structureSearch/StructureSearchResults.vue";
import SearchByParameters from "@/components/structureSearch/SearchByParameters.vue";
import { STRUCTURE_SEARCH } from "@/constants/StructureSearches";
import { onMounted, computed, ref } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { useConfigStore } from "@/stores/config";

export default {
  components: {
    BRKEY,
    BMSID,
    UploadBRKeyList,
    StructureSearchResults,
    SearchByParameters,
  },
  name: "StructureSearch",
  data: () => ({
    searchBy: STRUCTURE_SEARCH.BRKEY,
    STRUCTURE_SEARCH,
    message: { value: null, type: null, keys: null, show: false },
  }),
  setup() {
    const inspectionStore = useInspectionStore();
    const configStore = useConfigStore();
    let isOfflinePasswordSet = ref(true);
    let offlinePassword = ref(localStorage.getItem("hashedPass"));

    onMounted(async () => {
      await inspectionStore.setUnsavedChangesWarning(false);
      await inspectionStore.setDirtyFlag(false);
      offlinePassword.value = localStorage.getItem("hashedPass");
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!offlinePassword.value) {
            offlinePassword.value = localStorage.getItem("hashedPass");
          } else {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
      isOfflinePasswordSet.value =
        offlinePassword.value !== getDefaultPass.value;
    });

    const getDefaultPass = computed(() =>
      configStore.getEnvConfigValue(ENV_CONFIG_PROPERTY.OFFLINE_DEFAULT_PASS)
    );

    return { isOfflinePasswordSet };
  },
  methods: {
    showMsg(message) {
      this.message = message;
      this.message.show = message?.show;
      this.resetResultsTableSort();
      this.resetResultsTableSelections();
    },
    hideMsg() {
      this.message.show = false;
    },
    resetResultsTableSort() {
      this.$refs.searchResultsTable.resetSort();
    },
    resetResultsTableSelections() {
      this.$refs.searchResultsTable.resetSelections();
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/colors" as c;
@use "@/styles/general" as g;

.message-table-responsive {
  width: 100%;
  max-width: 700px;
  overflow: auto;
  margin: 0 auto;
}

@media #{g.$p-breakPointXsAndDown} {
  .message-table-responsive {
    max-width: 300px;
  }
}
</style>
