<template>
  <v-row>
    <ExpandCollapseToggle
      id="subStructPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panel"
    />
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel class="mt-5">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
          <h3>Substructure Conditions</h3>
        </v-expansion-panel-title>

        <v-expansion-panel-text eager>
          <v-form ref="subConditions" v-model="validSubConditions">
            <LabelSelect
              id="substructureConditionAndRating"
              showDurationIcon
              :tooltip="
                getHistDuration(
                  structure?.T_Insp_Condition_Hist?.SUB_DURATION,
                  structure?.T_Insp_Condition_Hist?.SUB_DURATION_CHANGES
                )
              "
              @update:modelValue="
                {
                  substructureConditionUpdated = true;
                  conditionUpdated = true;
                }
              "
              label="1A02 Substructure Condition Rating"
              :refTable="REFERENCE_TABLE.CONDITION_RATING"
              :isEditable="isEditable"
              isRequired
              v-model="structure.InspEvnt.SUBRATING"
            ></LabelSelect>

            <CommentTypeTextField
              label="Substructure Notes"
              id="field_substructure_Notes"
              :inspectionCommentType="INSPECTION_COMMENTS.SUBSTRUCTURE"
              :readonly="!isEditable"
              :counter="commentsMaxLength"
              :maxlength="commentsMaxLength"
              :singleLine="false"
            />
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <AbutmentComponent
      ref="abutmentNearRef"
      id="near_Abutment_Component"
      abutmentType="Near"
      :unitKey="nearAbutmentSubstructureUnitKey"
      :isExpanded="isExpanded"
    />
    <AbutmentComponent
      ref="abutmentFarRef"
      id="far_Abutment_Component"
      abutmentType="Far"
      :unitKey="farAbutmentSubstructureUnitKey"
      :isExpanded="isExpanded"
    />
    <v-expansion-panels v-model="pierPanel" multiple color="#fff">
      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!pierPanel.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="pierPanel.includes(0)" icon="fa-solid fa-minus" />
          <h3>Piers</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row align="center">
            <v-col>
              <v-label
                class="noRecordsMessage d-flex justify-center"
                v-if="!piers || piers?.length == 0"
                >This structure currently contains no Piers.
              </v-label>
            </v-col>
          </v-row>
          <v-row class="pa-0">
            <v-col>
              <div v-for="pier in piers" :key="pier">
                <v-lazy :min-height="39" :options="{ threshold: 0.1 }">
                  <v-row>
                    <v-col class="pa-0">
                      <PiersSubstructure :pier="pier" />
                    </v-col>
                  </v-row>
                </v-lazy>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>
<script>
import { computed, ref, watch } from "vue";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import {
  INSPECTION_COMMENTS,
  SUB_STRUCTURE_UNIT_KEY,
} from "@/constants/Inspections";
import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import LabelSelect from "@/components/common/LabelSelect.vue";
import AbutmentComponent from "@/components/inspection/substructureComponents/AbutmentComponent.vue";
import PiersSubstructure from "@/components/inspection/substructureComponents/PiersSubstructure.vue";
import { getHistDuration } from "@/composables/util";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";

export default {
  name: "InspectionSubstructure",
  setup() {
    //Common Code

    let abutmentNearRef = ref(null);

    let abutmentFarRef = ref(null);

    let valid = ref(true);

    let validSubConditions = ref(true);

    let subConditions = ref(null);

    //pier refs broken

    const configStore = useConfigStore();

    const inspectionStore = useInspectionStore();
    const isExpanded = ref(true);

    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });

    let structure = computed(() => inspectionStore.selectedInspection);

    let piers = computed(() => inspectionStore.getPiers());

    const nearAbutmentSubstructureUnitKey = computed(() =>
      inspectionStore.getSubStructureUnitKey(
        SUB_STRUCTURE_UNIT_KEY.NEAR_ABUTMENT
      )
    );
    const farAbutmentSubstructureUnitKey = computed(() =>
      inspectionStore.getSubStructureUnitKey(
        SUB_STRUCTURE_UNIT_KEY.FAR_ABUTMENT
      )
    );

    let substructureConditionUpdated = ref(false);
    let conditionUpdated = ref(false);

    const updateDuration = () => {
      if (!structure.value.T_Insp_Condition_Hist) {
        structure.value.T_Insp_Condition_Hist = {};
      }
      if (substructureConditionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.SUB_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.SUB_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.SUB_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.SUB_DURATION,
            structure.value.T_Insp_Condition_Hist.SUB_DURATION_CHANGES
          );
      }
      substructureConditionUpdated.value = false;
      conditionUpdated.value = false;
    };

    const allPanels = [0];
    const panel = ref(allPanels);

    const pierPanel = ref([]);
    if (piers.value?.length > 0) {
      pierPanel.value = [0];
    }

    const commentsMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });
    function validateUnderwaterInsp(structureUnit, subType) {
      let valid = true;
      if (subType == "abutment") {
        structureUnit = structure.value.Structure_Unit?.find(
          (unit) => unit.STRUNITKEY == structureUnit
        );
      }
      if (structureUnit?.IN_500YR_FP == "1") {
        let uwInsp = structure.value?.T_Underwater_Insp?.find(
          (element) => element?.STRUNITKEY == structureUnit.STRUNITKEY
        );
        const properties = [
          "OBS_SCOUR_RATING",
          "CHG_SINCE_LAST_INSP",
          "SCOUR_HOLE",
          "DEBRIS_POTENTIAL",
          "SUB_SCOUR",
          "OPEN_ADEQ_CHANNEL",
          "SED_DEPOSIT",
          "ALIGNMENT",
          "VELO_STREAM_SLOPE",
          "SUBUNIT_TYPE",
          "INV_FOUND_TYPE",
          "STREAM_BED_MATERIAL",
          "UNDERWATER_INSP_TYPE",
          "OBS_SCOUR_DEPTH",
          "MAX_WATER_DEPTH",
        ];
        for (let prop of properties) {
          if (
            uwInsp?.[prop] == null ||
            uwInsp?.[prop].toString().trim() === ""
          ) {
            valid = false;
            break;
          }
        }
      }
      return valid;
    }
    async function validate() {
      let pierValidArray = [];
      let pierValid = false;
      let nearValid = false;
      let farValid = false;
      subConditions.value.validate();
      if (nearAbutmentSubstructureUnitKey.value) {
        nearValid = validateUnderwaterInsp(
          nearAbutmentSubstructureUnitKey.value,
          "abutment"
        );
      } else {
        nearValid = true;
      }
      if (farAbutmentSubstructureUnitKey.value) {
        farValid = validateUnderwaterInsp(
          farAbutmentSubstructureUnitKey.value,
          "abutment"
        );
      } else {
        farValid = true;
      }

      if (piers.value.length > 0) {
        for (const pier of piers.value) {
          pierValidArray.push(validateUnderwaterInsp(pier, "pier"));
        }
        if (!pierValidArray.includes(false)) {
          pierValid = true;
        }
      } else {
        pierValid = true;
      }
      valid.value =
        pierValid && farValid && nearValid && validSubConditions.value;
    }

    const toggleAllPanels = (expandAll) => {
      isExpanded.value = expandAll;
      if (expandAll) {
        panel.value = allPanels;
        if (piers.value.length > 0) {
          pierPanel.value = [0];
        }
      } else {
        panel.value = [];
        pierPanel.value = [];
      }
    };

    //watch
    watch(
      () => [
        structure.value.T_FC_Insp,
        structure.value.InspEvnt,
        structure.value.T_Insp_Comment,
        structure.value.T_Struc_Unit_Insp_Comment,
        structure.value.T_Underwater_Insp,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      conditionUpdated,
      substructureConditionUpdated,
      updateDuration,
      validSubConditions,
      subConditions,
      valid,
      nearAbutmentSubstructureUnitKey,
      farAbutmentSubstructureUnitKey,
      validate,
      abutmentNearRef,
      abutmentFarRef,
      pierPanel,
      piers,
      commentsMaxLength,
      structure,
      REFERENCE_TABLE,
      getHistDuration,
      panel,
      inspectionStore,
      isEditable,
      INSPECTION_COMMENTS,
      SUB_STRUCTURE_UNIT_KEY,
      isExpanded,
      toggleAllPanels,
    };
  },
  components: {
    CommentTypeTextField,
    LabelSelect,
    AbutmentComponent,
    PiersSubstructure,
    ExpandCollapseToggle,
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/colors" as c;

h3 {
  order: 1;
  color: c.$p-white !important;
}

.v-expansion-panel-title__icon {
  order: 0;
}

.tableHeader {
  text-align: center !important;
  background: c.$p-pa-light-blue !important;
}

.v-label.v-field-label {
  color: c.$p-black !important;
  opacity: 1 !important;
  font-weight: bold !important;
}
</style>
