import { defineStore } from "pinia";
import defaultStructure from "@/data/structure";
import axios from "axios";
import { STRUCTURE_ENDPOINT, DOCUMENT_ENDPOINT } from "@/constants/Endpoints";
import { LOGGER } from "@/util/logger";
import { useStructureIDBStore } from "@/stores/structureIDB";
import { useInspectionStore } from "@/stores/inspection";
import {
  CONFIG_SORT_BY,
  ENV_CONFIG_PROPERTY,
} from "@/constants/EnvConfigProperties";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import mime from "mime";
import { RECORD_MANAGEMENT_TYPES } from "@/constants/StructureSearches";
import { DOWNLOAD_PROGRESS, EDOC_ERROR_DESC } from "@/constants/Inspections";
import {
  getEDocDownloadErrorDesc,
  getDefaultValue,
  scrubCharFromStart,
} from "@/composables/util";
import { HTTP_STATUS_CODE } from "@/constants/CommonWebCodes";
import { RESPONSE_ERROR_MESSAGE } from "@/constants/CommonWebConstants";
import rateLimit from "axios-rate-limit";
import auth from "@/auth";

export const useStructureSearchStore = defineStore("structureSearch", {
  state: () => ({
    structureSearchResults: [],
    selectedStructures: [],
    structuresToBeDownloadedSize: 0,
    isSearching: false,
    routesResult: [],
    contractsResult: [],
    userAuthenticationErrors: {},
    searchParams: { page: 0, size: 75, sort: "brkey,ASC" },
    searchCounts: {},
    searchBy: "",
    eDocs: { completedPct: 0, total: 0 },
  }),
  getters: {
    getStructureSearchResults() {
      return this.structureSearchResults;
    },
    getSelectedStructures() {
      return this.selectedStructures;
    },
    getIsSearching() {
      return this.isSearching;
    },
    getRoutes() {
      return this.routesResult;
    },
    getContracts() {
      return this.contractsResult;
    },
  },
  actions: {
    async getStructuresByBrKey(criteria) {
      LOGGER.logEvent(
        LOGGER.EventAction.Search,
        LOGGER.EventCat.Button,
        "BRKEY",
        criteria
      );
      this.structureSearchResults = [];
      this.searchCounts.totalElements = 0;
      this.searchParams = {};
      this.searchParams.brkeys = criteria.join();
      this.searchParams.sort = "brkey,ASC";
      this.searchBy = "getStructuresByBrKey";
      await this.getData();
    },
    async getStructuresByBmsId(criteria) {
      LOGGER.logEvent(
        LOGGER.EventAction.Search,
        LOGGER.EventCat.Button,
        "BMSID",
        criteria
      );
      this.structureSearchResults = [];
      this.searchCounts.totalElements = 0;
      this.searchParams = {};
      this.searchParams.bmsIds = criteria.join();
      this.searchParams.sort = "brkey,ASC";
      this.searchBy = "getStructuresByBmsId";
      await this.getData();
    },
    modifyGetStructureResult(result) {
      result.forEach((structureSearchResult) => {
        let cloneStructure = Object.assign(
          Object.create(defaultStructure),
          structureSearchResult
        );
        this.structureSearchResults.push(cloneStructure);
      });
    },
    getMissingResults(newData, cleanedBrkey) {
      return cleanedBrkey.filter(function (e) {
        return !newData.includes(e.trim());
      });
    },
    resetSearchResults() {
      this.structureSearchResults = [];
      this.searchCounts.totalElements = 0;
    },
    resetSelectedStructures() {
      this.selectedStructures = [];
      this.structuresToBeDownloadedSize = 0;
    },
    setIsSearching(value) {
      this.isSearching = value;
    },
    pushToSelectedStructures(structure) {
      this.selectedStructures.push(structure);
    },
    async checkIfInLocalStructures() {
      const structureIDBStore = useStructureIDBStore();
      await structureIDBStore.initDB(false);
      if (structureIDBStore.getLocalStructures.length == 0) {
        structureIDBStore.getStructuresFromIDB();
      }
      this.structuresToBeDownloadedSize = 0;
      this.selectedStructures.forEach((structure) => {
        let returnedStructure = null;
        try {
          //For if structure by itself or structure and edocs are in idb
          returnedStructure = structureIDBStore.getLocalStructures.find(
            (s) => s.Bridge.BRKEY == structure.brkey
          );
        } catch (e) {
          let error = Object.assign(e, {
            method: "checkIfInLocalStructures",
            structure,
          });
          LOGGER.logException(error);
          //For only edocs are in idb for a BRKEY
          returnedStructure = structureIDBStore.getLocalStructures.find(
            (s) => s.brKey == structure.brkey
          );
        }
        if (!returnedStructure) {
          this.structuresToBeDownloadedSize =
            this.structuresToBeDownloadedSize + 1;
        }
      });
    },
    getEDocs(
      structure,
      inspDetails,
      username,
      password,
      categories,
      abortController,
      http
    ) {
      const configStore = useConfigStore();
      const docTypeKeys = configStore.getDefaultDownloadDocKeys(categories);

      const docContainerSeqNums = inspDetails.T_Doc_Container?.filter((c) =>
        docTypeKeys.includes(c?.DOC_TYPE_KEY)
      ).map((s) => s.DOC_CONTAINER_SEQ_NUM);
      const docs = inspDetails.T_Edms_Doc?.filter((doc) =>
        docContainerSeqNums.includes(doc?.DOC_CONTAINER_SEQ_NUM)
      );
      return this.buildDownloadPromises(
        docs,
        structure.brkey,
        username,
        password,
        abortController,
        http
      );
    },
    addInterceptor(http) {
      http.interceptors.request.use(async (config) => {
        const user = await auth.getUser();
        config.headers.Authorization = `Bearer ${user.id_token}`;
        return config;
      });
    },
    buildDownloadPromises(
      docs,
      brkey,
      username,
      password,
      abortController,
      http
    ) {
      this.addInterceptor(http);
      const promises = docs?.map(async (doc) => {
        return http.get(DOCUMENT_ENDPOINT + `/${doc.EDMS_DOC_ID}`, {
          params: {
            brkey: brkey,
            repository: doc.EDMS_CS_REPOSITORY,
            documentVersion: doc.EDMS_DOC_VERSION,
          },
          headers: {
            userid: username,
            password: password,
            docId: doc.EDMS_DOC_ID,
            docContainer: doc.DOC_CONTAINER_SEQ_NUM,
          },
          signal: abortController.signal,
        });
      });
      return promises;
    },

    getDocuments(
      structure,
      inspDetails,
      username,
      password,
      abortController,
      http
    ) {
      let categories = [];
      if (structure.inspection) {
        categories.push("Inspection");
      }
      if (structure.inventory) {
        categories.push("Inventory");
      }
      if (structure.other) {
        categories.push("Other");
      }
      return this.getEDocs(
        structure,
        inspDetails,
        username,
        password,
        categories,
        abortController,
        http
      );
    },

    getPayload(structure, selectedInspection) {
      let payload = null;
      if (structure.isDocumentsOnly()) {
        payload = {
          brKey: structure.brkey,
          agreementContractNumber: structure.agreementContractNumber,
          bmsId: structure.bmsId,
          district: {
            districtName: structure.district.districtName,
            districtNum: structure.district.districtNum,
          },
          featuresIntersected: structure.featuresIntersected,
          inspectionDate: structure.inspectionDate,
          inspectionStatus: structure.inspectionStatus,
          reportingGroup: structure.reportingGroup,
          T_Edms_Doc: selectedInspection.T_Edms_Doc,
          T_Doc_Container: selectedInspection.T_Doc_Container,
          Bridge: selectedInspection.Bridge,
          recordType: RECORD_MANAGEMENT_TYPES.DOCUMENTS,
          UserBrdg: selectedInspection.UserBrdg,
          Structure_Unit: selectedInspection.Structure_Unit,
          UserStrUnit: selectedInspection.UserStrUnit,
          InspEvnt: selectedInspection.InspEvnt,
          EDocs: [],
        };
      } else if (structure.isStructureOnly()) {
        payload = {
          ...selectedInspection,
          recordType: RECORD_MANAGEMENT_TYPES.INSPECTION,
          EDocs: [],
        };
      } else {
        payload = {
          ...selectedInspection,
          recordType: RECORD_MANAGEMENT_TYPES.BOTH,
          EDocs: [],
        };
      }
      return payload;
    },

    async retryDownloadDocs(
      retryDocIds,
      username,
      password,
      inspDetails,
      structure,
      http
    ) {
      let retryDocs;
      if (retryDocIds?.length > 0) {
        //get doc object from docid
        retryDocs = inspDetails.T_Edms_Doc?.filter((doc) =>
          retryDocIds.includes(doc?.EDMS_DOC_ID)
        );
      }
      if (retryDocs?.length > 0) {
        let abortController = new AbortController();
        await auth.silentLogin();

        const promises = this.buildDownloadPromises(
          retryDocs,
          inspDetails.Bridge.BRKEY,
          username,
          password,
          abortController,
          http
        );
        await this.callDocDownloadService(
          promises,
          inspDetails,
          structure,
          [],
          abortController,
          true
        );
      }
    },

    async download(structure, username, password) {
      LOGGER.logEvent(
        LOGGER.EventAction.Download,
        LOGGER.EventCat.Button,
        "Structure",
        structure
      );
      let abortController = new AbortController();
      username = window.btoa(username);
      password = window.btoa(password);
      const inspectionStore = useInspectionStore();
      await inspectionStore.loadLatestInspection(
        structure.brkey,
        username,
        password,
        abortController
      );

      let selectedInspection = inspectionStore.getSelectedInspection || {};
      //Save to IDB only when selected inspection is retrieved from api
      let payload = this.getPayload(structure, selectedInspection);
      const structureIDBStore = useStructureIDBStore();
      const configStore = useConfigStore();

      if (!structure.isStructureOnly()) {
        const maxRequests = parseInt(
          configStore.getEnvConfigValue(
            ENV_CONFIG_PROPERTY.EDOC_SERVICE_MAX_REQUESTS
          )
        );
        const http = rateLimit(axios.create(), {
          maxRequests: maxRequests,
          perMilliseconds: parseInt(
            configStore.getEnvConfigValue(
              ENV_CONFIG_PROPERTY.EDOC_SERVICE_PER_MILLISECONDS
            )
          ),
          maxRPS: maxRequests,
        });
        payload._attachments = {};
        const promises = this.getDocuments(
          structure,
          payload,
          username,
          password,
          abortController,
          http
        );
        let failedDocsToRetry = [];
        await this.callDocDownloadService(
          promises,
          payload,
          structure,
          failedDocsToRetry,
          abortController
        );
        if (failedDocsToRetry?.length > 0) {
          await this.retryDownloadDocs(
            failedDocsToRetry,
            username,
            password,
            payload,
            structure,
            http
          );
        }
      } else {
        payload.downloadDate = new Date();
        structureIDBStore.saveStructure(payload).catch((e) => {
          let error = Object.assign(e, {
            method: "saveStructure",
            structure,
          });
          LOGGER.logException(error);
        });
      }

      //if user tries to download multiple times and if it failed the second time,
      //the previous value is in the selected inspection and hence clearing it
      inspectionStore.setSelectedInspection(null);
    },
    checkTokenExpiry(e, failedDocsToRetry, remaining, abortController) {
      //Contains Invalid JWT.
      const statusCode = e.response?.data?.statusCode;
      const message = e.response?.data?.message;
      const docId = e?.response?.config?.headers.docId;

      if (
        statusCode == HTTP_STATUS_CODE.NOT_AUTHORIZED &&
        message?.includes(RESPONSE_ERROR_MESSAGE.INVALID_JWT) &&
        docId
      ) {
        //the error is because of access token expiry
        //store the failed calls for retrying with new token
        failedDocsToRetry.push(docId);
      } else if (e?.code == "ERR_CANCELED") {
        const id = e?.config?.headers.docId;
        if (id) {
          failedDocsToRetry.push(id);
        }
      } else {
        remaining--;
      }

      if (statusCode === HTTP_STATUS_CODE.NOT_AUTHORIZED) {
        abortController.abort("Unauthorized");
      }
    },
    async callDocDownloadService(
      promises,
      payload,
      structure,
      failedDocsToRetry,
      abortController,
      retry
    ) {
      let remaining;
      if (!retry) {
        this.eDocs.total = getDefaultValue(promises?.length, 0);
      }
      remaining = getDefaultValue(promises?.length, 0);
      const structureIDBStore = useStructureIDBStore();
      await structureIDBStore.initDB(false);

      const instance = this;
      // get documents
      await Promise.allSettled(
        promises?.map((p) =>
          p
            .then((result) => {
              if (instance.eDocs.total == 0 && remaining == 0) return 0;
              remaining--;
              instance.eDocs.completedPct =
                ((instance.eDocs.total - remaining) / instance.eDocs.total) *
                100;
              return result;
            })
            .catch((e) => {
              LOGGER.logException(e);
              if (instance.eDocs.total == 0 && remaining == 0) return 0;
              instance.eDocs.completedPct =
                ((instance.eDocs.total - remaining) / instance.eDocs.total) *
                100;
              instance.checkTokenExpiry(
                e,
                failedDocsToRetry,
                remaining,
                abortController
              );
              throw e;
            })
        )
      )
        .then((responses) => {
          responses.forEach((r) => {
            const docContainerseqNum =
              r.value?.config.headers.docContainer ||
              r.reason?.config.headers.docContainer;
            const docId =
              r.value?.config.headers.docId || r.reason?.config.headers.docId;
            const downloadStatus = this.getDownloadStatus(r, payload);
            payload.EDocs.push({
              docId: docId,
              docContainerSeqNum: docContainerseqNum,
              download: downloadStatus?.download,
              error: downloadStatus?.error,
            });
          });

          payload.downloadDate = new Date();
          structureIDBStore.saveStructure(payload).catch((e) => {
            let error = Object.assign(e, {
              method: "saveStructure",
              structure,
            });
            LOGGER.logException(error);
          });
          if (!failedDocsToRetry || failedDocsToRetry.length == 0) {
            instance.eDocs.completedPct = 0;
            instance.eDocs.total = 0;
          }
        })
        .catch((e) => {
          let error = Object.assign(e, {
            method: "eDocDownload",
            structure,
          });
          LOGGER.logException(error);
        });
    },
    getDownloadStatus(r, payload) {
      if (r.status === "fulfilled" && r.value?.data?.data?.content) {
        let attachmentName =
          scrubCharFromStart(r.value.data.data.filename, "_") +
          "^" +
          r.value.config.headers.docId;
        let attachment = {
          content_type: mime.getType(r.value.data.data.filename),
          data: r.value.data.data.content,
        };
        payload._attachments[attachmentName] = attachment;
        return { download: DOWNLOAD_PROGRESS.SUCCESS };
      } else if (r.status === "fulfilled" && !r.value?.data?.data?.content) {
        return {
          download: DOWNLOAD_PROGRESS.FAIL,
          error: EDOC_ERROR_DESC.NO_CONTENT,
        };
      } else {
        return {
          download: DOWNLOAD_PROGRESS.FAIL,
          error: getEDocDownloadErrorDesc(r?.reason?.response?.status),
        };
      }
    },
    getRouteNumber(counties) {
      let params = {};
      if (counties.length > 0) params.counties = counties.join();
      return axios
        .get(STRUCTURE_ENDPOINT.GET_ROUTES, {
          params,
        })
        .then((response) => {
          return response.data.data;
        })
        .catch((e) => {
          let error = Object.assign(e, {
            method: "getRouteNumber",
            counties,
          });
          LOGGER.logException(error);
        });
    },
    getAgreementContractNumber(counties) {
      let params = {};
      if (counties.length > 0) params.counties = counties.join();
      return axios
        .get(STRUCTURE_ENDPOINT.GET_AGREEMENT_CONTRACTS, {
          params,
        })
        .then((response) => {
          return response.data.data;
        })
        .catch((e) => {
          let error = Object.assign(e, {
            method: "getAgreementContractNumber",
            counties,
          });
          LOGGER.logException(error);
        });
    },
    getReportingGroupByCounty(counties) {
      let params = {};
      const configStore = useConfigStore();
      let reportGroups = configStore.getReferenceValues(
        REFERENCE_TABLE.REPORTING_GROUP,
        CONFIG_SORT_BY.VALUE
      );
      if (counties.length > 0) params.counties = counties.join();
      return axios
        .get(STRUCTURE_ENDPOINT.GET_REPORT_GROUP_BY_COUNTY, {
          params,
        })
        .then((response) => {
          return reportGroups.filter((r) => {
            return response.data.data?.includes(r.value);
          });
        })
        .catch((e) => {
          let error = Object.assign(e, {
            method: "getReportingGroupByCounty",
            counties,
          });
          LOGGER.logException(error);
          return reportGroups;
        });
    },
    async getStructureSearchByParameters(
      districts,
      counties,
      municipalities,
      routeNumbers,
      agreementContractNumbers,
      reportGroups
    ) {
      this.searchParams = {};
      if (districts.length > 0) this.searchParams.districts = districts.join();
      if (counties.length > 0) this.searchParams.counties = counties.join();
      if (municipalities.length > 0)
        this.searchParams.municipalities = municipalities.join();
      if (routeNumbers.length > 0)
        this.searchParams.routeNumbers = routeNumbers.join();
      if (agreementContractNumbers.length > 0)
        this.searchParams.agreementContractNumbers =
          agreementContractNumbers.join();
      if (reportGroups.length > 0)
        this.searchParams.reportGroups = reportGroups.join();
      this.searchParams.sort = "brkey,ASC";
      this.searchBy = "getStructureSearchByParameters";
      await this.getData();
    },

    async getData() {
      let endpoint;
      if (this.searchBy === "getStructureSearchByParameters") {
        endpoint = STRUCTURE_ENDPOINT.SEARCH_BY_PARAMETER;
      } else if (this.searchBy === "getStructuresByBrKey") {
        endpoint = STRUCTURE_ENDPOINT.SEARCH_BY_BRKEY;
      } else if (this.searchBy === "getStructuresByBmsId") {
        endpoint = STRUCTURE_ENDPOINT.SEARCH_BY_BMSID;
      }
      this.isSearching = true;
      this.structureSearchResults = [];
      await axios
        .get(endpoint, {
          params: { ...this.searchParams },
        })
        .then((result) => {
          this.searchCounts.totalElements = result.data.totalElements;
          this.modifyGetStructureResult(result.data.data);
        })
        .catch((e) => {
          let error = Object.assign(e, {
            method: this.searchBy,
            ...this.searchParams,
          });
          LOGGER.logException(error);
        });
      this.isSearching = false;
    },
  },
});
