/**
 * Clones an object into a new instance
 * @param obj The object to be cloned
 * @returns A cloned version of the object
 */
export function clone(obj) {
  let clonedObj = null;

  if (obj != null) {
    const stringified = JSON.stringify(obj);
    clonedObj = JSON.parse(stringified);
  }

  return clonedObj;
}
