export const CUSTOM_ICONS = {
  SQUARE: "\ue800",
  SQUARE_CHECK: "\ue801",
};
export const FA_SOLID_ICONS = {
  SQUARE: "\uf0c8",
  SQUARE_CHECK: "\uf14a",
  SHIELD: "\uf132",
  BOLT_LIGHTNING: "\ue0b7",
  PUZZLE_PIECE: "\uf12e",
};
export const SYMBOLS = {
  DEGREE: "\u00b0",
};
