import { useConfigStore } from "@/stores/config";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { METRIC_CONVERSION_TYPES } from "@/constants/InspectionConstants";
import { useInspectionStore } from "@/stores/inspection";
import { REGEX_PATTERNS } from "@/constants/RegexPatterns";
import useFeaturesData from "@/composables/featuresData";
import { CUSTOM_ICONS } from "@/constants/Unicode";

export default function getFeaturesHighwayData(detail, structure) {
  const configStore = useConfigStore();
  const inspectionStore = useInspectionStore();
  const { highwayCounty } = useFeaturesData();

  return {
    table: {
      headerRows: 0,
      widths: ["24%", "26%", "26%", "*"],
      body: [
        [
          { text: "5C03 On/Under: ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.ON_UNDER,
              inspectionStore.getFeatureIntersectionSelectedRow(detail?.rowId)
                ?.ON_UNDER
            ),
          },
          {
            text: "6B02 New Wear Surface Ind: ",
            bold: true,
          },
          {
            text:
              structure?.UserInsp?.NEW_WS == "1"
                ? CUSTOM_ICONS.SQUARE_CHECK
                : CUSTOM_ICONS.SQUARE,
            style: "customFontAwesome",
          },
        ],
        [
          { text: "5C01 Feature Name: ", bold: true },
          {
            text: detail?.ROADWAY_NAME,
            alignment: "left",
          },
          { text: "6C18 Horiz (L): ", bold: true },
          {
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                detail?.TOT_HOR_CLEAR_LEFT,
                ROUNDING_FACTOR.HUNDRETH
              ) + " ft",
          },
        ],
        [
          { text: "6C01 County: ", bold: true },
          {
            text: highwayCounty(detail),
          },
          { text: "6C19 Horiz (R): ", bold: true },
          {
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                detail?.TOT_HOR_CLEAR_RIGHT,
                ROUNDING_FACTOR.HUNDRETH
              ) + " ft",
          },
        ],
        [
          { text: "6C02 State Route #: ", bold: true },
          {
            text: detail?.SR_NUM,
            alignment: "left",
          },
          { text: "6C20 Min Vert (L): ", bold: true },
          {
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                detail?.MIN_OVER_VERT_CLEAR_LEFT,
                ROUNDING_FACTOR.HUNDRETH,
                null,
                [METRIC_CONVERSION_TYPES.L3]
              ) + " ft",
          },
        ],
        [
          { text: "6C03 Segment: ", bold: true },
          {
            text: detail?.SEG_NUM,
            alignment: "left",
          },
          { text: "6C21 Min Vert (R): ", bold: true },
          {
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                detail?.MIN_OVER_VERT_CLEAR_RIGHT,
                ROUNDING_FACTOR.HUNDRETH,
                null,
                [METRIC_CONVERSION_TYPES.L3]
              ) + " ft",
          },
        ],
        [
          { text: "6C04 Offset: ", bold: true },
          {
            text: detail?.OFFSET,
            alignment: "left",
          },
          { text: "6C22 Defense Vert (L): ", bold: true },
          {
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                detail?.DEF_VERT_CLEAR_LEFT,
                ROUNDING_FACTOR.HUNDRETH,
                null,
                [METRIC_CONVERSION_TYPES.L3]
              ) + " ft",
          },
        ],
        [
          { text: "5C06 Route #: ", bold: true },
          {
            text: detail?.ROUTENUM,
            alignment: "left",
          },
          { text: "6C23 Defense Vert (R): ", bold: true },
          {
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                detail?.DEF_VERT_CLEAR_RIGHT,
                ROUNDING_FACTOR.HUNDRETH,
                null,
                [METRIC_CONVERSION_TYPES.L3]
              ) + " ft",
          },
        ],
        [
          { text: "5C06 Direction: ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.ROUTE_DIRECTION,
              detail?.DIRSUFFIX
            ),
          },
          { text: "6C34 Feature Type: ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.FEATURE_TYPE,
              detail?.FEATURE_TYPE
            ),
          },
        ],
        [
          { text: "5C22 Functional Class: ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.ROADWAY_FUNCTIONAL_CLASSIFICATION,
              detail?.FUNCCLASS
            ),
          },
          { text: "6C35 Vert Clr Sign (L): ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.VERTICAL_CLEARANCE_SIGN,
              detail?.VERT_CLEAR_SIGN
            ),
          },
        ],
        [
          { text: "5C29 Nat Hwy Sys: ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.NATIONAL_HIGHWAY_SYSTEM,
              detail?.NHS_IND
            ),
          },
          { text: "6C37 Vert Clr Posting (L): ", bold: true },
          {
            text:
              (detail?.VRT_CLR_POST_LEFT_FT
                ? detail?.VRT_CLR_POST_LEFT_FT
                : "") +
              " ft" +
              "     " +
              (detail?.VRT_CLR_POST_LEFT_INCH
                ? detail?.VRT_CLR_POST_LEFT_INCH
                : "") +
              " in",
          },
        ],
        [
          { text: "5C08 Lanes: ", bold: true },
          {
            text: detail?.LANES,
            alignment: "left",
          },
          { text: "6C36 Vert Clr Sign (R): ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.VERTICAL_CLEARANCE_SIGN,
              detail?.VERT_CLEAR_SIGN_R
            ),
          },
        ],
        [
          { text: "5C08 Medians: ", bold: true },
          {
            text: detail?.NUM_MEDIAN,
            alignment: "left",
          },
          { text: "6C38 Vert Clr Posting (R): ", bold: true },
          {
            text:
              (detail?.VRT_CLR_POST_RIGHT_FT
                ? detail?.VRT_CLR_POST_RIGHT_FT
                : "") +
              " ft" +
              "     " +
              (detail?.VRT_CLR_POST_RIGHT_INCH
                ? detail?.VRT_CLR_POST_RIGHT_INCH
                : "") +
              " in",
          },
        ],
        [
          { text: "5C08 Speed: ", bold: true },
          {
            colSpan: 3,
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.KPH_TO_MPH,
                detail?.ROAD_SPEED,
                ROUNDING_FACTOR.ROUND
              ) + " mph",
          },
        ],
        [
          { text: "5C10 Recent ADT: ", bold: true },
          {
            colSpan: 3,
            text: detail?.ADTTOTAL?.toString()?.replace(
              REGEX_PATTERNS.NUMBER_WITH_COMMAS.FIRST_HALF,
              REGEX_PATTERNS.NUMBER_WITH_COMMAS.SECOND_HALF
            ),
            alignment: "left",
          },
        ],
        [
          { text: "5C11 Year: ", bold: true },
          {
            colSpan: 3,
            text: detail?.ADTYEAR,
            alignment: "left",
          },
        ],
        [
          { text: "5C14 Truck % ADT: ", bold: true },
          {
            colSpan: 3,
            text: detail?.TRUCKPCT,
            alignment: "left",
          },
        ],
        [
          { text: "6C27 ADTT: ", bold: true },
          {
            colSpan: 3,
            text: detail?.ADTT?.toString()?.replace(
              REGEX_PATTERNS.NUMBER_WITH_COMMAS.FIRST_HALF,
              REGEX_PATTERNS.NUMBER_WITH_COMMAS.SECOND_HALF
            ),
            alignment: "left",
          },
        ],
        [
          { text: "6C28 ADTT Year: ", bold: true },
          {
            colSpan: 3,
            text: detail?.ADTT_YEAR,
            alignment: "left",
          },
        ],
      ],
    },
    layout: {
      hLineWidth: function (i, node) {
        return i === 0 || i === node.table.body.length ? 2 : 0;
      },
      vLineWidth: function (i, node) {
        return i === 0 || i === node.table.widths.length ? 1 : 0;
      },
      hLineColor: function () {
        return "black";
      },
      vLineColor: function () {
        return "gray";
      },
    },
    unbreakable: true,
    margin: [10, 0, 10, 0],
  };
}
