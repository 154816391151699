<template>
  <v-dialog v-model="dialog" :width="400" persistent>
    <v-card>
      <v-card-title>
        <div>Accessing Web Services</div>
      </v-card-title>
      <v-card-subtitle
        >Please login w/BMS2 Credentials to Proceed</v-card-subtitle
      >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="userName"
                @keyup.enter="enterKeyLoginHandler"
                autofocus
              >
                <template v-slot:label
                  ><span>Username</span
                  ><span class="important-text">*</span></template
                >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="password"
                v-model="password"
                @keyup.enter="enterKeyLoginHandler"
                ><template v-slot:label
                  ><span>Password</span
                  ><span class="important-text">*</span></template
                >
              </v-text-field>
              <div class="important-text" v-if="message">
                {{ message }}
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div class="important-text">* indicates required field</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          id="btn_login"
          variant="outlined"
          class="mx-5"
          text
          :disabled="!userName || !password"
          @click="loginHandler"
        >
          Login
        </v-btn>
        <v-btn
          id="btn_login_close"
          variant="outlined"
          class="mx-5"
          text
          @click="cancel"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-overlay
    :model-value="loginProgressDialog"
    class="align-center justify-center"
    scroll-strategy="none"
  >
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>
<script>
import { reactive, toRefs, nextTick, ref } from "vue";

export default {
  name: "LoginDialog",
  emits: ["loginClicked"],

  setup(_, ctx) {
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      userName: null,
      password: null,
    });
    let loginProgressDialog = ref(false);
    const open = () => {
      state.dialog = true;
      //On Edocs page we have to wait until the login is closed to download docs
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };

    const loginHandler = () => {
      loginProgressDialog.value = true;
      state.resolve({
        userName: state.userName,
        password: state.password,
      });
      //if the login failed - subsequent requests use emitter, because promise is a one time call
      if (state.message) {
        ctx.emit("loginClicked", {
          userName: state.userName,
          password: state.password,
        });
      }
    };
    const enterKeyLoginHandler = () => {
      if (state.userName && state.password) {
        nextTick(() => document.getElementById("btn_login").focus());
        document.getElementById("btn_login").click();
      }
    };

    const setMessage = (message) => {
      state.message = message;
    };

    const cancel = () => {
      closeDialog();
    };

    const reset = () => {
      state.userName = null;
      state.password = null;
      state.message = null;
    };
    const closeDialog = () => {
      loginProgressDialog.value = false;
      reset();
      state.dialog = false;
    };
    return {
      ...toRefs(state),
      open,
      loginHandler,
      cancel,
      setMessage,
      reset,
      closeDialog,
      enterKeyLoginHandler,
      loginProgressDialog,
    };
  },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;
.important-text {
  color: c.$p-alert-red;
}
</style>
