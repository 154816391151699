<template>
  <v-container>
    <v-card-text> Structures </v-card-text>
  </v-container>
</template>

<script>
export default {
  components: {},
};
</script>
