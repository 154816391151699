const joint = {
  ID: null,
  JOINT_KEY: null,
  RECORD_KEY: "",
  CONDITION_RATING: "",
  COVERED: "",
  CREATEDATETIME: new Date().toISOString(),
  CREATEUSERKEY: "",
  DAMAGE: "",
  DEBRIS_IMPACT: "",
  ECMS_ID: "",
  EXTRUSION_INSTALL_DATE: null,
  JOINT_COMMENT: "",
  JOINT_LENGTH: null,
  JOINT_LOCATION: "",
  JOINT_MANUFACTURER: "",
  JOINT_MOVEMENT: "",
  JOINT_TYPE: "",
  LEAKING: "",
  MODTIME: new Date().toISOString(),
  REPLACEMENT_COMMENT: "",
  REPLACEMENT_REASON: "",
  SEAL_INSTALL_DATE: null,
  USERKEY: "",
};
export default joint;
