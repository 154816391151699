import { useConfigStore } from "@/stores/config";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { METRIC_CONVERSION_TYPES } from "@/constants/InspectionConstants";

export default function getFeaturesRailroadData(detail, structure) {
  const configStore = useConfigStore();
  return {
    table: {
      headerRows: 0,
      widths: ["24%", "26%", "26%", "*"],
      body: [
        [
          { text: "5C03 On/Under: ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.ON_UNDER,
              detail?.ON_UNDER
            ),
          },
          { text: "6C34 Feature Type: ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.FEATURE_TYPE,
              detail?.FEATURE_TYPE
            ),
          },
        ],
        [
          { text: "FR01 RR Name: ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.RAILROAD_NAME,
              detail?.RR_NAME
            ),
          },
          { text: "4A19 Min Lat Under (R): ", bold: true },
          {
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                structure?.Bridge?.HCLRURT,
                ROUNDING_FACTOR.TENTH,
                null,
                [METRIC_CONVERSION_TYPES.L4]
              ) + " ft",
          },
        ],
        [
          { text: "FR03 Service Status: ", bold: true },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SERVICE_STATUS,
              detail?.RR_SERV_STATUS
            ),
          },
          { text: "4A20 Min Lat Under (L): ", bold: true },
          {
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                structure?.Bridge?.HCLRULT,
                ROUNDING_FACTOR.TENTH,
                null,
                [METRIC_CONVERSION_TYPES.L3, METRIC_CONVERSION_TYPES.L5]
              ) + " ft",
          },
        ],
        [
          { text: "FR04 RR Milepost: ", bold: true },
          {
            text: detail?.RR_MILEPOST,
            alignment: "left",
          },
          { text: "FR11 Min Over Vert (R): ", bold: true },
          {
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                detail?.MIN_OVER_VERT_CLEAR_RIGHT,
                ROUNDING_FACTOR.HUNDRETH,
                null,
                [METRIC_CONVERSION_TYPES.L3]
              ) + " ft",
          },
        ],
        [
          { text: "FR05 AAR DOT Number: ", bold: true },
          {
            text: detail?.AAR_DOT_NUM,
            alignment: "left",
          },
          { text: "FR13 Min Over Vert (L): ", bold: true },
          {
            text:
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.METERS_TO_FEET,
                detail?.TOT_HOR_CLEAR_RIGHT,
                ROUNDING_FACTOR.TENTH
              ) + " ft",
          },
        ],
        [
          { text: "FR06 # of Elec Tracks: ", bold: true },
          {
            colSpan: 3,
            text: detail?.ELEC_TRACK_QTY,
            alignment: "left",
          },
        ],
        [
          { text: "FR07 Total # of Tracks: ", bold: true },
          {
            colSpan: 3,
            text: detail?.LANES,
            alignment: "left",
          },
        ],
        [
          { text: "FR08 Span Description: ", bold: true },
          {
            colSpan: 3,
            text: detail?.SPAN_DESC,
            alignment: "left",
          },
        ],
        [
          { text: "FR09 Additional Operator: ", bold: true },
          {
            colSpan: 3,
            text: detail?.ADD_OPER_DESC,
            alignment: "left",
          },
        ],
        [
          { text: "FR16 Notes: ", bold: true },
          {
            colSpan: 3,
            text: detail?.NOTES,
            alignment: "left",
          },
        ],
      ],
    },
    layout: {
      hLineWidth: function (i, node) {
        return i === 0 || i === node.table.body.length ? 2 : 0;
      },
      vLineWidth: function (i, node) {
        return i === 0 || i === node.table.widths.length ? 1 : 0;
      },
      hLineColor: function () {
        return "black";
      },
      vLineColor: function () {
        return "gray";
      },
    },
    unbreakable: true,
    margin: [10, 0, 10, 0],
  };
}
