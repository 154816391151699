import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { styles } from "@/reports/reportStyles";
import { appendUnits } from "@/composables/util";
import { CUSTOM_ICONS } from "@/constants/Unicode";

function setCulvertDocDefinition(structure) {
  const configStore = useConfigStore();
  const culvertDetails = [];
  culvertDetails.push(getDetailsHeader());
  getCulvertOpeningDetails(culvertDetails, structure);

  let docDefinition = {
    header: function () {
      return getReportHeader("Culvert Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Culvert Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Culvert Conditions",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        columns: [
          {
            width: "40%",
            text: "1A03 Culvert Condition Rating: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.CULVRATING
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        text: "Overall Culvert Notes: ",
        bold: true,
        margin: [0, 3, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) => element?.COMMENT_TYPE == INSPECTION_COMMENTS.CULVERT
        )?.NOTES,
      },
      {
        columns: [
          {
            width: "40%",
            text: "6B48 Combustible Material Under Bridge: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.MATERIAL_STORED_UNDER,
              structure?.UserInsp?.MAT_STORE_UND
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        text: "6B48 Combustible Material Notes: ",
        bold: true,
        margin: [0, 3, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE ==
            INSPECTION_COMMENTS.COMBUSTIBLE_MATERIALS_COMMENTS
        )?.NOTES,
      },
      {
        columns: [
          {
            width: "40%",
            text: "6B35 New Coating Since Last Inspection: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text:
              structure?.UserInsp?.NEW_PAINT === "1"
                ? CUSTOM_ICONS.SQUARE_CHECK
                : CUSTOM_ICONS.SQUARE,
            style: "customFontAwesome",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "40%",
            text: "6B36 Protective Coating Rating: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.PAINT_CONDITION_RATING,
              structure?.UserInsp?.PAINT_COND_RATE
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "40%",
            text: "6B37 Protective Coating (Extent) Rating: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.PAINT_CONDITION_RATING_EXTENT,
              structure?.UserInsp?.PAINT_EXTENT_RATE
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        text: "Overall Protective Coating Notes: ",
        bold: true,
        margin: [0, 3, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.PAINT_OVERALL
        )?.NOTES,
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Culvert Details",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "auto",
            text: "5B11 Main Spans: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "25%",
            text: structure?.Bridge?.MAINSPANS,
            margin: [5, 3, 0, 0],
          },
          {
            width: "auto",
            text: "5B14 Approach Spans: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: structure?.Bridge?.APPSPANS,
            margin: [5, 3, 0, 0],
          },
        ],
      },
      {
        table: {
          headerRows: 1,
          widths: ["20%", "10%", "10%", "10%", "10%", "20%", "20%"],
          body: culvertDetails,
          margin: [0, 3, 0, 0],
        },
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Culvert Inspection Notes",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 3, 0, 0],
      },
      {
        text: "Top Slab: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.CULVERT_TOP_SLAB
        )?.NOTES,
      },
      {
        text: "Barrel: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.CULVERT_BARREL
        )?.NOTES,
      },
      {
        text: "Floor/Paving: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.CULVERT_FLOOR_PAVE
        )?.NOTES,
      },
      {
        text: "Headwall: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.CULVERT_HEADWALL
        )?.NOTES,
      },
      {
        text: "Wings: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.CULVERT_WINGS
        )?.NOTES,
      },
      {
        text: "Settlement: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.CULVERT_SETTLEMENT
        )?.NOTES,
      },
      {
        text: "Debris: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.CULVERT_DEBRIS
        )?.NOTES,
      },
      {
        text: "Footing: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.CULVERT_FOOTING
        )?.NOTES,
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}

function getDetailsHeader() {
  return [
    {
      text: "VD18\n Opening\n Type",
      style: ["tableHeader"],
    },
    {
      text: "VD19\n Length of\n Culvert Barrel",
      style: ["tableHeader"],
    },
    {
      text: "VD20\n Min Fill\n Height",
      style: ["tableHeader"],
    },
    {
      text: "VD21\n Max Fill\n Height",
      style: ["tableHeader"],
    },
    {
      text: "VD22\n Eff Width",
      style: ["tableHeader"],
    },
    {
      text: "VD23\n Tie Type",
      style: ["tableHeader"],
    },

    {
      text: "VD24\n Floor Type",
      style: ["tableHeader"],
    },
  ];
}

function getCulvertOpeningDetails(culvertDetails, structure) {
  const configStore = useConfigStore();

  for (const openingDetail of structure?.T_Culvert_Opening || []) {
    culvertDetails.push(
      [
        configStore.getReferenceValue(
          REFERENCE_TABLE.CULVERT_OPENING_TYPE,
          openingDetail?.OPENING_TYPE
        ),
        appendUnits(
          configStore.getMetricToEnglishConversions(
            PAIR_CODE.METERS_TO_FEET,
            openingDetail?.CULVERT_LENGTH,
            ROUNDING_FACTOR.ROUND
          ),
          "ft"
        ),
        appendUnits(
          configStore.getMetricToEnglishConversions(
            PAIR_CODE.METERS_TO_FEET,
            openingDetail?.MIN_FILL_HEIGHT,
            ROUNDING_FACTOR.TENTH
          ),
          "ft"
        ),
        appendUnits(
          configStore.getMetricToEnglishConversions(
            PAIR_CODE.METERS_TO_FEET,
            openingDetail?.MAX_FILL_HEIGHT,
            ROUNDING_FACTOR.TENTH
          ),
          "ft"
        ),
        appendUnits(
          configStore.getMetricToEnglishConversions(
            PAIR_CODE.METERS_TO_FEET,
            openingDetail?.EFF_WIDTH,
            ROUNDING_FACTOR.TENTH
          ),
          "ft"
        ),
        configStore.getReferenceValue(
          REFERENCE_TABLE.CULVERT_TIE_TYPE,
          openingDetail?.TIE_TYPE
        ),
        configStore.getReferenceValue(
          REFERENCE_TABLE.CULVERT_FLOOR_TYPE,
          openingDetail?.FLOOR_TYPE
        ),
      ].map((d) => {
        return {
          text: d,
          alignment: "center",
        };
      })
    );
  }
}

export { setCulvertDocDefinition };
