import { logoEncodedContent } from "@/assets/logoEncoded";
import useDateField from "@/composables/dateField";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";

function getReportFooter(currentPage, pageCount) {
  return [
    {
      //line separator
      table: {
        headerRows: 1,
        widths: ["100%"],
        body: [[""], [""]],
      },
      layout: {
        // eslint-disable-next-line no-unused-vars
        hLineWidth: function (i, node) {
          return i === 1 ? 1 : 0;
        },
        // eslint-disable-next-line no-unused-vars
        vLineWidth: function (i, node) {
          return 0;
        },
      },
      margin: [10, 0, 10, 0],
    },
    {
      columns: [
        {
          table: {
            body: [
              [
                {
                  text: "This document includes structure safety inspection information that is not public pursuant to federal law and may not be published, release or disclosed without the written permission of the PA Department of Transportation.",
                  fontSize: 8,
                },
              ],
            ],
          },
          margin: [10, 0, 0, 0],
          width: "88%",
          alignment: "left",
        },
        { text: "", alignment: "center", width: "1%" },
        {
          text: "Page " + currentPage.toString() + " of " + pageCount,
          alignment: "right",
          width: "auto",
          bold: true,
        },
      ],
    },
  ];
}

const getSnbiTypes = (structureValue) => {
  return structureValue.Snbi_Insp_Event?.map((i) => i.INSP_TYPE);
};

const getStructureInspectionTypes = (primaryInspType, snbiInspTypes) => {
  const configStore = useConfigStore();
  if (snbiInspTypes?.length > 0) {
    return (
      " Type " +
      snbiInspTypes
        .map((e) => e)
        ?.sort()
        .join("")
    );
  } else {
    return configStore.getReferenceValue(
      REFERENCE_TABLE.INSPECTION_TYPE,
      primaryInspType
    );
  }
};

function getReportHeader(reportTitle, structure) {
  const { getFormattedDateStringNoTime } = useDateField();
  const configStore = useConfigStore();
  return [
    {
      columns: [
        {
          width: "75%",
          table: {
            headerRows: 0,
            widths: ["50%", "*"],
            body: [
              [
                {
                  columns: [
                    {
                      text: "BMSID: ",
                      bold: true,
                      width: "auto",
                      margin: [0, 10, 0, 0],
                    },
                    {
                      text: structure.Bridge.BRIDGE_ID,
                      width: "auto",
                      margin: [2, 10, 8, 0],
                    },
                    {
                      text: "BRKEY: ",
                      bold: true,
                      width: "auto",
                      margin: [0, 10, 0, 0],
                    },
                    {
                      text: structure.Bridge.BRKEY,
                      width: "auto",
                      margin: [2, 10, 0, 0],
                    },
                  ],
                },
                {
                  text: reportTitle,
                  style: "header",
                  width: "20%",
                  margin: [20, 8, 0, 0],
                },
              ],
              [
                {
                  text: [
                    { text: "Status: ", bold: true },
                    configStore.getReferenceValue(
                      REFERENCE_TABLE.INSPECTION_STATUS,
                      structure.InspEvnt.INSPSTAT
                    ),
                  ],
                  width: "auto",
                },
                { text: "" },
              ],
              [
                {
                  colSpan: 2,
                  text: [
                    { text: "Inspection Record: ", bold: true },
                    `${getFormattedDateStringNoTime(
                      structure.InspEvnt.INSPDATE
                    )} - ${getStructureInspectionTypes(
                      structure?.InspEvnt?.INSPTYPE,
                      getSnbiTypes(structure)
                    )}`,
                  ],
                  width: "auto",
                },
              ],
            ],
          },
          margin: [20, 0, 0, 0],
          layout: "noBorders",
        },
        {
          width: "*",
          table: {
            headerRows: 0,
            body: [
              [
                {
                  image: logoEncodedContent,
                  fit: [130, 100],
                  width: "45%",
                },
              ],
              [
                {
                  text: [
                    { text: "Date Printed: ", bold: true },
                    getFormattedDateStringNoTime(new Date()),
                  ],
                  margin: [0, 0, 0, 0],
                  width: "auto",
                },
              ],
            ],
          },

          alignment: "right",
          layout: "noBorders",
        },
      ],
    },
    {
      //line separator
      table: {
        headerRows: 1,
        widths: ["100%"],
        body: [[""], [""]],
      },
      layout: {
        // eslint-disable-next-line no-unused-vars
        hLineWidth: function (i, node) {
          return i === 1 ? 1 : 0;
        },
        // eslint-disable-next-line no-unused-vars
        vLineWidth: function (i, node) {
          return 0;
        },
      },
      margin: [20, 0, 20, 0],
    },
  ];
}

export { getReportFooter, getReportHeader };
