const structure = {
  brKey: null,
  agreementContractNumber: null,
  bmsId: null,
  district: {
    districtName: null,
    districtNum: null,
  },
  featuresIntersected: null,
  inspectionDate: null,
  inspectionStatus: {
    code: null,
    description: null,
  },
  reportingGroup: {
    code: null,
    description: null,
  },
  downloadDate: null,
  get all() {
    return this.inspection && this.inventory && this.other;
  },
  set all(value) {
    this.inspection = value;
    this.inventory = value;
    this.other = value;
  },
  inspection: false,
  inventory: false,
  other: false,
  isSelected: false,
  isHighlighted: function () {
    return Boolean(
      this.inspection || this.inventory || this.other || this.isSelected
    );
  },
  isStructureOnly: function () {
    return Boolean(
      this.isSelected && !(this.inspection || this.inventory || this.other)
    );
  },
  isDocumentsOnly: function () {
    return Boolean(
      !this.isSelected && (this.inspection || this.inventory || this.other)
    );
  },
  clear: function () {
    this.all = false;
    this.isSelected = false;
  },
};
export default structure;
