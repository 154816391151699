/* eslint-disable vue/valid-v-slot */
<template>
  <v-row>
    <ExpandCollapseToggle
      id="maintenancePanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panel"
    />
    <v-col sm="12" md="12" lg="12" xl="12" class="pa-0">
      <v-expansion-panels v-model="panel" multiple color="#fff">
        <v-expansion-panel class="mt-3">
          <v-expansion-panel-title
            class="d-flex justify-start"
            hide-actions
            expand-icon="fa-solid fa-plus"
            collapse-icon="fa-solid fa-minus"
          >
            <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />

            <v-icon v-else icon="fa-solid fa-plus" />

            <h3>Proposed Candidates</h3>
          </v-expansion-panel-title>

          <v-expansion-panel-text>
            <MaintenanceTable
              :items="proposed"
              :isEditable="isEditable"
              canAddTo
              @save="(...args) => saveCandidate(...args)"
              @show-message="(...args) => showMessageEvent(...args, 'proposed')"
              @hide-message="hideMessageEvent('proposed')"
              id="proposed"
            />
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-title
            class="d-flex justify-start"
            hide-actions
            expand-icon="fa-solid fa-plus"
            collapse-icon="fa-solid fa-minus"
          >
            <v-icon v-if="panel.includes(1)" icon="fa-solid fa-minus" />

            <v-icon v-else icon="fa-solid fa-plus" />

            <h3>Completed Candidates</h3>
          </v-expansion-panel-title>

          <v-expansion-panel-text>
            <MaintenanceTable
              :items="completed"
              :isEditable="isEditable"
              isCompletedCandidates
              @save="(...args) => saveCandidate(...args)"
              @show-message="
                (...args) => showMessageEvent(...args, 'completed')
              "
              @hide-message="hideMessageEvent('completed')"
              id="completed"
            />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
  <v-snackbar
    v-model="inspectionStore.addingOrEditing"
    color="warning"
    position="sticky"
    location="top"
    timeout="-1"
    multi-line
  >
    <div v-if="saveMessage.proposedText" class="py-1">
      {{ saveMessage.proposedText }}
    </div>
    <div v-if="saveMessage.completedText" class="py-1">
      {{ saveMessage.completedText }}
    </div>
  </v-snackbar>
</template>

<script>
import { defineComponent, ref, computed, watch, reactive } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import { clone } from "@/util/clone";
import MaintenanceTable from "@/components/shared/MaintenanceTable.vue";
import { MAINTENANCE_CANDIDATE_TYPE } from "@/constants/InspectionConstants";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";

export default defineComponent({
  name: "InspectionMaintenance",
  setup() {
    const inspectionStore = useInspectionStore();
    const structure = computed(() => inspectionStore.selectedInspection);
    const isEditable = computed(() => inspectionStore.isEditable);
    const allPanels = [0, 1];
    let panel = ref(allPanels);

    let saveMessage = reactive({
      _proposedText: null,
      set proposedText(value) {
        this._proposedText = value;
      },
      get proposedText() {
        return this._proposedText;
      },
      _completedText: null,
      set completedText(value) {
        this._completedText = value;
      },
      get completedText() {
        return this._completedText;
      },
    });

    const convertToWCandObject = (detail) => {
      return {
        ACTCODE: detail.ACTCODE,
        ACTKIND: detail.ACTKIND,
        AGENCY_PRIORITY: detail.AGENCY_PRIORITY,
        AGENCY_STATUS: detail.AGENCY_STATUS,
        ASSIGNED: detail.ASSIGNED,
        BRKEY: detail.BRKEY,
        CREATEDATETIME: detail.CREATEDATETIME,
        CREATEUSERKEY: detail.CREATEUSERKEY,
        DOCREFKEY: detail.DOCREFKEY,
        ESTIMCOST: detail.ESTIMCOST,
        ESTIMQUANTITY: detail.ESTIMQUANTITY,
        FLAG_WHOLE: detail.FLAG_WHOLE,
        INSPKEY: detail.INSPKEY,
        MODTIME: detail.MODTIME,
        NOTES: detail.NOTES,
        OBJCODE: detail.OBJCODE,
        OBJKIND: detail.OBJKIND,
        REF_WITEMKEY: detail.REF_WITEMKEY,
        STATE1: detail.STATE1,
        STATE2: detail.STATE2,
        STATE3: detail.STATE3,
        STATE4: detail.STATE4,
        STATE5: detail.STATE5,
        STRUNITKEY: detail.STRUNITKEY ? detail.STRUNITKEY : -1,
        TARGETYEAR: detail.TARGETYEAR,
        USERKEY: detail.USERKEY,
        WCKEY: detail.WCKEY,
        WC_ID: detail.WC_ID,
        WORKASSIGNMENT: detail.WORKASSIGNMENT,
        WORKRECDATE: detail.WORKRECDATE,
      };
    };

    let completed = ref([]);
    let proposed = ref([]);

    const getCandidates = (candidateType) => {
      let cloneWCand = clone(structure.value.Insp_WCand);
      if (!cloneWCand[0]) {
        //remove null values from array(if any)
        cloneWCand = cloneWCand?.filter((a) => !!a);
      }
      let statuses = getStatuses(candidateType);

      cloneWCand.forEach((c) => {
        if (statuses.includes(c.AGENCY_STATUS)) {
          const userWcand = structure.value.T_UserInsp_WCand.find(
            (element) => element?.WCKEY == c.WCKEY
          );
          c.LOCATION = userWcand?.LOCATION;
          if (candidateType == MAINTENANCE_CANDIDATE_TYPE.COMPLETED) {
            c.COMPLETED_DATE = userWcand?.COMPLETED_DATE;
          }
          c.readOnly = true;
          c.isAdd = false;
          if (candidateType == MAINTENANCE_CANDIDATE_TYPE.PROPOSED) {
            proposed.value.push(c);
          } else {
            completed.value.push(c);
          }
        }
      });
      sortCandidates(proposed);
    };
    //sort candidates on load
    const sortCandidates = (proposed) => {
      proposed.value.sort(function (a, b) {
        const agencyPriorityComparison = a.AGENCY_PRIORITY.localeCompare(
          b.AGENCY_PRIORITY
        );
        if (agencyPriorityComparison === 0) {
          const dateA = a.WORKRECDATE ? new Date(a.WORKRECDATE) : null;
          const dateB = b.WORKRECDATE ? new Date(b.WORKRECDATE) : null;
          if (dateA === null && dateB === null) {
            return 0;
          } else if (dateA === null) {
            return 1;
          } else if (dateB === null) {
            return -1;
          }
          return dateA - dateB;
        }
      });
    };

    const getStatuses = (candidateType) => {
      if (candidateType == MAINTENANCE_CANDIDATE_TYPE.PROPOSED) {
        proposed.value = [];
        return ["0", "1", "2", "3", "4", "D"];
      } else {
        completed.value = [];
        return ["5", "6", "7"];
      }
    };

    const loadCandidates = () => {
      getCandidates(MAINTENANCE_CANDIDATE_TYPE.PROPOSED);
      getCandidates(MAINTENANCE_CANDIDATE_TYPE.COMPLETED);
    };

    loadCandidates();

    const saveCandidate = (detail) => {
      if (!structure.value?.Insp_WCand?.[0]) {
        //remove null values from array(if any)
        structure.value.Insp_WCand = structure.value.Insp_WCand?.filter(
          (a) => !!a
        );
      }
      if (detail.isAdd) {
        let newProposedUserInsp = {
          COMPLETED_DATE: detail.COMPLETED_DATE,
          CREATEDATETIME: new Date().toISOString(),
          DRAWING_IND: "",
          LOCATION: detail.LOCATION,
          MODTIME: structure.value.InspEvnt.MODTIME,
          PERMIT_IND: "",
          USERKEY: structure.value.InspEvnt.USERKEY,
          WCKEY: detail.WCKEY,
        };
        structure.value.T_UserInsp_WCand.push(newProposedUserInsp);
        structure.value.Insp_WCand.push(convertToWCandObject(detail));
      } else {
        //update existing
        const updateIndex = structure.value.Insp_WCand.findIndex(
          (a) => a?.WCKEY == detail?.WCKEY
        );
        const userWcand = structure.value.T_UserInsp_WCand.findIndex(
          (element) => element?.WCKEY == detail?.WCKEY
        );
        structure.value.T_UserInsp_WCand[userWcand].LOCATION = detail.LOCATION;
        if (detail.COMPLETED_DATE) {
          structure.value.T_UserInsp_WCand[userWcand].COMPLETED_DATE =
            detail.COMPLETED_DATE;
        }
        structure.value.Insp_WCand.splice(
          updateIndex,
          1,
          convertToWCandObject(detail)
        );
      }
      getCandidates(MAINTENANCE_CANDIDATE_TYPE.COMPLETED);
      getCandidates(MAINTENANCE_CANDIDATE_TYPE.PROPOSED);
    };

    const showMessageEvent = (message, candidate) => {
      if (candidate == "completed") {
        saveMessage.completedText = message;
      } else {
        saveMessage.proposedText = message;
      }
    };

    const hideMessageEvent = (candidate) => {
      if (candidate == "completed") {
        saveMessage.completedText = null;
      } else {
        saveMessage.proposedText = null;
      }
    };

    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [structure.value.Insp_WCand, structure.value.T_UserInsp_WCand],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      completed,
      saveCandidate,
      proposed,
      structure,
      isEditable,
      inspectionStore,
      saveMessage,
      showMessageEvent,
      hideMessageEvent,
      loadCandidates,
      toggleAllPanels,
      panel,
    };
  },
  components: {
    MaintenanceTable,
    ExpandCollapseToggle,
  },
});
</script>
