<template>
  <v-row>
    <v-col cols="12" sm="12" md="7" lg="8">
      <label
        >BMS ID Search:<v-tooltip>
          <template v-slot:activator="{ props }">
            <v-icon
              id="icon_bmsIdsInfo"
              class="ml-2 infoIcon"
              icon="fas fa-circle-info"
              v-bind="props"
            />
          </template>
          <span
            >For multiple entries, separate the number with a comma or a space.
            User must enter all 14 characters per BMSID to get search results.
          </span>
        </v-tooltip></label
      >
      <v-text-field
        id="txt_bmsIdSearch"
        v-model="bmsIds"
        v-maska="MASK.NUMBER_COMMA_SPACE_REPEATING"
        type="text"
        autofocus
        single-line
        label="Enter BMS ID"
        persistent-hint
        clearable
        @keyup.enter="enterKeyHandler"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="auto"
      lg="auto"
      class="d-flex align-content-center flex-wrap"
    >
      <v-btn
        id="btn_retrieveBmsIds"
        variant="outlined"
        @click="readDataFromAPI(bmsIds)"
      >
        Retrieve List
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { useStructureSearchStore } from "@/stores/structureSearch";
import { STRUCTURE_SEARCH_MESSAGES } from "@/constants/StructureSearches";
import { MASK } from "@/constants/Maskas";
import useFieldEntry from "@/composables/fieldEntry";
import { scrollToElement } from "@/util/scrollToElement";
import { nextTick } from "vue";

export default {
  name: "BMSID",
  emits: ["showMessage"],
  data: () => ({
    bmsIds: null,
  }),
  setup(_, ctx) {
    const structureSearchStore = useStructureSearchStore();
    const { isNumber } = useFieldEntry();

    async function readDataFromAPI(payload) {
      if (payload) {
        structureSearchStore.resetSelectedStructures();
        let cleanedPayload = payload.trim().split(/[ ,]+/);
        await structureSearchStore.getStructuresByBmsId([cleanedPayload]);
        showMessage(payload);
      }
    }

    async function showMessage(bmsIds) {
      let missingResults;
      let data;
      let cleanedBmsIds;

      data = JSON.parse(
        JSON.stringify(structureSearchStore.getStructureSearchResults)
      ).map(function (elem) {
        return elem.bmsId;
      });
      cleanedBmsIds = bmsIds.trim().split(/[, ]+/).filter(Boolean);
      missingResults = structureSearchStore.getMissingResults(
        data,
        cleanedBmsIds
      );
      ctx.emit(
        "showMessage",
        setMessageValue(cleanedBmsIds, missingResults, data)
      );
    }
    const enterKeyHandler = () => {
      nextTick(() => document.getElementById("btn_retrieveBmsIds").focus());
      document.getElementById("btn_retrieveBmsIds").click();
    };
    return {
      MASK,
      structureSearchStore,
      readDataFromAPI,
      showMessage,
      isNumber,
      enterKeyHandler,
    };
  },
};

function setMessageValue(cleanedBmsIds, missingResults, data) {
  let message = {
    header: "BMS ID Missing Results",
    value: null,
    type: null,
    keys: null,
    missingResults: missingResults,
    show: true,
  };

  if (data?.length === 0) {
    message.value = STRUCTURE_SEARCH_MESSAGES.SEARCH_NO_RESULTS;
    message.type = "error-dialog";
  } else if (missingResults?.length === 0) {
    message.show = false;
    scrollToElement("search_results_header");
  } else if (missingResults?.length !== 0) {
    message.value = STRUCTURE_SEARCH_MESSAGES.BMSID_SEARCH_SOME_RESULTS;
    message.type = "warning-dialog";
    message.keys = cleanedBmsIds;
    scrollToElement("search_results_header");
  }
  return message;
}
</script>
