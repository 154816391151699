import { defineStore } from "pinia";

export const useEdocStore = defineStore("edoc", {
  state: () => ({
    downloads: {},
    attachments: {},
  }),
  actions: {
    async saveDownloadProgress(
      brkey,
      docContainerSeqNum,
      docId,
      status,
      error
    ) {
      if (this.downloads[brkey]) {
        let doc = this.downloads[brkey]?.find(
          (d) => d.docId == docId && d.docContainerSeqNum == docContainerSeqNum
        );
        if (doc) {
          doc.download = status;
          doc.error = error;
        } else {
          this.downloads[brkey].push({
            docId: docId,
            docContainerSeqNum: docContainerSeqNum,
            download: status,
            error: error,
          });
        }
      } else {
        this.downloads[brkey] = [
          {
            docId: docId,
            docContainerSeqNum: docContainerSeqNum,
            download: status,
            error: error,
          },
        ];
      }
    },
    saveAttachment(attachmentName, attachment) {
      this.attachments[attachmentName] = attachment;
    },
  },
});
