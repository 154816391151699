import { defineStore } from "pinia";
import { ref } from "vue";

export const useUserStore = defineStore("user", () => {
  let loggedInUser = ref(null);
  let isAuthenticated = ref(false);

  const setUser = (user) => {
    loggedInUser.value = user;
  };
  const setIsAuthenticated = (value) => {
    isAuthenticated.value = value;
  };

  return {
    loggedInUser,
    isAuthenticated,
    setUser,
    setIsAuthenticated,
  };
});
