import { LOGGER } from "@/util/logger";

class ApiWorkerManager {
  constructor() {
    if (!ApiWorkerManager.instance) {
      this.store = {};
      this.worker = new Worker(new URL("api-worker.js", import.meta.url), {
        type: "module",
      });
      this.worker.onmessage = this.onMessage.bind(this);
      this.worker.onerror = this.onError.bind(this);
      this.worker.onmessageerror = this.onMessageError.bind(this);
      ApiWorkerManager.instance = this;
    }
  }

  /**
   * @param {Object} workerPayload
   * @param {string} workerPayload.endPoint URL of the API
   * @param {Object} workerPayload.urlParams params is options. In GET/ call params is added as URL params.
   * @param {string} workerPayload.method for logging purpose method is the calling method's name
   * @param {Object} workerPayload.auth authenticated user object
   */
  postMessage(workerPayload) {
    return new Promise((resolve, reject) => {
      const queryKey = crypto.randomUUID();
      this.setPromiseResolverForQuery(queryKey, resolve, reject);
      const workerData = {
        queryKey,
        workerPayload,
      };
      this.worker.postMessage(workerData);
    });
  }

  setPromiseResolverForQuery(queryKey, resolve, reject) {
    this.store[queryKey] = {
      resolve: (data) => resolve(data),
      reject: (reason) => reject(reason),
    };
  }

  onMessage(response) {
    const { queryKey, error, data } = response.data;
    const { resolve, reject } = this.store[queryKey];
    if (!queryKey) {
      LOGGER.logException("QueryKey Not Present");
    }

    if (resolve === undefined || reject === undefined) {
      LOGGER.logException("QueryKey Is Not Valid");
    }

    if (error) {
      reject(error);
    } else {
      resolve(data);
    }
    delete this.store[queryKey];
  }

  onError(err) {
    LOGGER.logException(err);
  }

  onMessageError(message) {
    LOGGER.logException(message);
  }
}

export default ApiWorkerManager;
