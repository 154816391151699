import {
  UserManager,
  WebStorageStateStore,
  InMemoryWebStorage,
} from "oidc-client-ts";
import { useUserStore } from "@/stores/userStore";
import { LOGGER } from "@/util/logger";

const storage = new InMemoryWebStorage();
const userManager = new UserManager({
  authority: "/affwebservices/CASSO/oidc/BMS3Web",
  client_id: import.meta.env.VITE_APP_CLIENT_ID,
  client_secret: import.meta.env.VITE_APP_CLIENT_SECRET,
  redirect_uri: import.meta.env.VITE_APP_REDIRECT_URI,
  response_type: "code",
  scope: "openid profile",
  automaticSilentRenew: true,
  client_authentication: "client_secret_post",
  post_logout_redirect_uri: "/",
  userStore: new WebStorageStateStore({ store: storage }),
  stateStore: new WebStorageStateStore({
    store: window.localStorage,
  }),
  revokeTokensOnSignout: true,
  metadata: {
    issuer: "/affwebservices/CASSO/oidc/BMS3Web",
    authorization_endpoint: import.meta.env.VITE_APP_AUTHORIZATION_ENDPOINT,
    token_endpoint: "/affwebservices/CASSO/oidc/BMS3Web/token",
    jwks_uri: "/affwebservices/CASSO/oidc/BMS3Web/jwks",
    userinfo_endpoint: "/affwebservices/CASSO/oidc/BMS3Web/userinfo",
    introspection_endpoint: "/affwebservices/CASSO/oidc/BMS3Web/introspect",
    revocation_endpoint: "/affwebservices/CASSO/oidc/BMS3Web/revoke",
    end_session_endpoint: import.meta.env.VITE_APP_END_SESSION_ENDPOINT,
  },
});

//handle token silent renew error happens when there is no network(offline)
userManager.events.addSilentRenewError((e) => {
  LOGGER.logException(e);
});

export default {
  login() {
    const originalUrl = window.location.href || "";
    //store original url for BMS3 application and not if user login from ESEC url
    if (
      !originalUrl.includes("affwebservices") &&
      !originalUrl.includes("code")
    ) {
      localStorage.setItem("originalUrl", originalUrl);
    }
    return userManager.signinRedirect();
  },

  logout() {
    const userStore = useUserStore();
    userStore.setUser(null);
    userStore.setIsAuthenticated(false);
    localStorage.removeItem("hashedPass");
    return userManager.signoutRedirect();
  },

  async getUser() {
    return await userManager.getUser();
  },

  async setToken() {
    const userStore = useUserStore();
    try {
      const user = await userManager.signinRedirectCallback();
      userStore.setUser(user?.profile);
      userStore.setIsAuthenticated(true);
    } catch (e) {
      LOGGER.logException(e);
    }
  },

  async silentLogin() {
    try {
      await userManager.signinSilent();
      const userStore = useUserStore();
      const user = await this.getUser();
      userStore.setUser(user?.profile);
      userStore.setIsAuthenticated(true);
    } catch (err) {
      LOGGER.logException(err);
      //silent login failed and hence taking them to the online login page
      this.login();
    }
  },
};
