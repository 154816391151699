import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { styles } from "@/reports/reportStyles";
import useNotesData from "@/composables/notesData";
import { clone } from "@/util/clone";

let sortedData;

function setNotesDocDefinition(structure) {
  const InspectionNotes = [];
  InspectionNotes.push(getInspectionNotesHeader());
  getInspectionNotesDetails(InspectionNotes, structure);

  const SubstructureNotes = [];
  SubstructureNotes.push(getSubstructureNotesHeader());
  getSubstructureNotesDetails(SubstructureNotes, structure);

  let docDefinition = {
    header: function () {
      return getReportHeader("Notes Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Notes Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Inspection Notes",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 10, 0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: ["30%", "70%"],
          body: InspectionNotes,
          margin: [50, 50, 50, 50],
          dontBreakRows: true,
        },
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Substructure Notes",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 10, 0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: ["11%", "19%", "70%"],
          body: SubstructureNotes,
          margin: [0, 10, 0, 0],
          dontBreakRows: true,
        },
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}

function getInspectionNotesHeader() {
  return [
    {
      text: "IC01\n Note Type",
      style: ["tableHeader"],
    },
    {
      text: "IC02\n Note",
      style: ["tableHeader"],
    },
  ];
}

function getSubstructureNotesHeader() {
  return [
    {
      text: "IC03\n Substucture\n Unit",
      style: ["tableHeader"],
    },
    {
      text: "IC04\n Note\n Type",
      style: ["tableHeader"],
    },
    {
      text: "IC05\n Note",
      style: ["tableHeader"],
    },
  ];
}

function sortDataBy(data) {
  sortedData = data?.sort(function (a, b) {
    return a?.COMMENT_TYPE - b?.COMMENT_TYPE;
  });
}

function getInspectionNotesDetails(InspectionNotes, structure) {
  const { noteTypeIC01 } = useNotesData();

  sortDataBy(clone(structure?.T_Insp_Comment));

  for (const detail of sortedData) {
    InspectionNotes.push(
      [noteTypeIC01(detail?.COMMENT_TYPE), detail?.NOTES].map((d) => {
        return {
          text: d,
          alignment: "left",
        };
      })
    );
  }
}

function getSubstructureNotesDetails(InspectionNotes, structure) {
  const { substuctureUnitIC03, noteTypeIC04, sortSubInspectionNotes } =
    useNotesData();
  const clonedComments = clone(structure.T_Struc_Unit_Insp_Comment) || [];
  sortSubInspectionNotes(clonedComments);

  for (const detail of clonedComments) {
    InspectionNotes.push(
      [
        substuctureUnitIC03(detail?.STRUNITKEY),
        noteTypeIC04(detail?.COMMENT_TYPE),
        detail?.NOTES,
      ].map((d) => {
        return {
          text: d,
          alignment: "left",
        };
      })
    );
  }
}

export { setNotesDocDefinition };
