import { styles } from "@/reports/reportStyles";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { useInspectionStore } from "@/stores/inspection";
import useDateField from "@/composables/dateField.js";
import {
  getIN15Value,
  getIN15ValueFromMaterials,
} from "@/composables/keyFields.js";

function setRatingsDocDefinition(structure) {
  const configStore = useConfigStore();
  const { getFormattedDateStringNoTime } = useDateField();
  const inspectionStore = useInspectionStore();
  const prevAcceptedInsp = structure?.keyFields;
  let seismicVulnerability = getSeismicVulnerability(
    inspectionStore,
    structure?.UserBrdg?.SEISMIC_VULN
  );

  let nstmInspectionCondition = getCondition(
    inspectionStore,
    structure?.UserInsp?.NSTM_CONDITION
  );
  let docDefinition = {
    header: function () {
      return getReportHeader("Ratings Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Ratings Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Inspection Status",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "20%",
            text: "1A09 Inspection Status: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.INSPECTION_STATUS,
              structure?.InspEvnt?.INSPSTAT
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Key Field Comparison since Last Accepted Inspection",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "27%",
            text: "",
            margin: [0, 5, 0, 0],
          },
          {
            width: "10%",
            text: "Current",
            bold: true,
            alignment: "center",
            margin: [0, 3, 0, 0],
          },
          {
            width: "2%",
            text: "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "9%",
            text: "Previous",
            bold: true,
            alignment: "center",
            margin: [0, 3, 0, 0],
          },
          {
            width: "18%",
            text: "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "12%",
            text: "7A07 Required",
            bold: true,
            alignment: "center",
            margin: [0, 3, 0, 0],
          },
          {
            width: "9%",
            text: "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "12%",
            text: "7A09 Interval",
            bold: true,
            alignment: "center",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["52%", "3%", "8%", "19%", "1%", "19%"],
          body: [
            [
              getRatingsTable(
                inspectionStore,
                structure,
                getFormattedDateStringNoTime,
                prevAcceptedInsp
              ),
              {
                text: "",
                margin: [0, 0, 0, 0],
              },
              {
                table: {
                  headerRows: 0,
                  widths: ["100%"],
                  body: [
                    [
                      {
                        text: "\n",
                      },
                    ],
                    [
                      {
                        text: "NBI",
                        bold: true,
                        alignment: "center",
                        margin: [0, 1, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "NSTM",
                        bold: true,
                        alignment: "center",
                        margin: [0, 1, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "UW",
                        bold: true,
                        alignment: "center",
                        margin: [0, 0, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "OS",
                        bold: true,
                        alignment: "center",
                        margin: [0, 1, 0, 0],
                      },
                    ],
                    [
                      {
                        text: "Element",
                        bold: true,
                        alignment: "center",
                        margin: [0, 0, 0, 0],
                      },
                    ],
                  ],
                },
                layout: "noBorders",
                margin: [0, 0, 0, 0],
              },
              {
                table: {
                  headerRows: 0,
                  widths: ["50%", "50%"],
                  body: [
                    [
                      {
                        text: "Current",
                        alignment: "center",
                      },
                      {
                        text: "Previous",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: "N/A",
                        alignment: "center",
                        margin: [0, 0, 0, 0],
                      },
                      {
                        text: "N/A",
                        alignment: "center",
                        margin: [0, 0, 0, 0],
                      },
                    ],
                    [
                      {
                        text: inspectionStore.getReportCheckbox(
                          structure?.InspEvnt?.FCINSPREQ
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 0],
                      },
                      {
                        text: inspectionStore.getReportCheckbox(
                          prevAcceptedInsp?.fcInspectionReq
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 0],
                      },
                    ],
                    [
                      {
                        text: inspectionStore.getReportCheckbox(
                          structure?.InspEvnt?.UWINSPREQ
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 0],
                      },
                      {
                        text: inspectionStore.getReportCheckbox(
                          prevAcceptedInsp?.uwInspectionReq
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 0],
                      },
                    ],
                    [
                      {
                        text: inspectionStore.getReportCheckbox(
                          structure?.InspEvnt?.OSINSPREQ
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 0],
                      },
                      {
                        text: inspectionStore.getReportCheckbox(
                          prevAcceptedInsp?.osInspectionReq
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 0],
                      },
                    ],
                    [
                      {
                        text: inspectionStore.getReportCheckbox(
                          structure?.InspEvnt?.ELINSPREQ
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 0],
                      },
                      {
                        text: inspectionStore.getReportCheckbox(
                          prevAcceptedInsp?.elInspectionReq
                        ),
                        alignment: "center",
                        style: "customFontAwesome",
                        margin: [0, 1.5, 0, 1],
                      },
                    ],
                  ],
                },
                layout: {
                  hLineWidth: function (i) {
                    return i < 2 || i > 5 ? 1 : 0;
                  },
                },
                margin: [0, 0, 0, 0],
              },

              {
                text: "",
                margin: [0, 0, 0, 0],
              },
              {
                table: {
                  headerRows: 0,
                  widths: ["50%", "50%"],
                  body: [
                    [
                      {
                        text: "Current",
                        alignment: "center",
                      },
                      {
                        text: "Previous",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: structure?.InspEvnt?.BRINSPFREQ + " mos",
                        alignment: "center",
                      },
                      {
                        text: prevAcceptedInsp?.brInspectionFreq
                          ? prevAcceptedInsp?.brInspectionFreq + " mos"
                          : "--",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: structure?.InspEvnt?.FCINSPFREQ + " mos",
                        alignment: "center",
                      },
                      {
                        text: prevAcceptedInsp?.fcInspectionFreq
                          ? prevAcceptedInsp?.fcInspectionFreq + " mos"
                          : "--",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: structure?.InspEvnt?.UWINSPFREQ + " mos",
                        alignment: "center",
                      },
                      {
                        text: prevAcceptedInsp?.uwInspectionFreq
                          ? prevAcceptedInsp?.uwInspectionFreq + " mos"
                          : "--",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: structure?.InspEvnt?.OSINSPFREQ + " mos",
                        alignment: "center",
                      },
                      {
                        text: prevAcceptedInsp?.osInspectionFreq
                          ? prevAcceptedInsp?.osInspectionFreq + " mos"
                          : "--",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: structure?.InspEvnt?.ELINSPFREQ + " mos",
                        alignment: "center",
                      },
                      {
                        text: prevAcceptedInsp?.elInspectionFreq
                          ? prevAcceptedInsp?.elInspectionFreq + " mos"
                          : "--",
                        alignment: "center",
                      },
                    ],
                  ],
                },
                layout: {
                  hLineWidth: function (i) {
                    return i < 2 || i > 5 ? 1 : 0;
                  },
                },
                margin: [0, 0, 0, 0],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 0, 0, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Condition Ratings",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "28%",
            text: "1A01 Deck: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.DKRATING
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "1A04 Superstructure: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.SUPRATING
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "1A02 Substructure: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.SUBRATING
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "1A05 Channel: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.CHANRATING
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "1A03 Culvert: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.CULVRATING
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "1A06 Waterway: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.WATERADEQ
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "1A15 NSTM Inspection Condition: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              nstmInspectionCondition
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "1A16 Lowest Condition Rating: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.UserInsp?.LOWEST_CONDITION
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        text: "2A02 Inspection Notes: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.InspEvnt?.NOTES,
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Appraisal Ratings",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "28%",
            text: "IA02 Railing: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.RAILING_RATING,
              structure?.InspEvnt?.RAILRATING
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "4A02 Approach Alignment: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL,
              structure?.InspEvnt?.APPRALIGN
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "IA02 Transition: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.TRANSITION_RATING,
              structure?.InspEvnt?.TRANSRATIN
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "6B38 Approach Slab: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.UserInsp?.APPR_SLAB_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "IA02 Approach Guide Rail: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.APPROACH_GUIDE_RAIL_RATING,
              structure?.InspEvnt?.ARAILRATIN
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "6B39 Approach Roadway: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.UserInsp?.APPR_ROAD_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "IA02 Approach Rail End: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.APPROACH_RAIL_END_RATING,
              structure?.InspEvnt?.AENDRATING
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "4A10 Deck Geometry: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL,
              structure?.InspEvnt?.DECKGEOM
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "6B35 New Protective Coating? ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: inspectionStore.getReportCheckbox(
              structure?.UserInsp?.NEW_PAINT
            ),
            style: "customFontAwesome",
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "4A11 Underclearance: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL,
              structure?.InspEvnt?.UNDERCLR
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "6B36 Protective Coating: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.PAINT_CONDITION_RATING,
              structure?.UserInsp?.PAINT_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "6B40 Deck Wearing Surface: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.UserInsp?.DECK_WS_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "6B37 Protective Coating (Extent): ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.PAINT_CONDITION_RATING_EXTENT,
              structure?.UserInsp?.PAINT_EXTENT_RATE
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "4A08 SCBI: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SCOUR_CRITICAL_BRIDGE_INDICATOR,
              structure?.InspEvnt?.SCOURCRIT
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "4B03 Posting:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.POSTING,
              structure?.Bridge?.POSTING
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "4A08b SCBI Category: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure?.InspEvnt?.SCOUR_CRIT_CTGRY,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "28%",
            text: "4A26 Seismic Vulnerability:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SEISMIC_VULNERABILITY,
              seismicVulnerability
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: "4A14 Bridge Condition: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.BRIDGE_CONDITION_RATING,
              structure?.InspEvnt?.CONDITION
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}
function getNSTMInspectionCondition(structure) {
  if (
    structure?.UserBrdg?.MAIN_FC_GROUP_NUM < 5 ||
    structure?.UserBrdg?.APPR_FC_GROUP_NUM < 5
  ) {
    return structure?.InspEvnt?.SUPRATING;
  } else {
    return "N";
  }
}
function getCondition(inspectionStore, nstmInspectionCondition) {
  if (
    !nstmInspectionCondition &&
    !inspectionStore.getNstmInspectionConditionChangedByUser
  ) {
    return getNSTMInspectionCondition();
  } else {
    return nstmInspectionCondition;
  }
}
function getSeismicVulnerability(inspectionStore, seismicVulnerability) {
  if (
    !seismicVulnerability &&
    !inspectionStore.getSeismicVulnerabilityChangedByUser
  ) {
    return "0";
  } else {
    return seismicVulnerability;
  }
}
function checkPrevAcceptedInspection(value) {
  if (value) {
    return value;
  } else {
    return "--";
  }
}
function getRatingsTable(
  inspectionStore,
  structure,
  getFormattedDateStringNoTime,
  prevAcceptedInsp
) {
  return {
    table: {
      headerRows: 0,
      widths: ["56%", "22%", "22%"],
      body: [
        [
          {
            text: "7A01 Inspection Date:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: getFormattedDateStringNoTime(structure?.InspEvnt?.INSPDATE),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: getFormattedDateStringNoTime(
              prevAcceptedInsp?.inspectionDate
            ),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "7A03 Inspection Type:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.InspEvnt?.INSPTYPE,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(prevAcceptedInsp?.inspectionType),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "1A01 Deck:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.InspEvnt?.DKRATING,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(prevAcceptedInsp?.dkRating),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "1A04 Superstructure:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure.InspEvnt?.SUPRATING,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(prevAcceptedInsp?.supRating),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "1A02 Substructure:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.InspEvnt?.SUBRATING,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(prevAcceptedInsp?.subRating),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "1A03 Culvert:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.InspEvnt?.CULVRATING,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(prevAcceptedInsp?.culvRating),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "IA02/B.C.05 Railings:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.InspEvnt?.RAILRATING,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(prevAcceptedInsp?.railRating),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "IA02/B.C.06 Transition:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.InspEvnt?.TRANSRATIN,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(prevAcceptedInsp?.transRating),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "IB01 Bearings:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure.T_Bearings?.[0]?.BEARING_OVERALL_COND_RATE,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(
              prevAcceptedInsp?.bearingsOverallCondRating
            ),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "IJ01 Joints:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure.T_Joints?.[0]?.OVERALL_COND_RATING,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(
              prevAcceptedInsp?.jointsOverallCondRating
            ),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "1A05 Channel:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.InspEvnt?.CHANRATING,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(prevAcceptedInsp?.chanRating),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "1A05b Channel Protection:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.UserInsp?.CHAN_PROT_RATING,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(prevAcceptedInsp?.chanProtRating),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "1A13 Scour:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: inspectionStore.getOverallScourHoles(structure),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(
              inspectionStore.getPrevOverallScourHoles(prevAcceptedInsp)
            ),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "1A14 Underwater:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.UserInsp?.UNDERWATER_RATING,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(
              prevAcceptedInsp?.underwaterRating
            ),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "1A15 NSTM:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.UserInsp?.NSTM_CONDITION,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(prevAcceptedInsp?.nstmCondition),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "4A08 SCBI:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.InspEvnt?.SCOURCRIT,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(prevAcceptedInsp?.scourCritical),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "4A08b SCBI Category:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.InspEvnt?.SCOUR_CRIT_CTGRY,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(
              prevAcceptedInsp?.scourCriticalCat
            ),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "IN15 Streambed Material:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: getIN15Value(structure?.T_Underwater_Insp),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: prevAcceptedInsp?.streamBedMaterial
              ? getIN15ValueFromMaterials(prevAcceptedInsp?.streamBedMaterial)
              : "--",
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
        [
          {
            text: "4A14 Bridge Condition:",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            text: structure?.InspEvnt?.CONDITION,
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
          {
            text: checkPrevAcceptedInspection(prevAcceptedInsp?.condition),
            alignment: "center",
            margin: [0, 5, 0, 0],
          },
        ],
      ],
    },
    layout: "noBorders",
    margin: [0, 0, 0, 0],
  };
}
export { setRatingsDocDefinition };
