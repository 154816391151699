const objSignLightInsp = {
  ACCESS_COND_RATE: "",
  BASE_COND_RATE: "",
  BRKEY: "",
  COLUMN_COND_RATE: "",
  GRAIL_COND_RATE: "",
  HOR_COND_RATE: "",
  INSPKEY: "",
  LIGHT_COND_RATE: "",
  MODTIME: "",
  NEXT_SIGN_INSPTYPE: "",
  SIGN_ASSET_TAG: "",
  SIGN_COND_RATE: "",
  SIGN_INSPTYPE: "",
  STRRATING: "",
  SURFACE_COND_RATE: "",
  USERKEY: "",
};
export default objSignLightInsp;
