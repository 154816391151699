/**
 * sortStructure is used to sort the table in a report
 * based on structure property and field name.
 * example propery and fieldName is below
 *      -----------------------------------------------------
 *      |           |   structureProperty   |   fieldName   |
 *      -----------------------------------------------------
 *      |   ID01    |   T_Signing           |   SIGN_TYPE   |
 *      |   5D01    |   Structure_Unit      |   STRUNITKEY  |
 *      |   5C03    |   Roadway             |   ON_UNDER    |
 *      -----------------------------------------------------
 * @param {Array} structure
 * @param {string} structureProperty
 * @param {string} fieldName
 * @return {Array}
 */
export const sortStructure = (structure, structureProperty, fieldName) => {
    return structure?.[structureProperty]?.sort((a, b) => {
        const valueA = a[fieldName];
        const valueB = b[fieldName];

        // Handle numeric and string values differently
        if (typeof valueA === 'number' && typeof valueB === 'number') {
            return valueA - valueB;
        } else {
            // Use localeCompare for string values
            return String(valueA).localeCompare(String(valueB));
        }
    }) || [];
}