import { HTTP_STATUS_CODE } from "@/constants/CommonWebCodes";
import { EDOC_ERROR_DESC } from "@/constants/Inspections";

//Return 0 if the value is blank or non number
function convertToInt(value) {
  return parseInt(value) || 0;
}

function convertToFloat(value, defaultValue) {
  if (value == 0) {
    return 0;
  }
  return (
    parseFloat(value) ||
    (defaultValue || defaultValue == 0 ? defaultValue : null)
  );
}

function convertToDecimalString(value, decimals) {
  if (decimals) {
    return (
      parseFloat(value)?.toFixed(decimals) || parseFloat(0).toFixed(decimals)
    );
  }
  return parseFloat(value)?.toString() || parseFloat(0).toString();
}

function convertToPercent(value, totalValue) {
  return convertToDecimalString(
    (parseFloat(value) * 100) / parseFloat(totalValue) || 0,
    2
  );
}
function convertFromPercent(value, totalValue) {
  return Math.round((parseFloat(value) * parseFloat(totalValue)) / 100);
}

function getHistDuration(duration, durationChanges) {
  //format the duration to shown as tooltip text
  let durationText = "0.0 year";
  if (!durationChanges) {
    durationChanges = 0;
  }

  if (duration && durationChanges < 2) {
    durationText =
      (Math.round(duration * 100) / 100).toFixed(1) +
      (duration > 1 ? " years" : " year");
  } else if (durationChanges > 1) {
    durationText =
      "Cannot display condition rating duration due to multiple changes.";
  }
  return durationText;
}

function compareValues(key, sortOrder) {
  return function innerSort(a, b) {
    let varA;
    let varB;
    //if key is an property inside nested object. Ex: district.districtNum
    let props = key.split(".");
    const lastKey = props.pop();
    const nestedObj = props.reduce((obj, prop) => obj[prop], a);
    if (nestedObj && Object.hasOwn(nestedObj, lastKey)) {
      varA =
        parseFloat(nestedObj[lastKey]) ||
        (typeof nestedObj[lastKey] === "string"
          ? nestedObj[lastKey].toUpperCase()
          : nestedObj[lastKey]);
    }

    const nestedObjB = props.reduce((obj, prop) => obj[prop], b);
    if (nestedObjB && Object.hasOwn(nestedObjB, lastKey)) {
      varB =
        parseFloat(nestedObjB[lastKey]) ||
        (typeof nestedObjB[lastKey] === "string"
          ? nestedObjB[lastKey].toUpperCase()
          : nestedObjB[lastKey]);
    }
    return compare(varA, varB) * sortOrder;
  };
}

function compare(valueA, valueB) {
  let comparison = 0;
  if (valueA > valueB) {
    comparison = 1;
  } else if (valueA < valueB) {
    comparison = -1;
  }
  return comparison;
}
function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

function getEDocDownloadErrorDesc(errorCode) {
  if (errorCode == HTTP_STATUS_CODE.NOT_FOUND) {
    return EDOC_ERROR_DESC.NOT_FOUND;
  } else if (errorCode == HTTP_STATUS_CODE.SERVICE_UNAVAILABLE) {
    return EDOC_ERROR_DESC.SERVICE_UNAVAILABLE;
  } else if (errorCode == HTTP_STATUS_CODE.SERVER_ERROR) {
    return EDOC_ERROR_DESC.DOC_SERVICE_ERROR;
  } else {
    return EDOC_ERROR_DESC.NOT_FOUND;
  }
}

function getDefaultValue(value, defaultValue) {
  return value || defaultValue;
}

function splitInput(input, splitBy) {
  return input.split(splitBy);
}

const getMessage = (messageValue) => {
  return { msgDTO: { message: messageValue } };
};

const truncateString = (str, max) => {
  if (str?.length <= max) {
    return str;
  }
  return str?.slice(0, max);
};

const encodeString = (str) => {
  if (str) {
    str = str.replaceAll("”", '"');
    str = str.replaceAll("’", "'");
    str = str.replaceAll("–", "-");
    str = str.replaceAll("“", '"');
    str = str.replaceAll("‘", "'");
  }
  return str;
};

const scrubCharFromStart = (str, char) => {
  if (str && str.indexOf(char) == 0) {
    return str.substring(1);
  }
  return str;
};
const appendUnits = (value, unit) => {
  if (value) {
    return value + " " + unit;
  }
  return value;
};

const sortEquipmentArray = (equipments) => {
  equipments.sort((a, b) => {
    if (a.value === "AN") return -1;
    if (b.value === "AN") return 1;
    if (a.value === "AX") return 1;
    if (b.value === "AX") return -1;

    if (a.value === "IN") return -1;
    if (b.value === "IN") return 1;
    if (a.value === "IX") return 1;
    if (b.value === "IX") return -1;

    return a.value < b.value ? -1 : 1;
  });
  return equipments;
};

export {
  encodeString,
  convertToInt,
  convertToFloat,
  convertToPercent,
  convertFromPercent,
  convertToDecimalString,
  getHistDuration,
  compareValues,
  compare,
  base64ToArrayBuffer,
  getEDocDownloadErrorDesc,
  getDefaultValue,
  splitInput,
  getMessage,
  truncateString,
  scrubCharFromStart,
  appendUnits,
  sortEquipmentArray,
};
