import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { getElementReport } from "@/composables/elementsHierarchy";
import { styles } from "@/reports/reportStyles";

function setElementsDocDefinition(structure) {
  const content = getElementReport(structure);
  let docDefinition = {
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    header: function () {
      return getReportHeader("Elements Page", structure);
    },
    pageMargins: [20, 70, 20, 50],
    content: content,
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };

  return docDefinition;
}
export { setElementsDocDefinition };
