<template>
  <v-row>
    <v-col class="px-0" cols="12">
      <v-btn
        id="btn_startNewInsp"
        variant="outlined"
        class="my-2"
        @click="startInspection"
        :disabled="
          structure.InspEvnt.INSPSTAT != '9' ||
          structure.InspEvnt.INSPKEY != inspectionArr[0]?.value
        "
        >Start New Inspection</v-btn
      >
    </v-col>
  </v-row>
  <v-row>
    <v-col class="px-0" cols="12" sm="12" md="12" lg="6" xl="6">
      <h3 class="withBackground px-2">Structure Identification</h3>
      <div class="px-4 py-2">
        <LabelText
          id="field_Structure_ID"
          label="5A01 Structure ID"
          v-model="structure.Bridge.BRIDGE_ID"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Structure_Name"
          label="5A02 Structure Name"
          v-model="structure.Bridge.STRUCNAME"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_NBI_Structure_No"
          label="5A03 NBI Structure No"
          v-model="structure.Bridge.STRUCT_NUM"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>
      </div>
      <h3 class="withBackground px-2">Location</h3>
      <div class="px-4 py-2">
        <LabelText
          id="field_District"
          label="5A04 District"
          v-model="structureDistrict"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_County"
          label="5A05 County"
          v-model="structureCounty"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_City_Town_Place"
          label="5A06 City/Town/Place"
          v-model="structureMunicipality"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Feature_Intersected"
          label="5A07 Feature Intersected"
          v-model="structure.Bridge.FEATINT"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Facility_Carried"
          label="5A08 Facility Carried"
          v-model="structure.Bridge.FACILITY"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Location"
          label="5A09 Location"
          v-model="structure.Bridge.LOCATION"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Latitude"
          label="5A10 Latitude"
          v-model="latitude"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Longitude"
          label="5A11 Longitude"
          v-model="longitude"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>
      </div>
      <h3 class="withBackground px-2">Age and Service</h3>
      <div class="px-4 py-2">
        <LabelText
          id="field_Year_Built"
          label="5A15 Year Built"
          v-model="structure.Bridge.YEARBUILT"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Year_Reconstruction"
          label="5A16 Year Reconstruction"
          v-model="structure.Bridge.YEARRECON"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Service_Type_On"
          label="5A17 Service Type On"
          v-model="serviceType"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Service_Type_Under"
          label="5A18 Service Type Under"
          v-model="serviceTypeUnder"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Number_of_Lanes_Under"
          label="5A19 Number of Lanes Under"
          v-model="structure.Bridge.SUMLANES"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Maintenance_Responsibility"
          label="5A20 Maintenance Responsibility"
          v-model="maintainanceResponsibility"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Owner_Code"
          label="5A21 Owner Code"
          v-model="ownerCode"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Submitting_Agency"
          label="6A06 Submitting Agency"
          v-model="submittingAgency"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Owner_Description"
          label="6A23 Owner Description"
          v-model="structure.UserBrdg.OWNER_DESC"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Business_Plan_Network"
          label="6A19 Business Plan Network"
          v-model="businessPlanNetwork"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Posting_Status"
          label="VP02 Posting Status"
          :model-value="postStatus"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>
      </div>
    </v-col>
    <v-col class="px-0" cols="12" sm="12" md="12" lg="6" xl="6">
      <h3 class="withBackground px-2">Deck Information</h3>
      <div class="px-4 py-2">
        <LabelText
          id="field_Deck_Structure_Type"
          label="5B01 Deck Structure Type"
          v-model="deckStructureType"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Deck_Surface_Type"
          label="5B02 Deck Surface Type"
          v-model="deckSurfaceType"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Deck_Membrane_Type"
          label="5B03 Deck Membrane Type"
          v-model="deckMembraneType"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Deck_Protection"
          label="5B04 Deck Protection"
          v-model="deckProtection"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Left_Curb_Sidewalk_Width"
          label="5B05 Left Curb/Sidewalk Width"
          v-model="leftCurbSidewalkWidth"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Right_Curb_Sidewalk_Width"
          label="5B06 Right Curb/Sidewalk Width"
          v-model="rightCurbsidewalkWidth"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Deck_Width"
          label="5B07 Deck Width (O/O)"
          v-model="deckWidth"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Median_Type"
          label="5B08 Median Type"
          v-model="medianType"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Skew"
          label="5B09 Skew"
          v-model="skew"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Structure_Flare"
          label="5B10 Structure Flare"
          v-model="structureFlare"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_PennDOT_Deck_Type"
          label="6A38 PennDOT Deck Type"
          v-model="penndotDeckType"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Relief_Joints"
          label="6A39 Relief Joints?"
          v-model="reliefJoints"
          :showColon="false"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Form_Type"
          label="6A40 Form Type"
          v-model="formType"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_no_of_joints"
          label="6A41 No. of Joints"
          v-model="structure.UserBrdg.DECK_JOINTS"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>
      </div>
      <h3 class="withBackground px-2">Span Information</h3>
      <div class="px-4 py-2">
        <LabelText
          id="field_No_Of_Main_Spans"
          label="5B11 No. of Main Spans"
          v-model="structure.Bridge.MAINSPANS"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Main_Span_Material_Type"
          label="5B12 Main Span Material Type"
          v-model="mainSpanMaterialType"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Main_Span_Design_Type"
          label="5B13 Main Span Design Type"
          v-model="mainSpanDesignType"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_No_of_Approach_Spans"
          label="5B14 No. of Approach Spans"
          v-model="structure.Bridge.APPSPANS"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Approach_Span_Material_Type"
          label="5B15 Approach Span Material Type"
          v-model="approachSpanMaterialType"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Approach_Span_Design_Type"
          label="5B16 Approach Span Design Type"
          v-model="approachSpanDesignType"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Structure_Length"
          label="5B18 Structure Length"
          v-model="structureLength"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Culvert_Length"
          label="VD19 Culvert Length"
          v-model="structureCulvertLength"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Deck_Area"
          label="5B19 Deck Area"
          v-model="deckArea"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>
      </div>
      <h3 class="withBackground px-2">Classification Items</h3>
      <div class="px-4 py-2">
        <LabelText
          id="field_NBIS_Bridge_Length"
          label="5E01 NBIS Bridge Length"
          v-model="bridgeLengthNBIS"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Temporary_Structure"
          label="5E03 Temporary Structure"
          v-model="temporaryStructure"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Historical_Significance"
          label="5E04 Historical Significance"
          v-model="historicalSignificance"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>

        <LabelText
          id="field_Reporting_Group"
          label="5A24 Report Group"
          v-model="reportingGroup"
          labelXl="5"
          labelLg="5"
          labelMd="5"
          labelSm="6"
          textXl="7"
          textLg="7"
          textMd="6"
          textSm="6"
        >
        </LabelText>
      </div>
    </v-col>
  </v-row>
  <v-row>
    <v-col class="px-0" sm="12">
      <LabelText
        labelXl="12"
        labelLg="12"
        labelMd="12"
        labelSm="12"
        textXl="12"
        textLg="12"
        textMd="12"
        textSm="12"
        id="text_Structure_Notes"
        label="2A01 Structure Notes"
        v-model="structure.Bridge.NOTES"
        :maxlength="notesMaxLength"
        type="textarea"
        counter
        persistentCounter
        class="pb-0"
      >
      </LabelText>
    </v-col>
  </v-row>
  <v-row>
    <v-col class="px-0" sm="12">
      <h3 class="withBackground px-2">Structure Type</h3>
    </v-col>
    <v-table class="w-100 compactTable">
      <thead>
        <tr>
          <td id="td_Blank" class="w-30 tdForTableHeader"></td>
          <th id="th_Main" class="w-35">Main</th>
          <th id="th_Approach" class="w-35">Approach</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>6A26 Material</td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.MATERIAL_MAKEUP,
                structure?.UserBrdg?.DEPT_MAIN_MATERIAL_TYPE
              )
            }}
          </td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.MATERIAL_MAKEUP,
                structure?.UserBrdg?.DEPT_APPR_MATERIAL_TYPE
              )
            }}
          </td>
        </tr>
        <tr>
          <td>6A27 Physical Span Make-Up</td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.PHYSICAL_MAKEUP,
                structure?.UserBrdg?.DEPT_MAIN_PHYSICAL_TYPE
              )
            }}
          </td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.PHYSICAL_MAKEUP,
                structure?.UserBrdg?.DEPT_APPR_PHYSICAL_TYPE
              )
            }}
          </td>
        </tr>
        <tr>
          <td>6A28 Span Interaction</td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.SPAN_INTERACTION,
                structure?.UserBrdg?.DEPT_MAIN_SPAN_INTERACTION
              )
            }}
          </td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.SPAN_INTERACTION,
                structure?.UserBrdg?.DEPT_APPR_SPAN_INTERACTION
              )
            }}
          </td>
        </tr>
        <tr>
          <td>6A29 Structure Type</td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.STRUCTURAL_CONFIGURATION,
                structure?.UserBrdg?.DEPT_MAIN_STRUC_CONFIG
              )
            }}
          </td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.STRUCTURAL_CONFIGURATION,
                structure?.UserBrdg?.DEPT_APPR_STRUC_CONFIG
              )
            }}
          </td>
        </tr>
        <tr>
          <td>6A33 Wearing Surface Thickness</td>
          <td>
            {{
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.MILLIMETERS_TO_INCHES,
                structure?.UserBrdg?.MAIN_WS_THICKNESS,
                ROUNDING_FACTOR.TENTH,
                true
              )
            }}
          </td>
          <td>
            {{
              configStore.getMetricToEnglishConversions(
                PAIR_CODE.MILLIMETERS_TO_INCHES,
                structure?.UserBrdg?.APPR_WS_THICKNESS,
                ROUNDING_FACTOR.TENTH,
                true
              )
            }}
          </td>
        </tr>
        <tr>
          <td>6A34 WS Thickness Date</td>
          <td>
            {{ wsThicknessDate }}
          </td>
          <td>
            {{ apprWsThicknessDate }}
          </td>
        </tr>
        <tr>
          <td>6A44 FC Group No.</td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.FC_GROUP_NUMBER,
                structure?.UserBrdg?.MAIN_FC_GROUP_NUM
              )
            }}
          </td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.FC_GROUP_NUMBER,
                structure?.UserBrdg?.APPR_FC_GROUP_NUM
              )
            }}
          </td>
        </tr>
        <tr>
          <td>6A45 FC Member Type</td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.MEMBER_TYPE,
                structure?.UserBrdg?.MAIN_CRF_MEM_TYPE
              )
            }}
          </td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.MEMBER_TYPE,
                structure?.UserBrdg?.APPR_CRF_MEM_TYPE
              )
            }}
          </td>
        </tr>
        <tr>
          <td>6A46 FC Fatigue Susceptibility</td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.CRF_FATIGUE_SUSCEPTIBILITY,
                structure?.UserBrdg?.MAIN_CRF_FATIG_SUS
              )
            }}
          </td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.CRF_FATIGUE_SUSCEPTIBILITY,
                structure?.UserBrdg?.APPR_CRF_FATIG_SUS
              )
            }}
          </td>
        </tr>
        <tr>
          <td>6A47 FC Material Type</td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.CRF_MATERIAL,
                structure?.UserBrdg?.MAIN_CRF_MATERIAL
              )
            }}
          </td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.CRF_MATERIAL,
                structure?.UserBrdg?.APPR_CRF_MATERIAL
              )
            }}
          </td>
        </tr>
        <tr>
          <td>6A48 FC ADTT</td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.CUMULATIVE_AVERAGE_DAILY_TRUCK_TRAFFIC,
                structure?.UserBrdg?.MAIN_CRF_CUM_ADTT
              )
            }}
          </td>
          <td>
            {{
              configStore.getReferenceValue(
                REFERENCE_TABLE.CUMULATIVE_AVERAGE_DAILY_TRUCK_TRAFFIC,
                structure?.UserBrdg?.APPR_CRF_CUM_ADTT
              )
            }}
          </td>
        </tr>
        <tr>
          <td>6A49 FC Total CRF</td>
          <td>
            {{ fctotalCrfMain }}
          </td>
          <td>
            {{ fctotalCrfApproach }}
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-row>
  <v-row class="mt-2">
    <v-col class="px-0" sm="12">
      <h3 class="withBackground px-2">Span-Specific Information</h3>
    </v-col>
  </v-row>
  <v-row class="mt-2">
    <v-col class="px-0">
      <v-table class="w-100 compactTable span-specific-table">
        <thead>
          <tr>
            <th id="th_5D01">
              5D01<br />
              Unit Key
            </th>
            <th id="th_5D04">5D04/SP01<br />Type</th>
            <th id="th_5D02">5D02/SP02<br />Unit ID</th>
            <th id="th_5D01">SP03<br />Span Length</th>
            <th id="th_5D04">SP04<br />Deck Width</th>
            <th id="th_5D02">SP05<br />Flared?</th>
            <th id="th_SP07">SP07 to SP10<br />Structural Config</th>
            <th id="th_DESCRIPTION">Structural Type Description</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(specificSpan, idx) in structureUnits" :key="idx">
            <td>{{ specificSpan.STRUNITKEY }}</td>
            <td>
              {{
                configStore.getReferenceValue(
                  REFERENCE_TABLE.STRUCTURE_UNIT_TYPE,
                  specificSpan.STRUNITTYPE
                )
              }}
            </td>
            <td>{{ specificSpan.STRUNITLABEL }}</td>
            <td>
              {{
                configStore.getMetricToEnglishConversions(
                  PAIR_CODE.METERS_TO_FEET,
                  specificSpan.userStrUnit.SPAN_LENGTH,
                  ROUNDING_FACTOR.TENTH
                )
              }}
            </td>
            <td>
              {{
                configStore.getMetricToEnglishConversions(
                  PAIR_CODE.METERS_TO_FEET,
                  specificSpan.userStrUnit.SPAN_DECK_WIDTH,
                  ROUNDING_FACTOR.TENTH
                )
              }}
            </td>
            <td>
              <LabelCheckbox
                style="display: inline-flex !important"
                :id="'checkbox_spanFlared' + idx"
                v-model="specificSpan.userStrUnit.SPAN_FLARED"
              >
              </LabelCheckbox>
            </td>
            <td>
              {{
                (specificSpan.userStrUnit.DEPT_MATERIAL_TYPE ?? "") +
                (specificSpan.userStrUnit.DEPT_PHYSICAL_TYPE ?? "") +
                (specificSpan.userStrUnit.DEPT_SPAN_INTERACTION ?? "") +
                (specificSpan.userStrUnit.DEPT_STRUC_CONFIG ?? "")
              }}
            </td>
            <td>
              {{
                configStore.getDepartmentStructureTypeDescription(
                  specificSpan.userStrUnit.DEPT_MATERIAL_TYPE +
                    specificSpan.userStrUnit.DEPT_PHYSICAL_TYPE +
                    specificSpan.userStrUnit.DEPT_SPAN_INTERACTION +
                    specificSpan.userStrUnit.DEPT_STRUC_CONFIG
                )
              }}
            </td>
          </tr>
        </tbody></v-table
      >
    </v-col>
  </v-row>
  <StartNewInspection
    ref="startNewInspection"
    @start-new-inspection="newInspection"
  />
</template>

<script>
import { computed, ref } from "vue";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import useDateField from "@/composables/dateField.js";
import useCoordinates from "@/composables/coordinates.js";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { convertToInt } from "@/composables/util";
import LabelText from "@/components/common/LabelText.vue";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";
import StartNewInspection from "@/components/inspection/StartNewInspection.vue";
import { SYMBOLS } from "@/constants/Unicode";
export default {
  name: "InspectionGeneral",
  emits: ["startNewInspection"],
  async setup(_, ctx) {
    const configStore = useConfigStore();
    const inspectionStore = useInspectionStore();
    const structure = computed(() => inspectionStore.selectedInspection);
    const coordinates = useCoordinates();
    const { getFormattedDateStringNoTime } = useDateField();
    let structureCulvertLength = "";
    let structureNotes = structure.value?.InspEvnt?.NOTES;
    let startNewInspection = ref(null);

    let inspectionArr = computed(() => inspectionStore.inspectionArray);

    let latitude = coordinates.parseBMSLatLongToDMS(
      structure.value?.Bridge?.LATITUDE,
      "N"
    ).decimal;
    let longitude = coordinates.parseBMSLatLongToDMS(
      structure.value?.Bridge?.LONGITUDE,
      "W"
    ).decimal;
    let serviceType = configStore.getReferenceValue(
      REFERENCE_TABLE.SERVICE_TYPE_ON,
      structure.value?.Bridge?.SERVTYPON
    );

    let serviceTypeUnder = configStore.getReferenceValue(
      REFERENCE_TABLE.SERVICE_TYPE_UNDER,
      structure.value?.Bridge?.SERVTYPUND
    );

    let submittingAgency = configStore.getReferenceValue(
      REFERENCE_TABLE.SUB_AGENCY,
      structure.value?.UserBrdg?.SUB_AGENCY
    );

    let businessPlanNetwork = configStore.getReferenceValue(
      REFERENCE_TABLE.BUS_PLAN_NETWORK,
      structure.value?.UserBrdg?.BUS_PLAN_NETWORK
    );

    let maintainanceResponsibility = configStore.getReferenceValue(
      REFERENCE_TABLE.MAINTENENCE_RESPONSIBILITY,
      structure.value?.Bridge?.CUSTODIAN
    );
    let ownerCode = configStore.getReferenceValue(
      REFERENCE_TABLE.OWNER,
      structure.value?.Bridge?.OWNER
    );
    let deckStructureType = configStore.getReferenceValue(
      REFERENCE_TABLE.DECK_STRUCTURE_TYPE,
      structure.value?.Bridge?.DKSTRUCTYP
    );

    let deckSurfaceType = configStore.getReferenceValue(
      REFERENCE_TABLE.DECK_SURFACE_TYPE,
      structure.value?.Bridge?.DKSURFTYPE
    );

    let deckMembraneType = configStore.getReferenceValue(
      REFERENCE_TABLE.DECK_MEMBRANE_TYPE,
      structure.value?.Bridge?.DKMEMBTYPE
    );

    let deckProtection = configStore.getReferenceValue(
      REFERENCE_TABLE.DECK_PROTECTION_TYPE,
      structure.value?.Bridge?.DKPROTECT
    );

    let medianType = configStore.getReferenceValue(
      REFERENCE_TABLE.BRIDGE_MEDIAN,
      structure.value?.Bridge?.BRIDGEMED,
      true
    );

    let leftCurbSidewalkWidth = configStore.getMetricToEnglishConversions(
      PAIR_CODE.METERS_TO_FEET,
      structure.value?.Bridge?.LFTCURBSW,
      ROUNDING_FACTOR.TENTH,
      true
    );

    let rightCurbsidewalkWidth = configStore.getMetricToEnglishConversions(
      PAIR_CODE.METERS_TO_FEET,
      structure.value?.Bridge?.RTCURBSW,
      ROUNDING_FACTOR.TENTH,
      true
    );

    let deckWidth = configStore.getMetricToEnglishConversions(
      PAIR_CODE.METERS_TO_FEET,
      structure.value?.Bridge?.DECKWIDTH,
      ROUNDING_FACTOR.TENTH,
      true
    );

    let structureFlare = configStore.getReferenceValue(
      REFERENCE_TABLE.STRUCTURE_FLARED,
      structure.value?.Bridge?.STRFLARED
    );

    let penndotDeckType = configStore.getReferenceValue(
      REFERENCE_TABLE.BRIDGE_DECK_TYPE,
      structure.value?.UserBrdg?.DEPT_DKSTRUCTYP
    );

    let reliefJoints = configStore.getReferenceValue(
      REFERENCE_TABLE.RELIEF_JOINT,
      structure.value?.UserBrdg?.RELIEF_JOINT
    );

    let formType = configStore.getReferenceValue(
      REFERENCE_TABLE.DECK_FORM_TYPE,
      structure.value?.UserBrdg?.DECK_FORM_TYPE
    );

    let mainSpanMaterialType = configStore.getReferenceValue(
      REFERENCE_TABLE.MAIN_SPAN_MATERIAL,
      structure.value?.Bridge?.MATERIALMAIN
    );

    let mainSpanDesignType = configStore.getReferenceValue(
      REFERENCE_TABLE.MAIN_SPAN_DESIGN,
      structure.value?.Bridge?.DESIGNMAIN
    );

    let approachSpanMaterialType = configStore.getReferenceValue(
      REFERENCE_TABLE.APPROACH_SPAN_MATERIAL,
      structure.value?.Bridge?.MATERIALAPPR
    );

    let approachSpanDesignType = configStore.getReferenceValue(
      REFERENCE_TABLE.APPROACH_SPAN_DESIGN,
      structure.value?.Bridge?.DESIGNAPPR
    );

    let structureLength = configStore.getMetricToEnglishConversions(
      PAIR_CODE.METERS_TO_FEET,
      structure.value?.Bridge?.LENGTH,
      ROUNDING_FACTOR.ROUND,
      true
    );

    let deckArea = configStore.getMetricToEnglishConversions(
      PAIR_CODE.SQUAREMETERS_TO_SQUAREFEET,
      structure.value?.Bridge?.DECK_AREA,
      ROUNDING_FACTOR.ROUND,
      true
    );

    let bridgeLengthNBIS = configStore.getReferenceValue(
      REFERENCE_TABLE.NBIS_BRIDGE_LENGTH,
      structure.value?.Bridge?.NBISLEN
    );

    let temporaryStructure = configStore.getReferenceValue(
      REFERENCE_TABLE.TEMPORARY_STRUCTURE,
      structure.value?.Bridge?.TEMPSTRUC
    );

    let historicalSignificance = configStore.getReferenceValue(
      REFERENCE_TABLE.HISTORICAL_SIGNIFIGANCE,
      structure.value?.Bridge?.HISTSIGN
    );

    let reportingGroup = configStore.getReferenceValue(
      REFERENCE_TABLE.REPORTING_GROUP,
      structure.value?.Bridge?.REPORTGROUP
    );

    for (let idx in structure.value?.T_Culvert_Opening) {
      let structureCulvertLengthPlaceHolder =
        configStore.getMetricToEnglishConversions(
          PAIR_CODE.METERS_TO_FEET,
          structure.value?.T_Culvert_Opening[idx]?.CULVERT_LENGTH,
          ROUNDING_FACTOR.ROUND,
          true
        );
      if (
        idx != structure.value?.T_Culvert_Opening?.length - 1 &&
        structure.value?.T_Culvert_Opening[idx]?.CULVERT_LENGTH
      ) {
        structureCulvertLength +=
          structureCulvertLengthPlaceHolder.toString() + ", ";
      } else if (structure.value?.T_Culvert_Opening[idx]?.CULVERT_LENGTH) {
        structureCulvertLength += structureCulvertLengthPlaceHolder.toString();
      }
    }

    const postStatus = computed(() => {
      if (structure.value?.T_Posting) {
        const latestActivePosting = structure.value.T_Posting?.find(
          (posting) => posting?.ACTIVE == "1"
        );
        return configStore.getReferenceValue(
          REFERENCE_TABLE.POSTING_STATUS,
          latestActivePosting?.POST_STATUS
        );
      }
      return "";
    });

    let wsThicknessDate = getFormattedDateStringNoTime(
      structure.value?.UserBrdg?.MAIN_WS_THICK_DATE
    );
    let apprWsThicknessDate = getFormattedDateStringNoTime(
      structure.value?.UserBrdg?.APPR_WS_THICK_DATE
    );

    let fctotalCrfMain =
      convertToInt(structure.value?.UserBrdg?.MAIN_CRF_MEM_TYPE) +
      convertToInt(structure.value?.UserBrdg?.MAIN_CRF_FATIG_SUS) +
      convertToInt(structure.value?.UserBrdg?.MAIN_CRF_MATERIAL) +
      convertToInt(structure.value?.UserBrdg?.MAIN_CRF_CUM_ADTT);
    let fctotalCrfApproach =
      convertToInt(structure.value?.UserBrdg?.APPR_CRF_MEM_TYPE) +
      convertToInt(structure.value?.UserBrdg?.APPR_CRF_FATIG_SUS) +
      convertToInt(structure.value?.UserBrdg?.APPR_CRF_MATERIAL) +
      convertToInt(structure.value?.UserBrdg?.APPR_CRF_CUM_ADTT);
    const structureCounty = computed(() => {
      const c = configStore.getCountyByFips(structure.value?.Bridge?.COUNTY);
      return `${c.countyCode} - ${c.countyName}`;
    });
    const structureDistrict = computed(() => {
      const d = configStore.getDistrictByDistrictNum(
        structure.value?.Bridge?.DISTRICT
      );
      return `${structure.value?.Bridge?.DISTRICT} - ${d}`;
    });
    const structureMunicipality = computed(() => {
      const m = configStore.getMunicipalityByPlaceCode(
        structure.value?.Bridge?.PLACECODE
      );
      return `${m.countyCode}/${m.municipalityNum} - ${m.municipalityName}`;
    });
    let skew = "";
    if (structure.value.Bridge.SKEW) {
      skew = structure.value.Bridge.SKEW + SYMBOLS.DEGREE;
    }
    const notesMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });
    const startInspection = () => {
      startNewInspection.value.openDialog();
    };

    const newInspection = () => {
      ctx.emit("startNewInspection");
    };

    const structureUnits = computed(() => {
      return structure.value?.Structure_Unit?.filter(
        (x) => x.STRUNITTYPE != "X"
      ).map((s) => {
        return {
          ...s,
          userStrUnit: structure.value.UserStrUnit?.find(
            (u) => u.STRUNITKEY === s.STRUNITKEY
          ),
        };
      });
    });

    return {
      inspectionArr,
      newInspection,
      startNewInspection,
      ROUNDING_FACTOR,
      coordinates,
      PAIR_CODE,
      skew,
      getFormattedDateStringNoTime,
      REFERENCE_TABLE,
      configStore,
      apprWsThicknessDate,
      wsThicknessDate,
      postStatus,
      fctotalCrfMain,
      fctotalCrfApproach,
      structureCulvertLength,
      structureMunicipality,
      structureCounty,
      structureDistrict,
      structure,
      startInspection,
      notesMaxLength,
      structureNotes,
      structureUnits,
      latitude,
      longitude,
      serviceType,
      serviceTypeUnder,
      maintainanceResponsibility,
      ownerCode,
      submittingAgency,
      businessPlanNetwork,
      deckStructureType,
      deckSurfaceType,
      deckMembraneType,
      deckProtection,
      leftCurbSidewalkWidth,
      rightCurbsidewalkWidth,
      deckWidth,
      medianType,
      structureFlare,
      penndotDeckType,
      reliefJoints,
      formType,
      mainSpanMaterialType,
      mainSpanDesignType,
      approachSpanMaterialType,
      approachSpanDesignType,
      structureLength,
      deckArea,
      bridgeLengthNBIS,
      temporaryStructure,
      historicalSignificance,
      reportingGroup,
    };
  },
  components: {
    StartNewInspection,
    LabelText,
    LabelCheckbox,
  },
};
</script>
<style lang="scss">
@use "@/styles/colors" as c;
.tdForTableHeaderWithBottomBorder {
  background: c.$p-dark-grey !important;
  border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
}
.tdForTableHeader {
  background: c.$p-dark-grey !important;
}
h3 {
  text-align: center !important;
}
.span-specific-table th {
  vertical-align: baseline !important;
  text-align: center !important;
}
.span-specific-table td {
  text-align: center !important;
}
td.align-center :deep(.v-checkbox) {
  padding-left: 8px !important;
}
</style>
