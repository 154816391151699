import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";

import LandingPage from "@/views/LandingPage.vue";
import StructureSearch from "@/views/StructureSearch.vue";
import StructureInspection from "@/views/StructureInspection.vue";
import ViewWorkList from "@/views/ViewWorkList.vue";
import LogoutPage from "@/views/LogoutPage.vue";
import OfflineLogin from "@/views/OfflineLogin.vue";

import { useInspectionStore } from "@/stores/inspection.js";
import { INSPECTION_PAGES } from "@/constants/Inspections";
import auth from "@/auth";
import CallbackPage from "@/views/CallbackPage.vue";
import { useConnectivityStore } from "@/stores/connectivity";
import { useUserStore } from "@/stores/userStore";
import OfflinePasswordReset from "@/views/OfflinePasswordReset.vue";
import { useConfigStore } from "@/stores/config";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";

const routes = [
  {
    path: "/",
    redirect: (to) => {
      if (to.query.error == "access_denied") {
        window.location.href = import.meta.env.VITE_APP_ESEC_ACCESS_DENIED_URI;
        return "/redirecting";
      }
      return "/callback";
    },
  },
  {
    path: "/callback",
    name: "Callback",
    component: CallbackPage,
  },
  {
    path: "/structure-search",
    name: "StructureSearch",
    component: StructureSearch,
  },
  // Redirect not found path to Landing Page
  {
    path: "/:pathMatch(.*)*",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/structures/:brkey",
    name: "LatestInspection",
    component: StructureInspection,
  },
  {
    path: "/structures/:brkey?page=:pageName",
    redirect: (to) => {
      return {
        name: "LatestInspection",
        params: { brkey: to.params.brkey },
        query: { page: to.query.pageName },
      };
    },
  },
  {
    path: "/structures/:brkey/inspections/:inspkey",
    name: "SpecificInspection",
    component: StructureInspection,
  },
  {
    path: "/structures/:brkey/inspections/:inspkey?page=:pageName",
    redirect: (to) => {
      return {
        name: "SpecificInspection",
        params: { brkey: to.params.brkey, inspkey: to.params.inspkey },
        query: { page: to.query.pageName },
      };
    },
  },
  {
    path: "/inspections/work-list",
    name: "ViewWorkList",
    component: ViewWorkList,
  },
  {
    path: "/logout",
    name: "LogoutPage",
    component: LogoutPage,
  },
  {
    path: "/offlineLogin",
    name: "OfflineLogin",
    component: OfflineLogin,
  },
  {
    path: "/offlinePasswordReset",
    name: "OfflinePasswordReset",
    component: OfflinePasswordReset,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const checkOnlineAuthentication = async (next, to) => {
  if (!to.name == "LogoutPage") {
    const user = await auth.getUser();
    if (user) {
      if (!user.expired) {
        next();
      } else {
        await auth.login();
      }
    } else {
      await auth.login();
    }
  } else {
    next();
  }
};

const redirectToLogin = async (next, to) => {
  const connectivity = useConnectivityStore();
  const userStore = useUserStore();
  await connectivity.getServiceStatus();
  if (!connectivity.getisOnline) {
    //offline login
    if (userStore.isAuthenticated) {
      next();
    } else {
      next({ name: "OfflineLogin" });
    }
  } else {
    await checkOnlineAuthentication(next, to);
  }
};

router.beforeEach(async (to, from, next) => {
  if (to.name !== "LogoutPage" || to.name !== "OfflineLogin") {
    const configStore = useConfigStore();
    const isRestricted = Boolean(
      configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.ENABLE_USERS_RESTRICTION
      )
    );
    const userStore = useUserStore();
    const userid = userStore.loggedInUser?.sub;
    if (isRestricted && !configStore.isUserAllowed(userid)) {
      logout();
    }
  }

  if (to.name == "OfflineLogin") {
    const connectivity = useConnectivityStore();
    await connectivity.getServiceStatus();
    if (!connectivity.getOnlineServiceStatus) {
      next();
    } else {
      next({ name: "StructureSearch" });
    }
  } else {
    await redirectToLogin(next, to);
  }

  const inspectionStore = useInspectionStore();
  if (from.name === "LatestInspection" || from.name === "SpecificInspection") {
    if (inspectionStore.getDirtyFlag) {
      inspectionStore.setNextRoute(to.path);
      inspectionStore.setNextPage(INSPECTION_PAGES.GENERAL);
      inspectionStore.setUnsavedChangesWarning(true);
      return false;
    }
  }
});
router.beforeEach((to, _, next) => {
  if (to.name === "OnlineOnly" && !window.navigator.onLine)
    next({ name: "PathNotFound" });
  else next();
});

trackRouter(router);

const logout = async () => {
  const userStore = useUserStore();
  const connectivity = useConnectivityStore();

  userStore.setUser(null);
  userStore.setIsAuthenticated(false);
  await connectivity.getServiceStatus();
  if (connectivity.getOnlineServiceStatus) {
    auth.logout();
    router.push({ name: "LogoutPage" });
  } else {
    router.push({ name: "OfflineLogin" });
  }
};

export default router;
