import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { styles } from "@/reports/reportStyles";

function setWallsDocDefinition(structure) {
  const configStore = useConfigStore();
  let docDefinition = {
    header: function () {
      return getReportHeader("Walls Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Walls Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Wall Conditions",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        columns: [
          {
            width: "25%",
            text: "IW10 Overall Condition Rating: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.WALL_CONDITION_RATING,
              structure.T_Wall_Insp?.STRRATING
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        text: "Overall Walls Notes: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) => element?.COMMENT_TYPE == INSPECTION_COMMENTS.OVERALL_WALL
        )?.NOTES,
      },

      {
        columns: [
          {
            width: "25%",
            text: "IW02 Anchorage: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.WALL_CONDITION_RATING,
              structure.T_Wall_Insp?.ANCHORAGE_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "30%",
            text: "IW07 Drainage: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.WALL_CONDITION_RATING,
              structure.T_Wall_Insp?.DRAINAGE_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IW03 Backfill: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            margin: [0, 5, 0, 0],
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.WALL_CONDITION_RATING,
              structure.T_Wall_Insp?.BACKFILL_COND_RATE
            ),
          },
          {
            margin: [0, 5, 0, 0],
            width: "30%",
            text: "IW08 Foundation: ",
            bold: true,
          },
          {
            margin: [0, 5, 0, 0],
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.WALL_CONDITION_RATING,
              structure.T_Wall_Insp?.FOUND_COND_RATE
            ),
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 5, 0, 0],
            width: "25%",
            text: "IW04 Wall: ",
            bold: true,
          },
          {
            margin: [0, 5, 0, 0],
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.WALL_CONDITION_RATING,
              structure.T_Wall_Insp?.WALL_COND_RATE
            ),
          },
          {
            margin: [0, 5, 0, 0],
            width: "30%",
            text: "IW09 Parapets: ",
            bold: true,
          },
          {
            margin: [0, 5, 0, 0],
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.WALL_CONDITION_RATING,
              structure.T_Wall_Insp?.PARAPETS_COND_RATE
            ),
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 5, 0, 0],
            width: "25%",
            text: "IW05 Panel: ",
            bold: true,
          },
          {
            margin: [0, 5, 0, 0],
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.WALL_CONDITION_RATING,
              structure.T_Wall_Insp?.PANEL_COND_RATE
            ),
          },
          {
            margin: [0, 5, 0, 0],
            width: "30%",
            text: "VW06 Primary Backfill Mat'l: ",
            bold: true,
          },
          {
            margin: [0, 5, 0, 0],
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.BACKFILL_MATERIAL,
              structure.T_Wall_Insp?.BACKFILL_MATERIAL1
            ),
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 5, 0, 0],
            width: "25%",
            text: "IW06 Post: ",
            bold: true,
          },
          {
            margin: [0, 5, 0, 0],
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.WALL_CONDITION_RATING,
              structure.T_Wall_Insp?.POST_COND_RATE
            ),
          },
          {
            margin: [0, 5, 0, 0],
            width: "30%",
            text: "VW07 Secondary Backfill Mat'l: ",
            bold: true,
          },
          {
            margin: [0, 5, 0, 0],
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.BACKFILL_MATERIAL,
              structure.T_Wall_Insp?.BACKFILL_MATERIAL2
            ),
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Wall Inspection Notes",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        text: "Anchorage: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.WALL_ANCHORAGE
        )?.NOTES,
      },
      {
        text: "Backfill: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.WALL_BACKFLIP_DAMPING
        )?.NOTES,
      },
      {
        text: "Wall: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) => element?.COMMENT_TYPE == INSPECTION_COMMENTS.WALL
        )?.NOTES,
      },
      {
        text: "Panel: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) => element?.COMMENT_TYPE == INSPECTION_COMMENTS.WALL_PANEL
        )?.NOTES,
      },
      {
        text: "Post: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) => element?.COMMENT_TYPE == INSPECTION_COMMENTS.WALL_POST
        )?.NOTES,
      },
      {
        text: "Drainage: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.WALL_PAGE_DRAINAGE
        )?.NOTES,
      },
      {
        text: "Foundation: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.WALL_FOUNDATION
        )?.NOTES,
      },
      {
        text: "Parapets: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.WALL_PARAPETS
        )?.NOTES,
      },
    ],
    styles: styles,

    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}

export { setWallsDocDefinition };
