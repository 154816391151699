const bearing = {
  ID: null,
  BEARING_KEY: null,
  RECORD_KEY: "",
  ALLIGNMENT_ISSUES: "",
  ANCHOR_BOLT: "",
  BEARING_AREA: "",
  BEARING_COMMENT: "",
  BEARING_COUNT: null,
  BEARING_LOCATION: "",
  BEARING_MOVEMENT: "",
  BEARING_SUBSTR_UNIT: "",
  BEARING_TYPE: "",
  CONDITION_RATING: "",
  CORROSION: "",
  CREATEDATETIME: "",
  CREATEUSERKEY: "",
  ECMS_ID: "",
  INSTALL_DATE: null,
  MODTIME: "",
  REPLACEMENT_COMMENT: "",
  REPLACEMENT_REASON: "",
  USERKEY: "",
};
export default bearing;
