<template>
  <v-row>
    <ExpandCollapseToggle
      id="signsPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panel" />
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel class="mt-5">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
          <h3>Sign & Light Conditions</h3>
        </v-expansion-panel-title>

        <v-expansion-panel-text eager>
          <v-form v-model="valid" ref="modifySignsLightsForm">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="6">
                <LabelSelect
                  labelXl="5"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  id="sel_Overall_Condition_Rating"
                  label="IS10 Overall Condition Rating"
                  :refTable="REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="strrating"
                  isRequired
                ></LabelSelect>
              </v-col>
            </v-row>

            <CommentTypeTextField
              label="Overall Sign & Light Notes"
              id="field_"
              :inspectionCommentType="INSPECTION_COMMENTS.OVERALL"
              :readonly="!isEditable"
              :counter="inspCommentMaxLength"
              :maxlength="inspCommentMaxLength"
            />

            <v-row>
              <v-col cols="12" sm="12" md="12" lg="6" class="pr-lg-3">
                <LabelSelect
                  labelXl="5"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  id="sel_Foundation"
                  label="IS02 Foundation"
                  :refTable="REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="baseCondRate"
                  isRequired
                ></LabelSelect>

                <LabelSelect
                  labelXl="5"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  id="sel_Guide_Rail"
                  label="IS03 Guide Rail"
                  :refTable="REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="grailCondRate"
                  isRequired
                ></LabelSelect>

                <LabelSelect
                  labelXl="5"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  id="sel_Column"
                  label="IS04 Column"
                  :refTable="REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="columnCondRate"
                  isRequired
                ></LabelSelect>

                <LabelSelect
                  labelXl="5"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  id="sel_Method_of_Access"
                  label="IS05 Method of Access"
                  :refTable="REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="accessCondRate"
                  isRequired
                ></LabelSelect>

                <LabelSelect
                  labelXl="5"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  id="sel_Sign"
                  label="IS06 Sign"
                  :refTable="REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="signCondRate"
                  isRequired
                ></LabelSelect>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="6" class="pl-lg-3">
                <LabelSelect
                  labelXl="5"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  id="sel_Lights"
                  label="IS07 Lights"
                  :refTable="REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="lightCondRate"
                  isRequired
                ></LabelSelect>

                <LabelSelect
                  labelXl="5"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  id="sel_Surface_Paint"
                  label="IS08 Surface/Paint"
                  :refTable="REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="surfaceCondRate"
                  isRequired
                ></LabelSelect>

                <LabelSelect
                  labelXl="5"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  id="sel_Horizontal_Member_Frame"
                  label="IS09 Horizontal Member/Frame"
                  :refTable="REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="horCondRate"
                  isRequired
                ></LabelSelect>

                <LabelSelect
                  labelXl="5"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  id="sel_Sign_Asset_Tag_Condition"
                  label="IS15 Sign Asset Tag Condition"
                  :refTable="REFERENCE_TABLE.SIGN_ASSET_TAG"
                  :isEditable="isEditable"
                  v-model="signAssetTag"
                  isRequired
                ></LabelSelect>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(1)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(1)" icon="fa-solid fa-minus" />
          <h3>Sign & Light Inspection Notes</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <CommentTypeTextField
            label="Foundation"
            id="field_Foundation"
            :inspectionCommentType="INSPECTION_COMMENTS.SIGN_COLUMN_BASE"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />

          <CommentTypeTextField
            label="Guide Rail"
            id="field_Guide_Rail"
            :inspectionCommentType="INSPECTION_COMMENTS.SIGN_GUIDE_RAIL"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />

          <CommentTypeTextField
            label="Column"
            id="field_Column"
            :inspectionCommentType="INSPECTION_COMMENTS.SIGN_COLUMN"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />

          <CommentTypeTextField
            label="Method of Access"
            id="field_Method of Access"
            :inspectionCommentType="INSPECTION_COMMENTS.SIGN_ACCESS_METH"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />

          <CommentTypeTextField
            label="Sign"
            id="field_Sign"
            :inspectionCommentType="INSPECTION_COMMENTS.SIGN_SIGN"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />

          <CommentTypeTextField
            label="Lights"
            id="field_Lights"
            :inspectionCommentType="INSPECTION_COMMENTS.SIGN_LIGHTS"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />

          <CommentTypeTextField
            label="Surface/Paint"
            id="field_Surface_Paint"
            :inspectionCommentType="INSPECTION_COMMENTS.SIGN_SURFACE_PAINT"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />

          <CommentTypeTextField
            label="Horizontal Member/Frame"
            id="field_Horizontal_Member_Frame"
            :inspectionCommentType="INSPECTION_COMMENTS.SIGN_FRAMEWORK"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />

          <CommentTypeTextField
            label="Sign Asset Tag Condition"
            id="field_Sign_Asset_Tag_Condition"
            :inspectionCommentType="INSPECTION_COMMENTS.SIGN_ASSET_TAGS"
            :readonly="!isEditable"
            :counter="inspCommentMaxLength"
            :maxlength="inspCommentMaxLength"
          />
        </v-expansion-panel-text>
      </v-expansion-panel> </v-expansion-panels
  ></v-row>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useInspectionStore } from "@/stores/inspection";
import LabelSelect from "@/components/common/LabelSelect.vue";
import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import { useConfigStore } from "@/stores/config";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { clone } from "@/util/clone";
import objSignLightInsp from "@/data/signsLights";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";

export default {
  name: "InspectionSignsLights",
  setup() {
    const allPanels = [0, 1, 2, 3];
    const panel = ref(allPanels);
    const inspectionStore = useInspectionStore();
    let structure = computed(() => inspectionStore.selectedInspection);
    const configStore = useConfigStore();
    let valid = ref(true);
    let modifySignsLightsForm = ref(null);
    onMounted(async () => {
      await validate();
    });
    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });
    if (!isEditable.value) {
      panel.value = allPanels;
    }
    const inspCommentMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });

    async function validate() {
      await modifySignsLightsForm.value.validate();
    }

    function defaultSignLightInsp() {
      if (!structure.value?.T_Sign_Light_Insp) {
        objSignLightInsp.BRKEY = structure.value.Bridge.BRKEY;
        objSignLightInsp.INSPKEY = structure.value.InspEvnt.INSPKEY;
        objSignLightInsp.MODTIME = new Date().toISOString();
        objSignLightInsp.USERKEY = structure.value.InspEvnt.USERKEY;
        structure.value.T_Sign_Light_Insp = clone(objSignLightInsp);
      }
    }
    defaultSignLightInsp();

    const strrating = computed({
      get: () => structure.value?.T_Sign_Light_Insp?.STRRATING,
      set: (value) => {
        structure.value.T_Sign_Light_Insp.STRRATING = value;
      },
    });

    const baseCondRate = computed({
      get: () => structure.value?.T_Sign_Light_Insp?.BASE_COND_RATE,
      set: (value) => {
        structure.value.T_Sign_Light_Insp.BASE_COND_RATE = value;
      },
    });

    const grailCondRate = computed({
      get: () => structure.value?.T_Sign_Light_Insp?.GRAIL_COND_RATE,
      set: (value) => {
        structure.value.T_Sign_Light_Insp.GRAIL_COND_RATE = value;
      },
    });
    const columnCondRate = computed({
      get: () => structure.value?.T_Sign_Light_Insp?.COLUMN_COND_RATE,
      set: (value) => {
        structure.value.T_Sign_Light_Insp.COLUMN_COND_RATE = value;
      },
    });
    const accessCondRate = computed({
      get: () => structure.value?.T_Sign_Light_Insp?.ACCESS_COND_RATE,
      set: (value) => {
        structure.value.T_Sign_Light_Insp.ACCESS_COND_RATE = value;
      },
    });
    const signCondRate = computed({
      get: () => structure.value?.T_Sign_Light_Insp?.SIGN_COND_RATE,
      set: (value) => {
        structure.value.T_Sign_Light_Insp.SIGN_COND_RATE = value;
      },
    });
    const lightCondRate = computed({
      get: () => structure.value?.T_Sign_Light_Insp?.LIGHT_COND_RATE,
      set: (value) => {
        structure.value.T_Sign_Light_Insp.LIGHT_COND_RATE = value;
      },
    });
    const surfaceCondRate = computed({
      get: () => structure.value?.T_Sign_Light_Insp?.SURFACE_COND_RATE,
      set: (value) => {
        structure.value.T_Sign_Light_Insp.SURFACE_COND_RATE = value;
      },
    });
    const horCondRate = computed({
      get: () => structure.value?.T_Sign_Light_Insp?.HOR_COND_RATE,
      set: (value) => {
        structure.value.T_Sign_Light_Insp.HOR_COND_RATE = value;
      },
    });
    const signAssetTag = computed({
      get: () => structure.value?.T_Sign_Light_Insp?.SIGN_ASSET_TAG,
      set: (value) => {
        structure.value.T_Sign_Light_Insp.SIGN_ASSET_TAG = value;
      },
    });

    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [
        structure.value?.T_Sign_Light_Insp,
        structure.value.T_Insp_Comment,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      panel,
      REFERENCE_TABLE,
      isEditable,
      structure,
      INSPECTION_COMMENTS,
      configStore,
      inspCommentMaxLength,
      valid,
      modifySignsLightsForm,
      validate,
      strrating,
      baseCondRate,
      grailCondRate,
      columnCondRate,
      accessCondRate,
      signCondRate,
      lightCondRate,
      surfaceCondRate,
      horCondRate,
      signAssetTag,
      toggleAllPanels,
    };
  },
  components: {
    LabelSelect,
    CommentTypeTextField,
    ExpandCollapseToggle,
  },
};
</script>
