<template>
  <v-row>
    <v-col class="pa-0" cols="12">
      <v-row no-gutters>
        <v-col class="navigationButtonColumns">
          <v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.GENERAL
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_general_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.GENERAL)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.GENERAL)"
          >
            {{ INSPECTION_PAGES.GENERAL }}
          </v-btn>
        </v-col>
        <v-col class="navigationButtonColumns">
          <v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.SCHEDULE
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_schedule_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.RATINGS)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.SCHEDULE)"
          >
            {{ INSPECTION_PAGES.SCHEDULE }}
          </v-btn>
        </v-col>
        <v-col class="navigationButtonColumns">
          <v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.RATINGS
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_ratings_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.RATINGS)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.RATINGS)"
          >
            {{ INSPECTION_PAGES.RATINGS }}
          </v-btn>
        </v-col>

        <v-col class="navigationButtonColumns">
          <v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.APPROACH
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_approach_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.APPROACH)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.APPROACH)"
          >
            {{ INSPECTION_PAGES.APPROACH }}
          </v-btn>
        </v-col>

        <v-col class="navigationButtonColumns">
          <v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.DECK
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_deck_Inspections"
            variant="outlined"
            :disabled="!navigation.includes(INSPECTION_PAGES.DECK)"
            @click="selectPage(INSPECTION_PAGES.DECK)"
          >
            {{ INSPECTION_PAGES.DECK }}
          </v-btn></v-col
        >
        <v-col class="navigationButtonColumns"
          ><v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.JOINTS
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_joints_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.JOINTS)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.JOINTS)"
          >
            {{ INSPECTION_PAGES.JOINTS }}
          </v-btn></v-col
        >
        <v-col class="navigationButtonColumns"
          ><v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.SUPERSTRUCTURE
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_superstructure_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.SUPERSTRUCTURE)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.SUPERSTRUCTURE)"
          >
            {{ INSPECTION_PAGES.SUPERSTRUCTURE }}
          </v-btn>
        </v-col>
        <v-col class="navigationButtonColumns"
          ><v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.BEARINGS
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_bearings_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.BEARINGS)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.BEARINGS)"
          >
            {{ INSPECTION_PAGES.BEARINGS }}
          </v-btn></v-col
        >
        <v-col class="navigationButtonColumns"
          ><v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.SUBSTRUCTURE
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_substructure_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.SUBSTRUCTURE)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.SUBSTRUCTURE)"
          >
            {{ INSPECTION_PAGES.SUBSTRUCTURE }}
          </v-btn></v-col
        >
        <v-col class="navigationButtonColumns"
          ><v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.CULVERT
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_culvert_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.CULVERT)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.CULVERT)"
          >
            {{ INSPECTION_PAGES.CULVERT }}
          </v-btn></v-col
        >
        <v-col class="navigationButtonColumns">
          <v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.WATERWAY
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_waterway_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.WATERWAY)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.WATERWAY)"
          >
            {{ INSPECTION_PAGES.WATERWAY }}
          </v-btn></v-col
        >
      </v-row>
    </v-col>
  </v-row>
  <v-row>
    <v-col class="pa-0" cols="12">
      <v-row no-gutters>
        <v-col class="navigationButtonColumns">
          <v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.LOAD_RATING
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_load_rating_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.LOAD_RATING)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.LOAD_RATING)"
          >
            {{ INSPECTION_PAGES.LOAD_RATING }}
          </v-btn>
        </v-col>
        <v-col class="navigationButtonColumns">
          <v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.NSTM_FATIGUE
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_nstm_fatigue_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.NSTM_FATIGUE)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.NSTM_FATIGUE)"
          >
            {{ INSPECTION_PAGES.NSTM_FATIGUE }}
          </v-btn>
        </v-col>
        <v-col class="navigationButtonColumns">
          <v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.ELEMENTS
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_elements_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.ELEMENTS)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.ELEMENTS)"
          >
            {{ INSPECTION_PAGES.ELEMENTS }}
          </v-btn>
        </v-col>
        <v-col class="navigationButtonColumns"
          ><v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.FEATURES
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_features_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.FEATURES)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.FEATURES)"
          >
            {{ INSPECTION_PAGES.FEATURES }}
          </v-btn></v-col
        >
        <v-col class="navigationButtonColumns"
          ><v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.MAINTENANCE_ITEMS
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_maintenance_items_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.MAINTENANCE_ITEMS)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.MAINTENANCE_ITEMS)"
          >
            {{ INSPECTION_PAGES.MAINTENANCE_ITEMS }}
          </v-btn></v-col
        >
        <v-col class="navigationButtonColumns"
          ><v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.NOTES
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_notes_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.NOTES)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.NOTES)"
          >
            {{ INSPECTION_PAGES.NOTES }}
          </v-btn></v-col
        >
        <v-col class="navigationButtonColumns">
          <v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.SIGNS_AND_LIGHTS
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_signs_and_lights_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.SIGNS_AND_LIGHTS)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.SIGNS_AND_LIGHTS)"
          >
            {{ INSPECTION_PAGES.SIGNS_AND_LIGHTS }}
          </v-btn>
        </v-col>
        <v-col class="navigationButtonColumns">
          <v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.WALLS
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_walls_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.WALLS)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.WALLS)"
          >
            {{ INSPECTION_PAGES.WALLS }}
          </v-btn></v-col
        >
        <v-col class="navigationButtonColumns"
          ><v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.TUNNELS
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_tunnels_Inspections"
            :disabled="!navigation.includes(INSPECTION_PAGES.TUNNELS)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.TUNNELS)"
          >
            {{ INSPECTION_PAGES.TUNNELS }}
          </v-btn>
        </v-col>

        <v-col class="navigationButtonColumns"
          ><v-btn
            class="navigationButton"
            block
            id="btn_posting_Inspections"
            disabled
            variant="outlined"
            aria-label="emptyButton"
          >
          </v-btn
        ></v-col>
        <v-col class="navigationButtonColumns">
          <v-btn
            :class="[
              selectedPage == INSPECTION_PAGES.EDOCS
                ? 'activeNavigationButton'
                : 'navigationButton',
            ]"
            block
            id="btn_eDocs"
            :disabled="!navigation.includes(INSPECTION_PAGES.EDOCS)"
            variant="outlined"
            @click="selectPage(INSPECTION_PAGES.EDOCS)"
          >
            {{ INSPECTION_PAGES.EDOCS }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />
</template>

<script>
import { defineComponent, ref, toRefs, computed } from "vue";
import { INSPECTION_PAGES } from "@/constants/Inspections";
import { useInspectionStore } from "@/stores/inspection";
import router from "@/router";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import { INSPECTION_MESSAGES } from "@/constants/InspectionConstants";
import { BUTTONS } from "@/constants/CommonWebConstants";
import { scrollToElement } from "@/util/scrollToElement";

export default defineComponent({
  name: "InspectionNavigation",
  props: ["brkey", "inspkey"],
  emits: ["selectedPage"],
  setup(props, ctx) {
    const inspectionStore = useInspectionStore();
    const selectedPage = computed(() => inspectionStore.getSelectedPage);
    const structure = computed(() => inspectionStore.selectedInspection);
    const closeConfirm = ref(null);
    const { brkey, inspkey } = toRefs(props);
    const brKey = brkey?.value;
    const inspectionKey = inspkey.value;

    const selectPage = (page) => {
      scrollToElement("inspections_container");
      if (inspectionStore.getDirtyFlag && !inspectionStore.getAddingOrEditing) {
        inspectionStore.setNextPage(page);
        inspectionStore.setNextRoute("");
        inspectionStore.setUnsavedChangesWarning(true);
      } else if (!inspectionStore.getAddingOrEditing) {
        inspectionStore.setSelectedPage(page);
        ctx.emit("selectedPage", selectedPage.value);
        //remove any query params from the url
        setUri();
      } else if (inspectionStore.getAddingOrEditing) {
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (!response) {
              inspectionStore.setSelectedPage(page);
              inspectionStore.setAddingOrEditing(false);
              ctx.emit("selectedPage", selectedPage.value);
              //remove any query params from the url
              setUri();
            }
          });
      }
    };
    let navigation = ref([]);
    function setNavigation() {
      navigation.value = inspectionStore.getActiveNavigation;
    }

    const setUri = () => {
      if (brKey && structure.value?.InspEvnt?.INSPKEY) {
        router.push({
          name: "SpecificInspection",
          params: { inspkey: structure.value.InspEvnt.INSPKEY },
        });
      } else if (brKey) {
        router.push({ name: "LatestInspection" });
      }
    };
    setNavigation();
    return {
      closeConfirm,
      setNavigation,
      navigation,
      structure,
      INSPECTION_PAGES,
      selectedPage,
      selectPage,
      brKey,
      inspectionKey,
      BUTTONS,
    };
  },
  components: { ConfirmDialog },
});
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;
@use "@/styles/general" as g;
@media #{"screen and (min-width: 1631px)"} {
  .navigationButton {
    text-transform: none;
    font-size: 15px;
    color: c.$p-pa-blue;
    border: thin solid c.$p-dark-grey;
  }
  .activeNavigationButton {
    text-transform: none;
    font-size: 15px;
    background-color: c.$p-pa-blue;
    color: c.$p-white;
  }
}
@media #{"screen and (max-width: 1630px)"} {
  .navigationButton {
    text-transform: none;
    font-size: 14px;
    color: c.$p-pa-blue;
    border: thin solid c.$p-dark-grey;
  }
  .activeNavigationButton {
    text-transform: none;
    font-size: 14px;
    background-color: c.$p-pa-blue;
    color: c.$p-white;
  }
}
@media #{"screen and (max-width: 1575px)"} {
  .navigationButton {
    text-transform: none;
    font-size: 13px;
    color: c.$p-pa-blue;
    border: thin solid c.$p-dark-grey;
  }
  .activeNavigationButton {
    text-transform: none;
    font-size: 13px;
    background-color: c.$p-pa-blue;
    color: c.$p-white;
  }
}
@media #{"screen and (max-width: 1470px)"} {
  .navigationButton {
    text-transform: none;
    font-size: 12px;
    color: c.$p-pa-blue;
    border: thin solid c.$p-dark-grey;
  }
  .activeNavigationButton {
    text-transform: none;
    font-size: 12px;
    background-color: c.$p-pa-blue;
    color: c.$p-white;
  }
}
@media #{"screen and (max-width: 1360px)"} {
  .navigationButton {
    text-transform: none;
    font-size: 11px;
    color: c.$p-pa-blue;
    border: thin solid c.$p-dark-grey;
  }
  .activeNavigationButton {
    text-transform: none;
    font-size: 11px;
    background-color: c.$p-pa-blue;
    color: c.$p-white;
  }
}
.navigationButtonColumns {
  width: 9.09%;
  max-width: 9.09%;
}
button:focus {
  outline: none;
}
.navigationButtonColumns button[disabled] {
  opacity: 1 !important;
  color: c.$p-grey-disabled !important;
}
</style>
