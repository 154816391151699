import { STREAM_BED_MATERIALS } from "@/constants/Inspections";

function getIN15Value(subUnits) {
  let arr = [];
  subUnits?.forEach((subUnit) => {
    //get the matching material and rank and put it in an array
    arr.push(
      STREAM_BED_MATERIALS.find(
        (bed) => bed.material === subUnit?.STREAM_BED_MATERIAL
      )
    );
  });
  //filter the matched materials with rank in ascending order and get the lowest rank material
  //destruture the array(arr) because of sonar issue, Array-mutating methods should not be used misleadingly
  const sortedMaterials = [...arr].sort((a, b) => a.rank - b.rank);
  if (sortedMaterials[0]) {
    return sortedMaterials[0].material;
  } else {
    return "--";
  }
}
function getIN15ValueFromMaterials(materials) {
  let arr = [];
  materials.forEach((material) => {
    //get the matching material and rank and put it in an array
    arr.push(STREAM_BED_MATERIALS.find((bed) => bed.material === material));
  });
  //filter the matched materials with rank in ascending order and get the lowest rank material
  //destruture the array(arr) because of sonar issue, Array-mutating methods should not be used misleadingly
  const sortedMaterials = [...arr].sort((a, b) => a.rank - b.rank);
  if (sortedMaterials[0]) {
    return sortedMaterials[0].material;
  } else {
    return "--";
  }
}
export { getIN15Value, getIN15ValueFromMaterials };
