export const ENVIRONMENT = Object.freeze({
  PRODUCTION: { name: "PRODUCTION", code: "P" },
  USER_ACCEPTANCE: { name: "USER ACCEPTANCE", code: "U" },
  SYSTEM_TEST: { name: "SYSTEM TEST", code: "S" },
  DEVELOPMENT: { name: "DEVELOPMENT", code: "D" },
});

export const ENV_CONFIG_PROPERTY = {
  ENVIRONMENT: "env",
  CLIENT_STORAGE_MAX: "clientStorageMax",
  CLIENT_SYNC_INTERVAL: "clientSyncInterval",
  STRUCTURE_NOTES_MAX_LENGTH: "structureNotesMaxLength",
  INSPECTION_COMMENT_MAX_LENGTH: "inspectionCommentMaxLength",
  ELEM_CONDITION_LEGAL_TEXT1: "legalText1",
  ELEM_CONDITION_LEGAL_TEXT2: "legalText2",
  ELEM_CONDITION_MAX_LENGTH: "elementConditionMaxLength",
  ELEM_DESC_MAX_LENGTH: "elementDescriptionMaxLength",
  JOINT_COMMENT_MAX_LENGTH: "jointCommentMaxLength",
  BEARING_COMMENT_MAX_LENGTH: "bearingCommentMaxLength",
  NOTES_MAX_LENGTH: "notesMaxLength",
  EDOC_SERVICE_MAX_REQUESTS: "docServiceMaxRequests",
  EDOC_SERVICE_PER_MILLISECONDS: "docServicePerMilliseconds",
  OFFLINE_DEFAULT_PASS: "defaultPassword",
  SUPPORT_USERS: "supportUsers",
  IDLE_TIMEOUT: "idleTimeout",
  SESSION_TIMEOUT: "sessionTimeout",
  ACTIVE_INSPECTION_PAGES: "activeInspectionPages",
  ENABLE_USERS_RESTRICTION: "enableUsersRestriction",
  ALLOWED_USERS_COUNT: "allowedUsersCount",
  ALLOWED_USERS: "allowedUsers",
  ENABLE_ROLE_RESTRICTION: "enableRolesRestriction",
  ALLOWED_ROLES: "allowedRoles",
};

export const ENV_CONFIG_PROPERTIES = [
  ENV_CONFIG_PROPERTY.ENVIRONMENT,
  ENV_CONFIG_PROPERTY.CLIENT_STORAGE_MAX,
  ENV_CONFIG_PROPERTY.CLIENT_SYNC_INTERVAL,
  ENV_CONFIG_PROPERTY.STRUCTURE_NOTES_MAX_LENGTH,
  ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH,
  ENV_CONFIG_PROPERTY.ELEM_CONDITION_LEGAL_TEXT1,
  ENV_CONFIG_PROPERTY.ELEM_CONDITION_LEGAL_TEXT2,
  ENV_CONFIG_PROPERTY.ELEM_CONDITION_MAX_LENGTH,
  ENV_CONFIG_PROPERTY.ELEM_DESC_MAX_LENGTH,
  ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH,
  ENV_CONFIG_PROPERTY.JOINT_COMMENT_MAX_LENGTH,
  ENV_CONFIG_PROPERTY.BEARING_COMMENT_MAX_LENGTH,
  ENV_CONFIG_PROPERTY.NOTES_MAX_LENGTH,
  ENV_CONFIG_PROPERTY.EDOC_SERVICE_MAX_REQUESTS,
  ENV_CONFIG_PROPERTY.OFFLINE_DEFAULT_PASS,
  ENV_CONFIG_PROPERTY.EDOC_SERVICE_PER_MILLISECONDS,
  ENV_CONFIG_PROPERTY.SUPPORT_USERS,
  ENV_CONFIG_PROPERTY.IDLE_TIMEOUT,
  ENV_CONFIG_PROPERTY.SESSION_TIMEOUT,
  ENV_CONFIG_PROPERTY.ACTIVE_INSPECTION_PAGES,
  ENV_CONFIG_PROPERTY.ENABLE_USERS_RESTRICTION,
  ENV_CONFIG_PROPERTY.ALLOWED_USERS_COUNT,
  ENV_CONFIG_PROPERTY.ENABLE_ROLE_RESTRICTION,
  ENV_CONFIG_PROPERTY.ALLOWED_ROLES,
];

export const CONFIG_SORT_BY = {
  VALUE: "value",
  TITLE: "title",
};

export function getEnvironmentFromName(environmentName) {
  return Object.values(ENVIRONMENT).find((env) => env.name === environmentName);
}
export function getEnvironmentFromCode(environmentCode) {
  return Object.values(ENVIRONMENT).find((env) => env.code === environmentCode);
}
