<template>
  <v-row>
    <v-col sm="12">
      <h3 id="search_results_header" class="withBackground px-2 py-1">
        Search Results
      </h3>
    </v-col>
  </v-row>
  <v-row>
    <v-col sm="12" class="font-weight-bold">
      Structures Returned:
      {{ structureSearchStore.searchCounts.totalElements }}</v-col
    >
  </v-row>
  <v-row>
    <v-col sm="12" class="font-weight-bold">
      Records in Local Database:
      {{ totalRecordsInLocalDB ? totalRecordsInLocalDB : 0 }}/{{
        clientMaxStorage
      }}</v-col
    >
  </v-row>
  <v-row>
    <v-col sm="12" class="font-weight-bold">
      Records to Download:
      {{ recordsSelected }}</v-col
    >
  </v-row>
  <v-row class="mb-8">
    <v-col sm="12">
      <v-data-table
        id="table_searchResults"
        ref="searchResultsDatatable"
        :headers="headers"
        :items="getResults"
        class="compactTable lessPaddingTableColumns"
        item-value="brkey"
        v-model:sort-by="sortBy"
        must-sort
        fixed-header
        hide-default-footer
        v-model:page="pageInspection.page"
        :items-per-page="pageInspection.itemsPerPage"
        @update:sort-by="resultsFooter.setPageValue(1)"
      >
        <template v-slot:[`column.selectAll`]="{ column }">
          {{ column.title }}
          <v-checkbox
            id="checkbox_selectAllStructures"
            aria-describedby="checkbox_selectAllStructures"
            aria-label="checkbox_selectAllStructures"
            v-model="selectAllStructures"
            @click="handleSelectAllStructures"
            hide-details
            :disabled="!hasResults"
          />
        </template>
        <template v-slot:[`column.eDocsAll`]="{ column }">
          <div class="align-center">{{ column.title }}</div>
          <v-row>
            <v-col sm="3">
              <div>All</div>
              <v-checkbox
                id="checkbox_allSuper"
                aria-describedby="
                  checkbox_allSuper
                "
                aria-label="
                  checkbox_allSuper
                "
                @click="handleAllSuperSelected"
                v-model="superDocsSelected.all"
                hide-details
                :disabled="!hasResults"
                class="justify-center"
            /></v-col>
            <v-col sm="3">
              <div>Insp</div>
              <v-checkbox
                id="checkbox_inspectionSuper"
                aria-describedby="
                  checkbox_inspectionSuper
                "
                aria-label="
                  checkbox_inspectionSuper
                "
                @click="handleInspectionSuperSelected"
                v-model="superDocsSelected.inspection"
                hide-details
                :disabled="!hasResults"
                class="justify-center"
              />
            </v-col>
            <v-col sm="3">
              <div>Invt</div>
              <v-checkbox
                id="checkbox_inventorySuper"
                aria-describedby="
                  checkbox_inventorySuper
                "
                aria-label="
                  checkbox_inventorySuper
                "
                @click="handleInventorySuperSelected"
                v-model="superDocsSelected.inventory"
                hide-details
                :disabled="!hasResults"
                class="justify-center"
            /></v-col>
            <v-col sm="3">
              <div>Other</div>
              <v-checkbox
                id="checkbox_otherSuper"
                aria-describedby="
                  checkbox_otherSuper
                "
                aria-label="
                  checkbox_otherSuper
                "
                @click="handleOtherSuperSelected"
                v-model="superDocsSelected.other"
                hide-details
                :disabled="!hasResults"
                class="justify-center"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:item="{ item }">
          <tr :class="rowClass(item.columns.brkey)">
            <td style="min-width: 25px">
              <v-tooltip
                v-if="
                  downloadedStructures.find(
                    (a) => a._id == item.columns.brkey
                  ) != null
                "
              >
                <template v-slot:activator="{ props }">
                  <div v-bind="props">
                    <v-checkbox
                      class="mr-0 pr-0"
                      :id="
                        'checkbox_individualStructure_Disabled' +
                        item.columns.brkey
                      "
                      :aria-describedby="
                        'checkbox_individualStructure_Disabled' +
                        item.columns.brkey
                      "
                      :aria-label="
                        'checkbox_individualStructure_Disabled' +
                        item.columns.brkey
                      "
                      disabled
                      v-model="item.raw.isSelected"
                      hide-details
                    >
                    </v-checkbox>
                  </div>
                </template>
                <span class="pl-0 ml-0"
                  >Unable to download, structure already exists in
                  worklist</span
                >
              </v-tooltip>
              <v-checkbox
                v-else
                class="mr-0 pr-0"
                :id="'checkbox_individualStructure' + item.columns.brkey"
                :aria-describedby="
                  'checkbox_individualStructure' + item.columns.brkey
                "
                :aria-label="
                  'checkbox_individualStructure' + item.columns.brkey
                "
                @click="(event) => updateStructureSelection(event, item.raw)"
                v-model="item.raw.isSelected"
                hide-details
              >
              </v-checkbox>
            </td>
            <td style="min-width: 5%">
              {{ item.columns.brkey }}
            </td>
            <td style="min-width: 10%">
              {{ item.columns.bmsId }}
            </td>
            <td style="min-width: 8%">
              {{ getFormattedDateStringNoTime(item.columns.inspectionDate) }}
            </td>
            <td
              :style="
                $vuetify?.display?.smAndDown
                  ? 'min-width: 15em'
                  : 'min-width: 10em'
              "
            >
              {{
                configStore.getReferenceValue(
                  REFERENCE_TABLE.INSPECTION_STATUS,
                  item.columns.inspectionStatus
                )
              }}
            </td>
            <td style="min-width: 3%">
              {{ item.columns.district.districtNum }}
            </td>
            <td style="min-width: 8%">
              {{ item.columns.agreementContractNumber }}
            </td>
            <td style="min-width: 5%">
              {{ item.columns.reportingGroup }}
            </td>
            <td style="min-width: 23%">
              {{ item.columns.featuresIntersected }}
            </td>
            <td style="min-width: 185px">
              <v-row>
                <v-col sm="3">
                  <v-checkbox
                    :id="'checkbox_allDocsSelection_' + item.columns.brkey"
                    :aria-describedby="
                      'checkbox_allDocsSelection_' + item.columns.brkey
                    "
                    :aria-label="
                      'checkbox_allDocsSelection_' + item.columns.brkey
                    "
                    @click="(event) => handleAllSelected(event, item.raw)"
                    v-model="item.raw.all"
                    hide-details
                    class="justify-center"
                /></v-col>
                <v-col sm="3">
                  <v-checkbox
                    :id="'checkbox_individualInspection_' + item.columns.brkey"
                    @click="
                      (event) => handleInspectionSelected(event, item.raw)
                    "
                    :aria-describedby="
                      'checkbox_individualInspection_' + item.columns.brkey
                    "
                    :aria-label="
                      'checkbox_individualInspection_' + item.columns.brkey
                    "
                    v-model="item.raw.inspection"
                    hide-details
                    class="justify-center"
                /></v-col>
                <v-col sm="3">
                  <v-checkbox
                    :id="'checkbox_individualInventory_' + item.columns.brkey"
                    :aria-describedby="
                      'checkbox_individualInventory_' + item.columns.brkey
                    "
                    :aria-label="
                      'checkbox_individualInventory_' + item.columns.brkey
                    "
                    @click="(event) => handleInventorySelected(event, item.raw)"
                    v-model="item.raw.inventory"
                    hide-details
                    class="justify-center"
                /></v-col>
                <v-col sm="3">
                  <v-checkbox
                    :id="'checkbox_individualOther_' + item.columns.brkey"
                    :aria-describedby="
                      'checkbox_individualOther_' + item.columns.brkey
                    "
                    :aria-label="
                      'checkbox_individualOther_' + item.columns.brkey
                    "
                    @click="(event) => handleOtherSelected(event, item.raw)"
                    v-model="item.raw.other"
                    hide-details
                    class="justify-center"
                /></v-col>
              </v-row>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <DataTableFooter
            ref="resultsFooter"
            id="results_Footer"
            :items="getResults"
            :updatePage="updatePage"
            v-model="pageInspection"
            :rowsPerPageOptions="footerOptions"
          ></DataTableFooter>
        </template>
      </v-data-table>
    </v-col>
  </v-row>

  <v-row class="px-3 mb-8">
    <v-col>
      <v-progress-linear
        striped
        height="20"
        color="secondary"
        v-model="docProgress.completedPct"
        ><template v-slot:default="{ value }">
          <strong
            >Downloading {{ docProgress.total }} Documents -
            {{ Math.ceil(value) }}%</strong
          >
        </template>
      </v-progress-linear></v-col
    >
    <v-col>
      <v-progress-linear
        striped
        height="20"
        color="secondary"
        v-model="progress.completedPct"
        ><template v-slot:default="{ value }">
          <strong
            >Downloading {{ progress.total }} Structures -
            {{ Math.ceil(value) }}%</strong
          >
        </template>
      </v-progress-linear></v-col
    ></v-row
  >
  <v-row justify="end" class="mb-8">
    <v-btn
      id="btn_download"
      variant="outlined"
      class="mx-5"
      @click="checkRecordsLimit()"
      :disabled="
        !isOfflinePasswordSet || (isOfflinePasswordSet && recordsSelected == 0)
      "
      >Download</v-btn
    >

    <v-btn
      :disabled="recordsSelected == 0"
      id="btn_clear"
      @click="clearSelections"
      variant="outlined"
      class="mx-5"
      >Clear Selections</v-btn
    >
  </v-row>

  <v-dialog v-model="msgDialog" transition="dialog-top-transition" class="w-50">
    <v-card class="pa-3 error-dialog">
      <p class="mb-2">
        Records to Download exceeds the remaining local database capacity.
        Expand the Worklist below to modify your local database records.
      </p>
      <div class="d-flex justify-center">
        <v-btn id="btn_ok_close" variant="outlined" text @click="hideMsgDialog">
          OK
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
  <v-expansion-panels class="mt-5" v-model="panel" color="#fff">
    <v-expansion-panel>
      <v-expansion-panel-title
        class="d-flex justify-start"
        hide-actions
        expand-icon="fa-solid fa-plus"
        collapse-icon="fa-solid fa-minus"
      >
        <v-icon v-if="panel != 0" icon="fa-solid fa-plus" />
        <v-icon v-if="panel === 0" icon="fa-solid fa-minus" />
        <h3>Worklist</h3>
      </v-expansion-panel-title>

      <v-expansion-panel-text>
        <WorkList />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-overlay
    :model-value="structureSearchStore.getIsSearching"
    class="align-center justify-center"
  >
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
  <v-dialog v-model="downloadErrorMessage.show" :width="800">
    <v-card class="pa-3 warning-dialog">
      <p class="h3 mb-6"><strong>&nbsp;</strong></p>
      <p class="mb-2">{{ downloadErrorMessage.message }}</p>

      <v-table
        v-if="downloadErrorMessage.type === HTTP_STATUS_CODE.NOT_FOUND"
        class="message-table-responsive"
      >
        <thead>
          <tr>
            <th id="missingBrkeys" class="text-left">
              {{ downloadErrorMessage.header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="brkey in downloadErrorMessage?.missingBrkeys" :key="brkey">
            <td>{{ brkey }}</td>
          </tr>
        </tbody>
      </v-table>

      <button
        @click="downloadErrorMessage.show = false"
        class="d-flex align-end flex-column align-content-center close-button"
      >
        <v-icon size="x-large" icon="fas fa-xmark mx-2" />
        <small>CLOSE</small>
      </button>
    </v-card>
  </v-dialog>
  <v-snackbar
    v-model="showDownloadSuccessMessage"
    :timeout="10000"
    color="success"
    position="sticky"
    location="top"
  >
    Download Successful
    <template v-slot:actions>
      <v-btn variant="text" @click="showDownloadSuccessMessage = false">
        <v-icon size="small" icon="fas fa-xmark mx-2" class="no-outline" />
      </v-btn>
    </template>
  </v-snackbar>
  <v-snackbar
    v-model="showServiceUnavailableMessage"
    :timeout="10000"
    color="error"
    position="sticky"
    location="top"
  >
    Server Unavailable, email ra-pdBMS3Support@pa.gov to report the issue.
    <template v-slot:actions>
      <v-btn variant="text" @click="showServiceUnavailableMessage = false">
        <v-icon size="small" icon="fas fa-xmark mx-2" class="no-outline" />
      </v-btn>
    </template>
  </v-snackbar>
  <v-snackbar
    v-model="showUnProcessableEntityMessage"
    :timeout="10000"
    color="error"
    position="sticky"
    location="top"
  >
    {{ DOWNLOAD_ERROR_MESSAGES.UNPROCESSABLE_ENTITY_MESSAGE }}
    <template v-slot:actions>
      <v-btn variant="text" @click="showUnProcessableEntityMessage = false">
        <v-icon size="small" icon="fas fa-xmark mx-2" class="no-outline" />
      </v-btn>
    </template>
  </v-snackbar>
  <LoginDialog ref="loginDialogRef" @login-clicked="checkLogin" />
</template>

<script>
import { useStructureSearchStore } from "@/stores/structureSearch";
import { useStructureIDBStore } from "@/stores/structureIDB";
import { useConfigStore } from "@/stores/config";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  onUnmounted,
  computed,
  watch,
} from "vue";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import useDateField from "@/composables/dateField.js";
import WorkList from "@/components/shared/WorkList.vue";
import { HTTP_STATUS_CODE } from "@/constants/CommonWebCodes";
import { DOWNLOAD_ERROR_MESSAGES } from "@/constants/CommonWebConstants";
import LoginDialog from "@/components/common/LoginDialog.vue";
import { onBeforeRouteLeave } from "vue-router";
import { useConnectivityStore } from "@/stores/connectivity";
import DataTableFooter from "@/components/common/DataTableFooter.vue";

export default defineComponent({
  name: "StructureSearchResults",
  props: { isOfflinePasswordSet: { default: false } },
  setup() {
    const structureIDBStore = useStructureIDBStore();
    const structureSearchStore = useStructureSearchStore();
    const configStore = useConfigStore();
    const connectivity = useConnectivityStore();
    let selectAllStructures = ref(false);
    let msgDialog = ref(false);
    let panel = ref();
    let showDownloadSuccessMessage = ref(false);
    let showServiceUnavailableMessage = ref(false);
    let showUnProcessableEntityMessage = ref(false);
    let loginDialogRef = ref(null);
    const hasResults = computed(
      () => structureSearchStore.getStructureSearchResults.length > 0
    );
    let selected = ref([]);

    const downloadedStructures = computed(
      () => structureIDBStore.getLocalStructures
    );
    let sortBy = ref([{ key: "brkey", order: "asc" }]);

    const progress = reactive({
      _total: 0,
      get total() {
        return this._total;
      },
      set total(value) {
        this._total = value;
        this.remaining = value;
      },
      remaining: 0,
      get completedPct() {
        if (this._total === 0 || this.remaining === 0) return 0;
        return ((this._total - this.remaining) / this._total) * 100;
      },
    });

    const docProgress = reactive({
      _completedPct: 0,
      _total: 0,
      get completedPct() {
        return Math.ceil(this._completedPct);
      },
      get total() {
        return this._total;
      },
    });

    let downloadErrorMessage = ref({
      show: false,
      header: "BRKEY",
      value: null,
      missingBrkeys: [],
    });
    const { getFormattedDateStringNoTime } = useDateField();
    const superDocsSelected = reactive({
      inspection: false,
      inventory: false,
      other: false,
      get all() {
        return this.inspection && this.inventory && this.other;
      },
      set all(value) {
        this.inspection = value;
        this.inventory = value;
        this.other = value;
      },
    });
    let searchResultsDatatable = ref(null);
    const pageInspection = ref({
      itemsPerPage: 75,
      page: 1,
    });
    const updatePage = ref(false);
    const footerOptions = [
      { title: "75", value: 75 },
      { title: "150", value: 150 },
      { title: "225", value: 225 },
      { title: "300", value: 300 },
    ];
    let resultsFooter = ref(null);

    onMounted(async () => {
      await structureIDBStore.initDB(false);
      await structureIDBStore.getStructuresFromIDB();
    });

    onUnmounted(async () => {
      await structureSearchStore.resetSearchResults();
    });

    const handleSelectAllStructures = () => {
      structureSearchStore.resetSelectedStructures();
      if (!selectAllStructures.value) {
        structureSearchStore.getStructureSearchResults.forEach((s) => {
          if (
            downloadedStructures.value.find((a) => a._id == s.brkey) == null
          ) {
            s.isSelected = true;
            structureSearchStore.pushToSelectedStructures(s);
          } else if (
            downloadedStructures.value.find((a) => a._id == s.brkey) != null &&
            (s.inspection || s.inventory || s.other)
          ) {
            s.isSelected = false;
            structureSearchStore.pushToSelectedStructures(s);
          }
        });
      } else {
        structureSearchStore.getStructureSearchResults.forEach((s) => {
          s.isSelected = false;
          if (s.inspection || s.inventory || s.other) {
            structureSearchStore.pushToSelectedStructures(s);
          }
        });
      }
    };

    const updateStructureSelection = (event, structure) => {
      structureSearchStore.resetSelectedStructures();
      if (!structure.isSelected) {
        structure.isSelected = true;
      } else {
        structure.isSelected = false;
        selectAllStructures.value = false;
      }
      updateStructureSelections();
    };
    const handleAllSuperSelected = () => {
      structureSearchStore.resetSelectedStructures();
      if (!superDocsSelected.all) {
        structureSearchStore.getStructureSearchResults.forEach((s) => {
          s.all = true;
          structureSearchStore.pushToSelectedStructures(s);
        });
      } else {
        structureSearchStore.getStructureSearchResults.forEach((s) => {
          s.all = false;
          if (s.isSelected) {
            structureSearchStore.pushToSelectedStructures(s);
          }
        });
      }
    };
    const handleOtherSuperSelected = () => {
      structureSearchStore.resetSelectedStructures();
      if (!superDocsSelected.other) {
        structureSearchStore.getStructureSearchResults.forEach((s) => {
          s.other = true;
          structureSearchStore.pushToSelectedStructures(s);
        });
      } else {
        structureSearchStore.getStructureSearchResults.forEach((s) => {
          s.other = false;
          if (s.isSelected || s.inspection || s.inventory) {
            structureSearchStore.pushToSelectedStructures(s);
          }
        });
      }
    };
    const handleInspectionSuperSelected = () => {
      structureSearchStore.resetSelectedStructures();
      if (!superDocsSelected.inspection) {
        structureSearchStore.getStructureSearchResults.forEach((s) => {
          s.inspection = true;
          structureSearchStore.pushToSelectedStructures(s);
        });
      } else {
        structureSearchStore.getStructureSearchResults.forEach((s) => {
          s.inspection = false;
          if (s.isSelected || s.other || s.inventory) {
            structureSearchStore.pushToSelectedStructures(s);
          }
        });
      }
    };
    const handleInventorySuperSelected = () => {
      structureSearchStore.resetSelectedStructures();
      if (!superDocsSelected.inventory) {
        structureSearchStore.getStructureSearchResults.forEach((s) => {
          s.inventory = true;
          structureSearchStore.pushToSelectedStructures(s);
        });
      } else {
        structureSearchStore.getStructureSearchResults.forEach((s) => {
          s.inventory = false;
          if (s.isSelected || s.other || s.inspection) {
            structureSearchStore.pushToSelectedStructures(s);
          }
        });
      }
    };
    const handleAllSelected = (event, structure) => {
      structureSearchStore.resetSelectedStructures();
      if (!structure.all) {
        structure.all = true;
      } else {
        superDocsSelected.all = false;
        structure.all = false;
      }
      updateStructureSelections();
    };
    const handleInspectionSelected = (event, structure) => {
      structureSearchStore.resetSelectedStructures();
      if (!structure.inspection) {
        structure.inspection = true;
      } else {
        superDocsSelected.inspection = false;
        structure.inspection = false;
      }
      updateStructureSelections();
    };
    const handleInventorySelected = (event, structure) => {
      structureSearchStore.resetSelectedStructures();
      if (!structure.inventory) {
        structure.inventory = true;
      } else {
        superDocsSelected.inventory = false;
        structure.inventory = false;
      }
      updateStructureSelections();
    };
    const handleOtherSelected = (event, structure) => {
      structureSearchStore.resetSelectedStructures();
      if (!structure.other) {
        structure.other = true;
      } else {
        superDocsSelected.other = false;
        structure.other = false;
      }
      updateStructureSelections();
    };
    const updateStructureSelections = () => {
      structureSearchStore.getStructureSearchResults.forEach((s) => {
        if (s.inspection || s.inventory || s.other || s.isSelected) {
          structureSearchStore.pushToSelectedStructures(s);
        }
      });
    };
    const clearSelections = () => {
      selectAllStructures.value = false;
      superDocsSelected.all = false;
      structureSearchStore.getStructureSearchResults.forEach((s) => {
        s.clear();
      });
      structureSearchStore.resetSelectedStructures();
    };
    const checkRecordsLimit = async () => {
      await structureSearchStore.checkIfInLocalStructures();
      if (
        totalRecordsInLocalDB.value + recordsToDownload.value >
        clientMaxStorage.value
      ) {
        msgDialog.value = true;
        //open the worklist expansion panel
        panel.value = 0;
      } else {
        loginDialogRef.value
          .open()
          .then((loginRequest) => checkLogin(loginRequest));
      }
    };
    const checkLogin = (request) => {
      download(request.userName, request.password);
    };

    const clientMaxStorage = computed(() =>
      configStore.getEnvConfigValue(ENV_CONFIG_PROPERTY.CLIENT_STORAGE_MAX)
    );

    const totalRecordsInLocalDB = computed(
      () => structureIDBStore.totalStructuresInIDB
    );
    const recordsToDownload = computed(
      () => structureSearchStore.structuresToBeDownloadedSize
    );
    const recordsSelected = computed(
      () => structureSearchStore.selectedStructures?.length
    );
    const hideMsgDialog = () => {
      msgDialog.value = false;
    };

    const download = async (userName, password) => {
      structureSearchStore.userAuthenticationErrors = {};
      if (
        totalRecordsInLocalDB.value + recordsToDownload.value >
        clientMaxStorage.value
      ) {
        loginDialogRef.value.reset();
        msgDialog.value = true;
        loginDialogRef.value.loginProgressDialog = false;
      } else {
        progress.total = recordsSelected.value;
        let abortController = new AbortController();

        for (const s of structureSearchStore.getSelectedStructures) {
          await structureSearchStore.download(
            s,
            userName,
            password,
            abortController
          );
          loginDialogRef.value.loginProgressDialog = false;
          progress.remaining--;
          //close the login if there is no authentication error in the first record
          if (
            loginDialogRef.value?.dialog &&
            !structureSearchStore?.userAuthenticationErrors[
              HTTP_STATUS_CODE.NOT_AUTHORIZED
            ]
          ) {
            loginDialogRef.value.closeDialog();
          }
        }
        progress.total = 0;

        if (
          Object.keys(structureSearchStore?.userAuthenticationErrors)?.length >
          0
        ) {
          showError();
        } else {
          clearSelections();
          if (connectivity.getisOnline) {
            showDownloadSuccessMessage.value = true;
          }
        }
      }
    };

    function showError() {
      if (
        structureSearchStore.userAuthenticationErrors[
          HTTP_STATUS_CODE.SERVICE_UNAVAILABLE
        ]
      ) {
        showServiceUnavailableMessage.value = true;
        clearSelections();
      } else if (
        structureSearchStore.userAuthenticationErrors[
          HTTP_STATUS_CODE.NOT_FOUND
        ]
      ) {
        downloadErrorMessage.value.type = HTTP_STATUS_CODE.NOT_FOUND;
        downloadErrorMessage.value.show = true;
        downloadErrorMessage.value.message =
          DOWNLOAD_ERROR_MESSAGES.UNAUTHORIZED_MESSAGE;
        downloadErrorMessage.value.missingBrkeys =
          structureSearchStore.userAuthenticationErrors[
            HTTP_STATUS_CODE.NOT_FOUND
          ];
        clearSelections();
      } else if (
        structureSearchStore.userAuthenticationErrors[
          HTTP_STATUS_CODE.NOT_AUTHORIZED
        ]
      ) {
        loginDialogRef.value.setMessage(
          DOWNLOAD_ERROR_MESSAGES.INVALID_CREDENTIALS_MESSAGE
        );
      } else if (
        structureSearchStore.userAuthenticationErrors[
          HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY
        ]
      ) {
        showUnProcessableEntityMessage.value = true;
        clearSelections();
      }
    }

    const rowClass = (brkey) => {
      return structureSearchStore.selectedStructures.some(
        (s) => s.brkey == brkey
      )
        ? "rowSelect"
        : "rowUnselect";
    };

    const resetSort = () => {
      sortBy.value = [{ key: "brkey", order: "asc" }];
    };
    const resetSelections = () => {
      superDocsSelected.all = false;
      selectAllStructures.value = false;
    };

    //watch changes in store for download progress of docs.
    watch(structureSearchStore.eDocs, () => {
      docProgress._completedPct = structureSearchStore.eDocs.completedPct;
      docProgress._total = structureSearchStore.eDocs.total;
      if (docProgress._completedPct > 1) {
        loginDialogRef.value.closeDialog();
      }
    });

    const headers = [
      {
        title: "",
        align: "start",
        key: "selectAll",
        sortable: false,
      },
      {
        title: "BRKEY",
        align: "start",
        key: "brkey",
        sortable: true,
      },
      {
        title: "BMS ID",
        align: "start",
        key: "bmsId",
        sortable: true,
      },
      {
        title: "Insp Date",
        align: "start",
        key: "inspectionDate",
        sortable: true,
      },
      {
        title: "Insp Status",
        align: "start",
        key: "inspectionStatus",
        sortable: true,
      },

      {
        title: "Dist",
        align: "start",
        key: "district",
        sortable: true,
      },

      {
        title: "Agrmt#",
        align: "start",
        key: "agreementContractNumber",
        sortable: true,
      },
      {
        title: "Rpt Grp",
        align: "start",
        key: "reportingGroup",
        sortable: true,
      },
      {
        title: "Features Intersected",
        align: "start",
        key: "featuresIntersected",
        sortable: true,
      },
      {
        title: "Electronic Documents",
        key: "eDocsAll",
        sortable: false,
        align: "center",
      },
    ];

    const getResults = computed(
      () => structureSearchStore.getStructureSearchResults
    );

    onBeforeRouteLeave(() => {
      showDownloadSuccessMessage.value = false;
    });
    return {
      downloadedStructures,
      showServiceUnavailableMessage,
      recordsSelected,
      getFormattedDateStringNoTime,
      structureIDBStore,
      structureSearchStore,
      selectAllStructures,
      configStore,
      REFERENCE_TABLE,
      superDocsSelected,
      handleSelectAllStructures,
      updateStructureSelection,
      handleAllSuperSelected,
      handleOtherSuperSelected,
      handleInspectionSuperSelected,
      handleInventorySuperSelected,
      handleAllSelected,
      handleInspectionSelected,
      handleInventorySelected,
      handleOtherSelected,
      clearSelections,
      download,
      clientMaxStorage,
      totalRecordsInLocalDB,
      recordsToDownload,
      hideMsgDialog,
      msgDialog,
      checkRecordsLimit,
      panel,
      progress,
      docProgress,
      downloadErrorMessage,
      HTTP_STATUS_CODE,
      rowClass,
      searchResultsDatatable,
      hasResults,
      sortBy,
      resetSort,
      showDownloadSuccessMessage,
      loginDialogRef,
      checkLogin,
      headers,
      getResults,
      selected,
      resetSelections,
      showUnProcessableEntityMessage,
      DOWNLOAD_ERROR_MESSAGES,
      pageInspection,
      updatePage,
      footerOptions,
      resultsFooter,
    };
  },
  components: {
    WorkList,
    LoginDialog,
    DataTableFooter,
  },
});
</script>

<style scoped lang="scss">
@use "@/styles/colors" as c;

.important-text {
  color: c.$p-alert-red;
}

.error-dialog {
  background-color: c.$p-alert-red !important;
}

.v-expansion-panel-title,
.v-expansion-panel-title:hover {
  background-color: c.$p-pa-light-blue !important;
}

h3 {
  order: 1;
  color: c.$p-white !important;
}
</style>
