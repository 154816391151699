import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";

export default () => {
  const configStore = useConfigStore();
  const inspectionStore = useInspectionStore();

  function noteTypeIC01(commentType) {
    return configStore.getReferenceValue(
      REFERENCE_TABLE.INSPECTION_COMMENT_TYPE,
      commentType,
      false,
      true
    );
  }

  function substuctureUnitIC03(STRUNITKEY) {
    return (
      inspectionStore.getSubStructure(STRUNITKEY)?.STRUNITKEY +
      " - " +
      inspectionStore.getSubStructure(STRUNITKEY)?.STRUNITLABEL
    );
  }

  function noteTypeIC04(commentType) {
    return configStore.getReferenceValue(
      REFERENCE_TABLE.SUBSTRUCTURE_COMMENT_TYPE,
      commentType
    );
  }

  const sortSubInspectionNotes = (notes) => {
    const abutmentSortOrder = ["NAB", "FAB"];
    notes?.sort((a, b) => {
      const strUnitA = inspectionStore.getSubStructure(a?.STRUNITKEY);
      const strUnitB = inspectionStore.getSubStructure(b?.STRUNITKEY);

      if (
        abutmentSortOrder?.includes(strUnitA?.STRUNITLABEL) &&
        abutmentSortOrder?.includes(strUnitB?.STRUNITLABEL)
      ) {
        return (
          abutmentSortOrder?.indexOf(strUnitA?.STRUNITLABEL) -
          abutmentSortOrder?.indexOf(strUnitB?.STRUNITLABEL)
        );
      }
      // If only one element is in the custom order, it comes first
      if (abutmentSortOrder?.includes(strUnitA?.STRUNITLABEL)) {
        return -1;
      }
      if (abutmentSortOrder?.includes(strUnitB?.STRUNITLABEL)) {
        return 1;
      }
      return strUnitA?.STRUNITLABEL?.localeCompare(strUnitB?.STRUNITLABEL);
    });
  };

  return {
    noteTypeIC01,
    substuctureUnitIC03,
    noteTypeIC04,
    sortSubInspectionNotes,
  };
};
