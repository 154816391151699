<template>
  <v-row
    ><v-col>
      <v-label v-if="label" class="bold"
        >{{ label }}<span v-if="showColon">:</span>
        <span class="pl-1" v-if="labelValue">{{ labelValue }}</span></v-label
      >

      <v-text-field
        v-if="singleLine"
        :id="id"
        :aria-describedby="id"
        :aria-label="ariaLabel"
        v-model="modelValue"
        :readonly="readonly"
        :counter="counter"
        :maxlength="maxlength"
        persistent-counter
        single-line
        :variant="readonly ? 'outlined' : 'filled'"
      >
      </v-text-field>

      <v-textarea
        v-if="previousData"
        :id="id"
        :aria-describedby="id"
        :aria-label="ariaLabel"
        v-model="previousNotesVal"
        :readonly="readonly"
        :counter="counter"
        :maxlength="maxlength"
        persistent-counter
        auto-grow
        rows="1"
        :variant="readonly ? 'outlined' : 'filled'"
      >
      </v-textarea>

      <v-textarea
        v-else
        :id="id"
        v-model="modelValue"
        :aria-describedby="id"
        :aria-label="ariaLabel"
        :readonly="readonly"
        :counter="counter"
        :maxlength="maxlength"
        persistent-counter
        auto-grow
        rows="1"
        :variant="readonly ? 'outlined' : 'filled'"
        :required="isRequired"
        :rules="computedRules"
        @keypress="handler"
        @input="truncate"
        @blur="trim"
      >
        <template #details>
          <div class="d-flex justify-space-between">
            <v-spacer />
            <span
              class="inputErrorMessage"
              v-if="
                secondCharacterCount &&
                modelValueString?.length >= 1 &&
                modelValueString?.length < 50 &&
                !readonly
              "
              >{{ modelValueString?.length || 0 }} of 50 Characters Required
            </span>
            <span
              v-else-if="isRequired && !modelValueString && !readonly"
              class="inputErrorMessage"
            >
              Required
            </span>
          </div>
        </template>
      </v-textarea>
    </v-col></v-row
  >
</template>
<script>
import { computed, ref } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import {
  secondCharacterCountRule,
  requiredRule,
} from "@/composables/validationRules";
import { truncateString, encodeString } from "@/composables/util";

export default {
  name: "CommentTypeTextField",
  props: {
    id: {},
    inspectionCommentType: { default: "" },
    readonly: { type: Boolean, default: false },
    counter: { default: "" },
    maxlength: { maxlength: "" },
    singleLine: { type: Boolean, default: false },
    label: { default: "" },
    labelValue: { default: "" },
    showColon: { type: Boolean, default: true },
    secondCharacterCount: { type: Boolean, default: false },
    previousData: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
  },
  setup(props) {
    const inspectionStore = useInspectionStore();
    const modelValueString = ref("");
    const structure = computed(() => inspectionStore.selectedInspection);
    const previousNotesVal = computed(() => {
      return structure.value?.T_Insp_Comment_Prev?.find(
        (element) => element?.COMMENT_TYPE == props.inspectionCommentType
      )?.NOTES;
    });
    const ariaLabel = computed({
      get() {
        if (props.label == "" || !props.label) {
          return props.id + " has no label";
        } else {
          return props.label + "_" + props.id;
        }
      },
    });
    function setElementByCommentType(object) {
      structure.value?.T_Insp_Comment.map(function (element) {
        if (element?.COMMENT_TYPE == object?.COMMENT_TYPE) {
          element.NOTES = object.NOTES;
          element.USERKEY = object.USERKEY;
          element.MODTIME = object.MODTIME;
        }
      });
    }

    function getInspCommentObjectByCommentType(commentType) {
      return computed(() =>
        structure.value?.T_Insp_Comment?.find(
          (element) => element?.COMMENT_TYPE == commentType
        )
      );
    }

    function getNotesByCommentType(commentType) {
      const object = getInspCommentObjectByCommentType(commentType);
      return object?.value?.NOTES;
    }

    function elseCase(commentType, notes) {
      return {
        BRKEY: structure.value.Bridge.BRKEY,
        COMMENT_TYPE: commentType,
        INSPKEY: structure.value.InspEvnt.INSPKEY,
        MODTIME: new Date().toISOString(),
        NOTES: notes,
        USERKEY: structure.value.InspEvnt.USERKEY,
      };
    }

    const modelValue = computed({
      get() {
        return getNotesByCommentType(props.inspectionCommentType);
      },
      set(approachAlignmentParam) {
        modelValueString.value = encodeString(approachAlignmentParam);

        const approachAlignmentObject = getInspCommentObjectByCommentType(
          props.inspectionCommentType
        );

        if (approachAlignmentObject.value) {
          approachAlignmentObject.value.NOTES = modelValueString.value;
          approachAlignmentObject.value.USERKEY =
            structure.value.InspEvnt.USERKEY;
          approachAlignmentObject.value.MODTIME = new Date().toISOString();
          setElementByCommentType(approachAlignmentObject.value);
        } else {
          structure.value.T_Insp_Comment.push(
            elseCase(props.inspectionCommentType, modelValueString.value)
          );
        }
      },
    });

    modelValueString.value = modelValue.value;

    const computedRules = computed(() => {
      let validationRules = [];

      if (props.rules && props.rules.length > 0) {
        validationRules = validationRules.concat(props.rules);
      }
      if (props.isRequired && !props.readonly) {
        validationRules.push(requiredRule);
      }
      if (props.secondCharacterCount && !props.readonly) {
        validationRules.push(secondCharacterCountRule(modelValueString.value));
      }
      return validationRules;
    });

    const handler = (event) => {
      if (event.which < 0x20 && event.which != 13) {
        // e.which < 0x20, then it's not a printable character like backspace etc.
        // e.which!=13 , enter key
        return; // Do nothing
      }

      if (modelValueString.value?.length >= parseInt(props.maxlength)) {
        event.preventDefault();
        return false;
      }
    };

    const truncate = () => {
      modelValue.value = truncateString(
        modelValueString.value?.replace(/\r\n|\r|\n/g, "\r\n"),
        parseInt(props.maxlength)
      );
    };

    const trim = () => {
      const trimmedString = modelValue.value.trim();
      if (modelValue.value != trimmedString) {
        modelValue.value = modelValue.value.trim();
      }
    };

    return {
      ariaLabel,
      modelValue,
      modelValueString,
      previousNotesVal,
      secondCharacterCountRule,
      computedRules,
      handler,
      truncate,
      trim,
    };
  },
};
</script>
