<!--This is a custom component for Label Select Input Fields with optional duration icon  -->
<template>
  <v-row v-if="noGrid">
    <v-col class="d-flex" v-if="label">
      <v-label class="bold noGridLabel"
        ><span :class="isRequired ? 'required' : ''"
          >{{ label }}<span v-if="showColon">:</span></span
        ></v-label
      >
      <v-checkbox
        class="pl-2"
        :id="`checkbox_${id}_No_Grid`"
        :aria-describedby="`checkbox_${id}_No_Grid`"
        :aria-label="ariaLabel"
        true-value="1"
        false-value="0"
        hide-details
        :disabled="!isEditable"
        v-model="computedValue"
        :required="isRequired"
        :rules="computedRules"
      ></v-checkbox>
    </v-col>
  </v-row>
  <v-row v-else style="align-items: center">
    <v-col
      class="d-flex"
      :xl="labelXl"
      :lg="labelLg"
      :md="labelMd"
      :sm="labelSm"
      :cols="labelSm"
      v-if="label"
    >
      <v-label class="bold"
        ><span :class="isRequired ? 'required' : ''">{{ label }}</span
        ><span v-if="showColon">:</span></v-label
      >
    </v-col>
    <v-col
      class="d-flex"
      :xl="checkboxXl"
      :lg="checkboxLg"
      :md="checkboxMd"
      :sm="checkboxSm"
      :cols="checkboxSm"
    >
      <!--Adding padding to match non editable custom component spacings-->
      <span v-if="!isEditable && indent" class="pl-2 py-0" />
      <span v-if="isEditable && editIndent" class="pl-2 py-0" />
      <v-checkbox
        :id="`checkbox_${id}_Grid`"
        :aria-describedby="`checkbox_${id}_Grid`"
        :aria-label="ariaLabel"
        true-value="1"
        false-value="0"
        hide-details
        :disabled="!isEditable"
        v-model="computedValue"
        :required="isRequired"
        :rules="computedRules"
      ></v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
import { computed } from "vue";
import { requiredRule } from "@/composables/validationRules";

export default {
  name: "LabelCheckbox",
  props: {
    labelXl: { default: 4 },
    labelLg: { default: 4 },
    labelMd: { default: 4 },
    labelSm: { default: 4 },
    checkboxXl: { default: 3 },
    checkboxLg: { default: 3 },
    checkboxMd: { default: 3 },
    checkboxSm: { default: 3 },
    id: {},
    label: { default: "" },
    isEditable: { type: Boolean, default: false },
    modelValue: { default: null },
    isRequired: { type: Boolean, default: false },
    rules: { default: [] },
    showColon: { type: Boolean, default: true },
    indent: { type: Boolean, default: true },
    editIndent: { type: Boolean, default: false },
    noGrid: { type: Boolean, default: false },
  },
  setup(props, context) {
    const computedValue = computed({
      get: () => props.modelValue,
      set: (value) => context.emit("update:modelValue", value),
    });

    const ariaLabel = computed({
      get() {
        if (props.label == "") {
          return props.id + " has no label";
        } else {
          return props.label + "_" + props.id;
        }
      },
    });

    const computedRules = computed(() => {
      let validationRules = [];

      if (props.rules && props.rules.length > 0) {
        validationRules = validationRules.concat(props.rules);
      }
      if (props.isRequired) {
        validationRules.push(requiredRule);
      }
      return validationRules;
    });

    return { computedValue, computedRules, ariaLabel };
  },
};
</script>
<style scoped lang="scss">
.noGridLabel {
  padding-top: 6px;
}
</style>
