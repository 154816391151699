<template>
  <v-form
    ref="featureIntersectionDetailsForm"
    v-model="validDetails"
    class="mt-2"
  >
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="6" class="pa-0">
        <LabelSelect
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :selectXl="6"
          :selectLg="6"
          :selectMd="6"
          :selectSm="6"
          :id="`label_Highway_On/Under_${rowId}`"
          label="5C03 On/Under"
          :refTable="REFERENCE_TABLE.ON_UNDER"
          v-model="selectedRow.ON_UNDER"
        >
        </LabelSelect>
        <LabelSelect
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :selectXl="6"
          :selectLg="6"
          :selectMd="6"
          :selectSm="6"
          :id="`label_Highway_Feature_Type_${rowId}`"
          label="6C34 Feature Type"
          :refTable="REFERENCE_TABLE.FEATURE_TYPE"
          v-if="$vuetify.display.mdAndDown"
          v-model="selectedRow.FEATURE_TYPE"
        >
        </LabelSelect>

        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Feature_Name_${rowId}`"
          label="5C01 Feature Name"
          v-model="selectedRow.ROADWAY_NAME"
          maxlength="30"
        >
        </LabelText>

        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_County_${rowId}`"
          label="6C01 County"
          v-model="highwayCountyValue"
        >
        </LabelText>

        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_State_Route_Number_${rowId}`"
          label="6C02 State Route Number"
          v-model="selectedRow.SR_NUM"
          maxlength="4"
        >
        </LabelText>
        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Segment_${rowId}`"
          label="6C03 Segment"
          v-model="selectedRow.SEG_NUM"
          maxlength="4"
        >
        </LabelText>
        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Offset_${rowId}`"
          label="6C04 Offset"
          v-model="selectedRow.OFFSET"
          maxlength="4"
        >
        </LabelText>

        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Route_#_${rowId}`"
          label="5C06 Route #"
          v-model="selectedRow.ROUTENUM"
        >
        </LabelText>

        <LabelSelect
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :selectXl="6"
          :selectLg="6"
          :selectMd="6"
          :selectSm="6"
          :id="`label_Direction_${rowId}`"
          label="5C06 Direction"
          :refTable="REFERENCE_TABLE.ROUTE_DIRECTION"
          v-model="selectedRow.DIRSUFFIX"
        >
        </LabelSelect>

        <LabelSelect
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :selectXl="6"
          :selectLg="6"
          :selectMd="6"
          :selectSm="6"
          :id="`label_Functional_Class_${rowId}`"
          label="5C22 Functional Class"
          :refTable="REFERENCE_TABLE.ROADWAY_FUNCTIONAL_CLASSIFICATION"
          v-model="selectedRow.FUNCCLASS"
        >
        </LabelSelect>
        <LabelSelect
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :selectXl="6"
          :selectLg="6"
          :selectMd="6"
          :selectSm="6"
          :id="`label_Nat_Hwy_Sys_${rowId}`"
          label="5C29 Nat Hwy Sys"
          :refTable="REFERENCE_TABLE.NATIONAL_HIGHWAY_SYSTEM"
          v-model="selectedRow.NHS_IND"
        >
        </LabelSelect>
        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Lanes_${rowId}`"
          label="5C08 Lanes"
          v-model="selectedRow.LANES"
          type="number"
        >
        </LabelText>
        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Median_${rowId}`"
          label="5C08 Medians"
          v-model="selectedRow.NUM_MEDIAN"
          type="number"
        >
        </LabelText>
        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Speed_${rowId}`"
          label="5C08 Speed"
          v-model="selectedRow.ROAD_SPEED"
          :pairCode="PAIR_CODE.KPH_TO_MPH"
          noNegativeDisplayed
          appendWith=" mph"
          type="number"
        >
        </LabelText>

        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Recent_ADT_${rowId}`"
          label="5C10 Recent ADT"
          v-model="selectedRow.ADTTOTAL"
          type="number"
        >
        </LabelText>

        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Year_${rowId}`"
          label="5C11 Year"
          v-model="selectedRow.ADTYEAR"
        >
        </LabelText>
        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Truck_ADT_${rowId}`"
          label="5C14 Truck % ADT"
          v-model="selectedRow.TRUCKPCT"
          type="number"
        >
        </LabelText>
        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_ADTT_${rowId}`"
          label="6C27 ADTT"
          v-model="selectedRow.ADTT"
          type="number"
        >
        </LabelText>

        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_ADTT_Year_${rowId}`"
          label="6C28 ADTT Year"
          v-model="selectedRow.ADTT_YEAR"
        >
        </LabelText>
      </v-col>

      <v-col class="pa-0">
        <LabelCheckbox
          labelXl="6"
          labelLg="6"
          labelMd="6"
          labelSm="6"
          editIndent
          class="labelCheckbox"
          :id="`checkbox_New Wear Surface Ind_${rowId}`"
          label="6B02 New Wear Surface Ind"
          v-model="structureNewWearSurface"
          :isEditable="isEditable && !readOnly"
          @update:modelValue="changesDetectedHighway = true"
        >
        </LabelCheckbox>

        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Horizontal_(L)_${rowId}`"
          label="6C18 Horizontal (L)"
          v-model="selectedRow.TOT_HOR_CLEAR_LEFT"
          :pairCode="PAIR_CODE.METERS_TO_FEET"
          appendWith=" ft"
          :decimalPlaces="2"
          type="number"
        >
        </LabelText>
        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Horizontal_(R)_${rowId}`"
          label="6C19 Horizontal (R)"
          v-model="selectedRow.TOT_HOR_CLEAR_RIGHT"
          :pairCode="PAIR_CODE.METERS_TO_FEET"
          appendWith=" ft"
          :decimalPlaces="2"
          type="number"
        >
        </LabelText>
        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Min_Vertical_(L)_${rowId}`"
          label="6C20 Min Vertical (L)"
          v-model="selectedRow.MIN_OVER_VERT_CLEAR_LEFT"
          appendWith=" ft"
          :decimalPlaces="2"
          type="number"
          :conversionTypes="[METRIC_CONVERSION_TYPES.L3]"
          :pairCode="PAIR_CODE.METERS_TO_FEET"
        >
        </LabelText>
        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Min_Vertical _(R)_${rowId}`"
          label="6C21 Min Vertical (R)"
          v-model="selectedRow.MIN_OVER_VERT_CLEAR_RIGHT"
          appendWith=" ft"
          :decimalPlaces="2"
          type="number"
          :pairCode="PAIR_CODE.METERS_TO_FEET"
          :conversionTypes="[METRIC_CONVERSION_TYPES.L3]"
        >
        </LabelText>
        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Defense_Vertical_(L)_${rowId}`"
          label="6C22 Defense Vertical (L)"
          v-model="selectedRow.DEF_VERT_CLEAR_LEFT"
          appendWith=" ft"
          :decimalPlaces="2"
          type="number"
          :pairCode="PAIR_CODE.METERS_TO_FEET"
          :conversionTypes="[METRIC_CONVERSION_TYPES.L3]"
        >
        </LabelText>
        <LabelText
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="6"
          :textMd="6"
          :textSm="6"
          :id="`txt_Defense_Vertical_(R)_${rowId}`"
          label="6C23 Defense Vertical (R)"
          v-model="selectedRow.DEF_VERT_CLEAR_RIGHT"
          appendWith=" ft"
          :decimalPlaces="2"
          type="number"
          :pairCode="PAIR_CODE.METERS_TO_FEET"
          :conversionTypes="[METRIC_CONVERSION_TYPES.L3]"
        >
        </LabelText>

        <LabelSelect
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :selectXl="6"
          :selectLg="6"
          :selectMd="6"
          :selectSm="6"
          :id="`label_Highway_Feature_Type_${rowId}`"
          label="6C34 Feature Type"
          v-if="$vuetify.display.lgAndUp"
          :refTable="REFERENCE_TABLE.FEATURE_TYPE"
          v-model="selectedRow.FEATURE_TYPE"
        >
        </LabelSelect>

        <LabelSelect
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :selectXl="6"
          :selectLg="6"
          :selectMd="6"
          :selectSm="6"
          :id="`label_6C35_Vertical_Clearance_Sign_(L)_${rowId}`"
          label="6C35 Vertical Clearance Sign (L)"
          :refTable="REFERENCE_TABLE.VERTICAL_CLEARANCE_SIGN"
          :isEditable="isEditable && !readOnly"
          v-model="selectedRow.VERT_CLEAR_SIGN"
          @update:modelValue="changesDetectedHighway = true"
        >
        </LabelSelect>

        <v-row class="valign_center">
          <v-col sm="6" md="6" lg="6" xl="6" class="d-flex"
            ><v-label class="bold"
              >6C37 Vertical Clearance Posting (L):</v-label
            ></v-col
          >
          <v-col sm="6" md="6" lg="6" xl="6" class="d-flex">
            <NumberField
              class="pr-4 details-text"
              :class="readOnly ? 'pl-4 pt-1' : ''"
              :id="`txt_Vert_Clear_Posting_(L)_ft_${rowId}`"
              :readonly="readOnly"
              v-model="selectedRow.VRT_CLR_POST_LEFT_FT"
              :rules="
                isEditable
                  ? [
                      (value) => {
                        return lessThanValueRule(value, 16);
                      },
                    ]
                  : []
              "
              appendWith="ft"
              @update:modelValue="changesDetectedHighway = true"
            >
            </NumberField>

            <NumberField
              class="details-text"
              :class="readOnly ? 'pt-1' : ''"
              :id="`txt_Vert_Clear_Posting_(L)_in_${rowId}`"
              :readonly="readOnly"
              v-model="selectedRow.VRT_CLR_POST_LEFT_INCH"
              appendWith=" in"
              :rules="
                isEditable
                  ? [
                      (value) => {
                        return lessThanValueRule(value, 11);
                      },
                    ]
                  : []
              "
              @update:modelValue="changesDetectedHighway = true"
            >
            </NumberField> </v-col
        ></v-row>

        <LabelSelect
          :labelXl="6"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :selectXl="6"
          :selectLg="6"
          :selectMd="6"
          :selectSm="6"
          :id="`label_Vertical_Clearance_Sign_(R)_${rowId}`"
          label="6C36 Vertical Clearance Sign (R)"
          :refTable="REFERENCE_TABLE.VERTICAL_CLEARANCE_SIGN"
          :isEditable="isEditable && !readOnly"
          v-model="selectedRow.VERT_CLEAR_SIGN_R"
          @update:modelValue="changesDetectedHighway = true"
        >
        </LabelSelect>

        <v-row class="valign_center">
          <v-col sm="6" md="6" lg="6" xl="6" class="d-flex"
            ><v-label class="bold"
              >6C38 Vertical Clearance Posting (R):</v-label
            ></v-col
          >

          <v-col sm="6" md="6" lg="6" xl="6" class="d-flex">
            <NumberField
              class="pr-4 details-text"
              :class="readOnly ? 'pl-4 pt-1' : ''"
              :id="`txt_Vert_Clear_Posting_(R)_ft_${rowId}`"
              :readonly="readOnly"
              v-model="selectedRow.VRT_CLR_POST_RIGHT_FT"
              :rules="
                isEditable
                  ? [
                      (value) => {
                        return lessThanValueRule(value, 16);
                      },
                    ]
                  : []
              "
              appendWith="ft"
              @update:modelValue="changesDetectedHighway = true"
            >
            </NumberField>

            <NumberField
              class="details-text"
              :class="readOnly ? 'pt-1' : ''"
              :id="`txt_Vert_Clear_Posting_(R)_in_${rowId}`"
              :readonly="readOnly"
              v-model="selectedRow.VRT_CLR_POST_RIGHT_INCH"
              appendWith="in"
              :rules="
                isEditable
                  ? [
                      (value) => {
                        return lessThanValueRule(value, 11);
                      },
                    ]
                  : []
              "
              @update:modelValue="changesDetectedHighway = true"
            >
            </NumberField>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { ref, computed, watch } from "vue";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useInspectionStore } from "@/stores/inspection";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";
import LabelText from "@/components/common/LabelText.vue";
import NumberField from "../../common/NumberField.vue";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { lessThanValueRule } from "@/composables/validationRules";
import { METRIC_CONVERSION_TYPES } from "@/constants/InspectionConstants";
import useFeaturesData from "@/composables/featuresData";

export default {
  name: "InspectionFeaturesHighway",
  props: {
    rowId: { default: 0 },
    readOnly: { default: false },
  },
  emits: ["changesDetectedHighway", "updatedNewWearSurface"],
  setup(props, context) {
    const inspectionStore = useInspectionStore();
    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });

    const { highwayCounty } = useFeaturesData();

    let selectedRow = computed(() =>
      inspectionStore.getFeatureIntersectionSelectedRow(props.rowId)
    );

    const highwayCountyValue = highwayCounty(selectedRow.value);

    let validDetails = ref(false);

    let featureIntersectionDetailsForm = ref(null);

    async function validate() {
      await featureIntersectionDetailsForm.value.validate();
      return validDetails.value;
    }
    let structure = computed(() => inspectionStore.selectedInspection);
    let localStructureNewWearSurface = ref(structure.value.UserInsp.NEW_WS);
    let changesDetectedHighway = ref(false);
    let isStructureNewWearSurfaceChecked = ref(false);
    let structureNewWearSurface = computed({
      get: () => {
        return localStructureNewWearSurface.value;
      },
      set: (value) => {
        localStructureNewWearSurface.value = value;
        context.emit("updatedNewWearSurface", value);
      },
    });

    watch(
      () => [changesDetectedHighway],
      () => {
        context.emit("changesDetectedHighway", changesDetectedHighway);
      },
      { deep: true }
    );

    return {
      validate,
      featureIntersectionDetailsForm,
      validDetails,
      REFERENCE_TABLE,
      isEditable,
      structure,
      changesDetectedHighway,
      selectedRow,
      PAIR_CODE,
      highwayCountyValue,
      lessThanValueRule,
      isStructureNewWearSurfaceChecked,
      structureNewWearSurface,
      METRIC_CONVERSION_TYPES,
    };
  },
  components: {
    LabelSelect,
    LabelCheckbox,
    LabelText,
    NumberField,
  },
};
</script>
<style scoped lang="scss">
th {
  text-align: center;
}

.details-text :deep(.v-input__details) {
  padding-left: 0;
}
</style>
