<template>
  <v-menu class="pa-0 ma-0" v-model="menu" :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-icon
        v-bind="props"
        icon="fas fa-calendar-days"
        @click="onCalendarClick"
      />
    </template>
    <v-date-picker
      class="pa-0 ma-0 hideScrollbar hideIconBox"
      hide-actions
      :display-date="displayDate"
      :max="maxDate"
      :min="minDate"
      show-adjacent-months
      input-mode="calendar"
      v-model="computedValue"
    >
      <template v-slot:header>
        <v-row>
          <v-col cols="4" class="pt-0 pl-4 ml-2"
            ><v-btn class="px-2" variant="outlined" @click="today()"
              >Today</v-btn
            >
          </v-col>
          <v-col cols="4" class="pt-0 pl-0 pr-0 ml-3"
            ><v-btn class="px-2" variant="outlined" @click="clear()"
              >Clear</v-btn
            >
          </v-col>
          <v-col cols="3" class="pt-0 pl-0 pr-0 ml-0"
            ><v-btn class="px-2" variant="outlined" @click="close()"
              >Close</v-btn
            >
          </v-col>
        </v-row>
      </template>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { computed, ref } from "vue";

export default {
  name: "DatePicker",
  props: {
    modelValue: { default: "" },
    minDate: { default: undefined },
    maxDate: { default: undefined },
    required: { default: false },
  },
  setup(props, context) {
    let menu = ref(false);
    const displayDate = computed({
      get: () => (props.modelValue ? new Date(props.modelValue) : new Date()),
    });
    const computedValue = computed({
      get: () => (props.modelValue ? new Date(props.modelValue) : null),

      set: (value) => {
        context.emit("update:modelValue", value);
        menu.value = false;
      },
    });
    const clear = () => {
      context.emit("update:modelValue", null);
      menu.value = false;
    };
    const close = () => {
      menu.value = false;
    };
    const today = () => {
      context.emit("update:modelValue", new Date());
      menu.value = false;
    };
    const onCalendarClick = () => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    };

    return {
      displayDate,
      close,
      today,
      clear,
      menu,
      computedValue,
      onCalendarClick,
    };
  },
};
</script>

<style scoped lang="scss">
.v-row {
  align-items: center;
}

.hideScrollbar {
  overflow: hidden !important;
}

.v-icon:hover {
  box-shadow: none !important;
}
.required {
  color: rgb(215, 26, 23);
}
</style>
