export function scrollToElement(element) {
  //scroll to search results table
  const searchResultsTable = document.getElementById(element);
  const bms3Header = document.getElementById("bms3_header_app_bar");
  window.scrollTo(
    0,
    searchResultsTable.getBoundingClientRect().top -
      bms3Header.getBoundingClientRect().bottom +
      window.scrollY
  );
}
export function scrollToDataTable(element) {
  //scroll to Top of Table
  const documentElement = document.getElementById(element);
  const bms3Header = document.getElementById("bms3_header_app_bar");
  window.scrollTo(
    0,
    documentElement?.getBoundingClientRect().top -
      bms3Header?.getBoundingClientRect().bottom +
      window.scrollY -
      260
  );
}
