const maintenanceCandidate = {
  ACTCODE: "",
  ACTKIND: "",
  ACTKINDBMS3: "2",
  AGENCY_PRIORITY: "",
  AGENCY_STATUS: "",
  ASSIGNED: null,
  BRKEY: "",
  CREATEDATETIME: "",
  CREATEUSERKEY: "",
  DOCREFKEY: "",
  ESTIMCOST: "",
  ESTIMQUANTITY: "",
  FLAG_WHOLE: "",
  INSPKEY: "",
  MODTIME: null,
  NOTES: "",
  OBJCODE: 0,
  OBJKIND: 0,
  REF_WITEMKEY: "",
  STATE1: "",
  STATE2: "",
  STATE3: "",
  STATE4: "",
  STATE5: "",
  STRUNITKEY: null,
  TARGETYEAR: null,
  USERKEY: "",
  WCKEY: null,
  WC_ID: null,
  WORKASSIGNMENT: "",
  WORKRECDATE: "",
};
export default maintenanceCandidate;
