export const INSPECTION_STATUSES = {
  READY_TO_SUBMIT: "0",
  NEW: "1",
  SUBMITTED: "2",
  UNDER_REVIEW: "3",
  VALIDATION_ERROR: "4",
  READY_FOR_ACCEPT_REVIEW: "5",
  INTERIM_FINAL_REVIEW: "6",
  RETURN_FOR_CORRECTION: "8",
  ACCEPTED: "9",
  NOT_APPLICABLE: "NA",
  UNKNOWN: "UN",
};
