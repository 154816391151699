<template>
  <v-row class="d-flex mx-1">
    <div class="align-self-center me-auto">
      <span
        class="font-weight-bold"
        v-if="
          itemsPerPageValue == -1 ||
          pageValue == Math.ceil(items.length / itemsPerPageValue) ||
          itemsPerPageValue >= items.length
        "
        >Records
        {{ itemsPerPageValue * (pageValue - 1) + (items.length ? 1 : 0) }}
        to {{ items.length }} of
        {{ items.length }}
      </span>

      <span class="font-weight-bold" v-else-if="pageValue == 1"
        >Records 1 to {{ itemsPerPageValue }} of
        {{ items.length }}
      </span>
      <span class="font-weight-bold" v-else-if="pageValue != 1"
        >Records
        {{ itemsPerPageValue * (pageValue - 1) + 1 }}
        to {{ itemsPerPageValue * pageValue }} of
        {{ items.length }}
      </span>
    </div>
    <LabelSelect
      :id="`select_${id}`"
      class="align-self-center tableFooterSelect"
      label="Records per page"
      :isEditable="true"
      no-grid
      :options="rowsPerPageOptions"
      v-model="itemsPerPageValue"
      :clearable="false"
    >
    </LabelSelect>
    <div class="align-self-center">
      <v-row align="center">
        <v-col cols="3">
          <v-row align="center">
            <v-col cols="6" class="pa-0">
              <v-btn
                aria-label="tablePageBackToOne"
                :disabled="pageValue == 1 || itemsPerPageValue == -1"
                variant="plain"
                icon="fas fa-step-backward "
                @click="setPageValue(1)"
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <v-btn
                aria-label="tablePageBackOne"
                :disabled="pageValue == 1 || itemsPerPageValue == -1"
                @click="setPageValue(pageValue - 1)"
                variant="plain"
                icon="fas fa-chevron-left"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6" class="pt-2">
          <v-row align="center">
            <v-col
              v-if="itemsPerPageValue != -1"
              cols="12"
              justify-center
              class="pa-0"
              >Page {{ items.length ? pageValue : 0 }} of
              {{ Math.ceil(items.length / itemsPerPageValue) }}</v-col
            >
            <v-col v-else cols="12" justify-center class="pa-0"
              >Page {{ pageValue }} of 1
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row align="center">
            <v-col cols="6" class="pa-0">
              <v-btn
                aria-label="tablePageForwardToMaxPage"
                :disabled="
                  pageValue == Math.ceil(items.length / itemsPerPageValue) ||
                  itemsPerPageValue == -1
                "
                @click="setPageValue(pageValue + 1)"
                variant="plain"
                icon="fas fa-chevron-right "
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <v-btn
                aria-label="tablePageForwardOne"
                :disabled="
                  pageValue == Math.ceil(items.length / itemsPerPageValue) ||
                  itemsPerPageValue == -1
                "
                variant="plain"
                icon="fas fa-step-forward"
                @click="
                  setPageValue(Math.ceil(items.length / itemsPerPageValue))
                "
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-row>
</template>
<script>
import LabelSelect from "@/components/common/LabelSelect.vue";
import { computed, ref, watch } from "vue";
export default {
  name: "DataTableFooter",
  props: {
    id: {},
    modelValue: {
      default: { itemsPerPage: 30, page: 1 },
    },
    items: { default: [] },
    updatePage: { default: false },
    rowsPerPageOptions: {
      default: [
        { title: "30", value: 30 },
        { title: "60", value: 60 },
        { title: "90", value: 90 },
        { title: "All", value: -1 },
      ],
    },
  },
  setup(props, context) {
    let pageValue = ref(props.modelValue.page);
    const itemsPerPageValue = computed({
      get: () => props.modelValue.itemsPerPage,
      set: (value) => {
        let updateValue = {
          itemsPerPage: value,
          page: pageValue.value,
        };
        setPageValue(1);
        context.emit("update:modelValue", updateValue);
      },
    });
    function setPageValue(value) {
      pageValue.value = value;
      let updateValue = {
        itemsPerPage: itemsPerPageValue.value,
        page: pageValue.value,
      };
      context.emit("update:modelValue", updateValue);
    }

    watch(
      () => [props.updatePage],
      () => {
        if (props.updatePage) {
          setPageValue(1);
        }
      },
      { deep: true }
    );

    return {
      setPageValue,
      itemsPerPageValue,
      pageValue,
    };
  },
  components: {
    LabelSelect,
  },
};
</script>
<style scoped lang="scss">
.tableFooterSelect :deep(.v-field) {
  max-width: 85px !important;
}
.v-col {
  padding: 0 !important;
}
.v-row {
  margin: 0 !important;
}
</style>
