<template>
  <v-row>
    <ExpandCollapseToggle
      id="wallsPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panels"
    />
    <v-expansion-panels v-model="panels" multiple color="#fff">
      <v-expansion-panel class="mt-5">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panels.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panels.includes(0)" icon="fa-solid fa-minus" />
          <h3>Wall Conditions</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-form v-model="valid" ref="modifyWallsValidation">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="6">
                <LabelSelect
                  labelXl="7"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  :isRequired="isEditable ? true : false"
                  id="select_Overall_Condition_Rating"
                  label="IW10 Overall Condition Rating"
                  :refTable="REFERENCE_TABLE.WALL_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="overallConditionRating"
                ></LabelSelect>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <CommentTypeTextField
                  label="Overall Wall Notes"
                  id="field_Overall_Wall_Notes"
                  :inspectionCommentType="INSPECTION_COMMENTS.OVERALL_WALL"
                  :readonly="!isEditable"
                  :counter="commentsMaxLength"
                  :maxlength="commentsMaxLength"
                  :singleLine="false"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12" lg="6" class="pr-lg-3">
                <LabelSelect
                  labelXl="7"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  :isRequired="isEditable ? true : false"
                  id="select_Anchorage_Rating"
                  label="IW02 Anchorage"
                  :refTable="REFERENCE_TABLE.WALL_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="anchorageRate"
                ></LabelSelect>
                <LabelSelect
                  labelXl="7"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  :isRequired="isEditable ? true : false"
                  id="select_Backfill"
                  label="IW03 Backfill"
                  :refTable="REFERENCE_TABLE.WALL_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="backfillRate"
                ></LabelSelect>
                <LabelSelect
                  labelXl="7"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  :isRequired="isEditable ? true : false"
                  id="select_Wall_Rating"
                  label="IW04 Wall"
                  :refTable="REFERENCE_TABLE.WALL_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="wallRate"
                ></LabelSelect>
                <LabelSelect
                  labelXl="7"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  :isRequired="isEditable ? true : false"
                  id="select_Panel_Rating"
                  label="IW05 Panel"
                  :refTable="REFERENCE_TABLE.WALL_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="panelRate"
                ></LabelSelect>
                <LabelSelect
                  labelXl="7"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  :isRequired="isEditable ? true : false"
                  id="select_Post_Rating"
                  label="IW06 Post"
                  :refTable="REFERENCE_TABLE.WALL_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="postRate"
                ></LabelSelect>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="6" class="pl-lg-3">
                <LabelSelect
                  labelXl="7"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  :isRequired="isEditable ? true : false"
                  id="select_Drainage_Rating"
                  label="IW07 Drainage"
                  :refTable="REFERENCE_TABLE.WALL_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="drainageRate"
                ></LabelSelect>
                <LabelSelect
                  labelXl="7"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  :isRequired="isEditable ? true : false"
                  id="select_Foundation_Rating"
                  label="IW08 Foundation"
                  :refTable="REFERENCE_TABLE.WALL_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="foundationRate"
                ></LabelSelect>
                <LabelSelect
                  labelXl="7"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  :isRequired="isEditable ? true : false"
                  id="select_Parapets_Rating"
                  label="IW09 Parapets"
                  :refTable="REFERENCE_TABLE.WALL_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="parapetsRate"
                ></LabelSelect>
                <LabelSelect
                  labelXl="7"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  :isRequired="false"
                  id="selected_Primary_Backfill_Material"
                  label="VW06 Primary Backfill Material"
                  :refTable="REFERENCE_TABLE.BACKFILL_MATERIAL"
                  :isEditable="false"
                  v-model="backfillMaterialOne"
                ></LabelSelect>
                <LabelSelect
                  labelXl="7"
                  labelLg="7"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="5"
                  selectMd="4"
                  selectSm="5"
                  :isRequired="false"
                  id="selected_Secondary_Backfill_Material"
                  label="VW07 Secondary Backfill Material"
                  :refTable="REFERENCE_TABLE.BACKFILL_MATERIAL"
                  :isEditable="false"
                  v-model="backfillMaterialTwo"
                ></LabelSelect>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panels.includes(1)" icon="fa-solid fa-plus" />
          <v-icon v-if="panels.includes(1)" icon="fa-solid fa-minus" />
          <h3>Wall Inspection Notes</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <CommentTypeTextField
            id="field_Wall_Anchorage_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.WALL_ANCHORAGE"
            :readonly="!isEditable"
            :counter="commentsMaxLength"
            :maxlength="commentsMaxLength"
            label="Anchorage"
            :singleLine="false"
          />
          <CommentTypeTextField
            id="field_Wall_Backfill_Damping_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.WALL_BACKFLIP_DAMPING"
            :readonly="!isEditable"
            :counter="commentsMaxLength"
            label="Backfill"
            :maxlength="commentsMaxLength"
            :singleLine="false"
          />
          <CommentTypeTextField
            id="field_Wall_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.WALL"
            :readonly="!isEditable"
            :counter="commentsMaxLength"
            label="Wall"
            :maxlength="commentsMaxLength"
            :singleLine="false"
          />
          <CommentTypeTextField
            id="field_Wall_Panel_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.WALL_PANEL"
            :readonly="!isEditable"
            :counter="commentsMaxLength"
            label="Panel"
            :maxlength="commentsMaxLength"
            :singleLine="false"
          />
          <CommentTypeTextField
            id="field_Wall_Post_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.WALL_POST"
            :readonly="!isEditable"
            :counter="commentsMaxLength"
            :maxlength="commentsMaxLength"
            label="Post"
            :singleLine="false"
          />
          <CommentTypeTextField
            id="field_Wall_Drainage_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.WALL_PAGE_DRAINAGE"
            :readonly="!isEditable"
            :counter="commentsMaxLength"
            :maxlength="commentsMaxLength"
            label="Drainage"
            :singleLine="false"
          />
          <CommentTypeTextField
            id="field_Wall_Foundation_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.WALL_FOUNDATION"
            :readonly="!isEditable"
            :counter="commentsMaxLength"
            :maxlength="commentsMaxLength"
            label="Foundation"
            :singleLine="false"
          />
          <CommentTypeTextField
            id="field_Wall_Parapets_Notes"
            :inspectionCommentType="INSPECTION_COMMENTS.WALL_PARAPETS"
            :readonly="!isEditable"
            :counter="commentsMaxLength"
            :maxlength="commentsMaxLength"
            label="Parapets"
            :singleLine="false"
          />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>
<script>
import { computed, ref, watch, onMounted } from "vue";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import LabelSelect from "@/components/common/LabelSelect.vue";
import { clone } from "@/util/clone";
import { objTWalls, objTWallsInsp } from "@/data/walls";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";

export default {
  name: "WallsComponent",
  setup() {
    const configStore = useConfigStore();

    const inspectionStore = useInspectionStore();

    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });

    let structure = computed(() => inspectionStore.selectedInspection);
    const allPanels = [0, 1, 2];
    const panels = ref(allPanels);

    const commentsMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });

    onMounted(async () => {
      await validate();
    });

    let valid = ref(true);
    let modifyWallsValidation = ref(null);

    async function validate() {
      await modifyWallsValidation.value.validate();
    }

    function defaultTWall() {
      if (!structure.value?.T_Wall) {
        objTWalls.BRKEY = structure.value.Bridge.BRKEY;
        objTWalls.MODTIME = new Date().toISOString();
        objTWalls.USERKEY = structure.value.InspEvnt.USERKEY;
        structure.value.T_Wall = clone(objTWalls);
      }
    }
    defaultTWall();

    function defaultTWallInsp() {
      if (!structure.value?.T_Wall_Insp) {
        objTWallsInsp.BRKEY = structure.value.Bridge.BRKEY;
        objTWallsInsp.INSPKEY = structure.value.InspEvnt.INSPKEY;
        objTWallsInsp.MODTIME = new Date().toISOString();
        objTWallsInsp.USERKEY = structure.value.InspEvnt.USERKEY;
        structure.value.T_Wall_Insp = clone(objTWallsInsp);
      }
    }
    defaultTWallInsp();

    const overallConditionRating = computed({
      get() {
        return structure.value?.T_Wall_Insp?.STRRATING;
      },
      set(overallConditionRatingParam) {
        structure.value.T_Wall_Insp.STRRATING = overallConditionRatingParam;
      },
    });

    const backfillMaterialOne = computed({
      get() {
        return structure.value?.T_Wall?.BACKFILL_MATERIAL1;
      },
      set(backfillMaterialOneParam) {
        structure.value.T_Wall.BACKFILL_MATERIAL1 = backfillMaterialOneParam;
      },
    });

    const backfillMaterialTwo = computed({
      get() {
        return structure.value?.T_Wall?.BACKFILL_MATERIAL2;
      },
      set(backfillMaterialTwoParam) {
        structure.value.T_Wall.BACKFILL_MATERIAL2 = backfillMaterialTwoParam;
      },
    });

    const anchorageRate = computed({
      get() {
        return structure.value?.T_Wall_Insp?.ANCHORAGE_COND_RATE;
      },
      set(anchorageParam) {
        structure.value.T_Wall_Insp.ANCHORAGE_COND_RATE = anchorageParam;
      },
    });

    const backfillRate = computed({
      get() {
        return structure.value?.T_Wall_Insp?.BACKFILL_COND_RATE;
      },
      set(backfillRateParam) {
        structure.value.T_Wall_Insp.BACKFILL_COND_RATE = backfillRateParam;
      },
    });

    const wallRate = computed({
      get() {
        return structure.value?.T_Wall_Insp?.WALL_COND_RATE;
      },
      set(wallRateParam) {
        structure.value.T_Wall_Insp.WALL_COND_RATE = wallRateParam;
      },
    });

    const panelRate = computed({
      get() {
        return structure.value?.T_Wall_Insp?.PANEL_COND_RATE;
      },
      set(panelRateParam) {
        structure.value.T_Wall_Insp.PANEL_COND_RATE = panelRateParam;
      },
    });

    const postRate = computed({
      get() {
        return structure.value?.T_Wall_Insp?.POST_COND_RATE;
      },
      set(postRateParam) {
        structure.value.T_Wall_Insp.POST_COND_RATE = postRateParam;
      },
    });

    const drainageRate = computed({
      get() {
        return structure.value?.T_Wall_Insp?.DRAINAGE_COND_RATE;
      },
      set(drainageRateParam) {
        structure.value.T_Wall_Insp.DRAINAGE_COND_RATE = drainageRateParam;
      },
    });

    const foundationRate = computed({
      get() {
        return structure.value?.T_Wall_Insp?.FOUND_COND_RATE;
      },
      set(foundationRateParam) {
        structure.value.T_Wall_Insp.FOUND_COND_RATE = foundationRateParam;
      },
    });

    const parapetsRate = computed({
      get() {
        return structure.value?.T_Wall_Insp?.PARAPETS_COND_RATE;
      },
      set(parapetsRateParam) {
        structure.value.T_Wall_Insp.PARAPETS_COND_RATE = parapetsRateParam;
      },
    });

    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panels.value = allPanels;
      } else {
        panels.value = [];
      }
    };

    watch(
      () => [structure.value.T_Wall_Insp, structure.value.T_Insp_Comment],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      parapetsRate,
      foundationRate,
      drainageRate,
      postRate,
      panelRate,
      wallRate,
      backfillRate,
      anchorageRate,
      backfillMaterialOne,
      backfillMaterialTwo,
      overallConditionRating,
      modifyWallsValidation,
      valid,
      validate,
      REFERENCE_TABLE,
      panels,
      isEditable,
      structure,
      commentsMaxLength,
      INSPECTION_COMMENTS,
      toggleAllPanels,
    };
  },
  components: {
    LabelSelect,
    CommentTypeTextField,
    ExpandCollapseToggle,
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/colors" as c;

h3 {
  order: 1;
  color: c.$p-white !important;
}

.v-expansion-panel-title__icon {
  order: 0;
}

.tableHeader {
  text-align: center !important;
  background: c.$p-pa-light-blue !important;
}

.v-label.v-field-label {
  color: c.$p-black !important;
  opacity: 1 !important;
  font-weight: bold !important;
}
</style>
