import { useConfigStore } from "@/stores/config";

export default () => {
  const configStore = useConfigStore();

  function highwayCounty(detail) {
    const c = configStore.getCountyByCountyCode(detail.PA_COUNTY);
    return c ? `${c.countyCode} - ${c.countyName}` : detail.PA_COUNTY;
  }

  return {
    highwayCounty,
  };
};
