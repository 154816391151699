const INSPECTION_TYPE = {
  BRKEY: "",
  CREATE_DATE: null,
  DUE_DATE: null,
  END_DATE: null,
  EQUIP_A01: "0",
  EQUIP_A02: "0",
  EQUIP_A03: "0",
  EQUIP_A04: "0",
  EQUIP_A05: "0",
  EQUIP_A06: "0",
  EQUIP_A07: "0",
  EQUIP_A08: "0",
  EQUIP_A09: "0",
  EQUIP_A10: "0",
  EQUIP_A11: "0",
  EQUIP_A12: "0",
  EQUIP_A13: "0",
  EQUIP_A14: "0",
  EQUIP_AN: "0",
  EQUIP_AX: "0",
  EQUIP_CONCAT: null,
  EQUIP_IN: "0",
  EQUIP_I01: "0",
  EQUIP_I02: "0",
  EQUIP_I03: "0",
  EQUIP_I04: "0",
  EQUIP_I05: "0",
  EQUIP_I06: "0",
  EQUIP_I07: "0",
  EQUIP_I08: "0",
  EQUIP_I09: "0",
  EQUIP_I10: "0",
  EQUIP_I11: "0",
  EQUIP_I12: "0",
  EQUIP_I13: "0",
  EQUIP_I14: "0",
  EQUIP_I15: "0",
  EQUIP_IX: "0",
  INSP_INTERVAL: null,
  INSP_TYPE: "",
  INSPECTED_BY: null,
  INSPECTED_BY_NAME: null,
  INSPKEY: "",
  MOD_DATE: null,
  QA_DATE: null,
  QC_DATE: null,
  RBM: null,
  SCOPE_NOTE: null,
  START_DATE: "",
  TEAM_HELPER: null,
  TEAM_LEADER: "",
};
export default INSPECTION_TYPE;
