<template>
  <div class="v-input--error" v-if="showMessage">
    <div class="v-input__details">
      <div class="v-messages">
        <div class="v-messages__message">{{ message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputMessage",
  props: {
    showMessage: { type: Boolean, default: false },
    message: { default: "" },
  },
};
</script>
