<template>
  <v-row>
    <v-col xl="4" lg="4" md="4" sm="12" xs="12">
      <v-table class="hide_scroll compactTable padding-xs">
        <thead>
          <tr>
            <th
              id="th_fieldComparison"
              aria-label="blankTableHeader"
              style="width: 55%"
              class="align-center"
            />
            <th id="th_current" class="align-center">Current</th>
            <th id="th_previous" class="align-center">Previous</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="boldFont">7A01 Inspection Date:</td>
            <td class="align-center">
              {{
                getFormattedDateStringNoTime(structure.InspEvnt.INSPDATE) ||
                "--"
              }}
            </td>
            <td class="align-center">
              {{
                getFormattedDateStringNoTime(
                  prevAcceptedInsp?.inspectionDate
                ) || "--"
              }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">7A03 Inspection Type:</td>

            <td class="align-center">
              {{ structure.InspEvnt.INSPTYPE || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.inspectionType || "--" }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">1A01 Deck:</td>
            <td class="align-center">
              {{ structure.InspEvnt.DKRATING || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.dkRating || "--" }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">1A04 Superstructure:</td>
            <td class="align-center">
              {{ structure.InspEvnt.SUPRATING || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.supRating || "--" }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">1A02 Substructure:</td>
            <td class="align-center">
              {{ structure.InspEvnt.SUBRATING || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.subRating || "--" }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">1A03 Culvert:</td>
            <td class="align-center">
              {{ structure.InspEvnt.CULVRATING || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.culvRating || "--" }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">IA02/B.C.05 Railings:</td>
            <td class="align-center">
              {{ structure.InspEvnt.RAILRATING || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.railRating || "--" }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">IA02/B.C.06 Transition:</td>
            <td class="align-center">
              {{ structure.InspEvnt.TRANSRATIN || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.transRating || "--" }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">IB01 Bearings:</td>
            <td class="align-center">
              {{ structure.T_Bearings?.[0]?.BEARING_OVERALL_COND_RATE || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.bearingsOverallCondRating || "--" }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">IJ01 Joints:</td>
            <td class="align-center">
              {{ structure.T_Joints?.[0]?.OVERALL_COND_RATING || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.jointsOverallCondRating || "--" }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">1A05 Channel:</td>
            <td class="align-center">
              {{ structure.InspEvnt.CHANRATING || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.chanRating || "--" }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">1A05b Channel Protection:</td>
            <td class="align-center">
              {{ structure.UserInsp.CHAN_PROT_RATING || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.chanProtRating || "--" }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">1A13 Scour:</td>
            <td class="align-center">
              {{
                overallScourHoles && overallScourHoles != ""
                  ? overallScourHoles
                  : "--"
              }}
            </td>
            <td class="align-center">
              {{
                prevOverallScourHoles && prevOverallScourHoles != ""
                  ? prevOverallScourHoles
                  : "--"
              }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">1A14 Underwater:</td>
            <td class="align-center">
              {{ structure.UserInsp.UNDERWATER_RATING || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.underwaterRating || "--" }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">1A15 NSTM:</td>
            <td class="align-center">
              {{ structure.UserInsp.NSTM_CONDITION || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.nstmCondition || "--" }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">4A08 SCBI:</td>
            <td class="align-center">
              {{ structure.InspEvnt.SCOURCRIT || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.scourCritical || "--" }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">4A08b SCBI Category:</td>
            <td class="align-center">
              {{ structure.InspEvnt.SCOUR_CRIT_CTGRY || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.scourCriticalCat || "--" }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">IN15 Streambed Material:</td>
            <td class="align-center">
              {{ getIN15Value(structure.T_Underwater_Insp) || "--" }}
            </td>
            <td class="align-center">
              {{
                prevAcceptedInsp?.streamBedMaterial
                  ? getIN15ValueFromMaterials(
                      prevAcceptedInsp?.streamBedMaterial
                    )
                  : "--"
              }}
            </td>
          </tr>
          <tr>
            <td class="boldFont">4A14 Bridge Condition:</td>
            <td class="align-center">
              {{ structure.InspEvnt.CONDITION || "--" }}
            </td>
            <td class="align-center">
              {{ prevAcceptedInsp?.condition || "--" }}
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-col>
    <v-col xl="8" lg="8" md="8" sm="12" xs="12">
      <v-row>
        <v-col align="center" xl="1" lg="2" md="2" sm="2" xs="2">
          <span>&nbsp;</span>
          <v-table class="noBorders hide_scroll compactTable pt-2 padding-xs">
            <thead>
              <tr>
                <th id="th_empty" aria-label="blankTableHeader">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tableRowHeight">
                <td class="rightAlign pt-2 pb-1">NBI</td>
              </tr>
              <tr class="tableRowHeight">
                <td class="rightAlign pt-2 pb-1">NSTM</td>
              </tr>
              <tr class="tableRowHeight">
                <td class="rightAlign pt-2 pb-1">UW</td>
              </tr>
              <tr class="tableRowHeight">
                <td class="rightAlign pt-2 pb-1">OS</td>
              </tr>
              <tr class="tableRowHeight">
                <td class="rightAlign pt-2 pb-1">Element</td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="3"
          xs="3"
          align="center"
          v-if="showPerformedData"
        >
          <v-label class="bold">7A06 Performed</v-label>
          <v-table class="hide_scroll compactTable padding-xs">
            <thead>
              <tr>
                <th id="th_current" class="align-center">Current</th>
                <th id="th_previous" class="align-center">Previous</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tableRowHeight align-center">
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_nstm_Current"
                    v-model="structure.InspEvnt.NBINSPDONE"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_nstm_Previous"
                    :model-value="prevAcceptedInsp?.nbInspectionDone"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
              </tr>
              <tr class="tableRowHeight">
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_nstm_Current"
                    v-model="structure.InspEvnt.FCINSPDONE"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_nstm_Previous"
                    :model-value="prevAcceptedInsp?.fcInspectionDone"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
              </tr>
              <tr class="tableRowHeight">
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_uw_Current"
                    v-model="structure.InspEvnt.UWINSPDONE"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_uw_Previous"
                    :model-value="prevAcceptedInsp?.uwInspectionDone"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
              </tr>
              <tr class="tableRowHeight">
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_os_Current"
                    v-model="structure.InspEvnt.OSINSPDONE"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_os_Previous"
                    :model-value="prevAcceptedInsp?.osInspectionDone"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
              </tr>
              <tr class="tableRowHeight">
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_element_Current"
                    v-model="structure.InspEvnt.ELINSPDONE"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_element_Previous"
                    :model-value="prevAcceptedInsp?.elInspectionDone"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
        <v-col xl="3" lg="3" md="3" sm="3" xs="3" align="center">
          <v-label class="bold">7A07 Required</v-label>
          <v-table class="hide_scroll compactTable padding-xs">
            <thead>
              <tr>
                <th id="th_current" class="align-center">Current</th>
                <th id="th_previous" class="align-center">Previous</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tableRowHeight align-center">
                <td>N/A</td>
                <td>N/A</td>
              </tr>
              <tr class="tableRowHeight">
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_nstm_Current"
                    v-model="structure.InspEvnt.FCINSPREQ"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_nstm_Previous"
                    :model-value="prevAcceptedInsp?.fcInspectionReq"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
              </tr>
              <tr class="tableRowHeight">
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_uw_Current"
                    v-model="structure.InspEvnt.UWINSPREQ"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_uw_Previous"
                    :model-value="prevAcceptedInsp?.uwInspectionReq"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
              </tr>
              <tr class="tableRowHeight">
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_os_Current"
                    v-model="structure.InspEvnt.OSINSPREQ"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_os_Previous"
                    :model-value="prevAcceptedInsp?.osInspectionReq"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
              </tr>
              <tr class="tableRowHeight">
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_element_Current"
                    v-model="structure.InspEvnt.ELINSPREQ"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
                <td>
                  <v-checkbox
                    class="d-flex justify-center"
                    id="checkbox_element_Previous"
                    :model-value="prevAcceptedInsp?.elInspectionReq"
                    true-value="1"
                    false-value="0"
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
        <v-col xl="3" lg="3" md="3" sm="3" xs="3" align="center">
          <v-label class="bold">7A09 Interval</v-label>
          <v-table class="hide_scroll compactTable padding-xs">
            <thead>
              <tr>
                <th id="th_intervalCurrent" class="align-center">Current</th>
                <th id="th_intervalPrevious" class="align-center">Previous</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tableRowHeight align-center">
                <td>
                  {{ structure.InspEvnt.BRINSPFREQ }}
                  <span>mos</span>
                </td>
                <td>
                  {{
                    prevAcceptedInsp?.brInspectionFreq
                      ? prevAcceptedInsp?.brInspectionFreq + " mos"
                      : "--"
                  }}
                </td>
              </tr>
              <tr class="tableRowHeight align-center">
                <td>
                  {{ structure.InspEvnt.FCINSPFREQ }}
                  <span>mos</span>
                </td>
                <td>
                  {{
                    prevAcceptedInsp?.fcInspectionFreq
                      ? prevAcceptedInsp?.fcInspectionFreq + " mos"
                      : "--"
                  }}
                </td>
              </tr>
              <tr class="tableRowHeight align-center">
                <td>
                  {{ structure.InspEvnt.UWINSPFREQ }}
                  <span>mos</span>
                </td>
                <td>
                  {{
                    prevAcceptedInsp?.uwInspectionFreq
                      ? prevAcceptedInsp?.uwInspectionFreq + " mos"
                      : "--"
                  }}
                </td>
              </tr>
              <tr class="tableRowHeight align-center">
                <td>
                  {{ structure.InspEvnt.OSINSPFREQ }}
                  <span>mos</span>
                </td>
                <td>
                  {{
                    prevAcceptedInsp?.osInspectionFreq
                      ? prevAcceptedInsp?.osInspectionFreq + " mos"
                      : "--"
                  }}
                </td>
              </tr>
              <tr class="tableRowHeight align-center">
                <td>
                  {{ structure.InspEvnt.ELINSPFREQ }}
                  <span>mos</span>
                </td>
                <td>
                  {{
                    prevAcceptedInsp?.elInspectionFreq
                      ? prevAcceptedInsp?.elInspectionFreq + " mos"
                      : "--"
                  }}
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script setup>
import useDateField from "@/composables/dateField.js";
import {
  getIN15Value,
  getIN15ValueFromMaterials,
} from "@/composables/keyFields.js";
import { useInspectionStore } from "@/stores/inspection";
import { computed } from "vue";

defineProps({
  showPerformedData: { default: false, type: Boolean },
});

const { getFormattedDateStringNoTime } = useDateField();
const inspectionStore = useInspectionStore();
const structure = computed(() => inspectionStore.selectedInspection);
const prevAcceptedInsp = computed(() => structure.value.keyFields);
const overallScourHoles = computed({
  get() {
    return inspectionStore.getOverallScourHoles(structure.value);
  },
});
const prevOverallScourHoles = computed({
  get() {
    return inspectionStore.getPrevOverallScourHoles(prevAcceptedInsp.value);
  },
});
</script>
<style scoped lang="scss">
@use "@/styles/general" as g;

.boldFont {
  font-weight: bold !important;
}

.tableRowHeight {
  height: 40px;
}

.rightAlign {
  text-align: right;
  font-weight: bold !important;
}
</style>
