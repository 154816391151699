<template>
  <v-row>
    <ExpandCollapseToggle
      id="superStructPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panel"
    />
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel class="mt-5">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
          <h3>Superstructure Conditions</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-form v-model="valid" ref="modifySuperstructureValidation">
            <LabelSelect
              id="condRatings"
              showDurationIcon
              :tooltip="
                getHistDuration(
                  structure?.T_Insp_Condition_Hist?.SUP_DURATION,
                  structure?.T_Insp_Condition_Hist?.SUP_DURATION_CHANGES
                )
              "
              @update:modelValue="
                {
                  superstructureConditionUpdated = true;
                  conditionUpdated = true;
                }
              "
              label="1A04 Superstructure Condition Rating"
              :refTable="REFERENCE_TABLE.CONDITION_RATING"
              :isEditable="isEditable"
              :isRequired="isEditable"
              v-model="structure.InspEvnt.SUPRATING"
            ></LabelSelect>

            <CommentTypeTextField
              label="Overall Superstructure Notes"
              id="textarea_superstructure"
              :inspectionCommentType="INSPECTION_COMMENTS.SUPERSTRUCTURE"
              :readonly="!isEditable"
              :counter="notesMaxLength"
              :maxlength="notesMaxLength"
            />
            <LabelSelect
              id="combustible_Material_Under_Bridge"
              label="6B48 Combustible Material Under Bridge"
              :refTable="REFERENCE_TABLE.MATERIAL_STORED_UNDER"
              :isEditable="isEditable"
              :isRequired="isEditable"
              v-model="structure.UserInsp.MAT_STORE_UND"
            ></LabelSelect>

            <CommentTypeTextField
              label="Combustible Material Under Bridge Notes"
              id="field_Combustible_Material_Notes"
              :inspectionCommentType="
                INSPECTION_COMMENTS.COMBUSTIBLE_MATERIALS_COMMENTS
              "
              :readonly="!isEditable"
              :counter="notesMaxLength"
              :maxlength="notesMaxLength"
            />

            <LabelCheckbox
              id="newCoating"
              class="labelCheckbox"
              label="6B35 New Coating Since Last Inspection"
              :isEditable="isEditable"
              indent
              editIndent
              v-model="structure.UserInsp.NEW_PAINT"
              labelXl="3"
              labelLg="4"
              labelMd="5"
              labelSm="6"
            >
            </LabelCheckbox>
            <LabelSelect
              id="newCoating"
              showDurationIcon
              :tooltip="
                getHistDuration(
                  structure?.T_Insp_Condition_Hist?.PANIT_DURATION,
                  structure?.T_Insp_Condition_Hist?.PANIT_DURATION_CHANGES
                )
              "
              @update:modelValue="
                {
                  protectiveCoatingUpdated = true;
                  conditionUpdated = true;
                }
              "
              label="6B36 Protective Coating Rating"
              :refTable="REFERENCE_TABLE.PAINT_CONDITION_RATING"
              :isEditable="isEditable"
              :isRequired="isEditable"
              v-model="structure.UserInsp.PAINT_COND_RATE"
            ></LabelSelect>
            <LabelSelect
              id="protectiveExtentCoatings"
              showDurationIcon
              :tooltip="
                getHistDuration(
                  structure?.T_Insp_Condition_Hist?.PAINT_EXT_DURATION,
                  structure?.T_Insp_Condition_Hist?.PAINT_EXT_DURATION_CHANGES
                )
              "
              @update:modelValue="
                {
                  protectiveCoatingExtentUpdated = true;
                  conditionUpdated = true;
                }
              "
              label="6B37 Protective Coating (Extent) Rating"
              :refTable="REFERENCE_TABLE.PAINT_CONDITION_RATING_EXTENT"
              :isEditable="isEditable"
              :isRequired="isEditable"
              v-model="structure.UserInsp.PAINT_EXTENT_RATE"
            ></LabelSelect>
            <CommentTypeTextField
              label="Overall Protective Coating Notes"
              id="textarea_overall"
              :inspectionCommentType="INSPECTION_COMMENTS.PAINT_OVERALL"
              :readonly="!isEditable"
              :counter="notesMaxLength"
              :maxlength="notesMaxLength"
            />
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(1)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(1)" icon="fa-solid fa-minus" />
          <h3>Superstructure Details</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row>
            <v-col sm="6" md="6" lg="6">
              <LabelText
                id="txt_Main_Spans"
                label="5B11 Main Spans"
                noGrid
                v-model="structure.Bridge.MAINSPANS"
              >
              </LabelText>
            </v-col>
            <v-col sm="6" md="6" lg="6">
              <LabelText
                id="txt_Approach_Spans"
                label="5B14 Approach Spans"
                noGrid
                v-model="structure.Bridge.APPSPANS"
              >
              </LabelText>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs="12" sm="12" md="6" lg="6">
              <LabelText
                labelXl="6"
                labelLg="9"
                labelMd="10"
                labelSm="8"
                textXl="6"
                textLg="3"
                textMd="2"
                textSm="4"
                textStyle="width:70%"
                id="txt_Bridge_Seat_Cleaning"
                label="VD31 Bridge Seat Cleaning"
                :isEditable="isEditable"
                v-model="structure.UserBrdg.SEAT_CLEANING"
                maxlength="2"
                type="number"
              >
              </LabelText>
            </v-col>
          </v-row>
          <LabelText
            labelXl="12"
            labelLg="12"
            labelMd="12"
            labelSm="12"
            textXl="12"
            textLg="12"
            textMd="12"
            textSm="12"
            id="txt_Bridge_Seat_Cleaning_Notes"
            label="VD32 Bridge Seat Cleaning Notes"
            :isEditable="isEditable"
            v-model="structure.UserBrdg.SEAT_CLEANING_NOTE"
            maxlength="2000"
            type="textarea"
            counter
            persistentCounter
          >
          </LabelText>

          <v-row>
            <v-col xs="12" sm="12" md="6" lg="6">
              <LabelText
                labelXl="6"
                labelLg="9"
                labelMd="10"
                labelSm="8"
                textXl="6"
                textLg="3"
                textMd="2"
                textSm="4"
                textStyle="width:70%"
                id="txt_Scuppers_w/Downspouts"
                label="VD33 Scuppers w/Downspouts"
                :isEditable="isEditable"
                v-model="structure.UserBrdg.SCUPPERS_WITH_DOWNSPOUTS"
                maxlength="2"
                type="number"
              >
              </LabelText>
            </v-col>
            <v-col xs="12" sm="12" md="6" lg="6">
              <LabelText
                labelXl="6"
                labelLg="9"
                labelMd="10"
                labelSm="8"
                textXl="6"
                textLg="3"
                textMd="2"
                textSm="4"
                textStyle="width:70%"
                id="txt_Scuppers_w/out_Downspouts"
                label="VD34 Scuppers w/out Downspouts"
                :isEditable="isEditable"
                v-model="structure.UserBrdg.SCUPPERS_WITHOUT_DOWNSPOUTS"
                maxlength="2"
                type="number"
              >
              </LabelText> </v-col
          ></v-row> </v-expansion-panel-text
      ></v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(2)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(2)" icon="fa-solid fa-minus" />
          <h3>Superstructure Inspection Notes</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <CommentTypeTextField
            label="Girders/Beams"
            id="textarea_superGirders"
            :inspectionCommentType="INSPECTION_COMMENTS.SUPER_GIRDERS"
            :readonly="!isEditable"
            :counter="notesMaxLength"
            :maxlength="notesMaxLength" />
          <CommentTypeTextField
            label="Floorbeams"
            id="textarea_superFloorBeams"
            :inspectionCommentType="INSPECTION_COMMENTS.SUPER_FLOORBEAMS"
            :readonly="!isEditable"
            :counter="notesMaxLength"
            :maxlength="notesMaxLength" />
          <CommentTypeTextField
            label="Stringers"
            id="textarea_superStringers"
            :inspectionCommentType="INSPECTION_COMMENTS.SUPER_STRINGERS"
            :readonly="!isEditable"
            :counter="notesMaxLength"
            :maxlength="notesMaxLength" />
          <CommentTypeTextField
            label="Diaphragms"
            id="textarea_superDiaphragms"
            :inspectionCommentType="INSPECTION_COMMENTS.SUPER_DIAPHRAGMS"
            :readonly="!isEditable"
            :counter="notesMaxLength"
            :maxlength="notesMaxLength" />
          <CommentTypeTextField
            label="Truss Members"
            id="textarea_superTruss"
            :inspectionCommentType="INSPECTION_COMMENTS.SUPER_TRUSS_MEMBERS"
            :readonly="!isEditable"
            :counter="notesMaxLength"
            :maxlength="notesMaxLength" />
          <CommentTypeTextField
            label="Portals/Bracings"
            id="textarea_superPortals"
            :inspectionCommentType="INSPECTION_COMMENTS.SUPER_PORTALS"
            :readonly="!isEditable"
            :counter="notesMaxLength"
            :maxlength="notesMaxLength" />
          <CommentTypeTextField
            label="Drainage System"
            id="textarea_superDrainage"
            :inspectionCommentType="INSPECTION_COMMENTS.SUPER_DRAINAGE"
            :readonly="!isEditable"
            :counter="notesMaxLength"
            :maxlength="notesMaxLength" /></v-expansion-panel-text
      ></v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(3)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(3)" icon="fa-solid fa-minus" />
          <h3>Protective Coating Inspection Notes</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <CommentTypeTextField
            label="Interior Girder/Beam"
            id="textarea_girder"
            :inspectionCommentType="INSPECTION_COMMENTS.INTERIOR_BEAM_GIRDER"
            :readonly="!isEditable"
            :counter="notesMaxLength"
            :maxlength="notesMaxLength"
          />
          <CommentTypeTextField
            label="Fascia"
            id="textarea_paintFascias"
            :inspectionCommentType="INSPECTION_COMMENTS.PAINT_FASCIAS"
            :readonly="!isEditable"
            :counter="notesMaxLength"
            :maxlength="notesMaxLength"
          />
          <CommentTypeTextField
            label="Splash Zone - Truss/Girder"
            id="textarea_paintSplash"
            :inspectionCommentType="INSPECTION_COMMENTS.PAINT_SPLASH_ZONE"
            :readonly="!isEditable"
            :counter="notesMaxLength"
            :maxlength="notesMaxLength"
          />
          <CommentTypeTextField
            label="Truss"
            id="textarea_paintTruss"
            :inspectionCommentType="INSPECTION_COMMENTS.PAINT_TRUSS"
            :readonly="!isEditable"
            :counter="notesMaxLength"
            :maxlength="notesMaxLength"
          />
          <CommentTypeTextField
            label="Bearing"
            id="textarea_paintBearings"
            :inspectionCommentType="INSPECTION_COMMENTS.PAINT_BEARINGS"
            :readonly="!isEditable"
            :counter="notesMaxLength"
            :maxlength="notesMaxLength"
          />
          <CommentTypeTextField
            label="Other"
            id="textarea_paintOther"
            :inspectionCommentType="INSPECTION_COMMENTS.PAINT_OTHER"
            :readonly="!isEditable"
            :counter="notesMaxLength"
            :maxlength="notesMaxLength"
          />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>
<script>
import { ref, computed, watch, onMounted } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import { useConfigStore } from "@/stores/config";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { getHistDuration } from "@/composables/util";
import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelText from "@/components/common/LabelText.vue";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";

export default {
  name: "InspectionSuperStructure",

  setup() {
    const allPanels = [0, 1, 2, 3];
    let panel = ref(allPanels);
    onMounted(async () => {
      await validate();
    });

    let valid = ref(true);
    let modifySuperstructureValidation = ref(null);
    async function validate() {
      await modifySuperstructureValidation.value.validate();
    }
    const configStore = useConfigStore();
    const inspectionStore = useInspectionStore();
    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });
    let structure = computed(() => inspectionStore.selectedInspection);

    const notesMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });
    let superstructureConditionUpdated = ref(false);
    let protectiveCoatingUpdated = ref(false);
    let protectiveCoatingExtentUpdated = ref(false);
    let conditionUpdated = ref(false);
    const updateDuration = () => {
      if (!structure.value.T_Insp_Condition_Hist) {
        structure.value.T_Insp_Condition_Hist = {};
      }
      if (superstructureConditionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.SUP_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.SUP_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.SUP_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.SUP_DURATION,
            structure.value.T_Insp_Condition_Hist.SUP_DURATION_CHANGES
          );
      }
      if (protectiveCoatingUpdated.value) {
        structure.value.T_Insp_Condition_Hist.PANIT_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.PANIT_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.PANIT_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.PANIT_DURATION,
            structure.value.T_Insp_Condition_Hist.PANIT_DURATION_CHANGES
          );
      }

      if (protectiveCoatingExtentUpdated.value) {
        structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION,
            structure.value.T_Insp_Condition_Hist.PAINT_EXT_DURATION_CHANGES
          );
      }

      superstructureConditionUpdated.value = false;
      protectiveCoatingUpdated.value = false;
      protectiveCoatingExtentUpdated.value = false;
      conditionUpdated.value = false;
    };
    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };
    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [
        structure.value.InspEvnt,
        structure.value.UserInsp,
        structure.value.T_Insp_Comment,
        structure.value.Bridge,
        structure.value.UserBrdg,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      conditionUpdated,
      protectiveCoatingExtentUpdated,
      protectiveCoatingUpdated,
      updateDuration,
      superstructureConditionUpdated,
      panel,
      isEditable,
      structure,
      notesMaxLength,
      getHistDuration,
      INSPECTION_COMMENTS,
      REFERENCE_TABLE,
      valid,
      modifySuperstructureValidation,
      toggleAllPanels,
    };
  },
  components: {
    CommentTypeTextField,
    LabelSelect,
    LabelText,
    LabelCheckbox,
    ExpandCollapseToggle,
  },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;

h3 {
  color: c.$p-white !important;
}
</style>
