<template>
  <v-row class="text-left">
    <v-col sm="12">
      <div>
        <v-btn
          v-if="!brKeys?.length > 0"
          variant="outlined"
          @click="handleFileImport"
          id="btn_uploadFile"
          aria-describedby="
                  btn_uploadFile
                "
          aria-label="
                  btn_uploadFile
                "
        >
          Upload File
        </v-btn>
        <v-btn
          v-else
          variant="outlined"
          @click="readDataFromAPI(brKeys)"
          id="btn_retrieveList"
          aria-describedby="
                  btn_retrieveList
                "
          aria-label="
                  btn_retrieveList
                "
        >
          Retrieve List
        </v-btn>

        <v-icon
          id="icon_resetForm"
          aria-describedby="
                  icon_resetForm
                "
          aria-label="
                  icon_resetForm
                "
          class="ml-3"
          v-if="brKeys?.length > 0"
          icon="fas fa-xmark"
          @click="resetForm"
        />

        <v-tooltip bottom>
          <template v-slot:activator="{ props }">
            <v-icon
              id="icon_uploadInfo"
              aria-describedby="
                  icon_uploadInfo
                "
              aria-label="
                  icon_uploadInfo
                "
              class="ml-2 infoIcon"
              icon="fas fa-circle-info"
              v-bind="props"
            />
          </template>
          <span
            >All BRKEYs must be entered in the first column of the first tab of
            the spreadsheet. txt files allow comma or space separated or one
            number per row without any special characters.</span
          >
        </v-tooltip>
        <input
          type="file"
          ref="doc"
          @change="readFile"
          style="display: none"
          accept=".xls,.xlsx,.csv,.txt"
          id="file_brKeyFile"
          aria-describedby="
                  file_brKeyFile
                "
          aria-label="
                  file_brKeyFile
                "
        />
      </div>
    </v-col>
  </v-row>
  <v-row>
    <v-col sm="12">
      <div style="font-size: 13px">
        <span>Acceptable file types are .csv, .txt, and .xlsx.</span>
      </div>
    </v-col>
  </v-row>

  <v-row>
    <v-col sm="12" md="6" lg="2">
      <v-table fixed-header height="300px" id="table_uplodedBRKeys">
        <thead>
          <tr>
            <th id="th_brkeyList" class="text-left">BRKEY List</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="brkey in brKeys" :key="brkey">
            <td>{{ brkey }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-col>
  </v-row>

  <v-dialog v-model="fileUploadMessage.show" :width="800">
    <v-card class="pa-3" :class="fileUploadMessage.type">
      <p class="h3 mb-6"><strong>&nbsp;</strong></p>
      <p class="mb-2">{{ fileUploadMessage.value }}</p>
      <button
        @click="hideFileUploadMsg"
        class="d-flex align-end flex-column align-content-center close-button"
      >
        <v-icon size="x-large" icon="fas fa-xmark mx-2" />
        <small>CLOSE</small>
      </button>
    </v-card>
  </v-dialog>
  <v-dialog
    v-model="invalidBrkeyMessage.show"
    :width="invalidBrkeyMessage.type === 'warning-dialog' ? 800 : 400"
  >
    <v-card class="pa-3" :class="invalidBrkeyMessage.type">
      <p class="h3 mb-6"><strong>&nbsp;</strong></p>
      <p class="mb-2">{{ invalidBrkeyMessage.value }}</p>

      <v-table
        v-if="invalidBrkeyMessage.type === 'warning-dialog'"
        class="message-table-responsive"
      >
        <thead>
          <tr>
            <th id="invalidBrkeys" class="text-left">
              {{ invalidBrkeyMessage.header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in invalidBrkeyMessage?.invalidBrkeys" :key="item">
            <td>{{ item }}</td>
          </tr>
        </tbody>
      </v-table>

      <button
        @click="invalidBrkeyMessage.show = false"
        class="d-flex align-end flex-column align-content-center close-button"
      >
        <v-icon size="x-large" icon="fas fa-xmark mx-2" />
        <small>CLOSE</small>
      </button>
    </v-card>
  </v-dialog>
</template>

<script>
import { LOGGER } from "@/util/logger";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { STRUCTURE_SEARCH_MESSAGES } from "@/constants/StructureSearches";
import { useStructureSearchStore } from "@/stores/structureSearch";
import { scrollToElement } from "@/util/scrollToElement";
import { ref } from "vue";

export default {
  name: "UploadBRKeyList",
  emits: ["showMessage"],
  data: () => ({
    file: null,
    content: null,
    brKeys: null,
    invalidBrkeyMessage: {},
  }),
  setup(_, ctx) {
    const structureSearchStore = useStructureSearchStore();
    let fileUploadMessage = ref({ value: null, show: false, type: null });

    async function readDataFromAPI(payload) {
      structureSearchStore.resetSelectedStructures();
      await structureSearchStore.getStructuresByBrKey(payload);
      showMessage(payload);
    }

    async function showMessage(brKeys) {
      let missingResults;
      let cleanBrKeys = [];
      let data = structureSearchStore.getStructureSearchResults;
      if (data) {
        data = JSON.parse(JSON.stringify(data)).map(function (elem) {
          return elem.brkey;
        });
        for (let element of brKeys) {
          cleanBrKeys.push(String(element));
        }
        missingResults = structureSearchStore.getMissingResults(
          data,
          cleanBrKeys
        );
      }
      ctx.emit(
        "showMessage",
        setMessageValue(cleanBrKeys, missingResults, data)
      );
    }

    return {
      structureSearchStore,
      readDataFromAPI,
      showMessage,
      fileUploadMessage,
    };
  },
  methods: {
    handleFileImport() {
      this.$refs.doc.click();
    },

    readFile(event) {
      if (event.target.files.length) {
        this.brKeys = null;
        this.file = event.target.files[0];

        const reader = new FileReader();
        if (this.file.name.includes(".txt")) {
          reader.onload = (res) => {
            this.content = res.target.result;
            this.brKeys = this.content
              .trim()
              .split(/[ ,\r?\n]+/)
              .map((item) => item.trim());
          };
          reader.onerror = (err) => LOGGER.logException(err);
          reader.readAsText(this.file);
        } else if (this.file.name.includes(".csv")) {
          Papa.parse(this.file, {
            header: false,
            skipEmptyLines: true,
            complete: this.onCsvFileParseComplete,
          });
        } else if (
          this.file.name.includes(".xlsx") ||
          this.file.name.includes(".xls")
        ) {
          reader.onload = (res) => {
            let invalidBrkeys = [];
            this.content = res.target.result;
            let workbook = XLSX.read(this.content);
            let results = XLSX.utils
              .sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
                header: 1,
              })
              .map((a) => a[0]);
            this.brKeys = [];
            results.forEach((a) => {
              const trimmedValue = a?.toString().trim() || "";
              if (trimmedValue.includes(",") || trimmedValue.includes(" ")) {
                invalidBrkeys.push(trimmedValue);
              } else if (trimmedValue.length != 0) {
                this.brKeys.push(trimmedValue);
              }
            });
            this.showExcelUploadErrorMsg(invalidBrkeys);
          };
          reader.onerror = (err) => LOGGER.logException(err);
          reader.readAsArrayBuffer(this.file);
        } else {
          this.resetForm();
          this.showFileUploadMsg();
        }
      }
    },
    onCsvFileParseComplete(results) {
      let invalidBrkeys = [];
      this.brKeys = [];
      results.data.forEach((a) => {
        const trimmedValue = a[0]?.toString().trim() || "";
        if (trimmedValue.includes(",") || trimmedValue.includes(" ")) {
          invalidBrkeys.push(trimmedValue);
        } else if (trimmedValue.length != 0) {
          this.brKeys.push(trimmedValue);
        }
      });
      this.showExcelUploadErrorMsg(invalidBrkeys);
    },
    resetForm() {
      this.file = null;
      this.content = null;
      this.brKeys = null;
      this.$refs.doc.value = null;
    },
    showFileUploadMsg() {
      this.fileUploadMessage.type = "error-dialog";
      this.fileUploadMessage.value =
        STRUCTURE_SEARCH_MESSAGES.INVALID_FILE_TYPE_ERROR;
      this.fileUploadMessage.show = true;
    },
    showExcelUploadErrorMsg(miss) {
      this.invalidBrkeyMessage = setInvalidBrkeysMessage(miss);
    },
    hideFileUploadMsg() {
      this.fileUploadMessage.show = false;
    },
  },
};
function setMessageValue(brKeys, missingResults, data) {
  let message = {
    header: "BRKEY Missing Results",
    value: null,
    type: null,
    keys: null,
    missingResults: missingResults,
    show: true,
  };

  if (data?.length === 0) {
    message.value = STRUCTURE_SEARCH_MESSAGES.SEARCH_NO_RESULTS;
    message.type = "error-dialog";
  } else if (missingResults?.length === 0) {
    message.show = false;
    scrollToElement("search_results_header");
  } else if (missingResults?.length !== 0) {
    message.value = STRUCTURE_SEARCH_MESSAGES.BRKEY_SEARCH_SOME_RESULTS;
    message.type = "warning-dialog";
    message.keys = brKeys;
    scrollToElement("search_results_header");
  }
  return message;
}

function setInvalidBrkeysMessage(invalidBrkeys) {
  let message = {
    header: "Invalid BRKEYs",
    value: null,
    type: null,
    keys: null,
    invalidBrkeys: invalidBrkeys,
    show: false,
  };

  if (invalidBrkeys?.length !== 0) {
    message.value = STRUCTURE_SEARCH_MESSAGES.INVALID_BRKEY_UPLOAD;
    message.type = "warning-dialog";
    message.keys = invalidBrkeys;
    message.show = true;
  }
  return message;
}
</script>
