<template>
  <v-row>
    <ExpandCollapseToggle
      id="nstmFatiguePanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panels"
    />
    <v-expansion-panels v-model="panels" multiple color="#fff">
      <v-expansion-panel value="nstmConditions" class="mt-5">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon
            v-if="panels.includes('nstmConditions')"
            icon="fa-solid fa-minus"
          />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h3>NSTM Conditions</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-form
            v-model="validNSTMInspectionCondition"
            ref="nstmConditionForm"
          >
            <LabelSelect
              labelXl="3"
              labelLg="4"
              labelMd="5"
              labelSm="6"
              selectXl="3"
              selectLg="4"
              selectMd="4"
              selectSm="5"
              id="nstmInspectionCondition"
              label="1A15 NSTM Inspection Condition"
              :refTable="REFERENCE_TABLE.CONDITION_RATING"
              :isEditable="isEditable"
              isRequired
              v-model="nstmInspectionCondition"
              @update:modelValue="
                inspectionStore.setNstmInspectionConditionChangedByUser(true)
              "
            ></LabelSelect></v-form
        ></v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel value="generalInformation">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon
            v-if="panels.includes('generalInformation')"
            icon="fa-solid fa-minus"
          />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h3>General Information</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-row>
            <v-col class="px-0 pt-3">
              <v-row class="pb-2">
                <v-col cols="6" class="px-4">
                  <h3 class="withBackground mb-2">Plug Weld Indicator</h3>
                  <v-row>
                    <v-col class="ml-3">
                      <LabelSelect
                        labelXl="6"
                        labelLg="7"
                        labelMd="7"
                        labelSm="7"
                        selectXl="6"
                        selectLg="5"
                        selectMd="5"
                        selectSm="5"
                        id="superStructure"
                        label="6A50 Superstructure"
                        :refTable="REFERENCE_TABLE.PLUG_WELD_PROBLEM_TYPE"
                        v-model="superStructure"
                      ></LabelSelect>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="ml-3">
                      <LabelSelect
                        labelXl="6"
                        labelLg="7"
                        labelMd="7"
                        labelSm="7"
                        selectXl="6"
                        selectLg="5"
                        selectMd="5"
                        selectSm="5"
                        id="substructure"
                        label="6A51 Substructure"
                        :refTable="REFERENCE_TABLE.PLUG_WELD_PROBLEM_TYPE"
                        v-model="subStructure"
                      ></LabelSelect>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="px-4">
                  <h3 class="withBackground mb-2">Estimated Truck Traffic</h3>
                  <v-row>
                    <v-col class="ml-3">
                      <LabelText
                        labelXl="9"
                        labelLg="10"
                        labelMd="10"
                        labelSm="10"
                        textXl="3"
                        textLg="2"
                        textMd="2"
                        textSm="2"
                        id="cumlativeTruckTraffic"
                        label="6A52 Cumulative Truck Traffic"
                        v-model="estimatedTruckTraffic"
                      ></LabelText>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="ml-3">
                      <LabelText
                        labelXl="9"
                        labelLg="10"
                        labelMd="10"
                        labelSm="10"
                        textXl="3"
                        textLg="2"
                        textMd="2"
                        textSm="2"
                        id="cumlativeTruckTrafficForFatigueDamage"
                        label="6A53 Cumulative Truck Traffic for Fatigue Damage"
                        v-model="cumulativeTruckTrafficforFatigueDamage"
                      ></LabelText>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="ml-3">
                      <LabelText
                        labelXl="9"
                        labelLg="10"
                        labelMd="10"
                        labelSm="10"
                        textXl="3"
                        textLg="2"
                        textMd="2"
                        textSm="2"
                        id="yearOfEstimate"
                        label="6A54 Year of Estimate"
                        v-model="estimatedTruckYear"
                      ></LabelText>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel value="nstmDetails">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon
            v-if="panels.includes('nstmDetails')"
            icon="fa-solid fa-minus"
          />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h3>NSTM Details</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-row>
            <v-col class="px-0">
              <v-table class="w-100 compactTable padding-xs">
                <thead>
                  <tr>
                    <th id="th_Span_Set">
                      <v-row>
                        <v-col cols="12" class="pa-1">
                          <v-row>
                            <v-col cols="12" class="minHeight pa-0 ma-0"
                              ><span></span>
                            </v-col>
                            <v-col cols="12" class="minHeight pa-0 ma-0">
                              <span>Span Set</span>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </th>
                    <th id="th_Group" style="width: 10%">
                      <v-row>
                        <v-col cols="12" class="pa-0 ma-0">
                          <v-row>
                            <v-col cols="12" class="minHeight pa-0 ma-0"
                              ><span>6A44</span>
                            </v-col>
                            <v-col cols="12" class="minHeight pa-0 ma-0">
                              <span>Group</span>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </th>
                    <th id="th_Critical_Ranking_Factor">
                      <v-row>
                        <v-col cols="12" class="pa-0 ma-0">
                          <v-row>
                            <v-col cols="12" class="minHeight pa-0 ma-0"
                              ><span>6A45-48</span>
                            </v-col>
                            <v-col cols="12" class="minHeight pa-0 ma-0">
                              <span>CRF</span>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </th>
                    <th id="th_Total_Critical_Ranking_Factor">
                      <v-row>
                        <v-col cols="12" class="pa-0 ma-0">
                          <v-row>
                            <v-col cols="12" class="minHeight pa-0 ma-0"
                              ><span>6A49</span>
                            </v-col>
                            <v-col cols="12" class="minHeight pa-0 ma-0">
                              <span>Total CRF</span>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </th>
                    <th id="th_Material_Makeup" style="width: 15%">
                      <v-row>
                        <v-col cols="12" class="pa-0 ma-0">
                          <v-row>
                            <v-col cols="12" class="minHeight pa-0 ma-0"
                              ><span>6A26</span>
                            </v-col>
                            <v-col cols="12" class="minHeight pa-0 ma-0">
                              <span>Material Makeup</span>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </th>
                    <th id="th_Physical_Makeup" style="width: 15%">
                      <v-row>
                        <v-col cols="12" class="pa-0 ma-0">
                          <v-row>
                            <v-col cols="12" class="minHeight pa-0 ma-0"
                              ><span>6A27</span>
                            </v-col>
                            <v-col cols="12" class="minHeight pa-0 ma-0">
                              <span>Physical Makeup</span>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </th>
                    <th id="th_Span_Interaction" style="width: 20%">
                      <v-row>
                        <v-col cols="12" class="pa-0 ma-0">
                          <v-row>
                            <v-col cols="12" class="minHeight pa-0 ma-0"
                              ><span>6A28</span>
                            </v-col>
                            <v-col cols="12" class="minHeight pa-0 ma-0">
                              <span>Span Interaction</span>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </th>
                    <th id="th_Structural_Config" style="width: 20%">
                      <v-row>
                        <v-col cols="12" class="pa-0 ma-0">
                          <v-row>
                            <v-col cols="12" class="minHeight pa-0 ma-0"
                              ><span>6A29</span>
                            </v-col>
                            <v-col cols="12" class="minHeight pa-0 ma-0">
                              <span>Structural Config</span>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Main</td>
                    <td>
                      {{
                        configStore.getReferenceValue(
                          REFERENCE_TABLE.FC_GROUP_NUMBER,
                          structure?.UserBrdg?.MAIN_FC_GROUP_NUM
                        )
                      }}
                    </td>
                    <td>
                      {{ fctotalCrfMainString }}
                    </td>
                    <td>
                      {{ fctotalCrfMain }}
                    </td>
                    <td style="width: 15%">
                      {{
                        configStore.getReferenceValue(
                          REFERENCE_TABLE.MATERIAL_MAKEUP,
                          structure?.UserBrdg?.DEPT_MAIN_MATERIAL_TYPE
                        )
                      }}
                    </td>
                    <td style="width: 15%">
                      {{
                        configStore.getReferenceValue(
                          REFERENCE_TABLE.PHYSICAL_MAKEUP,
                          structure?.UserBrdg?.DEPT_MAIN_PHYSICAL_TYPE
                        )
                      }}
                    </td>
                    <td style="width: 20%">
                      {{
                        configStore.getReferenceValue(
                          REFERENCE_TABLE.SPAN_INTERACTION,
                          structure?.UserBrdg?.DEPT_MAIN_SPAN_INTERACTION
                        )
                      }}
                    </td>
                    <td style="width: 20%">
                      {{
                        configStore.getReferenceValue(
                          REFERENCE_TABLE.STRUCTURAL_CONFIGURATION,
                          structure?.UserBrdg?.DEPT_MAIN_STRUC_CONFIG
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Approach</td>
                    <td>
                      {{
                        configStore.getReferenceValue(
                          REFERENCE_TABLE.FC_GROUP_NUMBER,
                          structure?.UserBrdg?.APPR_FC_GROUP_NUM
                        )
                      }}
                    </td>
                    <td>
                      {{ fctotalCrfApproachString }}
                    </td>
                    <td>
                      {{ fctotalCrfApproach }}
                    </td>
                    <td style="width: 15%">
                      {{
                        configStore.getReferenceValue(
                          REFERENCE_TABLE.MATERIAL_MAKEUP,
                          structure?.UserBrdg?.DEPT_APPR_MATERIAL_TYPE
                        )
                      }}
                    </td>
                    <td style="width: 15%">
                      {{
                        configStore.getReferenceValue(
                          REFERENCE_TABLE.PHYSICAL_MAKEUP,
                          structure?.UserBrdg?.DEPT_APPR_PHYSICAL_TYPE
                        )
                      }}
                    </td>
                    <td style="width: 20%">
                      {{
                        configStore.getReferenceValue(
                          REFERENCE_TABLE.SPAN_INTERACTION,
                          structure?.UserBrdg?.DEPT_APPR_SPAN_INTERACTION
                        )
                      }}
                    </td>
                    <td style="width: 20%">
                      {{
                        configStore.getReferenceValue(
                          REFERENCE_TABLE.STRUCTURAL_CONFIGURATION,
                          structure?.UserBrdg?.DEPT_APPR_STRUC_CONFIG
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>
          <NSTMFatigueTable ref="nstmTable" />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import { computed, watch, ref, onMounted } from "vue";
import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import NSTMFatigueTable from "@/components/shared/NSTMFatigueTable.vue";
import useNSTMFatigueData from "@/composables/NSTMFatigueData";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelText from "@/components/common/LabelText.vue";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";

export default {
  name: "InspectionNSTMFatigue",
  setup() {
    let nstmConditionForm = ref(null);
    let validNSTMInspectionCondition = ref(true);
    let valid = ref(true);
    async function validate() {
      await nstmConditionForm.value.validate();
      if (validNSTMInspectionCondition.value) {
        valid.value = true;
      } else {
        valid.value = false;
      }
    }
    onMounted(async () => {
      await validate();
      inspectionStore.setDirtyFlag(false);
    });
    const allPanels = ["nstmConditions", "generalInformation", "nstmDetails"];
    const panels = ref(allPanels);
    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });
    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panels.value = allPanels;
      } else {
        panels.value = [];
      }
    };
    const saveConditionFields = async () => {
      structure.value.UserInsp.NSTM_CONDITION = nstmInspectionCondition.value;
    };
    const configStore = useConfigStore();
    const inspectionStore = useInspectionStore();
    function getNSTMInspectionCondition() {
      if (
        structure.value?.UserBrdg?.MAIN_FC_GROUP_NUM < 5 ||
        structure.value?.UserBrdg?.APPR_FC_GROUP_NUM < 5
      ) {
        return structure.value?.InspEvnt?.SUPRATING;
      } else {
        return "N";
      }
    }
    const nstmInspectionCondition = computed({
      get() {
        if (
          !structure.value.UserInsp.NSTM_CONDITION &&
          !nstmInspectionConditionChangedByUser.value
        ) {
          return getNSTMInspectionCondition();
        } else {
          return structure.value?.UserInsp?.NSTM_CONDITION;
        }
      },
      set(inspectionConditionParam) {
        structure.value.UserInsp.NSTM_CONDITION = inspectionConditionParam;
      },
    });
    const superStructure = computed({
      get() {
        return structure.value?.UserBrdg?.SUP_PROBLEM_TYPE;
      },
    });
    const subStructure = computed({
      get() {
        return structure.value?.UserBrdg?.SUB_PROBLEM_TYPE;
      },
    });
    const estimatedTruckTraffic = computed({
      get() {
        return structure.value?.UserBrdg?.EST_TRUCK_TRAFFIC;
      },
    });
    const cumulativeTruckTrafficforFatigueDamage = computed({
      get() {
        return structure.value?.UserBrdg?.EST_CUM_FATIG_LIFE;
      },
    });
    const estimatedTruckYear = computed({
      get() {
        return structure.value?.UserBrdg?.EST_TRUCK_TRAFFIC_YEAR;
      },
    });
    const {
      fctotalCrfMainString,
      fctotalCrfMain,
      fctotalCrfApproachString,
      fctotalCrfApproach,
    } = useNSTMFatigueData();

    const structure = computed(() => inspectionStore.selectedInspection);

    const nstmInspectionConditionChangedByUser = computed(
      () => inspectionStore.getNstmInspectionConditionChangedByUser
    );

    let nstmTable = ref(null);

    const resetNSTMData = () => {
      nstmTable.value.getAllDetails();
    };

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [
        structure.value?.T_FC_Insp,
        structure.value?.UserBrdg,
        structure.value?.Structure_Unit,
        structure.value?.UserInsp,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      estimatedTruckYear,
      cumulativeTruckTrafficforFatigueDamage,
      estimatedTruckTraffic,
      subStructure,
      superStructure,
      validate,
      valid,
      validNSTMInspectionCondition,
      nstmConditionForm,
      saveConditionFields,
      isEditable,
      panels,
      toggleAllPanels,
      nstmInspectionCondition,
      inspectionStore,
      fctotalCrfApproachString,
      fctotalCrfMainString,
      fctotalCrfMain,
      fctotalCrfApproach,
      REFERENCE_TABLE,
      configStore,
      structure,
      nstmTable,
      resetNSTMData,
    };
  },
  components: {
    LabelText,
    ExpandCollapseToggle,
    LabelSelect,
    NSTMFatigueTable,
  },
};
</script>
<style scoped lang="scss">
@use "@/styles/general" as g;
@media #{"screen and (max-width: 1729px)"} {
  span {
    font-size: 12px !important;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media #{"screen and (max-width: 1523px)"} {
  span {
    font-size: 10px !important;
    white-space: nowrap;
    overflow: hidden;
  }
}
.minHeight {
  @media #{g.$p-breakPointXlAndUp} {
    min-height: 1.5em !important;
    max-height: 1.5em !important;
  }
  @media #{g.$p-breakPointLgAndDown} {
    min-height: 1.4em !important;
    max-height: 1.4em !important;
  }
  @media #{"screen and (max-width: 1729px)"} {
    min-height: 1.3em !important;
    max-height: 1.3em !important;
  }
  @media #{"screen and (max-width: 1523px)"} {
    min-height: 1.2em !important;
    max-height: 1.2em !important;
  }
}
.v-table {
  th {
    text-align: center !important;
  }
  tr {
    text-align: center !important;
  }
}
</style>
