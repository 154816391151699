<template>
  <v-card
    :style="$vuetify.display.lgAndUp ? '' : 'width: 40%'"
    v-if="!isOnline"
    class="card_align-center"
  >
    <v-card-title>
      <span class="text-h5">Offline Login</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="loginForm" v-model="valid">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="userName"
                :rules="[requiredRule]"
                @keyup.enter="enterKeyLoginHandler"
              >
                <template v-slot:label
                  ><span>Username</span
                  ><span class="important-text">*</span></template
                >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="password"
                v-model="password"
                :rules="[requiredRule]"
                @keyup.enter="enterKeyLoginHandler"
                ><template v-slot:label
                  ><span>Password</span
                  ><span class="important-text">*</span></template
                >
              </v-text-field>
              <div class="important-text" v-if="message">
                {{ message }}
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <div class="important-text">* indicates required field</div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        id="btn_login"
        variant="outlined"
        class="mx-5"
        text
        :disabled="!userName || !password"
        @click="handleLogin"
      >
        Login
      </v-btn>
    </v-card-actions>
  </v-card>
  <div class="align-center" style="width: 100%">
    <span class="instructionalText" v-if="isOnline"
      >Online connection established, attempting BMS3 Login.</span
    >
  </div>
</template>
<script setup>
import { ref, nextTick, computed, onMounted } from "vue";
import { requiredRule } from "@/composables/validationRules";
import { useUserStore } from "@/stores/userStore";
import router from "@/router";
import { useStructureIDBStore } from "@/stores/structureIDB";
import sha256 from "crypto-js/sha256";
import { useConnectivityStore } from "@/stores/connectivity";

let message = ref(null);
let userName = ref(null);
let password = ref(null);
let loginForm = ref(null);
let valid = ref(false);
const userStore = useUserStore();
const connectivity = useConnectivityStore();
let isOnline = computed(() => connectivity.getOnlineServiceStatus);

onMounted(() => {
  connectivity.getServiceStatus();
});
const handleLogin = () => {
  message.value = "";
  const hashedPassFromDB = localStorage.getItem("hashedPass");

  if (userName.value && password.value) {
    const hashedPassword = sha256(password.value).toString();
    loginForm.value?.validate().then(async () => {
      if (valid.value) {
        // use the entered password to decrypt the DB
        localStorage.setItem("hashedPass", hashedPassword);
        userStore.setUser({ sub: userName.value });
        const structureIDBStore = useStructureIDBStore();
        await structureIDBStore.initDB(true);
        //Check if user owned DB is present
        const userDBExists = await structureIDBStore.checkIfDBExists();
        //delete the empty DB created by pouchdb in the checkIfDBExists call
        if (!userDBExists) {
          await structureIDBStore.deleteDB(userName.value);
          reset();
          message.value = "Please enter a valid User ID and password";
          userStore.setUser(null);
          // set the previous password that was there before login
          localStorage.setItem("hashedPass", hashedPassFromDB);
          return;
        }

        //check if userid is in IDB
        const userInfo = await structureIDBStore.getUserInfo(userName.value);
        if (userInfo?.sub == userName.value) {
          userStore.setIsAuthenticated(true);
          userStore.setUser(userInfo);
          reset();
          backOrRedirectToLogin();
        } else {
          //login error
          reset();
          message.value = "Please enter a valid User ID and password";
          userStore.setUser(null);
          // set the previous password that was there before login
          localStorage.setItem("hashedPass", hashedPassFromDB);
        }
      }
    });
  }
};
const backOrRedirectToLogin = () => {
  //Route user to the page they were on before they logged out
  const prevRoute = router.options.history.state.back;
  if (
    !prevRoute ||
    ["/structure-search", "/", "/offlineLogin"].includes(prevRoute)
  ) {
    //these are not allowed when offline - take them to worklist
    router.push({ name: "ViewWorkList" });
  } else {
    router.back();
  }
};

const enterKeyLoginHandler = () => {
  if (userName.value && password.value) {
    nextTick(() => document.getElementById("btn_login").focus());
    document.getElementById("btn_login").click();
  }
};

const reset = () => {
  userName.value = null;
  password.value = null;
  message.value = null;
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;
.important-text {
  color: c.$p-alert-red;
}
.card_align-center {
  width: 25%;
  margin-left: auto;
  margin-right: auto;
}
.instructionalText {
  font-size: 1.5em;
  background-color: c.$p-alert-green;
  color: c.$p-white;
}
</style>
