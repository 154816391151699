<template>
  <v-row v-if="tableDate" align="center">
    <v-col cols="12">
      {{ computedValueTextField }}
    </v-col>
  </v-row>
  <div v-else-if="noGrid" style="display: flex">
    <span
      style="font-weight: bold"
      :class="addLabelPadding ? 'd-inline pt-1 pr-0' : 'd-inline  pr-1'"
      >{{ label }}:</span
    >
    <div v-if="isEditable">
      <div class="pl-3">
        <v-text-field
          :id="`dateField_${id}_Table`"
          hide-details="auto"
          placeholder="MM/DD/YYYY"
          class="pa-0 ma-0"
          :style="textStyle"
          :aria-describedby="`dateField_${id}_Table`"
          :aria-label="ariaLabel"
          v-model="computedValueTextField"
          @focus="isFocused = true"
          @blur="blur()"
          :rules="
            isRequired
              ? [
                  (value) => {
                    return requiredRule(value);
                  },
                  (value) => {
                    return validDateFormatRule(value);
                  },
                  (value) => {
                    return validDateRangeRule(value, maximumDate, minimumDate);
                  },
                ]
              : [
                  (value) => {
                    return validDateFormatRule(value);
                  },
                  (value) => {
                    return validDateRangeRule(value, maximumDate, minimumDate);
                  },
                ]
          "
        >
          <template v-slot:append-inner>
            <DatePicker
              :maxDate="maximumDate"
              :minDate="minimumDate"
              :required="isRequired"
              v-model="computedValue"
            />
          </template>
        </v-text-field>
      </div>
    </div>
    <span
      v-else
      :class="addDatePadding ? 'd-inline pl-4 pt-1' : 'd-inline pl-4'"
      >{{ computedValueTextField }}</span
    >
  </div>
  <v-row v-else>
    <v-col
      class="d-flex"
      :xl="labelXl"
      :lg="labelLg"
      :md="labelMd"
      :sm="labelSm"
      :cols="labelSm"
      v-if="label"
    >
      <v-label class="bold"
        ><span :class="isRequired ? 'required' : ''">{{ label }}</span
        >:</v-label
      >
    </v-col>
    <v-col :xl="textXl" :lg="textLg" :md="textMd" :sm="textSm" :cols="textSm">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-if="isEditable"
            :id="`dateField_${id}`"
            hide-details="auto"
            placeholder="MM/DD/YYYY"
            :aria-describedby="`dateField_${id}_Table`"
            :aria-label="ariaLabel"
            class="pa-0 ma-0"
            :style="textStyle"
            v-model="computedValueTextField"
            @focus="isFocused = true"
            @blur="blur()"
            :rules="
              isRequired
                ? [
                    (value) => {
                      return requiredRule(value);
                    },
                    (value) => {
                      return validDateFormatRule(value);
                    },
                    (value) => {
                      return validDateRangeRule(
                        value,
                        maximumDate,
                        minimumDate
                      );
                    },
                  ]
                : [
                    (value) => {
                      return validDateFormatRule(value);
                    },
                    (value) => {
                      return validDateRangeRule(
                        value,
                        maximumDate,
                        minimumDate
                      );
                    },
                  ]
            "
          >
            <template v-slot:append-inner>
              <DatePicker
                :maxDate="maximumDate"
                :minDate="minimumDate"
                v-model="computedValue"
                :required="isRequired"
              />
            </template>
          </v-text-field>
          <span v-else class="d-inline pl-4">{{ computedValueTextField }}</span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { computed, watch, ref } from "vue";
import {
  requiredRule,
  validDateFormatRule,
  validDateRangeRule,
} from "@/composables/validationRules";
import useDateField from "@/composables/dateField.js";
import DatePicker from "@/components/common/DatePicker.vue";

export default {
  name: "LabelDatefield",
  props: {
    labelXl: { default: 3 },
    labelLg: { default: 4 },
    labelMd: { default: 5 },
    labelSm: { default: 6 },
    textXl: { default: 4 },
    textLg: { default: 5 },
    textMd: { default: 5 },
    textSm: { default: 6 },
    id: {},
    rules: { default: [] },
    label: { default: "" },
    textStyle: { default: "" },
    isEditable: { type: Boolean, default: false },
    noGrid: { type: Boolean, default: false },
    modelValue: { default: "" },
    minDate: { default: new Date("01/01/1901") },
    maxDate: { default: new Date("12/31/2099") },
    isRequired: { type: Boolean, default: false },
    tableDate: { type: Boolean, default: false },
    addLabelPadding: { type: Boolean, default: false },
    addDatePadding: { type: Boolean, default: false },
  },
  setup(props, context) {
    const { getFormattedDateStringNoTime, checkValidDate } = useDateField();
    const minimumDate = computed({
      get: () => {
        return new Date(props.minDate.toLocaleDateString());
      },
    });
    const maximumDate = computed({
      get: () => {
        return new Date(props.maxDate.toLocaleDateString());
      },
    });
    const ariaLabel = computed({
      get() {
        if (props.label == "" || !props.label) {
          return props.id + " has no label";
        } else {
          return props.label + "_" + props.id;
        }
      },
    });
    let textValue = getFormattedDateStringNoTime(props.modelValue);
    let isFocused = ref(false);
    let updatedTextValue = false;
    let updateDatePicker = false;

    const blur = () => {
      isFocused.value = false;
      if (updatedTextValue && checkValidDate(textValue)) {
        context.emit("update:modelValue", new Date(textValue));
      } else if (updatedTextValue) {
        context.emit("update:modelValue", textValue);
      }
    };
    const computedValueTextField = computed({
      get: () => {
        if (isFocused.value) {
          return textValue;
        } else {
          return props.modelValue && checkValidDate(textValue)
            ? getFormattedDateStringNoTime(props.modelValue)
            : textValue;
        }
      },
      set: (value) => {
        updatedTextValue = true;
        textValue = value;
      },
    });
    const computedValue = computed({
      get: () =>
        props.modelValue && checkValidDate(textValue)
          ? new Date(props.modelValue)
          : null,

      set: (value) => {
        updateDatePicker = true;
        textValue = value ? getFormattedDateStringNoTime(value) : null;
        context.emit("update:modelValue", value);
      },
    });

    watch(
      () => [props.modelValue],
      () => {
        if (!updateDatePicker && !updatedTextValue) {
          textValue = props.modelValue
            ? getFormattedDateStringNoTime(props.modelValue)
            : null;
        } else {
          updateDatePicker = false;
          updatedTextValue = false;
        }
      },
      { deep: true }
    );
    return {
      ariaLabel,
      maximumDate,
      minimumDate,
      validDateRangeRule,
      requiredRule,
      validDateFormatRule,
      blur,
      isFocused,
      computedValueTextField,
      checkValidDate,
      getFormattedDateStringNoTime,
      close,
      computedValue,
    };
  },
  components: { DatePicker },
};
</script>

<style scoped lang="scss">
.v-row {
  align-items: center;
}
.requiredText {
  font-size: 12px;
  color: rgb(215, 26, 23);
}
</style>
