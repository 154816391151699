// Vuetify
import "@fortawesome/fontawesome-free/css/all.css";
import { createVuetify } from "vuetify";
import * as labs from "vuetify/labs/components";
// Styles
import { aliases, fa } from "vuetify/lib/iconsets/fa";
import "vuetify/styles";

const pdsLightTheme = {
  dark: false,
  colors: {
    primary: "#002469",
    secondary: "#6699C2",
    accent: "#82B1FF",
    error: "#D71A17",
    info: "#BDE5FF",
    success: "#029949",
    warning: "#E0AB06",
    white: "#FFFFFF",
    black: "#000000",
    alert_gold: "#E0AB06",
    alert_green: "#029949",
    brand_red: "#AD2624",
    brand_green: "007336",
    base_light_grey: "#EBEBEB",
    agency_light_grey: "#F4F4F4",
    disabled_steppers: "#949494",
  },
};

const pdsDarkTheme = {
  dark: true,
  colors: {
    primary: "#2962FF",
    secondary: "#B388FF",
    accent: "#82B1FF",
    error: "#FF5252",
    info: "#BDE5FF",
    success: "#4CAF50",
    warning: "#FFC107",
    white: "#FFFFFF",
    black: "#000000",
    alert_gold: "#E0AB06",
    alert_green: "#029949",
    brand_red: "#AD2624",
    brand_green: "007336",
    base_light_grey: "#EBEBEB",
    agency_light_grey: "#F4F4F4",
    disabled_steppers: "#949494",
  },
};

const vuetify = createVuetify({
  components: {
    ...labs,
  },
  icons: {
    defaultSet: "fa",
    aliases,
    sets: {
      fa,
    },
  },
  theme: {
    defaultTheme: "pdsLightTheme",
    themes: {
      pdsLightTheme,
      pdsDarkTheme,
    },
  },
  defaults: {
    global: {
      ripple: false,
    },
    VRow: { dense: true },
    VIcon: {
      size: "x-small",
    },
    VCheckbox: {
      density: "compact",
    },
    VTable: {
      density: "compact",
    },
    VTextField: {
      density: "compact",
    },
    VTextarea: {
      density: "compact",
    },
    VInput: {
      density: "compact",
    },
    VSelect: {
      density: "compact",
    },
    VAutocomplete: {
      density: "compact",
    },
    VDialog: {
      transition: "dialog-top-transition",
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default vuetify;
