import { defineStore } from "pinia";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import {
  ENV_CONFIG_PROPERTY,
  ENV_CONFIG_PROPERTIES,
  CONFIG_SORT_BY,
  getEnvironmentFromCode,
} from "@/constants/EnvConfigProperties";
import { LOGGER } from "@/util/logger";
import { SORT_ORDER, TYPES } from "@/constants/CommonWebConstants";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { METRIC_CONVERSION_TYPES } from "@/constants/InspectionConstants";
import ApiWorkerManager from "@/ApiWorkerManager";

export const useConfigStore = defineStore("config", {
  state: () => ({
    apiWorkerManager: new ApiWorkerManager(),
    references: new Map(),
    districts: new Map(),
    counties: [],
    municipalities: [],
    envConfigProperties: [],
    appBuildInfo: {},
    departmentStructureTypeDescriptions: [],
    metricConversions: [],
    elementDefinitions: [],
    elementChildren: {},
    elementStateDefinitions: {},
    users: [],
    scourCriticalBridgeIndicators: [],
    docTypes: [],
    flexActions: [],
    bridgeActions: [],
    codeFilters: [],
    organizations: [],
    webUsers: [],
    nbisBridgeInspectors: [],
    nbisNSTMBridgeInspectors: [],
    nbisTunnelInspectors: [],
    inspectors: [],
    underwaterDivers: [],
    allowedUsers: [],
  }),
  getters: {
    getAppBuildInfo() {
      return this.appBuildInfo;
    },
    isEnvironment: (state) => (env) => {
      return Boolean(state.appBuildInfo.environment?.code === env.code);
    },
    getMetricToEnglishConversions:
      (state) =>
      (pairCode, value, roundingFactor, addUomSuffix, conversionTypes) => {
        const conversionObject = state.getMetricConversionObject(pairCode);
        let returnValue = "";
        if (value == -1) {
          switch (roundingFactor) {
            case ROUNDING_FACTOR.TENTH: {
              returnValue = "-1.0";
              break;
            }
            case ROUNDING_FACTOR.HUNDRETH: {
              returnValue = "-1.00";
              break;
            }
            case ROUNDING_FACTOR.ROUND:
            case ROUNDING_FACTOR.NONE:
            default: {
              returnValue = "-1";
              break;
            }
          }
          returnValue = state.addUnitSuffix(
            returnValue,
            addUomSuffix,
            conversionObject?.englishUnit
          );
          return returnValue;
        } else if (value == 0) {
          switch (roundingFactor) {
            case ROUNDING_FACTOR.TENTH: {
              returnValue = "0.0";
              break;
            }
            case ROUNDING_FACTOR.HUNDRETH: {
              returnValue = "0.00";
              break;
            }
            case ROUNDING_FACTOR.ROUND:
            case ROUNDING_FACTOR.NONE:
            default: {
              returnValue = "0";
              break;
            }
          }
          returnValue = state.addUnitSuffix(
            returnValue,
            addUomSuffix,
            conversionObject?.englishUnit
          );
          return returnValue;
        } else if (value) {
          let { englishValue, isConverted } =
            state.getEnglishValueFromConversionTypes(value, conversionTypes);

          if (isConverted) {
            return englishValue;
          }
          //if no exceptional conversion types, proceed with calculations
          switch (roundingFactor) {
            case ROUNDING_FACTOR.ROUND: {
              //rounded number format with comma
              returnValue = Math.round(
                parseFloat(value) / conversionObject?.factor
              ).toLocaleString();
              break;
            }
            case ROUNDING_FACTOR.TENTH: {
              returnValue = (
                parseFloat(value) / conversionObject?.factor
              ).toFixed(1);
              break;
            }
            case ROUNDING_FACTOR.HUNDRETH: {
              returnValue = (
                parseFloat(value) / conversionObject?.factor
              ).toFixed(2);
              break;
            }
            case ROUNDING_FACTOR.NONE:
            default: {
              returnValue = parseFloat(value) / conversionObject?.factor;
              break;
            }
          }

          returnValue = state.addUnitSuffix(
            returnValue,
            addUomSuffix,
            conversionObject?.englishUnit
          );
          return returnValue;
        }
        return "";
      },
    addUnitSuffix: () => (value, addUomSuffix, unit) => {
      if (addUomSuffix) {
        return value + " " + unit;
      } else {
        return value;
      }
    },
    getEnglishValueFromConversionTypes: () => (value, conversionTypes) => {
      if (!conversionTypes || conversionTypes?.length <= 0) {
        return { isConverted: false };
      }
      let isConverted = false;
      if (
        conversionTypes.includes(METRIC_CONVERSION_TYPES.L1) &&
        parseFloat(value) == 99
      ) {
        isConverted = true;
        return { englishValue: 99, isConverted: isConverted };
      }
      if (
        conversionTypes.includes(METRIC_CONVERSION_TYPES.L2) &&
        parseFloat(value) == 199
      ) {
        isConverted = true;
        return { englishValue: 99, isConverted: isConverted };
      }
      if (
        (conversionTypes.includes(METRIC_CONVERSION_TYPES.L3) ||
          conversionTypes.includes(METRIC_CONVERSION_TYPES.L4)) &&
        parseFloat(value) >= 99.9
      ) {
        isConverted = true;
        return { englishValue: 99.9, isConverted: isConverted };
      }
      if (
        conversionTypes.includes(METRIC_CONVERSION_TYPES.L5) &&
        parseFloat(value) >= 99.8
      ) {
        isConverted = true;
        return { englishValue: 99.8, isConverted: isConverted };
      }
      if (
        conversionTypes.includes(METRIC_CONVERSION_TYPES.W1) &&
        parseFloat(value) >= 99.9
      ) {
        isConverted = true;
        return { englishValue: 99, isConverted: isConverted };
      }
      if (!isConverted) {
        return { isConverted: false };
      }
    },
    getEnglishToMetricConversions:
      (state) => (pairCode, value, conversionTypes) => {
        const { metricValue, isConverted } =
          state.getMetricValueFromConversionTypes(value, conversionTypes);
        if (isConverted) {
          return metricValue;
        }
        const conversionObject = state.getMetricConversionObject(pairCode);
        if (value) {
          return parseFloat(value) * conversionObject.factor;
        } else {
          return value != null ? parseFloat(value) : null;
        }
      },
    getMetricValueFromConversionTypes: () => (value, conversionTypes) => {
      if (!conversionTypes || conversionTypes?.length <= 0) {
        return { isConverted: false };
      }
      let isConverted = false;
      if (value == -1) {
        isConverted = true;
        return { metricValue: -1, isConverted: isConverted };
      }
      if (
        conversionTypes?.includes(METRIC_CONVERSION_TYPES.L1) &&
        parseFloat(value) == 99
      ) {
        isConverted = true;
        return { metricValue: 99, isConverted: isConverted };
      }
      if (
        conversionTypes?.includes(METRIC_CONVERSION_TYPES.L2) &&
        parseFloat(value) == 99
      ) {
        isConverted = true;
        return { metricValue: 199, isConverted: isConverted };
      }
      if (
        conversionTypes?.includes(METRIC_CONVERSION_TYPES.L3) ||
        (conversionTypes?.includes(METRIC_CONVERSION_TYPES.L4) &&
          parseFloat(value) >= 99.9)
      ) {
        isConverted = true;
        return { metricValue: 99.9, isConverted: isConverted };
      }
      if (
        conversionTypes?.includes(METRIC_CONVERSION_TYPES.L5) &&
        parseFloat(value) >= 99.8
      ) {
        isConverted = true;
        return { metricValue: 99.8, isConverted: isConverted };
      }
      if (
        conversionTypes?.includes(METRIC_CONVERSION_TYPES.W1) &&
        parseFloat(value) >= 99
      ) {
        isConverted = true;
        return { metricValue: 99.9, isConverted: isConverted };
      }
      if (!isConverted) {
        return { isConverted: false };
      }
    },
    getMetricConversionObject: (state) => (pairCode) => {
      return state.metricConversions.find((c) => c.pairCode === pairCode);
    },
    getUsers: (state) => {
      return state.users;
    },
    getWebUsers: (state) => {
      return state.webUsers;
    },
    getOrganizations: (state) => {
      return state.organizations;
    },
    getFlexActions: (state) => (serviceTypeOn) => {
      const filter = state.codeFilters
        .find((a) => a.filterCriterion.includes(serviceTypeOn))
        ?.filterCode.split(",");
      let flexActionOptions = [];
      state.flexActions.forEach((action) => {
        if (
          filter.find(
            (a) => parseInt(a) == parseInt(action.flexAction.fxActKey)
          )
        ) {
          flexActionOptions.push({
            title:
              action.flexAction.fxActKey + " - " + action.flexAction.fxName,
            value: action.flexAction.fxActKey,
          });
        }
      });
      flexActionOptions.sort(function (a, b) {
        return parseInt(a.value) - parseInt(b.value);
      });
      return flexActionOptions;
    },
    getFlexAction: (state) => (key) => {
      let action = state.flexActions.find((c) => c.flexAction.fxActKey === key);
      return action?.flexAction;
    },
    getFlexActionTitle: (state) => (key) => {
      if (key == "") {
        return key;
      } else {
        let action = state.flexActions.find(
          (c) => c.flexAction.fxActKey === key
        );
        return (
          action?.flexAction?.fxActKey + " - " + action?.flexAction?.fxName
        );
      }
    },
    getBridgeActions: (state) => {
      return state.bridgeActions
        .filter((a) => {
          const idValue = parseInt(a.bridgeAction.tid);
          //BUG# 84024
          return idValue < 31 || idValue > 43 || idValue == 40;
        })
        .map((action) => {
          return {
            title:
              action.bridgeAction.tid + " - " + action.bridgeAction.aTypeShort,
            value: action.bridgeAction.tid,
          };
        })
        .sort(function (a, b) {
          return parseInt(a.value) - parseInt(b.value);
        });
    },
    getBridgeAction: (state) => (key) => {
      let action = state.bridgeActions.find((c) => c.bridgeAction.tid === key);
      return action?.bridgeAction;
    },
    getBridgeActionTitle: (state) => (key) => {
      if (key == "") {
        return key;
      } else {
        let action = state.bridgeActions.find(
          (c) => c.bridgeAction.tid === key
        );
        return (
          action?.bridgeAction?.tid + " - " + action?.bridgeAction?.aTypeShort
        );
      }
    },
    getUser: (state) => (userKey) => {
      return state.users.find((u) => u.userId == userKey);
    },
    getNbisBridgeInspectors: (state) => {
      return state.nbisBridgeInspectors;
    },
    getNbisNSTMBridgeInspectors: (state) => {
      return state.nbisNSTMBridgeInspectors;
    },
    getNbisTunnelInspectors: (state) => {
      return state.nbisTunnelInspectors;
    },
    getInspectors: (state) => {
      return state.inspectors;
    },
    getUnderwaterDivers: (state) => {
      return state
        .buildTeamLeadersAndUnderwaterDivers(state.underwaterDivers)
        .sort(state.compare);
    },
    switchNull: () => (value) => {
      if (value) {
        return String(value);
      } else {
        return " ";
      }
    },
    compare: () => (a, b) => {
      const aLastName = (
        a.title?.split(".")[1]?.split("(")[0] || ""
      ).toLowerCase();
      const bLastName = (
        b.title?.split(".")[1]?.split("(")[0] || ""
      ).toLowerCase();

      if (aLastName < bLastName) {
        return -1;
      } else if (aLastName > bLastName) {
        return 1;
      } else {
        const aFirstName = (a.title?.split(".")[0] || "").toLowerCase();
        const bFirstName = (b.title?.split(".")[0] || "").toLowerCase();

        if (aFirstName < bFirstName) {
          return -1;
        } else if (aFirstName > bFirstName) {
          return 1;
        } else {
          return 0;
        }
      }
    },
    buildTeamLeadersAndUnderwaterDivers: () => (users) => {
      return users?.map((user) => {
        return {
          value: user.userId.toString(),
          title: `${user.userFirstName?.substring(0, 1)}. ${
            user.userLastName
          } (${user.userId.toString()})`,
        };
      });
    },
    getTeamLeaders: (state) => (structure, nstmChecked) => {
      let teamLeaders = [];
      switch (structure.Bridge.REPORTGROUP.trim()) {
        case "S1":
        case "L1":
        case "A1":
          teamLeaders = nstmChecked
            ? state.buildTeamLeadersAndUnderwaterDivers(
                state.getNbisNSTMBridgeInspectors
              )
            : state.buildTeamLeadersAndUnderwaterDivers(
                state.getNbisBridgeInspectors
              );
          break;
        case "T1":
          teamLeaders = state.buildTeamLeadersAndUnderwaterDivers(
            state.getNbisTunnelInspectors
          );
          break;
        default:
          teamLeaders = state.buildTeamLeadersAndUnderwaterDivers(
            state.getInspectors
          );
          break;
      }

      if (!teamLeaders.find((l) => l.value == structure.InspEvnt.INSPUSRKEY)) {
        const currentLeaderToAdd = state.getUser(structure.InspEvnt.INSPUSRKEY);
        if (currentLeaderToAdd) {
          teamLeaders.push({
            value: currentLeaderToAdd.userId.toString(),
            title: `${currentLeaderToAdd.userFirstName?.substring(0, 1)}. ${
              currentLeaderToAdd.userLastName
            } (${currentLeaderToAdd.userId.toString()})`,
          });
        } else {
          teamLeaders.push({
            title: structure.InspEvnt.INSPUSRKEY,
            value: structure.InspEvnt.INSPUSRKEY,
          });
        }
      }
      return teamLeaders.sort(state.compare);
    },
    getScourCriticalBridgeIndicatorReturnObject:
      (state) => (structure, underwaterInspection, structureUnit) => {
        let strucConfig = structure.UserBrdg.DEPT_MAIN_STRUC_CONFIG;

        if (strucConfig?.charAt(0) == "0") {
          strucConfig = strucConfig?.charAt(1);
        }
        return state.scourCriticalBridgeIndicators.find(
          (scbis) =>
            scbis.strUnitType == structureUnit.STRUNITTYPE &&
            (scbis.foundType ==
              state.switchNull(underwaterInspection.FOUND_TYPE) ||
              scbis.foundType == "ANY") &&
            (scbis.subUnitType ==
              state.switchNull(underwaterInspection.SUBUNIT_TYPE) ||
              scbis.subUnitType == "ANY") &&
            (scbis.scourHole ==
              state.switchNull(underwaterInspection.SCOUR_HOLE) ||
              scbis.scourHole == "ANY") &&
            (scbis.changeSinceLastInsp ==
              state.switchNull(underwaterInspection.CHG_SINCE_LAST_INSP) ||
              scbis.changeSinceLastInsp == "ANY") &&
            (scbis.streamBedMat ==
              state.switchNull(underwaterInspection.STREAM_BED_MATERIAL) ||
              scbis.streamBedMat == "ANY") &&
            (scbis.moveMnt == state.switchNull(underwaterInspection.MOVEMENT) ||
              scbis.moveMnt == "ANY") &&
            (scbis.deptMainStrConf == state.switchNull(strucConfig) ||
              scbis.deptMainStrConf == "ANY")
        );
      },
    cleanupScourCriticalBridgeIndicator: () => (structure, scbiCodes) => {
      const { InspEvnt, T_Scour_Comp } = structure;
      if (scbiCodes.includes(6)) {
        T_Scour_Comp.OVERALL_OSA = "6";
      } else if (
        scbiCodes.includes(1) ||
        scbiCodes.includes(2) ||
        scbiCodes.includes(3) ||
        scbiCodes.includes(4)
      ) {
        T_Scour_Comp.OVERALL_OSA = Math.min(...scbiCodes).toString();
      } else if (scbiCodes.includes(7)) {
        T_Scour_Comp.OVERALL_OSA = "7";
      } else if (scbiCodes.includes(5)) {
        T_Scour_Comp.OVERALL_OSA = "5";
      } else if (scbiCodes.includes(8)) {
        T_Scour_Comp.OVERALL_OSA = "8";
      }
      if (T_Scour_Comp.SCBI_SOURCE == "O") {
        InspEvnt.SCOURCRIT = T_Scour_Comp.OVERALL_OSA;
      }
    },
    filterWingwalls: () => (Structure_Unit, T_Underwater_Insp) => {
      const wingWallKeys = Structure_Unit?.filter(
        (ww) => ww.STRUNITTYPE == "W"
      ).map((su) => su.STRUNITKEY);

      return T_Underwater_Insp?.filter(
        (i) => !wingWallKeys.includes(i.STRUNITKEY)
      );
    },
    calculateScourVulnerability: (state) => (structure) => {
      const { Bridge, T_Underwater_Insp, UserInsp, Structure_Unit } = structure;

      const filteredUWInsp =
        state.filterWingwalls(Structure_Unit, T_Underwater_Insp) ?? [];

      if (isServiceTypeUnder01234(Bridge.SERVTYPUND)) {
        UserInsp.SCOUR_VULNERABILITY = "N";
      } else if (isPorX(filteredUWInsp)) {
        UserInsp.SCOUR_VULNERABILITY = "U";
      } else {
        const scbiCodes = filteredUWInsp.map(({ SCBI_CODE }) =>
          parseInt(SCBI_CODE)
        );

        if (scbiCodes.includes(6)) {
          UserInsp.SCOUR_VULNERABILITY = "0";
        } else if (scbiCodes.some((code) => [3, 2, 1, 0].includes(code))) {
          UserInsp.SCOUR_VULNERABILITY = "D";
        } else if (scbiCodes.some((code) => code === 7)) {
          UserInsp.SCOUR_VULNERABILITY = "C";
        } else if (
          filteredUWInsp.some(
            ({ SCBI_CODE, STREAM_BED_MATERIAL }) =>
              [5, 8].includes(parseInt(SCBI_CODE)) &&
              STREAM_BED_MATERIAL === "D9"
          )
        ) {
          UserInsp.SCOUR_VULNERABILITY = "B";
        } else if (
          filteredUWInsp.some(
            ({ SCBI_CODE, SCOUR_HOLE, STREAM_BED_MATERIAL }) =>
              parseInt(SCBI_CODE) === 4 &&
              parseInt(SCOUR_HOLE) <= 5 &&
              STREAM_BED_MATERIAL !== "D9"
          )
        ) {
          UserInsp.SCOUR_VULNERABILITY = "D";
        } else if (
          filteredUWInsp.some(
            ({ SCBI_CODE, STREAM_BED_MATERIAL }) =>
              [5, 8].includes(parseInt(SCBI_CODE)) &&
              STREAM_BED_MATERIAL !== "D9"
          )
        ) {
          UserInsp.SCOUR_VULNERABILITY = "A";
        } else if (
          filteredUWInsp.some(
            ({ SCBI_CODE, SCOUR_HOLE, STREAM_BED_MATERIAL }) =>
              parseInt(SCBI_CODE) === 4 &&
              parseInt(SCOUR_HOLE) > 5 &&
              STREAM_BED_MATERIAL !== "D9"
          )
        ) {
          UserInsp.SCOUR_VULNERABILITY = "A";
        } else if (scbiCodes.includes(9)) {
          UserInsp.SCOUR_VULNERABILITY = "A";
        } else {
          UserInsp.SCOUR_VULNERABILITY = "0";
        }
      }
    },
    getScourCriticalBridgeIndicator: (state) => (structure) => {
      const { Structure_Unit, T_Underwater_Insp } = structure;
      const scbiCodes = [];
      const filteredUWInsp = state.filterWingwalls(
        Structure_Unit,
        T_Underwater_Insp
      );

      filteredUWInsp.forEach((underwaterInspection) => {
        const structureUnit = Structure_Unit?.find(
          (su) => su.STRUNITKEY == underwaterInspection.STRUNITKEY
        );
        const returnObject = state.getScourCriticalBridgeIndicatorReturnObject(
          structure,
          underwaterInspection,
          structureUnit
        );
        underwaterInspection.SCBI_CODE = returnObject
          ? returnObject.scbiCode
          : "6";
        underwaterInspection.SCBI_CASE = returnObject
          ? returnObject.scbiCase
          : "4";
        scbiCodes.push(parseInt(underwaterInspection.SCBI_CODE));
      });
      state.cleanupScourCriticalBridgeIndicator(structure, scbiCodes);
      state.calculateScourVulnerability(structure);
    },
    getCountyByFips: (state) => (value) => {
      return state.counties.find((r) => r.fipsCode === value);
    },
    getCountyByCountyCode: (state) => (value) => {
      return state.counties.find((r) => r.countyCode === value);
    },
    getMetricConversion: (state) => (value) => {
      return state.metricConversions.find((r) => r.pairCode === value)?.factor;
    },
    getDistrictByDistrictNum: (state) => (value) => {
      return state.districts.get(value);
    },
    getMunicipalityByPlaceCode: (state) => (value) => {
      return state.municipalities.find((r) => r.municipalityCode === value);
    },
    getConfigProperty: (state) => (propertyName) => {
      let property = state.properties.find((element) => {
        return element.name === propertyName;
      });
      return property ? property.value : null;
    },
    getCountiesByDistrict: (state) => (districts) => {
      const countyArr = [];
      let filteredCounties = state.counties.filter((c) => {
        return districts?.includes(c.districtNum);
      });
      if (filteredCounties.length === 0) filteredCounties = state.counties;
      filteredCounties.forEach((c) =>
        countyArr.push({
          value: c.countyCode,
          title: `${c.countyCode} - ${c.countyName}`,
        })
      );
      return countyArr;
    },
    getMunicipalitiesByCounty: (state) => (counties) => {
      let municipalityArr = [];
      let filteredMunis = state.municipalities.filter((m) => {
        return counties?.includes(m.countyCode);
      });
      if (filteredMunis.length === 0) filteredMunis = state.municipalities;
      filteredMunis.sort(function (a, b) {
        return (
          a.countyCode - b.countyCode || a.municipalityNum - b.municipalityNum
        );
      });
      filteredMunis.forEach((m) =>
        municipalityArr.push({
          value: m.municipalityCode,
          title: `${m.countyCode}/${m.municipalityNum} ${m.municipalityName}`,
        })
      );
      return municipalityArr;
    },
    getEnvConfigValue: (state) => (property) => {
      let envConfigProperty = state.envConfigProperties.find((element) => {
        return element.property === property;
      });
      return envConfigProperty ? envConfigProperty.value : null;
    },
    // Default to include `Code - Description` in title.
    getReferenceValues:
      (state) =>
      (
        referenceName,
        sortBy,
        sortOrder,
        removeCodeInDesc,
        valueType,
        showLongDesc
      ) => {
        let values = [];
        values = removeCodeInDescription(
          state,
          removeCodeInDesc,
          referenceName,
          showLongDesc
        );
        if (sortBy == CONFIG_SORT_BY.VALUE) {
          values = sortByDescription(sortOrder, values, valueType);
        } else if (sortOrder == SORT_ORDER.DESC) {
          values?.sort((a, b) => b.title.localeCompare(a.title));
        } else {
          values?.sort((a, b) => a.title.localeCompare(b.title));
        }

        return values;
      },
    getDepartmentStructureTypeDescriptions: (state) => {
      return state.departmentStructureTypeDescriptions;
    },
    getDepartmentStructureTypeDescription: (state) => (value) => {
      try {
        return state.departmentStructureTypeDescriptions.find(
          (r) => r.deptStructureTypeId === value
        )?.deptStructureDesc;
      } catch (e) {
        let error = Object.assign(e, {
          method: "getDepartmentStructureTypeDescription",
          value,
        });
        LOGGER.logException(error);
        return "";
      }
    },
    getReferenceValue:
      (state) => (referenceName, value, isDescriptionOnly, showLongDesc) => {
        const refTable = state.references.get(referenceName);
        try {
          if (value.length > 1) {
            value = value.trim();
          }
          if (value.length !== 0) {
            const reference = refTable.find((r) => r.value == value);
            if (showLongDesc) {
              return isDescriptionOnly
                ? reference.title.long
                : `${reference.value} - ${reference.title.long}`;
            } else {
              return isDescriptionOnly
                ? reference.title.short
                : `${reference.value} - ${reference.title.short}`;
            }
          }
        } catch (e) {
          let error = Object.assign(e, {
            method: "getReferenceValue",
            referenceName,
            value,
          });
          LOGGER.logException(error);
        }
        return value;
      },
    getElementDefinition: (state) => (elementKey) => {
      try {
        return state.elementDefinitions.find((e) => e.elemId == elementKey);
      } catch (e) {
        let error = Object.assign(e, {
          method: "getElementDefinition",
          elementKey,
        });
        LOGGER.logException(error);
        return elementKey;
      }
    },
    getAddElementDefinitions: (state) => (structureType) => {
      try {
        return state.elementDefinitions.filter(
          (e) =>
            e.elemSmartFlag != "Y" &&
            e.elemProtectSys != "Y" &&
            e.elemSubComp != "Y" &&
            e.structureType === structureType
        );
      } catch (e) {
        let error = Object.assign(e, {
          method: "getAddElementDefinitions",
          structureType,
        });
        LOGGER.logException(error);
        return [];
      }
    },
    getElementStateDefinitions() {
      return (elemKey) => {
        try {
          let definitions = [];
          this.elementStateDefinitions[elemKey]?.forEach((d) => {
            definitions.push({
              ELEM_KEY: elemKey,
              ELEM_STATE_KEY: d.elemStateKey,
              ELEM_STATE_NAME_SHORT: d.elemStateNameShort,
              ELEM_STATE_DESC: d.elemStateDesc,
            });
          });
          return definitions;
        } catch (e) {
          let error = Object.assign(e, {
            method: "getElementStateDefinitions",
            elemKey,
          });
          LOGGER.logException(error);
          return [];
        }
      };
    },
    getElementChildren() {
      return (elemKey) => {
        let elementWithChildren = {
          ELEM_KEY: elemKey,
          defects: [],
          protectiveSystems: [],
          subComponents: [],
        };
        try {
          this.elementChildren[elemKey]?.forEach((elem) => {
            let elementDefinition = this.getElementDefinition(
              elem.elemChildKey
            );
            if (elementDefinition?.elemProtectSys === "Y") {
              //element protective systems
              let protectiveSystem = { SUB_COMPS: [] };
              protectiveSystem.ELEM_KEY = elem.elemChildKey;
              protectiveSystem.ELEM_SHORTNAME =
                elementDefinition?.elemShortName;
              protectiveSystem.ELEM_PARENT_KEY = elemKey;
              protectiveSystem.LEGAL_TXT_CD = elementDefinition?.legalTextCD;
              elem.elemGrandChildren.forEach((grandChildKey) => {
                const elemDef = this.getElementDefinition(grandChildKey);
                protectiveSystem.SUB_COMPS.push({
                  ELEM_KEY: grandChildKey,
                  ELEM_SHORTNAME: elemDef?.elemShortName,
                  ELEM_PARENT_KEY: elem.elemChildKey,
                  ELEM_GRANDPARENT_KEY: elemKey,
                  COND_TEXT: this.getElementStateDefinitions(grandChildKey),
                  LEGAL_TXT_CD: elemDef?.legalTextCD,
                });
              });
              elementWithChildren.protectiveSystems.push(protectiveSystem);
            } else if (elementDefinition?.elemSmartFlag === "Y") {
              //element defects
              let defect = {};
              defect.ELEM_KEY = elem.elemChildKey;
              defect.ELEM_SHORTNAME = elementDefinition?.elemShortName;
              defect.ELEM_PARENT_KEY = elemKey;
              defect.LEGAL_TXT_CD = elementDefinition?.legalTextCD;
              defect.COND_TEXT = this.getElementStateDefinitions(
                defect.ELEM_KEY
              );
              elementWithChildren.defects.push(defect);
            } else if (elementDefinition?.elemSubComp === "Y") {
              //element Sub components
              let subComponent = {};
              subComponent.ELEM_KEY = elem.elemChildKey;
              subComponent.ELEM_SHORTNAME = elementDefinition?.elemShortName;
              subComponent.ELEM_PARENT_KEY = elemKey;
              subComponent.LEGAL_TXT_CD = elementDefinition?.legalTextCD;
              subComponent.COND_TEXT = this.getElementStateDefinitions(
                subComponent.ELEM_KEY
              );
              elementWithChildren.subComponents.push(subComponent);
            }
          });
          return elementWithChildren;
        } catch (e) {
          let error = Object.assign(e, {
            method: "getElementChildren",
            elemKey,
          });
          LOGGER.logException(error);
          return {};
        }
      };
    },
    getDefaultDownloadDocKeys: (state) => (docCategories) => {
      try {
        return state.docTypes
          .filter(
            (d) =>
              docCategories.includes(d.docCategory) &&
              d.iFormsDownloadInitial === "1"
          )
          .map((t) => t.docTypeId);
      } catch (e) {
        let error = Object.assign(e, {
          method: "getDocKeys",
          docCategories,
        });
        LOGGER.logException(error);
        return docCategories;
      }
    },
    getDocType: (state) => (docTypeId) => {
      try {
        return state.docTypes.find((d) => d.docTypeId == docTypeId);
      } catch (e) {
        let error = Object.assign(e, {
          method: "getDocType",
          docTypeId,
        });
        LOGGER.logException(error);
        return docTypeId;
      }
    },
    isUserAllowed: (state) => (userId) => {
      return userId ? state.allowedUsers?.includes(userId) : false;
    },
  },
  actions: {
    loadAppBuildInfo() {
      this.appBuildInfo = {
        // eslint-disable-next-line no-undef
        buildVersion: __APP_VERSION__,
        // eslint-disable-next-line no-undef
        buildTime: __BUILD_TIME__,
        environment: getEnvironmentFromCode(
          this.getEnvConfigValue(ENV_CONFIG_PROPERTY.ENVIRONMENT)
        ),
      };
    },
    loadEnvProperties(user) {
      return new Promise((resolve) => {
        const data = {
          endPoint: CONFIG_ENDPOINT.ENV_CONFIG_VALUES,
          auth: user,
          urlParams: { properties: ENV_CONFIG_PROPERTIES.join(",") },
          method: "loadEnvProperties",
        };

        this.apiWorkerManager.postMessage(data).then((response) => {
          this.envConfigProperties = response.data;
          resolve();
        });
      });
    },
    loadAllowedUsersEnvProperties(user) {
      return new Promise((resolve) => {
        //check if restriction is enabled
        const isRestricted = Boolean(
          this.getEnvConfigValue(ENV_CONFIG_PROPERTY.ENABLE_USERS_RESTRICTION)
        );
        if (isRestricted) {
          const noOfUserVariables = this.getEnvConfigValue(
            ENV_CONFIG_PROPERTY.ALLOWED_USERS_COUNT
          );
          const allowedUserProperties = [];
          for (let index = 1; index <= noOfUserVariables; index++) {
            allowedUserProperties.push(
              ENV_CONFIG_PROPERTY.ALLOWED_USERS + index
            );
          }

          const data = {
            endPoint: CONFIG_ENDPOINT.ENV_CONFIG_VALUES,
            auth: user,
            urlParams: { properties: allowedUserProperties.join(",") },
            method: "loadAllowedUsersEnvProperties",
          };
          this.apiWorkerManager.postMessage(data).then((response) => {
            this.allowedUsers = response.data.data
              ?.map((users) => users.value)
              .join(",")
              .split(",");
            resolve();
          });
        } else {
          return resolve(null);
        }
      });
    },
    loadDistricts(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.DISTRICTS,
        auth: user,
        method: "loadDistricts",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        response.data.forEach((d) => {
          this.districts.set(d.districtNum, d.districtName);
        });
      });
    },
    loadUsers(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.USERS,
        auth: user,
        method: "loadUsers",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        this.users = response.data;
      });
    },
    loadNbisBridgeInspectors(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.NBIS_BRIDGE_INSPECTORS,
        auth: user,
        method: "loadNbisBridgeInspectors",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        this.nbisBridgeInspectors = response.data;
      });
    },
    loadNbisNSTMBridgeInspectors(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.NBIS_NSTM_BRIDGE_INSPECTORS,
        auth: user,
        method: "loadNbisNSTMBridgeInspectors",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        this.nbisNSTMBridgeInspectors = response.data;
      });
    },
    loadNbisTunnelInspectors(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.NBIS_TUNNEL_INSPECTORS,
        auth: user,
        method: "loadNbisTunnelInspectors",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        this.nbisTunnelInspectors = response.data;
      });
    },
    loadInspectors(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.INSPECTORS,
        auth: user,
        method: "loadInspectors",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        this.inspectors = response.data;
      });
    },
    loadUnderwaterDivers(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.UNDERWATER_DIVERS,
        auth: user,
        method: "loadUnderwaterDivers",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        this.underwaterDivers = response.data;
      });
    },
    loadReferenceData(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.REFERENCES,
        auth: user,
        method: "loadReferenceData",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        response.data.forEach((ref) => {
          let pairs = [];
          for (const [value, text] of Object.entries(ref.pair)) {
            pairs.push({ value, title: text });
          }
          this.references.set(ref.table, pairs);
        });
      });
    },
    loadCounties(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.COUNTIES,
        auth: user,
        method: "loadCounties",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        response.data.forEach((c) => {
          this.counties.push(c);
        });
      });
    },
    loadMetricConversions(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.METRIC_CONVERSIONS,
        auth: user,
        method: "loadMetricConversions",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        response.data.forEach((metricConversion) => {
          this.metricConversions.push(metricConversion);
        });
      });
    },
    loadMunicipalities(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.MUNICIPALITIES,
        auth: user,
        method: "loadMunicipalities",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        response.data.forEach((c) => {
          this.municipalities.push(c);
        });
      });
    },
    loadCodeFilters(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.CODE_FILTERS,
        auth: user,
        method: "loadCodeFilters",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        response.data.forEach((c) => {
          this.codeFilters.push(c);
        });
      });
    },
    loadScourCriticalBridgeIndicators(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.SCOUR_CRITICAL_BRIDGE_INDICATORS,
        auth: user,
        method: "loadScourCriticalBridgeIndicators",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        response.data.forEach((c) => {
          this.scourCriticalBridgeIndicators.push(c);
        });
      });
    },
    loadDepartmentStructureTypeDescriptions(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.DEPARTMENT_STRUCTURE_TYPE_DESCRIPTIONS,
        auth: user,
        method: "loadDepartmentStructureTypeDescriptions",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        response.data.forEach((c) => {
          this.departmentStructureTypeDescriptions.push(c);
        });
      });
    },
    loadElementDefinitions(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.ELEMENT_DEFINITIONS,
        auth: user,
        method: "loadElementDefinitions",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        response.data.forEach((elementDef) => {
          this.elementDefinitions.push(elementDef);
        });
      });
    },
    loadElementChildren(user) {
      let storeInstance = this;

      const data = {
        endPoint: CONFIG_ENDPOINT.ELEMENT_CHILDREN,
        auth: user,
        method: "loadElementChildren",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        Object.keys(response.data).forEach(function (elemKey) {
          storeInstance.elementChildren[elemKey] = response.data[elemKey];
        });
      });
    },
    loadFlexActions(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.FLEX_ACTIONS,
        auth: user,
        method: "loadFlexActions",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        response.data.forEach((flexAction) => {
          this.flexActions.push({
            flexAction,
          });
        });
      });
    },
    loadBridgeActions(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.BRIDGE_ACTIONS,
        auth: user,
        method: "loadBridgeActions",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        response.data.forEach((bridgeAction) => {
          this.bridgeActions.push({
            bridgeAction,
          });
        });
      });
    },
    loadOrganizations(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.ORGANIZATIONS,
        auth: user,
        method: "loadOrganizations",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        response.data.forEach((organization) => {
          this.organizations.push({
            organization,
          });
        });
      });
    },
    loadWebUsers(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.WEBUSERS,
        auth: user,
        method: "loadWebUsers",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        response.data.forEach((webUser) => {
          this.webUsers.push({
            webUser,
          });
        });
      });
    },
    loadElementStateDefinitions(user) {
      let storeInstance = this;

      const data = {
        endPoint: CONFIG_ENDPOINT.ELEMENT_STATE_DEFINITIONS,
        auth: user,
        method: "loadElementStateDefinitions",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        Object.keys(response.data).forEach(function (elemKey) {
          storeInstance.elementStateDefinitions[elemKey] =
            response.data[elemKey];
        });
      });
    },
    loadDocTypes(user) {
      const data = {
        endPoint: CONFIG_ENDPOINT.DOC_TYPES,
        auth: user,
        method: "loadDocTypes",
      };
      return this.apiWorkerManager.postMessage(data).then((response) => {
        response.data.forEach((c) => {
          this.docTypes.push(c);
        });
      });
    },
  },
});
function isPorX(T_Underwater_Insp) {
  return T_Underwater_Insp?.some(({ INV_FOUND_TYPE }) =>
    ["P", "X"].includes(INV_FOUND_TYPE)
  );
}
function isServiceTypeUnder01234(serviceTypeUnder) {
  return [0, 1, 2, 3, 4].includes(serviceTypeUnder);
}

function removeCodeInDescription(
  state,
  removeCodeInDesc,
  referenceName,
  showLongDesc
) {
  let values = [];
  if (showLongDesc) {
    state.references.get(referenceName)?.forEach((r) =>
      values.push({
        value: r.value,
        title: removeCodeInDesc ? r.title.long : `${r.value} - ${r.title.long}`,
      })
    );
  } else {
    state.references.get(referenceName)?.forEach((r) =>
      values.push({
        value: r.value,
        title: removeCodeInDesc
          ? r.title.short
          : `${r.value} - ${r.title.short}`,
      })
    );
  }
  return values;
}

function sortByDescription(sortOrder, values, valueType) {
  if (sortOrder == SORT_ORDER.DESC) {
    values?.sort((a, b) => {
      if (valueType == TYPES.NUMBER) {
        return b.value - a.value;
      } else {
        return b.value.localeCompare(a.value);
      }
    });
  } else {
    values?.sort((a, b) => {
      if (valueType == TYPES.NUMBER) {
        return a.value - b.value;
      } else {
        return a.value.localeCompare(b.value);
      }
    });
  }
  return values;
}
