export const ALERT_TYPE = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

export const HTTP_STATUS_CODE = {
  SUCCESS: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  NOT_AUTHORIZED: 401,
  SERVICE_UNAVAILABLE: 412,
  UNPROCESSABLE_ENTITY: 422,
  SERVER_ERROR: 500,
  GATEWAY_TIMEOUT: 504,
};

export const RESPONSE_STATUS = {
  SUCCESS: "1000",
  NOT_MODIFIED: "1004",
  VALIDATION_ERROR: "1022",
  DEPENDENT_SERVICE_ERROR: "5000",
};
