export const PAIR_CODE = {
  MILLIMETERS_TO_INCHES: 1,
  MILLIMETERS_TO_FEET: 2,
  MILLIMETERS_TO_YARDS: 3,
  MILLIMETERS_TO_MILES: 4,
  METERS_TO_INCHES: 5,
  METERS_TO_FEET: 6,
  METERS_TO_YARDS: 7,
  METERS_TO_MILES: 8,
  KILOMETERS_TO_INCHES: 9,
  KILOMETERS_TO_FEET: 10,
  KILOMETERS_TO_YARDS: 11,
  KILOMETERS_TO_MILES: 12,
  SQUAREMILLIMETERS_TO_SQUAREINCHES: 13,
  SQUAREMILLIMETERS_TO_SQUAREFEET: 14,
  SQUAREMILLIMETERS_TO_SQUAREYARDS: 15,
  SQUAREMETERS_TO_SQUAREINCHES: 19,
  SQUAREMETERS_TO_SQUAREFEET: 20,
  SQUAREMETERS_TO_SQUAREYARDS: 21,
  SQUAREMETERS_TO_SQUAREMILES: 22,
  SQUAREKILOMETERS_TO_SQUAREFEET: 27,
  SQUAREKILOMETERS_TO_SQUAREYARDS: 28,
  SQUAREKILOMETERS_TO_SQUAREMILES: 29,
  KPH_TO_MPH: 33,
  KPH_TO_FEETPERSECOND: 34,
  METERSPERSECOND_TO_MPH: 40,
  METERSPERSECOND_TO_FEETPERSECOND: 42,
  METRICTON_TO_TON: 45,
  METRICTON_TO_POUND: 46,
  KILOGRAM_TO_POUND: 48,
  KILOGRAM_TO_TON: 49,
  CUBICMETERSPERSECOND_TO_CUBICFEETPERSECOND: 51,
  CUBICMETERS_TO_CUBICFEET: 53,
  CUBICMETERS_TO_CUBICYARDS: 54,
  METERS_TO_LINEARFEET: 56,
  PERSQUAREMETERS_TO_PERSQUAREFEET: 58,
  PERMETERS_TO_PERFEET: 59,
  PERKILOMETERS_TO_PERMILES: 60,
  KILOGRAMSPERSQUAREMETER_TO_PSI: 61,
  LITERS_TO_GALLONS: 62,
  MILIMETERS_TO_MILS: 63,
  MPA_TO_KSI: 64,
  KILOGRAM_TO_KIPS: 65,
};
