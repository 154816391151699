import { computed, ref } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import { convertToInt } from "@/composables/util";
import { clone } from "@/util/clone";
import { STRUCTURE_UNIT_TYPE } from "@/constants/Inspections";

export default () => {
  const inspectionStore = useInspectionStore();
  let fcDetails = ref([]);

  const sort = (arr) => {
    arr.value = arr.value?.sort(function (a, b) {
      return a?.STRUNITKEY - b?.STRUNITKEY || a?.FC_KEY - b?.FC_KEY;
    });
  };

  function getFcDetails(unitKey) {
    if (unitKey) {
      fcDetails.value = clone(
        structure.value?.T_FC_Insp?.filter((a) => a?.STRUNITKEY === unitKey)
      );
    } else {
      fcDetails.value = clone(structure.value?.T_FC_Insp);
    }
    if (!fcDetails?.value?.[0]) {
      //remove null values from array(if any)
      fcDetails.value = fcDetails.value?.filter((a) => !!a);
    }
    //set unique key for toggle expansion
    if (fcDetails.value) {
      for (const element of fcDetails.value) {
        element.UNIQUE_KEY = element?.STRUNITKEY + "-" + element?.FC_KEY;
        element.readOnly = true;
      }
      sort(fcDetails);
    } else {
      fcDetails.value = [];
    }
    return fcDetails.value;
  }

  let structure = computed(() => inspectionStore.selectedInspection);

  function locationOptions(modelValue, isNumericValue) {
    const inspectionStore = useInspectionStore();

    const locations = inspectionStore
      .getFilteredStructureUnits(
        [
          STRUCTURE_UNIT_TYPE.MAIN,
          STRUCTURE_UNIT_TYPE.APPROACH,
          STRUCTURE_UNIT_TYPE.PIER,
        ],
        true
      )
      ?.map((b) => ({
        title: `${b?.STRUNITKEY} - ${b?.STRUNITLABEL}`,
        value: b?.STRUNITKEY,
      }))
      .sort((a, b) => a?.value - b?.value);

    const item = modelValue
      ? locations.find(
          (selectItem) =>
            selectItem.value?.toString() == modelValue?.toString()?.trimEnd()
        )
      : null;
    if (item) {
      return item?.title;
    } else {
      //if isNumericValue=true return the int format of computed value
      return isNumericValue ? parseInt(modelValue) || -1 : modelValue;
    }
  }

  let fctotalCrfMain =
    convertToInt(structure.value?.UserBrdg?.MAIN_CRF_MEM_TYPE) +
    convertToInt(structure.value?.UserBrdg?.MAIN_CRF_FATIG_SUS) +
    convertToInt(structure.value?.UserBrdg?.MAIN_CRF_MATERIAL) +
    convertToInt(structure.value?.UserBrdg?.MAIN_CRF_CUM_ADTT);
  let fctotalCrfApproach =
    convertToInt(structure.value?.UserBrdg?.APPR_CRF_MEM_TYPE) +
    convertToInt(structure.value?.UserBrdg?.APPR_CRF_FATIG_SUS) +
    convertToInt(structure.value?.UserBrdg?.APPR_CRF_MATERIAL) +
    convertToInt(structure.value?.UserBrdg?.APPR_CRF_CUM_ADTT);
  let fctotalCrfMainString =
    (structure.value?.UserBrdg?.MAIN_CRF_MEM_TYPE ?? "") +
    (structure.value?.UserBrdg?.MAIN_CRF_FATIG_SUS ?? "") +
    (structure.value?.UserBrdg?.MAIN_CRF_MATERIAL ?? "") +
    (structure.value?.UserBrdg?.MAIN_CRF_CUM_ADTT ?? "");
  let fctotalCrfApproachString =
    (structure.value?.UserBrdg?.APPR_CRF_MEM_TYPE ?? "") +
    (structure.value?.UserBrdg?.APPR_CRF_FATIG_SUS ?? "") +
    (structure.value?.UserBrdg?.APPR_CRF_MATERIAL ?? "") +
    (structure.value?.UserBrdg?.APPR_CRF_CUM_ADTT ?? "");

  const setFatigueDetails = () => {
    const fcDetailE = structure.value?.T_FC_Insp?.find(
      (detail) =>
        detail?.FATIG_STRESS_CAT?.trim() == "E" ||
        detail?.FATIG_STRESS_CAT?.trim() == "E'"
    );
    if (fcDetailE) {
      structure.value.UserInsp.FATIGUEDETAILS = "Y";
    } else {
      structure.value.UserInsp.FATIGUEDETAILS = "N";
    }
  };

  const fatigueDetail = computed({
    get() {
      setFatigueDetails();
      return structure.value?.UserInsp?.FATIGUEDETAILS;
    },
  });

  return {
    fctotalCrfMainString,
    fctotalCrfMain,
    fctotalCrfApproachString,
    fctotalCrfApproach,
    fatigueDetail,
    getFcDetails,
    locationOptions,
  };
};
