<template>
  <v-btn
    :id="`btn_${props.id}`"
    variant="outlined"
    @click="toggleAll"
    :class="props.styleClass"
    :disabled="inspectionStore.getAddingOrEditing"
    >{{ buttonText }}</v-btn
  >
</template>

<script setup>
import { computed } from "vue";
import { useInspectionStore } from "@/stores/inspection";

const inspectionStore = useInspectionStore();
const props = defineProps({
  id: { default: "" },
  styleClass: { default: "" },
  panel: { default: [] },
});

const emits = defineEmits(["toggleAllPanels"]);

const toggleAll = () => {
  let expand = props.panel.length == 0;
  emits("toggleAllPanels", expand);
};

const buttonText = computed(() =>
  props.panel.length != 0 ? "Collapse All" : "Expand All"
);
</script>
