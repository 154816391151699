<template>
  <v-row v-if="column.sortable" class="alignCenter">
    <v-col
      cols="12"
      :class="getSortIcon(column.key).isSorted ? 'pa-1' : 'headerhover pa-1'"
    >
      <v-row>
        <v-col cols="12" class="minHeight pa-0 ma-0">
          <span class="line1And3Padding" v-if="column.line1">{{
            column.line1
          }}</span>
        </v-col>
        <v-col
          cols="12"
          :class="onlyOne() ? 'pa-0 ma-0' : 'minHeight pa-0 ma-0'"
        >
          <span class="pl-md-4 pl-lg-4 pl-xl-4" v-if="column.line2">{{
            column.line2
          }}</span>
          <v-icon
            v-if="getSortIcon(column.key).isSorted"
            id="icon_subUnit"
            class="pa-0 ma-0"
            :icon="'fas ' + getSortIcon(column.key).sortType"
          />
          <v-icon
            v-else
            class="hiddenIcon pa-0 ma-0"
            id="icon_subUnit_light"
            :icon="'fas fa-sort-up'"
          />
        </v-col>
        <v-col
          cols="12"
          :class="onlyTwo() ? 'pa-0 ma-0' : 'minHeight pa-0 ma-0'"
        >
          <span class="line1And3Padding">{{ column.line3 }}</span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-else class="alignCenter">
    <v-col cols="12" class="pa-1">
      <v-row>
        <v-col cols="12" class="minHeight pa-0 ma-0">
          <span v-if="column.line1">{{ column.line1 }}</span>
        </v-col>
        <v-col
          cols="12"
          :class="onlyOne() ? 'pa-0 ma-0' : 'minHeight pa-0 ma-0'"
        >
          <span v-if="column.line2">{{ column.line2 }}</span>
        </v-col>
        <v-col
          cols="12"
          :class="onlyTwo() ? 'pa-0 ma-0' : 'minHeight pa-0 ma-0'"
        >
          <span v-if="column.line3">{{ column.line3 }}</span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DataTableHeader",
  props: {
    column: { default: {} },
    sortBy: { default: [] },
    lines: { type: Number, default: 3 },
  },

  setup(props) {
    function getSortIcon(id) {
      const item = props.sortBy?.find((item) => item.key === id);
      if (!item) {
        return { isSorted: false, sortType: "fa-sort-up" };
      } else {
        return item.order === "asc"
          ? { isSorted: true, sortType: "fa-sort-up" }
          : { isSorted: true, sortType: "fa-sort-down" };
      }
    }
    function onlyOne() {
      return props.lines === 1;
    }
    function onlyTwo() {
      return props.lines === 2;
    }
    return { getSortIcon, onlyOne, onlyTwo };
  },
};
</script>
<style scoped lang="scss">
@use "@/styles/general" as g;
@media #{"screen and (max-width: 1729px)"} {
  span {
    font-size: 12px !important;
    white-space: nowrap;
    overflow: hidden;
  }
  .v-icon {
    font-size: 12px !important;
  }
}
@media #{"screen and (max-width: 1523px)"} {
  span {
    font-size: 10px !important;
    white-space: nowrap;
    overflow: hidden;
  }
  .v-icon {
    font-size: 10px !important;
  }
}
@media #{"screen and (max-width: 1279px)"} {
  span {
    font-size: 10px !important;
    white-space: nowrap;
    overflow: hidden;
  }
  .v-icon {
    font-size: 9px !important;
  }
}
.minHeight {
  @media #{g.$p-breakPointXlAndUp} {
    min-height: 1.5em !important;
    max-height: 1.5em !important;
  }
  @media #{g.$p-breakPointLgAndDown} {
    min-height: 1.4em !important;
    max-height: 1.4em !important;
  }
  @media #{"screen and (max-width: 1729px)"} {
    min-height: 1.3em !important;
    max-height: 1.3em !important;
  }
  @media #{"screen and (max-width: 1523px)"} {
    min-height: 1.2em !important;
    max-height: 1.2em !important;
  }
}
.alignCenter {
  text-align: center;
}
</style>
