const sign = {
  BRKEY: "",
  INSPKEY: "",
  SIGN_TYPE: "",
  FAR_ADVANCE_COND: "",
  FAR_SITE_COND: "",
  MODTIME: new Date().toISOString(),
  NEAR_ADVANCE_COND: "",
  NEAR_SITE_COND: "",
  NOTES: "",
  SIGN_MESSAGE: "",
  SIGN_NEEDED: "",
  USERKEY: "",
};
export default sign;
