import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import {
  INSPECTION_COMMENTS,
  STRUCTURE_UNIT_TYPE,
} from "@/constants/Inspections";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { styles } from "@/reports/reportStyles";
import { clone } from "@/util/clone";
import { convertToInt } from "@/composables/util";

function setBearingsDocDefinition(structure) {
  const configStore = useConfigStore();
  const bearingDetailsOne = [];
  bearingDetailsOne.push(getBearingsOneHeader());
  getBearingOne(bearingDetailsOne, structure);

  let docDefinition = {
    header: function () {
      return getReportHeader("Bearings Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Bearings Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Bearing Conditions",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        columns: [
          {
            width: "35%",
            text: "IB01 Overall Bearing Condition Rating: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.BEARING_CONDITION_RATING,
              structure?.T_Bearings?.[0]?.BEARING_OVERALL_COND_RATE
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        text: "Overall Bearing Notes: ",
        bold: true,
        margin: [0, 3, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE ==
            INSPECTION_COMMENTS.BEARING_OVERALL_COMMENTS
        )?.NOTES,
        margin: [0, 0, 0, 5],
      },
      {
        table: {
          widths: ["100%"],
          headerRows: 0,
          body: [
            ["Legend:"],
            [
              {
                table: {
                  headerRows: 0,
                  widths: ["25%", "25%", "25%", "25%"],
                  body: [
                    [
                      "IB02 Bearing Key",
                      "IB05 Bearing Location",
                      "IB08 Corrosion?",
                      "IB11 Loss of Bearing Area?",
                    ],
                    [
                      "IB03 Record Key",
                      "IB06 Bearing Count",
                      "IB09 Alignment Issues?",
                      "IB12 Condition Rating",
                    ],
                    [
                      "IB04 Bearing Type",
                      "IB07 Bearing Movement?",
                      "IB10 Anchor Bolt Issues?",
                      "",
                    ],
                  ],
                },
                layout: "noBorders",
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 1 : 0;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 1 : 0;
          },
          hLineColor: function () {
            return "black";
          },
          vLineColor: function () {
            return "black";
          },
        },
        unbreakable: true,
      },
      {
        table: {
          headerRows: 1,
          widths: [
            "auto",
            "30%",
            "30%",
            "auto",
            "auto",
            "auto",
            "auto",
            "auto",
            "auto",
            "*",
          ],
          body: bearingDetailsOne,
        },
        margin: [0, 5, 0, 0],
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}

function getBearingsOneHeader() {
  return [
    {
      text: "IB02-IB03",
      style: ["tableHeader"],
    },
    {
      text: "IB04",
      style: ["tableHeader"],
    },
    {
      text: "IB05",
      style: ["tableHeader"],
    },
    {
      text: "IB06",
      style: ["tableHeader"],
    },
    {
      text: "IB07",
      style: ["tableHeader"],
    },
    {
      text: "IB08",
      style: ["tableHeader"],
    },
    {
      text: "IB09",
      style: ["tableHeader"],
    },
    {
      text: "IB10",
      style: ["tableHeader"],
    },
    {
      text: "IB11",
      style: ["tableHeader"],
    },
    {
      text: "IB12",
      style: ["tableHeader"],
    },
  ];
}
function sort(arr) {
  return arr?.sort(function (a, b) {
    if (a?.BEARING_KEY == b?.BEARING_KEY) {
      return convertToInt(b?.RECORD_KEY) - convertToInt(a?.RECORD_KEY);
    } else {
      return a?.BEARING_KEY - b?.BEARING_KEY;
    }
  });
}
function cloneCurrentInspectionBearings(structure) {
  let bearingsClone = clone(structure?.T_Bearings_Details);

  if (!bearingsClone) {
    //remove null values from array(if any)
    bearingsClone = bearingsClone?.filter((a) => !!a);
  }

  const currentInspectionBearingIndex = structure?.T_Bearings?.findIndex(
    (a) => a?.INSPKEY == structure?.InspEvnt?.INSPKEY
  );

  bearingsClone = bearingsClone?.filter(
    (a) => a?.ID == structure?.T_Bearings?.[currentInspectionBearingIndex]?.ID
  );

  return bearingsClone;
}
function getBearingLocations() {
  const configStore = useConfigStore();
  const inspectionStore = useInspectionStore();
  return inspectionStore
    .getFilteredStructureUnits([STRUCTURE_UNIT_TYPE.APRAS_SPAN], false)
    ?.map((b) => ({
      title: b?.STRUNITDESCRIPTION
        ? `${b?.STRUNITDESCRIPTION}-${b?.STRUNITLABEL}`
        : `${configStore.getReferenceValue(
            REFERENCE_TABLE.STRUCTURE_UNIT_TYPE,
            b?.STRUNITTYPE,
            true
          )}-${b?.STRUNITLABEL}`,
      value: b?.STRUNITKEY.toString(),
    }))
    .sort((a, b) => a?.title?.localeCompare(b?.title));
}
function loadBearings(structure) {
  let bearings = cloneCurrentInspectionBearings(structure) || [];
  //set unique key for toggle expansion
  for (const element of bearings) {
    element.UNIQUE_KEY = element?.BEARING_KEY + "-" + element?.RECORD_KEY;
  }
  return sort(bearings);
}

function getBearingOne(bearingDetailsOne, structure) {
  const bearingLocations = getBearingLocations();
  const configStore = useConfigStore();
  const bearings = loadBearings(structure) || [];
  for (const Bearing of bearings) {
    bearingDetailsOne.push([
      {
        text: Bearing?.BEARING_KEY + "-" + Bearing?.RECORD_KEY,
        style: ["tableBody"],
      },
      {
        text: configStore.getReferenceValue(
          REFERENCE_TABLE.BEARING_TYPE,
          Bearing?.BEARING_TYPE
        ),
        style: ["tableBody"],
      },
      {
        text: bearingLocations?.find(
          (a) => a?.value === Bearing?.BEARING_LOCATION
        )?.title,
        style: ["tableBody"],
      },
      {
        text: Bearing?.BEARING_COUNT,
        style: ["tableBody"],
      },
      {
        text: Bearing?.BEARING_MOVEMENT,
        style: ["tableBody"],
      },
      {
        text: getShortCode(Bearing?.CORROSION),
        style: ["tableBody"],
      },
      {
        text: getShortCode(Bearing?.ALLIGNMENT_ISSUES),
        style: ["tableBody"],
      },
      {
        text: getShortCode(Bearing?.ANCHOR_BOLT),
        style: ["tableBody"],
      },
      {
        text: getShortCode(Bearing?.BEARING_AREA),
        style: ["tableBody"],
      },
      {
        text: Bearing?.CONDITION_RATING,
        style: ["tableBody"],
      },
    ]);
    //Joint notes
    bearingDetailsOne.push([
      {
        colSpan: "10",
        text: [
          { text: "IB17 Bearing Notes: ", bold: true },
          Bearing?.BEARING_COMMENT,
        ],
      },
    ]);
  }
}

function getShortCode(value) {
  if (value == 0) {
    return "N";
  } else if (value == 1) {
    return "Y-O";
  } else if (value == 2) {
    return "Y-M";
  } else {
    return "";
  }
}

export { setBearingsDocDefinition };
