<!--This is a custom component that opens a tool tip when hovered on an icon -->
<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ props }">
      <v-icon
        :id="id"
        :class="iconclass ? `ml-3 no-outline ${iconclass}` : 'ml-3 no-outline'"
        :icon="icon"
        v-bind="props"
        @click="$emit('click')"
        :disabled="disabled"
        :style="styleClass"
      />
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "IconTooltip",
  props: ["id", "icon", "tooltip", "iconclass", "disabled", "styleClass"],
};
</script>
