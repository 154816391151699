<template>
  <v-app-bar
    id="bms3_header_app_bar"
    prominent
    class="white-header"
    density="compact"
  >
    <v-app-bar-nav-icon
      id="btn_nav"
      @click.stop="drawer = !drawer"
    ></v-app-bar-nav-icon>

    <v-toolbar-title>
      <div class="d-flex header-logo">
        <img :src="logo" alt="PA Keystone Logo" class="h3 lang-logo" />
        <div class="ml-2">BMS3</div>
      </div>
    </v-toolbar-title>

    <v-spacer></v-spacer>
    <div v-if="isAuthenticated && user">Welcome {{ user?.sub }}</div>

    <v-btn
      id="btn_update"
      v-if="updateExists"
      variant="outlined"
      @click="refreshApp"
    >
      Update
    </v-btn>
    <v-menu
      v-model="menu"
      v-if="isAuthenticated"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ props }">
        <v-icon
          id="icon_User"
          class="ml-6 mr-3"
          icon="fa-solid fa-user"
          v-bind="props"
        >
        </v-icon>
      </template>

      <v-list nav density="compact">
        <template v-for="item in userProfileMenuItems" :key="item.title">
          <v-list-subheader v-if="item.children">{{
            item.title
          }}</v-list-subheader>
          <template v-for="childItem in item.children" :key="childItem.title">
            <v-list-item
              v-if="!childItem.offlineToggle"
              :title="childItem.title"
              :to="childItem.action"
              @click="userMenuClicked(childItem)"
              :prepend-icon="childItem.icon"
              :disabled="
                !connectivity.getOnlineServiceStatus
                  ? !childItem.offlineAccess
                  : false
              "
              :variant="childItem.variant"
            >
            </v-list-item>
            <v-list-item-action start v-else>
              <v-switch
                hide-details="auto"
                v-model="onlineToggle"
                :disabled="isDirty || !connectivity.getisOnline"
                inset
                :hint="getHint()"
                :persistent-hint="isDirty || !connectivity.getisOnline"
                :class="onlineToggle ? 'switchGreen hint' : 'switchRed hint'"
              >
                <template v-slot:label>
                  <div class="pt-4">
                    {{ onlineToggle ? "Online" : "Offline" }}
                  </div>
                </template>
              </v-switch>
            </v-list-item-action>
          </template>
          <v-list-item title="UserID:" :subtitle="user?.sub"> </v-list-item>
          <v-list-item
            title="User Roles:"
            density="compact"
            v-if="userRoles.length > 0"
          >
            <v-list-item-subtitle v-for="role in userRoles" :key="role">
              {{ role }}
            </v-list-item-subtitle>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
    <v-tooltip v-if="connectivity.getisOnline">
      <template v-slot:activator="{ props }">
        <v-icon
          id="icon_Green_Check"
          class="mr-6"
          color="green"
          icon="fa-solid fa-circle-check"
          v-bind="props"
        >
        </v-icon>
      </template>
      <span>Online</span>
    </v-tooltip>
    <v-tooltip v-else>
      <template v-slot:activator="{ props }">
        <v-icon
          id="icon_Red_Xmark"
          class="mr-6"
          color="red"
          icon="fa-solid fa-circle-xmark"
          v-bind="props"
        >
        </v-icon>
      </template>
      <span>Offline</span>
    </v-tooltip>
  </v-app-bar>

  <v-navigation-drawer v-model="drawer" v-if="isAuthenticated">
    <v-list nav dense>
      <template v-for="item in menuItems" :key="item.title">
        <v-list-group v-if="item.children">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              :title="item.title"
              :prepend-icon="item.icon"
              v-if="doesCurrentUserHasAccess(item)"
            >
            </v-list-item>
          </template>

          <template v-for="childItem in item.children" :key="childItem.title">
            <v-list-item
              :title="childItem.title"
              :to="childItem.action"
              :prepend-icon="childItem.icon"
              :disabled="
                !connectivity.getOnlineServiceStatus
                  ? !childItem.offlineAccess ||
                    (router.currentRoute._value.name == 'OfflineLogin' &&
                      !item.offlineLoginAccess)
                  : false
              "
              v-if="doesCurrentUserHasAccess(childItem)"
            >
            </v-list-item>
          </template>
        </v-list-group>
        <v-list-item
          v-else-if="doesCurrentUserHasAccess(item)"
          :title="item.title"
          :to="item.action"
          :prepend-icon="item.icon"
          :disabled="
            !connectivity.getOnlineServiceStatus
              ? !item.offlineAccess ||
                (router.currentRoute._value.name == 'OfflineLogin' &&
                  !item.offlineLoginAccess)
              : false
          "
        >
        </v-list-item>
        <v-divider />
      </template>
    </v-list>
    <v-container v-if="!isProd">
      <v-row>
        <v-col justify="center" class="increaseContrast" align="center">
          <v-chip id="chip_environment" label :color="envColor"
            >{{ appInfo.environment?.name }}
          </v-chip>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col justify="center" class="increaseContrast" align="center">
          <v-chip id="chip_ProdBuild" label v-if="isProd"
            >{{ appInfo.buildVersion }}-{{
              getFormattedDateStringNoTime(appInfo.buildTime)
            }}
          </v-chip>
          <v-chip id="chip_nonProdBuild" label v-else
            >{{ appInfo.buildVersion }}-{{ appInfo.buildTime }}
          </v-chip>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
  <OfflinePasswordReset
    :showPasswordSetup="showPasswordSetup"
    @hide-password-setup="showPasswordSetup = false"
  />
  <v-overlay
    :model-value="isLoading"
    class="align-center justify-center"
    scroll-strategy="none"
  >
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script setup>
import logo from "@/assets/PennDOT-keystone-logo.svg";
import { ref, computed } from "vue";
import {
  ENVIRONMENT,
  ENV_CONFIG_PROPERTY,
} from "@/constants/EnvConfigProperties";
import { useConfigStore } from "@/stores/config";
import useDateField from "@/composables/dateField.js";
import { useConnectivityStore } from "@/stores/connectivity";
import auth from "@/auth";
import { useUserStore } from "@/stores/userStore";
import router from "@/router";
import OfflinePasswordReset from "@/views/OfflinePasswordReset.vue";
import { useInspectionStore } from "@/stores/inspection";

let drawer = ref(true);
let showPasswordSetup = ref(false);
const inspectionStore = useInspectionStore();
const configStore = useConfigStore();
const connectivity = useConnectivityStore();
const { getFormattedDateStringNoTime } = useDateField();
const updateExists = ref(false);
const registration = ref(null);
const refreshing = ref(false);
const userStore = useUserStore();
const user = computed(() => userStore.loggedInUser);
const isAuthenticated = computed(() => userStore.isAuthenticated);

const supportUsers = computed(() =>
  configStore
    .getEnvConfigValue(ENV_CONFIG_PROPERTY.SUPPORT_USERS)
    ?.split(",")
    .map((u) => u.trim())
);

const userRoles = user.value?.groups?.split("^") || [];
let isLoading = ref(false);

const doesCurrentUserHasAccess = (menuItem) => {
  if (menuItem?.accessedBy && menuItem.accessedBy.length > 0) {
    return menuItem.accessedBy.includes(userStore?.loggedInUser?.sub);
  }
  return true;
};

const isDirty = computed(() => inspectionStore.getDirtyFlag);

const menuItems = [
  {
    title: "Structures",
    icon: "",
    children: [
      {
        title: "Search to Download",
        action: "/structure-search",
        icon: "",
        offlineAccess: false,
        offlineLoginAccess: false,
      },
    ],
  },
  {
    title: "Inspections",
    icon: "",
    children: [
      {
        title: "Worklist",
        action: "/inspections/work-list",
        icon: "",
        offlineAccess: true,
        offlineLoginAccess: false,
      },
    ],
  },
  {
    title: "Support",
    icon: "",
    accessedBy: supportUsers.value,
    children: [
      {
        title: "Services",
        action: "",
        icon: "",
        offlineAccess: false,
        offlineLoginAccess: false,
        accessedBy: supportUsers.value,
      },
    ],
  },
];

const userProfileMenuItems = [
  {
    title: "Account Actions",
    icon: "",
    children: [
      {
        title: "Offline Password Setup",
        action: "",
        clickAction: "changePassword",
        icon: "",
        offlineAccess: false,
        offlineLoginAccess: false,
        variant: "outlined",
      },
      {
        offlineToggle: true,
      },
      {
        title: "Logout",
        action: "",
        clickAction: "logout",
        icon: "",
        offlineAccess: true,
        offlineLoginAccess: true,
        variant: "outlined",
      },
    ],
  },
];

const refreshApp = () => {
  updateExists.value = false;
  // Make sure we only send a 'skip waiting' message if the SW is waiting
  if (!registration.value?.waiting) return;
  // Send message to SW to skip the waiting and activate the new SW
  registration.value.waiting.postMessage({ type: "SKIP_WAITING" });
};

const updateAvailable = (e) => {
  registration.value = e.detail;
  updateExists.value = true;
};

// ---
// Custom code to let user update the app
// when a new service worker is available
// ---
document?.addEventListener("swUpdated", updateAvailable, {
  once: true,
});

navigator?.serviceWorker?.addEventListener("controllerchange", () => {
  // // We'll also need to add 'refreshing' to our data originally set to false.
  if (refreshing.value) return;
  refreshing.value = true;
  // Here the actual reload of the page occurs
  window.location.reload();
});
const appInfo = computed(() => {
  return configStore.getAppBuildInfo;
});

const envColor = computed(() => {
  if (configStore.isEnvironment(ENVIRONMENT.DEVELOPMENT))
    return "green darken-3";
  if (configStore.isEnvironment(ENVIRONMENT.SYSTEM_TEST))
    return "blue darken-2";
  if (configStore.isEnvironment(ENVIRONMENT.USER_ACCEPTANCE))
    return "purple darken-2";
  return "";
});

const isProd = computed(() =>
  configStore.isEnvironment(ENVIRONMENT.PRODUCTION)
);

const logout = async () => {
  userStore.setUser(null);
  userStore.setIsAuthenticated(false);
  //check if there is network connection
  await connectivity.getServiceStatus();
  //take to esec login if there is network and user not manually  toggled to offline
  if (connectivity.getOnlineServiceStatus) {
    auth.logout();
    router.push({ name: "LogoutPage" });
  } else {
    router.push({ name: "OfflineLogin" });
  }
};

let menu = ref(false);

let onlineToggle = computed({
  get() {
    return connectivity.getOnlineServiceStatus;
  },
  set(value) {
    if (value && connectivity.getisOnline) {
      isLoading.value = true;
      auth.silentLogin().then(() => {
        isLoading.value = false;
        router.push({ name: "StructureSearch" });
      });
    }
    connectivity.toggleOnline(value);
  },
});
const getHint = () => {
  if (isDirty.value && connectivity.getisOnline) {
    return "User must save changes before toggling Online/Offline";
  }
  if (!connectivity.getisOnline) {
    return "User cannot toggle Online/Offline without connection";
  }
};
const userMenuClicked = (userProfileMenuItem) => {
  menu.value = false;
  if (userProfileMenuItem?.clickAction === "logout") {
    logout();
  } else if (userProfileMenuItem?.clickAction === "changePassword") {
    showPasswordSetup.value = true;
  }
};
</script>

<style lang="scss">
@use "@/styles/colors" as c;

.increaseContrast {
  filter: contrast(1.5);
}

.white-header {
  background-color: c.$p-white;
}

.header-logo {
  align-items: center;
}
.hint .v-messages {
  color: c.$p-alert-red;
  font-weight: bold;
  padding-bottom: 5px;
}
.switchGreen {
  .v-switch__track {
    background-color: c.$p-alert-green !important;
  }
}
.switchRed {
  .v-switch__track {
    background-color: c.$p-alert-red !important;
  }
}
</style>
