<template>
  <v-app theme="pdsLightTheme">
    <BMS3Header />
    <v-main>
      <v-banner
        class="align-center error"
        v-if="!isOnline"
        sticky
        style="top: 48px; z-index: 1001"
      >
        <v-banner-text>
          OFFLINE - Don't clear your cache or you will lose data.
        </v-banner-text>
      </v-banner>
      <Suspense><router-view></router-view></Suspense>
    </v-main>
    <TimeoutPopup v-if="isAuthenticated" />
  </v-app>
</template>

<script>
import BMS3Header from "@/components/header/BMS3Header.vue";
import { defineComponent, onBeforeUnmount, onMounted, computed } from "vue";
import { useConfigStore } from "@/stores/config";
import { useConnectivityStore } from "@/stores/connectivity";
import { setupSyncInterval } from "@/util/syncInterval";
import TimeoutPopup from "@/components/timeout/TimeoutPopup.vue";
import { useUserStore } from "@/stores/userStore";

export default defineComponent({
  setup() {
    const configStore = useConfigStore();
    const connectivity = useConnectivityStore();
    const userStore = useUserStore();
    let isOnline = computed(() => connectivity.getOnlineServiceStatus);

    onMounted(() => {
      configStore.loadAppBuildInfo();
      connectivity.addListeners();
      connectivity.getServiceStatus();
      //Set up backgroud process to auto sync inspection data.
      setupSyncInterval();
    });
    onBeforeUnmount(() => {
      connectivity.removeListeners();
    });

    let isAuthenticated = computed(() => userStore.isAuthenticated);
    return { userStore, isOnline, isAuthenticated };
  },
  components: {
    BMS3Header,
    TimeoutPopup,
  },
});
</script>
