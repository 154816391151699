<template>
  <v-row>
    <ExpandCollapseToggle
      id="featuresPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panel"
    />
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel class="mt-5">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
          <h3>Clearance Information</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row>
            <v-col xs="12" sm="12" md="12" lg="6">
              <h3 class="withBackground px-2">Minimum Vertical Clearance</h3>

              <LabelText
                labelXl="5"
                labelLg="5"
                labelMd="5"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="txt_Over_Structure"
                label="4A15 Over Structure"
                v-model="structure.Bridge.VCLROVER"
                maxlength="5"
                appendWith=" ft"
                :decimalPlaces="2"
                type="number"
                :conversionTypes="[METRIC_CONVERSION_TYPES.L3]"
                :pairCode="PAIR_CODE.METERS_TO_FEET"
              >
              </LabelText>

              <LabelSelect
                labelXl="5"
                labelLg="5"
                labelMd="5"
                labelSm="6"
                selectXl="6"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                id="label_Under_Reference"
                label="4A16 Under (Reference)"
                :refTable="REFERENCE_TABLE.MINIMUM_UNDERCLEARANCE"
                v-model="structure.Bridge.REFVUC"
              >
              </LabelSelect>

              <LabelText
                labelXl="5"
                labelLg="5"
                labelMd="5"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="txt_Under_Clearance"
                label="4A17 Under Clearance"
                v-model="structure.Bridge.VCLRUNDER"
                maxlength="5"
                appendWith=" ft"
                :decimalPlaces="2"
                :pairCode="PAIR_CODE.METERS_TO_FEET"
                type="number"
                :conversionTypes="[METRIC_CONVERSION_TYPES.L3]"
              >
              </LabelText>
            </v-col>
            <v-col xs="12" sm="12" md="12" lg="6">
              <h3 class="withBackground px-2">Minimum Lateral Clearance</h3>

              <LabelSelect
                labelXl="5"
                labelLg="5"
                labelMd="5"
                labelSm="6"
                selectXl="6"
                selectLg="6"
                selectMd="6"
                selectSm="6"
                id="label_Reference_Feature"
                label="4A18 Reference Feature"
                :refTable="REFERENCE_TABLE.MINIMUM_UNDERCLEARANCE"
                v-model="structure.Bridge.REFHUC"
              >
              </LabelSelect>

              <LabelText
                labelXl="5"
                labelLg="5"
                labelMd="5"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="txt_Under_Right"
                label="4A19 Under Right"
                v-model="structure.Bridge.HCLRURT"
                maxlength="4"
                appendWith="ft"
                :decimalPlaces="1"
                type="number"
                :pairCode="PAIR_CODE.METERS_TO_FEET"
                :conversionTypes="[METRIC_CONVERSION_TYPES.L4]"
              >
              </LabelText>

              <LabelText
                labelXl="5"
                labelLg="5"
                labelMd="5"
                labelSm="6"
                textXl="6"
                textLg="6"
                textMd="6"
                textSm="6"
                id="txt_Under_Left"
                label="4A20 Under Left"
                v-model="structure.Bridge.HCLRULT"
                maxlength="5"
                appendWith="ft"
                :decimalPlaces="1"
                type="number"
                :pairCode="PAIR_CODE.METERS_TO_FEET"
                :conversionTypes="[
                  METRIC_CONVERSION_TYPES.L3,
                  METRIC_CONVERSION_TYPES.L5,
                ]"
              >
              </LabelText>
            </v-col>
          </v-row>

          <v-col class="px-0" xs="12" sm="12" md="12" lg="12">
            <h3 class="withBackground px-2">Navigation Control</h3>
            <v-row>
              <v-col xs="12" sm="12" md="12" lg="6">
                <LabelSelect
                  labelXl="5"
                  labelLg="5"
                  labelMd="5"
                  labelSm="6"
                  selectXl="6"
                  selectLg="6"
                  selectMd="6"
                  selectSm="6"
                  id="label_Nav_Control_Exists"
                  label="4A21 Nav Control Exists"
                  :refTable="REFERENCE_TABLE.NAVIGATION_CONTROL"
                  v-model="structure.Bridge.NAVCNTROL"
                >
                </LabelSelect>

                <LabelText
                  labelXl="5"
                  labelLg="5"
                  labelMd="5"
                  labelSm="6"
                  textXl="6"
                  textLg="6"
                  textMd="6"
                  textSm="6"
                  id="txt_Nav_Horizontal_Clearance"
                  label="4A23 Nav Horizontal Clearance "
                  v-model="structure.Bridge.NAVHC"
                  maxlength="22"
                  appendWith=" ft"
                  :decimalPlaces="2"
                  type="number"
                  :pairCode="PAIR_CODE.METERS_TO_FEET"
                >
                </LabelText>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="6">
                <LabelText
                  labelXl="5"
                  labelLg="5"
                  labelMd="5"
                  labelSm="6"
                  textXl="6"
                  textLg="6"
                  textMd="6"
                  textSm="6"
                  id="txt_Nav_Vertical_Clearance"
                  label="4A22 Nav Vertical Clearance"
                  v-model="structure.Bridge.NAVVC"
                  maxlength="22"
                  appendWith=" ft"
                  :decimalPlaces="2"
                  type="number"
                  :pairCode="PAIR_CODE.METERS_TO_FEET"
                >
                </LabelText>

                <LabelText
                  labelXl="5"
                  labelLg="5"
                  labelMd="5"
                  labelSm="6"
                  textXl="6"
                  textLg="6"
                  textMd="6"
                  textSm="6"
                  id="txt_Min_Vertical_Lift_Clearance"
                  label="4A24 Min Vertical Lift Clearance"
                  v-model="structure.Bridge.LFTBRNAVCL"
                  maxlength="22"
                  appendWith=" ft"
                  :decimalPlaces="2"
                  type="number"
                  :pairCode="PAIR_CODE.METERS_TO_FEET"
                >
                </LabelText>
              </v-col>
            </v-row>
          </v-col>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(1)" icon="fa-solid fa-plus" />

          <v-icon v-if="panel.includes(1)" icon="fa-solid fa-minus" />

          <h3>Feature Intersection Details</h3>
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <v-row class="mb-8">
            <v-col sm="12">
              <v-data-table
                class="noScrollTable compactTable"
                :items="mergedTableData"
                :headers="inspectionDetailsHeaders"
                v-model:expanded="expandedRows"
                hide-default-footer
                :items-per-page="-1"
                show-expand
                return-object
              >
                <template v-slot:[`column.data-table-expand`]="{ column }">
                  {{ column.title }}
                </template>
                <template v-slot:[`column.ON_UNDER`]="{ column }">
                  <DataTableHeader :column="column" :lines="2" />
                </template>
                <template v-slot:[`column.FEATURE_NAME`]="{ column }">
                  <DataTableHeader :column="column" :lines="2" />
                </template>
                <template v-slot:[`column.FEATURE_TYPE`]="{ column }">
                  <DataTableHeader :column="column" :lines="2" />
                </template>
                <template v-slot:[`column.Action`]="{ column }">
                  <DataTableHeader :column="column" :lines="2" />
                </template>
                <template v-slot:item="{ item }">
                  <tr :class="rowClass(item.raw)">
                    <td class="text-center">
                      <template v-if="!anyDetailEditable">
                        <button
                          v-if="!isExpanded(item.raw)"
                          @click="toggleRow(item.raw)"
                        >
                          <v-icon
                            :id="`icon_Chevron_Right_${item.raw.BRKEY}_${item.raw.ON_UNDER}`"
                            icon="fa-solid fa-chevron-right"
                          ></v-icon>
                        </button>

                        <button
                          v-if="isExpanded(item.raw)"
                          @click="toggleRow(item.raw)"
                        >
                          <v-icon
                            :id="`icon_Chevron_Down_${item.raw.BRKEY}_${item.raw.ON_UNDER}`"
                            icon="fa-solid fa-chevron-down"
                          ></v-icon>
                        </button>
                      </template>
                    </td>
                    <td class="alignCenter">
                      {{
                        item.raw?.ON_UNDER
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.ON_UNDER,
                              item.raw?.ON_UNDER
                            )
                          : ""
                      }}
                    </td>
                    <td class="alignCenter">
                      {{ getRoadName(item.raw) }}
                    </td>
                    <td class="alignCenter">
                      {{
                        item.raw?.FEATURE_TYPE
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.FEATURE_TYPE,
                              item.raw?.FEATURE_TYPE
                            )
                          : ""
                      }}
                    </td>
                    <td class="alignCenter">
                      <div
                        v-if="
                          item.raw?.FEATURE_TYPE === '1' ||
                          item.raw?.FEATURE_TYPE === '4'
                        "
                      >
                        <div
                          style="white-space: nowrap"
                          v-if="
                            isExpanded(item.raw) &&
                            anyDetailEditable &&
                            isEditable
                          "
                        >
                          <IconTooltip
                            id="icon_add"
                            icon="fa-solid fa-check"
                            :tooltip="item.raw?.isAdd ? 'Add' : 'Update'"
                            @click="saveDetail()"
                          />
                          <IconTooltip
                            id="icon_close"
                            icon="fa-solid fa-xmark"
                            tooltip="Close"
                            @click="closeDetail"
                          />
                        </div>
                        <div
                          style="white-space: nowrap"
                          v-else-if="
                            isEditable &&
                            (expandedRows?.length == 0 || !anyDetailEditable)
                          "
                        >
                          <IconTooltip
                            id="icon_edit"
                            icon="fa-solid fa-pencil"
                            tooltip="Edit"
                            @click="editDetail(item.raw)"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-slot:expanded-row="{ item }">
                  <td :colspan="inspectionDetailsHeaders.length">
                    <InspectionFeaturesHighway
                      ref="highwayRef"
                      :rowId="item.raw.rowId + ''"
                      :readOnly="!anyDetailEditable"
                      @changes-detected-highway="changesDetected = true"
                      v-if="
                        item.raw.FEATURE_TYPE === '1' ||
                        item.raw.FEATURE_TYPE === '4'
                      "
                      @updated-new-wear-surface="updatedNewWearSurface"
                    />

                    <InspectionFeaturesWaterway
                      :rowId="item.raw.rowId + ''"
                      v-if="item.raw.FEATURE_TYPE === '3'"
                    />

                    <InspectionFeaturesRailroad
                      :rowId="item.raw.rowId + ''"
                      v-if="item.raw.FEATURE_TYPE === '2'"
                    />
                  </td>
                </template>
                <template v-slot:bottom> </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />
    <v-snackbar
      v-model="inspectionStore.addingOrEditing"
      color="warning"
      position="sticky"
      location="top"
      timeout="-1"
    >
      {{ INSPECTION_MESSAGES.FEATURE_INTERSECTION_DETAILS_DATATABLE }}
    </v-snackbar>
  </v-row>
</template>

<script>
import { ref, computed, watch } from "vue";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useInspectionStore } from "@/stores/inspection";
import LabelSelect from "@/components/common/LabelSelect.vue";
import { useConfigStore } from "@/stores/config";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import LabelText from "@/components/common/LabelText.vue";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import IconTooltip from "@/components/common/IconTooltip.vue";
import {
  INSPECTION_MESSAGES,
  METRIC_CONVERSION_TYPES,
} from "@/constants/InspectionConstants";
import { BUTTONS } from "@/constants/CommonWebConstants";
import InspectionFeaturesHighway from "@/components/inspection/featureComponents/InspectionFeaturesHighway.vue";
import InspectionFeaturesRailroad from "@/components/inspection/featureComponents/InspectionFeaturesRailroad.vue";
import InspectionFeaturesWaterway from "@/components/inspection/featureComponents/InspectionFeaturesWaterway.vue";
import DataTableHeader from "@/components/common/DataTableHeader.vue";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";

export default {
  name: "InspectionFeatures",
  data() {
    return {
      inspectionDetailsHeaders: [
        { key: "data-table-expand" },
        {
          line1: "5C03",
          line2: "On/Under",
          line3: null,
          sortable: false,
          key: "ON_UNDER",
        },
        {
          line1: "5C01/FW01/FR01",
          line2: "Feature Name",
          line3: null,
          sortable: false,
          key: "FEATURE_NAME",
        },
        {
          line1: "6C34",
          line2: "Feature Type",
          line3: null,
          sortable: false,
          key: "FEATURE_TYPE",
        },
        {
          line1: null,
          line2: "Action",
          line3: null,
          sortable: false,
          key: "Action",
        },
      ],
    };
  },
  setup() {
    const allPanels = [0, 1];
    const panel = ref([0, 1]);
    const inspectionStore = useInspectionStore();
    const configStore = useConfigStore();
    const closeConfirm = ref(null);

    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });
    if (!isEditable.value) {
      panel.value = allPanels;
    }

    let highwayRef = ref(null);

    let featureIntersectionDetailsForm = ref(null);
    let validDetails = ref(false);
    let changesDetected = ref(false);
    let structure = computed(() => inspectionStore.selectedInspection);
    let mergedTableData = computed(() =>
      inspectionStore.getFeatureIntersectionTableData()
    );
    let expandedRows = ref([]);
    let changesNewWearSurfaceDetected = ref(false);
    let localNewWearSurface = ref(structure.value.UserInsp.NEW_WS);

    const rowClass = (data) => {
      return expandedRows.value?.find((a) => a.ON_UNDER === data.ON_UNDER)
        ? "rowSelect"
        : "rowUnselect";
    };

    const anyDetailEditable = computed(() => {
      return inspectionStore.getAddingOrEditing;
    });

    const isExpanded = (details) => {
      return (
        expandedRows.value?.findIndex(
          (a) => a?.BRKEY === details.BRKEY && a?.ON_UNDER === details.ON_UNDER
        ) >= 0
      );
    };

    function editDetail(detail) {
      inspectionStore.setAddingOrEditing(true);
      expandedRows.value = [detail];
      changesDetected.value = false;
    }

    const toggleRow = (detail) => {
      if (expandedRows.value?.includes(detail)) {
        const index = expandedRows.value.findIndex(
          (a) => a?.BRKEY === detail.BRKEY && a?.ON_UNDER === detail.ON_UNDER
        );
        expandedRows.value.splice(index, 1);
      } else {
        expandedRows.value.push(detail);
      }
    };

    const updatedNewWearSurface = (updatedValue) => {
      changesDetected.value = true;
      localNewWearSurface.value = updatedValue;
    };

    async function saveDetail() {
      let isValid = await highwayRef.value.validate();
      if (isValid) {
        inspectionStore.setShowValidationSnackbar(false);
        inspectionStore.saveFeatureInspectionDetails();
        if (changesDetected.value) {
          inspectionStore.setDirtyFlag(true);
          structure.value.UserInsp.NEW_WS = localNewWearSurface.value;
        }

        expandedRows.value = [];
        inspectionStore.setAddingOrEditing(false);
      } else {
        inspectionStore.setShowValidationSnackbar(true);
      }
    }

    const closeDetail = () => {
      if (isEditable.value && changesDetected.value) {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (!response) {
              expandedRows.value = [];
              inspectionStore.setAddingOrEditing(false);
              changesDetected.value = false;
            }
          });
      } else {
        expandedRows.value = [];
        inspectionStore.setAddingOrEditing(false);
      }
    };

    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    const getRoadName = (detail) => {
      if (detail?.FEATURE_TYPE == "2") {
        return detail.RR_NAME
          ? configStore.getReferenceValue(
              REFERENCE_TABLE.RAILROAD_NAME,
              detail?.RR_NAME
            )
          : "";
      } else {
        return detail.ROADWAY_NAME;
      }
    };

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      () => [
        structure.value.UserRWay,
        structure.value.Roadway,
        structure.value.UserInsp,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      highwayRef,
      toggleRow,
      panel,
      REFERENCE_TABLE,
      isEditable,
      structure,
      configStore,
      PAIR_CODE,
      featureIntersectionDetailsForm,
      saveDetail,
      validDetails,
      changesDetected,
      mergedTableData,
      isExpanded,
      anyDetailEditable,
      editDetail,
      rowClass,
      expandedRows,
      closeDetail,
      closeConfirm,
      BUTTONS,
      inspectionStore,
      INSPECTION_MESSAGES,
      changesNewWearSurfaceDetected,
      updatedNewWearSurface,
      METRIC_CONVERSION_TYPES,
      toggleAllPanels,
      getRoadName,
    };
  },
  components: {
    DataTableHeader,
    LabelSelect,
    LabelText,
    IconTooltip,
    ConfirmDialog,
    InspectionFeaturesHighway,
    InspectionFeaturesRailroad,
    InspectionFeaturesWaterway,
    ExpandCollapseToggle,
  },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;
th {
  text-align: center;
}
.alignCenter {
  text-align: center;
}
.alignEnd {
  text-align: end;
}
.v-data-table :deep(button:hover) {
  background-color: inherit !important;
  color: inherit !important;
}

.v-data-table :deep(button:focus) {
  outline: none !important;
}
.tableHeader {
  text-align: center !important;
  background: c.$p-pa-light-blue !important;
}
</style>
