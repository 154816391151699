import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { styles } from "@/reports/reportStyles";
import { CUSTOM_ICONS } from "@/constants/Unicode";

function setSuperstructureDocDefinition(structure) {
  const configStore = useConfigStore();

  let docDefinition = {
    header: function () {
      return getReportHeader("Superstructure Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Superstructure Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Superstructure Conditions",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        columns: [
          {
            width: "40%",
            text: "1A04 Superstructure Condition Rating: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.SUPRATING
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        text: "Overall Superstructure Notes: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SUPERSTRUCTURE
        )?.NOTES,
      },
      {
        columns: [
          {
            width: "40%",
            text: "6B48 Combustible Material Under Bridge: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.MATERIAL_STORED_UNDER,
              structure?.UserInsp?.MAT_STORE_UND
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        text: "Combustible Material Under Bridge Notes: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE ==
            INSPECTION_COMMENTS.COMBUSTIBLE_MATERIALS_COMMENTS
        )?.NOTES,
      },
      {
        columns: [
          {
            width: "40%",
            text: "6B35 New Coating Since Last Inspection: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text:
              structure?.UserInsp?.NEW_PAINT === "1"
                ? CUSTOM_ICONS.SQUARE_CHECK
                : CUSTOM_ICONS.SQUARE,
            style: "customFontAwesome",
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "40%",
            text: "6B36 Protective Coating Rating: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.PAINT_CONDITION_RATING,
              structure?.UserInsp?.PAINT_COND_RATE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "40%",
            text: "6B37 Protective Coating (Extent) Rating: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.PAINT_CONDITION_RATING_EXTENT,
              structure?.UserInsp?.PAINT_EXTENT_RATE
            ),
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        text: "Overall Protective Coating Notes: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.PAINT_OVERALL
        )?.NOTES,
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Superstructure Details",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "auto",
            text: "5B11 Main Spans: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: structure?.Bridge?.MAINSPANS,
            margin: [5, 5, 0, 0],
          },
          {
            width: "auto",
            text: "5B14 Approach Spans: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure?.Bridge?.APPSPANS,
            margin: [5, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "auto",
            text: "VD31 Bridge Seat Cleaning: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure?.UserBrdg?.SEAT_CLEANING,
            margin: [5, 5, 0, 0],
          },
        ],
      },
      {
        text: "VD32 Bridge Seat Cleaning Notes: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.UserBrdg?.SEAT_CLEANING_NOTE,
      },
      {
        columns: [
          {
            width: "auto",
            text: "VD33 Scuppers w/Downspouts: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "25%",
            text: structure?.UserBrdg?.SCUPPERS_WITH_DOWNSPOUTS,
            margin: [5, 5, 0, 0],
          },
          {
            width: "auto",
            text: "VD34 Scuppers w/out Downspouts: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: structure?.UserBrdg?.SCUPPERS_WITHOUT_DOWNSPOUTS,
            margin: [5, 5, 0, 0],
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Superstructure Inspection Notes",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        text: "Girders/Beams: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SUPER_GIRDERS
        )?.NOTES,
      },
      {
        text: "Floorbeams: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SUPER_FLOORBEAMS
        )?.NOTES,
      },
      {
        text: "Stringers: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SUPER_STRINGERS
        )?.NOTES,
      },
      {
        text: "Diaphrams: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SUPER_DIAPHRAGMS
        )?.NOTES,
      },
      {
        text: "Truss Members: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SUPER_TRUSS_MEMBERS
        )?.NOTES,
      },
      {
        text: "Portals/Bracings: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SUPER_PORTALS
        )?.NOTES,
      },
      {
        text: "Drainage System: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.SUPER_DRAINAGE
        )?.NOTES,
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Protective Coating Inspection Notes",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        text: "Interior Girder/Beam: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.INTERIOR_BEAM_GIRDER
        )?.NOTES,
      },
      {
        text: "Fascia: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.PAINT_FASCIAS
        )?.NOTES,
      },
      {
        text: "Splash Zone - Truss/Girder: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.PAINT_SPLASH_ZONE
        )?.NOTES,
      },
      {
        text: "Truss: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) => element?.COMMENT_TYPE == INSPECTION_COMMENTS.PAINT_TRUSS
        )?.NOTES,
      },
      {
        text: "Bearing: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == INSPECTION_COMMENTS.PAINT_BEARINGS
        )?.NOTES,
      },
      {
        text: "Other: ",
        bold: true,
        margin: [0, 5, 0, 0],
      },
      {
        text: structure?.T_Insp_Comment?.find(
          (element) => element?.COMMENT_TYPE == INSPECTION_COMMENTS.PAINT_OTHER
        )?.NOTES,
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}

export { setSuperstructureDocDefinition };
