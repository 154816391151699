export const REFERENCE_TABLE = {
  STRUCTURAL_CONFIGURATION_OF_APPROACH_SPANS: "structuralConfiguration",
  APPROACH_SPAN_MATERIAL: "approachSpanMaterial",
  APPROACH_ROADWAY_ALIGNMENT_APPRAISAL: "approachRoadwayAlignmentAppraisal",
  BRIDGE_DECK_TYPE: "deckType",
  BRIDGE_MEDIAN: "medianType",
  BUS_PLAN_NETWORK: "businessPlanNetwork",
  CONDITION_RATING: "conditionRating",
  DECK_FORM_TYPE: "formType",
  DECK_PROTECTION_TYPE: "deckProtectionType",
  DECK_MEMBRANE_TYPE: "deckMembraneType",
  DECK_SURFACE_TYPE: "deckSurfaceType",
  DECK_STRUCTURE_TYPE: "deckStructureType",
  EXTENDED_INSPECTION_INTERVAL_ELIGIBILITY: "inspectionIntervalEligibility",
  ENVIRONMENT: "environment",
  FRACTURE_CRITICAL_FATIGUE_STRESS_CATEGORY:
    "fractureCriticalFatigueStressCategory",
  FIELD_COND: "fieldConditions",
  FRACTURE_CRITICAL_MEMBER_TYPE: "fractureCriticalMemberType",
  HISTORICAL_SIGNIFIGANCE: "historicalSignificance",
  INSPECTION_STATUS: "inspectionStatus",
  INSPECTION_TYPE: "inspectionType",
  MAIN_SPAN_DESIGN: "mainSpanDesign",
  MAIN_SPAN_MATERIAL: "mainSpanMaterial",
  MAINTENENCE_RESPONSIBILITY: "maintenenceResponsibility",
  MATERIAL_STORED_UNDER: "materialStoredUnder",
  MATERIAL_MAKEUP: "materialMakeup",
  MEMBER_TYPE: "memberType",
  PAINT_CONDITION_RATING: "paintConditionRating",
  PAINT_CONDITION_RATING_EXTENT: "paintConditionRatingExtent",
  PHYSICAL_MAKEUP: "physicalMakeup",
  PLUG_WELD_PROBLEM_TYPE: "plugWeldProblemType",
  POST_REASON: "postingReason",
  POSTING_STATUS: "postingStatus",
  RELIEF_JOINT: "reliefJoint",
  REPORTING_GROUP: "reportingGroup",
  SERVICE_TYPE_ON: "serviceTypeOn",
  SUB_AGENCY: "agencySubmittingStructure",
  SERVICE_TYPE_UNDER: "serviceTypeUnder",
  SPAN_INTERACTION: "spanInteract",
  SPECIAL_RESTRICT_POST: "specialRestrictivePosting",
  STRUCTURAL_CONFIGURATION: "structuralConfiguration",
  STRUCTURE_FLARED: "structureFlared",
  TEMPORARY_STRUCTURE: "temporaryStructure",
  WEATHER_CONDITION: "weatherCondition",
  TEAM_LEADER: "teamLeader",
  INSPECTION_PERFORMED_BY: "inspectionPerformedBy",
  INSPECTION_INTERVAL_CONCURRENCE: "inspectionIntervalConcurrence",
  RAILING_RATING: "railingRating",
  SIGN_TYPE: "signType",
  SIGN_CONDITION_RAITING: "signConditionRating",
  TRANSITION_RATING: "transitionRating",
  APPROACH_GUIDE_RAIL_RATING: "approachGuideRailRating",
  APPROACH_RAIL_END_RATING: "approachRailEndRating",
  APPROACH_SPAN_DESIGN: "approachSpanDesign",
  OWNER: "owner",
  NBIS_BRIDGE_LENGTH: "nbisBridgeLength",
  FC_GROUP_NUMBER: "fcGroupNumber",
  CRF_FATIGUE_SUSCEPTIBILITY: "crfFatigueSusceptibility",
  CRF_MATERIAL: "crfMaterial",
  CUMULATIVE_AVERAGE_DAILY_TRUCK_TRAFFIC: "cumulativeAverageDailyTruckTraffic",
  STRUCTURE_UNIT_TYPE: "structureUnitType",
  CONSULTANT_HIRED_BY: "consultantHiredBy",
  INACCESSIBLE_AREA: "inaccessibleArea",
  PIER_ABUTMENT_TYPE: "pierAbutmentType",
  PA_FOUNDATION_TYPE: "paFoundationType",
  STREAM_BED_MATERIAL: "streamBedMaterial",
  OBSERVED_SCOUR_RATING: "observedScourRating",
  SCOUR_CONDITION_RATING: "scourConditionRating",
  SCOUR_VULNERABILITY: "scourVulnerability",
  UNDERWATER_INSPECTION_TYPE: "underwaterInspectionType",
  CHANGE_SINCE_LAST_INSP: "changeSinceLastInspection",
  SCOUR_HOLE: "scourHole",
  DEBRIS_POTENTIAL: "debrisPotential",
  SUBSTRUCTURE_SCOURABILITY: "substructureScourability",
  OPENING_ADEQUACY_CHANNEL: "openingAdequacyChannel",
  SEDIMENT_DEPOSITS: "sedimentDeposits",
  ALIGNMENT: "alignment",
  VELOCITY_STREAM_SLOPE: "velocityStreamSlope",
  JOINT_CONDITION_RATING: "jointConditionRating",
  JOINT_TYPE: "jointType",
  JOINT_MOVEMENT: "jointMovement",
  JOINT_MANUFACTURER: "jointManufacturer",
  JOINT_REPLACEMENT_REASON: "jointReplacementReason",
  BEARING_CONDITION_RATING: "bearingConditionRating",
  BEARING_TYPE: "bearingType",
  BEARING_SUBSTRUCTURE_UNIT: "bearingSubstructureUnit",
  BEARING_MOVEMENT: "bearingMovement",
  BEARING_CORROSION: "bearingCorrosion",
  BEARING_ALLIGNMENT_ISSUES: "bearingAlignmentIssues",
  BEARING_ANCHOR_BOLT: "bearingAnchorBoltIssues",
  BEARING_LOSS_AREA: "bearingLossAreas",
  BEARING_REPLACEMENT_REASON: "bearingReplacementReason",
  REVIEWER_ACTION: "reviewerAction",
  LOAD_TYPE: "loadType",
  NBI_RATING: "nbiRating",
  LOAD_RATING_METHOD: "loadRatingMethod",
  CONTROLLING_MEMBER_TYPE: "controllingMemberType",
  CONTROLLING_MEMBER_FATIGUE_STRESS_CATEGORY:
    "controllingMemberFatigueStressCategory",
  FATIGUE_LOAD_TYPE: "fatigueLoadType",
  GOVERNING_CRITERIA: "governingCriteria",
  DESIGN_LOAD: "designLoad",
  POSTING: "posting",
  YES_NO: "yesNo",
  TUNNEL_LOAD_RATING_METHOD: "tunnelLoadRatingMethod",
  CULVERT_OPENING_TYPE: "culvertOpeningType",
  CULVERT_TIE_TYPE: "culvertTieType",
  CULVERT_FLOOR_TYPE: "culvertFloorType",
  SIGN_LIGHT_CONDITION_RATING: "signLightConditionRating",
  SIGN_ASSET_TAG: "signAssetTag",
  WALL_CONDITION_RATING: "wallConditionRating",
  BACKFILL_MATERIAL: "backfillMaterial",
  TUNNEL_SHAPE: "tunnelShape",
  PORTAL_SHAPE: "portalShape",
  GROUND_CONDITION: "groundCondition",
  COMPLEX: "complex",
  SERVICE_IN_TUNNEL: "serviceInTunnel",
  DIRECTION_OF_TRAVEL: "directionOfTravel",
  TOLL: "toll",
  TUNNEL_FUNCTIONAL_CLASSIFICATION: "tunnelFunctionalClassification",
  URBAN_CODE: "urbanCode",
  IN_DEPTH: "inDepthInspectionScheduled",
  SCOUR_CRITICAL_BRIDGE_INDICATOR: "scourCriticalBridgeIndicator",
  SCOUR_CRITICAL_BRIDGE_INDICATOR_SOURCE: "scourCriticalBridgeIndicatorSource",
  ABUTMENT_LOCATION: "abutmentLocation",
  WINGWALL_PRESENCE: "wingwallPresence",
  UPSTREAM_WINGWALL_CONDITION: "upstreamWingwallCondition",
  CURRENT_COUNTERMEASURE_TYPE: "currentCountermeasureType",
  CURRENT_COUNTERMEASURE_LOCATION: "currentCountermeasureLocation",
  CURRENT_COUNTERMEASURE_CONDITION: "currentCountermeasureCondition",
  WORK_CANDIDATE: "workCandidate",
  OSA_FOUNDATION_TYPE: "osaFoundationType",
  METHOD1_ELIGIBILITY: "method1Eligibility",
  OVERTOPPING_RISK: "overtoppingRisk",
  TRAFFIC_DELAY: "trafficDelay",
  MINIMUM_UNDERCLEARANCE: "minimumUnderclearance",
  NAVIGATION_CONTROL: "navigationControl",
  NSTM_DETAIL_PRESENT: "nstmDetailPresent",
  NSTM_PRESENT: "nstmPresent",
  ON_UNDER: "onUnder",
  POSTING_TYPE: "postingType",
  POSTING_CONDITION: "postingCondition",
  VEHICLE_POSTING_TYPE: "vehiclePostingType",
  ROUTE_DIRECTION: "routeDirection",
  ROADWAY_FUNCTIONAL_CLASSIFICATION: "roadwayFunctionalClassification",
  WORST_FLOOD_EVENT: "worstFloodEvent",
  BRIDGE_CONDITION_RATING: "bridgeConditionRating",
  NATIONAL_HIGHWAY_SYSTEM: "nationalHighwaySystem",
  VERTICAL_CLEARANCE_SIGN: "verticalClearanceSign",
  INSPECTION_COMMENT_TYPE: "inspectionCommentType",
  SUBSTRUCTURE_COMMENT_TYPE: "substructureCommentType",
  AGENCY_PRIORITY: "agencyPriority",
  MAINTENANCE_ACTION: "maintenanceAction",
  AGENCY_STATUS: "agencyStatus",
  ACTION_SCOPE: "actionScope",
  WORK_ASSIGNMENT: "workAssignment",
  FEATURE_TYPE: "featureType",
  RAILROAD_NAME: "railroadName",
  SERVICE_STATUS: "serviceStatus",
  SEISMIC_VULNERABILITY: "seismicVulnerability",
  STREAM_CLASSIFICATION: "streamClassification",
  WATERFLOW_DIRECTION: "waterflowDirection",
  SEVERITY: "severity",
  FATIGUE_DETAILS: "fatigueDetails",
  SPECIAL_CONDITIONS: "specialCondition",
  AASHTO_IMPACT_CODE: "aashtoImpactCode",
  SCOUR_POA_REQUIRED: "scourPOARequired",
  SNBI_INSPECTION_TYPE: "snbiInspectionType",
  RBI_METHOD: "rbiMethod",
  INSPECTION_EQUIPMENT: "inspectionEquipment",
  COMPLEX_FEATURE: "complexFeaturePresent",
};

export const REFERENCE_TABLES = [
  REFERENCE_TABLE.APPROACH_SPAN_MATERIAL,
  REFERENCE_TABLE.APPROACH_ROADWAY_ALIGNMENT_APPRAISAL,
  REFERENCE_TABLE.BRIDGE_DECK_TYPE,
  REFERENCE_TABLE.BRIDGE_MEDIAN,
  REFERENCE_TABLE.BUS_PLAN_NETWORK,
  REFERENCE_TABLE.CONDITION_RATING,
  REFERENCE_TABLE.DECK_FORM_TYPE,
  REFERENCE_TABLE.DECK_MEMBRANE_TYPE,
  REFERENCE_TABLE.DECK_PROTECTION_TYPE,
  REFERENCE_TABLE.DECK_SURFACE_TYPE,
  REFERENCE_TABLE.DECK_STRUCTURE_TYPE,
  REFERENCE_TABLE.ENVIRONMENT,
  REFERENCE_TABLE.EXTENDED_INSPECTION_INTERVAL_ELIGIBILITY,
  REFERENCE_TABLE.FRACTURE_CRITICAL_FATIGUE_STRESS_CATEGORY,
  REFERENCE_TABLE.FIELD_COND,
  REFERENCE_TABLE.FRACTURE_CRITICAL_MEMBER_TYPE,
  REFERENCE_TABLE.HISTORICAL_SIGNIFIGANCE,
  REFERENCE_TABLE.INSPECTION_STATUS,
  REFERENCE_TABLE.INSPECTION_TYPE,
  REFERENCE_TABLE.MAIN_SPAN_DESIGN,
  REFERENCE_TABLE.MAIN_SPAN_MATERIAL,
  REFERENCE_TABLE.MAINTENENCE_RESPONSIBILITY,
  REFERENCE_TABLE.MATERIAL_STORED_UNDER,
  REFERENCE_TABLE.MATERIAL_MAKEUP,
  REFERENCE_TABLE.MEMBER_TYPE,
  REFERENCE_TABLE.PAINT_CONDITION_RATING,
  REFERENCE_TABLE.PAINT_CONDITION_RATING_EXTENT,
  REFERENCE_TABLE.PHYSICAL_MAKEUP,
  REFERENCE_TABLE.PLUG_WELD_PROBLEM_TYPE,
  REFERENCE_TABLE.POST_REASON,
  REFERENCE_TABLE.POSTING_STATUS,
  REFERENCE_TABLE.RELIEF_JOINT,
  REFERENCE_TABLE.REPORTING_GROUP,
  REFERENCE_TABLE.SIGN_TYPE,
  REFERENCE_TABLE.SIGN_CONDITION_RAITING,
  REFERENCE_TABLE.SERVICE_TYPE_ON,
  REFERENCE_TABLE.SPAN_INTERACTION,
  REFERENCE_TABLE.SPECIAL_RESTRICT_POST,
  REFERENCE_TABLE.STRUCTURAL_CONFIGURATION,
  REFERENCE_TABLE.STRUCTURAL_CONFIGURATION_OF_APPROACH_SPANS,
  REFERENCE_TABLE.STRUCTURE_FLARED,
  REFERENCE_TABLE.SUB_AGENCY,
  REFERENCE_TABLE.SERVICE_TYPE_UNDER,
  REFERENCE_TABLE.TEMPORARY_STRUCTURE,
  REFERENCE_TABLE.WEATHER_CONDITION,
  REFERENCE_TABLE.TEAM_LEADER,
  REFERENCE_TABLE.INSPECTION_PERFORMED_BY,
  REFERENCE_TABLE.INSPECTION_INTERVAL_CONCURRENCE,
  REFERENCE_TABLE.RAILING_RATING,
  REFERENCE_TABLE.TRANSITION_RATING,
  REFERENCE_TABLE.APPROACH_GUIDE_RAIL_RATING,
  REFERENCE_TABLE.APPROACH_RAIL_END_RATING,
  REFERENCE_TABLE.APPROACH_SPAN_DESIGN,
  REFERENCE_TABLE.OWNER,
  REFERENCE_TABLE.NBIS_BRIDGE_LENGTH,
  REFERENCE_TABLE.FC_GROUP_NUMBER,
  REFERENCE_TABLE.CRF_FATIGUE_SUSCEPTIBILITY,
  REFERENCE_TABLE.CRF_MATERIAL,
  REFERENCE_TABLE.CUMULATIVE_AVERAGE_DAILY_TRUCK_TRAFFIC,
  REFERENCE_TABLE.STRUCTURE_UNIT_TYPE,
  REFERENCE_TABLE.CONSULTANT_HIRED_BY,
  REFERENCE_TABLE.INACCESSIBLE_AREA,
  REFERENCE_TABLE.PIER_ABUTMENT_TYPE,
  REFERENCE_TABLE.PA_FOUNDATION_TYPE,
  REFERENCE_TABLE.STREAM_BED_MATERIAL,
  REFERENCE_TABLE.OBSERVED_SCOUR_RATING,
  REFERENCE_TABLE.SCOUR_CONDITION_RATING,
  REFERENCE_TABLE.SCOUR_VULNERABILITY,
  REFERENCE_TABLE.UNDERWATER_INSPECTION_TYPE,
  REFERENCE_TABLE.CHANGE_SINCE_LAST_INSP,
  REFERENCE_TABLE.SCOUR_HOLE,
  REFERENCE_TABLE.DEBRIS_POTENTIAL,
  REFERENCE_TABLE.SUBSTRUCTURE_SCOURABILITY,
  REFERENCE_TABLE.OPENING_ADEQUACY_CHANNEL,
  REFERENCE_TABLE.SEDIMENT_DEPOSITS,
  REFERENCE_TABLE.ALIGNMENT,
  REFERENCE_TABLE.VELOCITY_STREAM_SLOPE,
  REFERENCE_TABLE.JOINT_CONDITION_RATING,
  REFERENCE_TABLE.JOINT_TYPE,
  REFERENCE_TABLE.JOINT_MOVEMENT,
  REFERENCE_TABLE.JOINT_MANUFACTURER,
  REFERENCE_TABLE.JOINT_REPLACEMENT_REASON,
  REFERENCE_TABLE.BEARING_CONDITION_RATING,
  REFERENCE_TABLE.BEARING_TYPE,
  REFERENCE_TABLE.BEARING_SUBSTRUCTURE_UNIT,
  REFERENCE_TABLE.BEARING_MOVEMENT,
  REFERENCE_TABLE.BEARING_CORROSION,
  REFERENCE_TABLE.BEARING_ALLIGNMENT_ISSUES,
  REFERENCE_TABLE.BEARING_ANCHOR_BOLT,
  REFERENCE_TABLE.BEARING_LOSS_AREA,
  REFERENCE_TABLE.BEARING_REPLACEMENT_REASON,
  REFERENCE_TABLE.REVIEWER_ACTION,
  REFERENCE_TABLE.LOAD_TYPE,
  REFERENCE_TABLE.NBI_RATING,
  REFERENCE_TABLE.LOAD_RATING_METHOD,
  REFERENCE_TABLE.CONTROLLING_MEMBER_TYPE,
  REFERENCE_TABLE.CONTROLLING_MEMBER_FATIGUE_STRESS_CATEGORY,
  REFERENCE_TABLE.FATIGUE_LOAD_TYPE,
  REFERENCE_TABLE.GOVERNING_CRITERIA,
  REFERENCE_TABLE.DESIGN_LOAD,
  REFERENCE_TABLE.POSTING,
  REFERENCE_TABLE.POSTING_TYPE,
  REFERENCE_TABLE.POSTING_CONDITION,
  REFERENCE_TABLE.VEHICLE_POSTING_TYPE,
  REFERENCE_TABLE.YES_NO,
  REFERENCE_TABLE.TUNNEL_LOAD_RATING_METHOD,
  REFERENCE_TABLE.CULVERT_OPENING_TYPE,
  REFERENCE_TABLE.CULVERT_TIE_TYPE,
  REFERENCE_TABLE.CULVERT_FLOOR_TYPE,
  REFERENCE_TABLE.SIGN_LIGHT_CONDITION_RATING,
  REFERENCE_TABLE.SIGN_ASSET_TAG,
  REFERENCE_TABLE.WALL_CONDITION_RATING,
  REFERENCE_TABLE.BACKFILL_MATERIAL,
  REFERENCE_TABLE.TUNNEL_SHAPE,
  REFERENCE_TABLE.PORTAL_SHAPE,
  REFERENCE_TABLE.GROUND_CONDITION,
  REFERENCE_TABLE.COMPLEX,
  REFERENCE_TABLE.SERVICE_IN_TUNNEL,
  REFERENCE_TABLE.DIRECTION_OF_TRAVEL,
  REFERENCE_TABLE.TOLL,
  REFERENCE_TABLE.TUNNEL_FUNCTIONAL_CLASSIFICATION,
  REFERENCE_TABLE.URBAN_CODE,
  REFERENCE_TABLE.IN_DEPTH,
  REFERENCE_TABLE.SCOUR_CRITICAL_BRIDGE_INDICATOR,
  REFERENCE_TABLE.SCOUR_CRITICAL_BRIDGE_INDICATOR_SOURCE,
  REFERENCE_TABLE.ABUTMENT_LOCATION,
  REFERENCE_TABLE.WINGWALL_PRESENCE,
  REFERENCE_TABLE.UPSTREAM_WINGWALL_CONDITION,
  REFERENCE_TABLE.CURRENT_COUNTERMEASURE_TYPE,
  REFERENCE_TABLE.CURRENT_COUNTERMEASURE_LOCATION,
  REFERENCE_TABLE.CURRENT_COUNTERMEASURE_CONDITION,
  REFERENCE_TABLE.WORK_CANDIDATE,
  REFERENCE_TABLE.OVERTOPPING_RISK,
  REFERENCE_TABLE.TRAFFIC_DELAY,
  REFERENCE_TABLE.METHOD1_ELIGIBILITY,
  REFERENCE_TABLE.MINIMUM_UNDERCLEARANCE,
  REFERENCE_TABLE.NAVIGATION_CONTROL,
  REFERENCE_TABLE.NSTM_DETAIL_PRESENT,
  REFERENCE_TABLE.NSTM_PRESENT,
  REFERENCE_TABLE.OSA_FOUNDATION_TYPE,
  REFERENCE_TABLE.ON_UNDER,
  REFERENCE_TABLE.ROUTE_DIRECTION,
  REFERENCE_TABLE.ROADWAY_FUNCTIONAL_CLASSIFICATION,
  REFERENCE_TABLE.WORST_FLOOD_EVENT,
  REFERENCE_TABLE.BRIDGE_CONDITION_RATING,
  REFERENCE_TABLE.NATIONAL_HIGHWAY_SYSTEM,
  REFERENCE_TABLE.VERTICAL_CLEARANCE_SIGN,
  REFERENCE_TABLE.INSPECTION_COMMENT_TYPE,
  REFERENCE_TABLE.SUBSTRUCTURE_COMMENT_TYPE,
  REFERENCE_TABLE.AGENCY_PRIORITY,
  REFERENCE_TABLE.MAINTENANCE_ACTION,
  REFERENCE_TABLE.AGENCY_STATUS,
  REFERENCE_TABLE.ACTION_SCOPE,
  REFERENCE_TABLE.WORK_ASSIGNMENT,
  REFERENCE_TABLE.FEATURE_TYPE,
  REFERENCE_TABLE.RAILROAD_NAME,
  REFERENCE_TABLE.SERVICE_STATUS,
  REFERENCE_TABLE.SEISMIC_VULNERABILITY,
  REFERENCE_TABLE.STREAM_CLASSIFICATION,
  REFERENCE_TABLE.WATERFLOW_DIRECTION,
  REFERENCE_TABLE.SEVERITY,
  REFERENCE_TABLE.FATIGUE_DETAILS,
  REFERENCE_TABLE.SPECIAL_CONDITIONS,
  REFERENCE_TABLE.AASHTO_IMPACT_CODE,
  REFERENCE_TABLE.SCOUR_POA_REQUIRED,
  REFERENCE_TABLE.SNBI_INSPECTION_TYPE,
  REFERENCE_TABLE.RBI_METHOD,
  REFERENCE_TABLE.INSPECTION_EQUIPMENT,
  REFERENCE_TABLE.COMPLEX_FEATURE,
];
