import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import {
  INSPECTION_COMMENTS,
  SUB_STRUCTURE_UNIT_KEY,
  STRUCTURE_UNIT_TYPE,
} from "@/constants/Inspections";
import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { styles } from "@/reports/reportStyles";
import { clone } from "@/util/clone";
import { useInspectionStore } from "@/stores/inspection";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { SORT_ORDER, TYPES } from "@/constants/CommonWebConstants";
import { CONFIG_SORT_BY } from "@/constants/EnvConfigProperties";
let structure = null;
function setSubstructureDocDefinition(structureValue) {
  structure = structureValue;
  //common code
  const inspectionStore = useInspectionStore();
  const configStore = useConfigStore();
  const pierAndAbutTypes = configStore.getReferenceValues(
    REFERENCE_TABLE.PIER_ABUTMENT_TYPE,
    CONFIG_SORT_BY.VALUE,
    SORT_ORDER.ASC,
    false,
    TYPES.NUMBER
  );
  //nearAbutmentCode
  const nearAbutmentSubstructureUnitKey =
    inspectionStore.getSubStructureUnitKey(
      SUB_STRUCTURE_UNIT_KEY.NEAR_ABUTMENT
    );
  const nearAbutmentStructureUnit = getStructureUnit(
    nearAbutmentSubstructureUnitKey
  );
  const nearAbutmentUnderwaterInsp = getUnderwaterInsp(
    nearAbutmentSubstructureUnitKey
  );
  let nearAbutmentFatigueTable = [];

  nearAbutmentFatigueTable.push(nstmFatigueTable());

  const nearAbutmentFatigue = getNSTMFatigue(nearAbutmentSubstructureUnitKey);

  nearAbutmentFatigueTable = setupFatigueTables(
    nearAbutmentFatigue,
    nearAbutmentFatigueTable,
    configStore,
    findLocation
  );
  //farAbutmentCode
  const farAbutmentSubstructureUnitKey = inspectionStore.getSubStructureUnitKey(
    SUB_STRUCTURE_UNIT_KEY.FAR_ABUTMENT
  );
  const farAbutmentStructureUnit = getStructureUnit(
    farAbutmentSubstructureUnitKey
  );
  const farAbutmentUnderwaterInsp = getUnderwaterInsp(
    farAbutmentSubstructureUnitKey
  );
  let farAbutmentFatigueTable = [];

  farAbutmentFatigueTable.push(nstmFatigueTable());

  const farAbutmentFatigue = getNSTMFatigue(farAbutmentSubstructureUnitKey);

  farAbutmentFatigueTable = setupFatigueTables(
    farAbutmentFatigue,
    farAbutmentFatigueTable,
    configStore,
    findLocation
  );

  //pierCode
  function getPierFatigue(structureUnitKey) {
    let pierFatigueTable = [];

    pierFatigueTable.push(nstmFatigueTable());

    const pierFatigue = getNSTMFatigue(structureUnitKey);
    pierFatigueTable = setupFatigueTables(
      pierFatigue,
      pierFatigueTable,
      configStore,
      findLocation
    );
    return pierFatigueTable;
  }

  let docDefinition = {
    header: function () {
      return getReportHeader("Substructure Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "Substructure Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Substructure Conditions",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        columns: [
          {
            width: "30%",
            text: "1A02 Substructure Condition Rating: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              structure?.InspEvnt?.SUBRATING
            ),
            margin: [5, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Substructure Notes: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: structure?.T_Insp_Comment?.find(
              (element) =>
                element?.COMMENT_TYPE == INSPECTION_COMMENTS.SUBSTRUCTURE
            )?.NOTES,
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Near Abutment",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Near Abutment Inspection Notes",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Backwall: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: getComment(
              nearAbutmentSubstructureUnitKey,
              INSPECTION_COMMENTS.BACKWALL
            ),
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Bridge Seats: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: getComment(
              nearAbutmentSubstructureUnitKey,
              INSPECTION_COMMENTS.BRIDGE_SEATS
            ),
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Cheekwalls: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: getComment(
              nearAbutmentSubstructureUnitKey,
              INSPECTION_COMMENTS.CHEEKWALLS
            ),
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Stem: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: getComment(
              nearAbutmentSubstructureUnitKey,
              INSPECTION_COMMENTS.STEM
            ),
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Wings: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: getComment(
              nearAbutmentSubstructureUnitKey,
              INSPECTION_COMMENTS.WINGS
            ),
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Footing: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: getComment(
              nearAbutmentSubstructureUnitKey,
              INSPECTION_COMMENTS.FOOTING
            ),
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Piles: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: getComment(
              nearAbutmentSubstructureUnitKey,
              INSPECTION_COMMENTS.PILES
            ),
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Settlement: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: getComment(
              nearAbutmentSubstructureUnitKey,
              INSPECTION_COMMENTS.SETTLEMENT
            ),
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Embank Slope-Wall: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: getComment(
              nearAbutmentSubstructureUnitKey,
              INSPECTION_COMMENTS.EMBANK_SLOPE_WALL
            ),
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Wall Drainage: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: getComment(
              nearAbutmentSubstructureUnitKey,
              INSPECTION_COMMENTS.WALL_DRAINAGE
            ),
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Near Abutment - Waterway",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN01 Sub Unit: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: getSubUnit(
              nearAbutmentStructureUnit?.STRUNITKEY,
              inspectionStore
            ),
            margin: [0, 5, 0, 0],
          },
          {
            width: "30%",
            text: "IN12 Pier/Abutment Type: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
          {
            width: "*",
            text: pierAndAbutTypes?.find((type) => {
              return type?.value == nearAbutmentUnderwaterInsp?.SUBUNIT_TYPE;
            })?.title,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN02 Current Inspection? ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: inspectionStore.getReportCheckbox(
              nearAbutmentUnderwaterInsp?.PREVIOUS_CURRENT
            ),
            style: "customFontAwesome",
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN13 Inv Foundation Type: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.PA_FOUNDATION_TYPE,
              nearAbutmentUnderwaterInsp?.INV_FOUND_TYPE
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IU27 SCBI Code: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: nearAbutmentUnderwaterInsp?.SCBI_CODE,
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN14 OSA Foundation Type: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.OSA_FOUNDATION_TYPE,
              nearAbutmentUnderwaterInsp?.FOUND_TYPE
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IU28 SCBI Case: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: nearAbutmentUnderwaterInsp?.SCBI_CASE,
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN15 Streambed Material: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.STREAM_BED_MATERIAL,
              nearAbutmentUnderwaterInsp?.STREAM_BED_MATERIAL
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN03 Scour Rating: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.OBSERVED_SCOUR_RATING,
              nearAbutmentUnderwaterInsp?.OBS_SCOUR_RATING
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN16 UW Inspection Type: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.UNDERWATER_INSPECTION_TYPE,
              nearAbutmentUnderwaterInsp?.UNDERWATER_INSP_TYPE
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN04 Change Since Last Insp: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CHANGE_SINCE_LAST_INSP,
              nearAbutmentUnderwaterInsp?.CHG_SINCE_LAST_INSP
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN17 Observed Scour Depth: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: getDecimalString(
              nearAbutmentUnderwaterInsp?.OBS_SCOUR_DEPTH,
              1
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN05 Scour Hole: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SCOUR_HOLE,
              nearAbutmentUnderwaterInsp?.SCOUR_HOLE
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN18 Water Depth: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: getDecimalString(
              nearAbutmentUnderwaterInsp?.MAX_WATER_DEPTH,
              1
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN06 Debris Potential: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.DEBRIS_POTENTIAL,
              nearAbutmentUnderwaterInsp?.DEBRIS_POTENTIAL
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN19 Movement Indicator: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: inspectionStore.getReportCheckbox(
              nearAbutmentUnderwaterInsp?.MOVEMENT
            ),
            style: "customFontAwesome",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN07 Scourability: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SUBSTRUCTURE_SCOURABILITY,
              nearAbutmentUnderwaterInsp?.SUB_SCOUR
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN20 Scour/Undermine Indicator: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: inspectionStore.getReportCheckbox(
              nearAbutmentUnderwaterInsp?.SCOUR_UNDERMINE
            ),
            style: "customFontAwesome",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN08 Opening Adeq Channel: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.OPENING_ADEQUACY_CHANNEL,
              nearAbutmentUnderwaterInsp?.OPEN_ADEQ_CHANNEL
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN21 Countermeasures: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: inspectionStore.getReportCheckbox(
              nearAbutmentUnderwaterInsp?.COUNTERMEASURES
            ),
            style: "customFontAwesome",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN09 Sediment: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SEDIMENT_DEPOSITS,
              nearAbutmentUnderwaterInsp?.SED_DEPOSIT
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN22 100yr Flood Scour Depth: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: getDecimalString(
              nearAbutmentUnderwaterInsp?.CALC_SCOUR_DEPTH_100,
              1
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN10 Alignment: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.ALIGNMENT,
              nearAbutmentUnderwaterInsp?.ALIGNMENT
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN23 500yr Flood Scour Depth: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: getDecimalString(
              nearAbutmentUnderwaterInsp?.CALC_SCOUR_DEPTH_500,
              1
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN11 Velocity Stream Slope: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.VELOCITY_STREAM_SLOPE,
              nearAbutmentUnderwaterInsp?.VELO_STREAM_SLOPE
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN25 In 500yr Flood Plain? ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: inspectionStore.getReportCheckbox(
              nearAbutmentStructureUnit?.IN_500YR_FP
            ),
            style: "customFontAwesome",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN24 Notes: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: nearAbutmentUnderwaterInsp?.UNDERWATER_INSP_DESC,
          },
        ],
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  if (nearAbutmentFatigue) {
    docDefinition.content.push(
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Near Abutment - NSTM/Fatigue",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        table: {
          headerRows: 1,
          widths: ["5%", "15%", "15%", "20%", "*", "*"],
          body: nearAbutmentFatigueTable,
        },
        layout: {
          paddingLeft: () => 0,
          paddingRight: () => 0,
          paddingTop: () => 0,
          paddingBottom: () => 0,
        },
      }
    );
  }
  docDefinition.content.push(
    {
      table: {
        headerRows: 0,
        widths: ["100%"],
        body: [
          [
            {
              text: "Far Abutment",
              fillColor: "#ebebeb",
              alignment: "center",
              style: ["sectionHeader"],
            },
          ],
        ],
      },
      layout: "noBorders",
      margin: [0, 5, 0, 0],
    },
    {
      table: {
        headerRows: 0,
        widths: ["100%"],
        body: [
          [
            {
              text: "Far Abutment Inspection Notes",
              fillColor: "#ebebeb",
              alignment: "center",
              style: ["sectionHeader"],
            },
          ],
        ],
      },
      layout: "noBorders",
      margin: [0, 5, 0, 0],
    },
    {
      columns: [
        {
          width: "25%",
          text: "Backwall: ",
          bold: true,
          margin: [0, 5, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          text: getComment(
            farAbutmentSubstructureUnitKey,
            INSPECTION_COMMENTS.BACKWALL
          ),
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "Bridge Seats: ",
          bold: true,
          margin: [0, 5, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          text: getComment(
            farAbutmentSubstructureUnitKey,
            INSPECTION_COMMENTS.BRIDGE_SEATS
          ),
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "Cheekwalls: ",
          bold: true,
          margin: [0, 5, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          text: getComment(
            farAbutmentSubstructureUnitKey,
            INSPECTION_COMMENTS.CHEEKWALLS
          ),
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "Stem: ",
          bold: true,
          margin: [0, 5, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          text: getComment(
            farAbutmentSubstructureUnitKey,
            INSPECTION_COMMENTS.STEM
          ),
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "Wings: ",
          bold: true,
          margin: [0, 5, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          text: getComment(
            farAbutmentSubstructureUnitKey,
            INSPECTION_COMMENTS.WINGS
          ),
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "Footing: ",
          bold: true,
          margin: [0, 5, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          text: getComment(
            farAbutmentSubstructureUnitKey,
            INSPECTION_COMMENTS.FOOTING
          ),
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "Piles: ",
          bold: true,
          margin: [0, 5, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          text: getComment(
            farAbutmentSubstructureUnitKey,
            INSPECTION_COMMENTS.PILES
          ),
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "Settlement: ",
          bold: true,
          margin: [0, 5, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          text: getComment(
            farAbutmentSubstructureUnitKey,
            INSPECTION_COMMENTS.SETTLEMENT
          ),
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "Embank Slope-Wall: ",
          bold: true,
          margin: [0, 5, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          text: getComment(
            farAbutmentSubstructureUnitKey,
            INSPECTION_COMMENTS.EMBANK_SLOPE_WALL
          ),
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "Wall Drainage: ",
          bold: true,
          margin: [0, 5, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          text: getComment(
            farAbutmentSubstructureUnitKey,
            INSPECTION_COMMENTS.WALL_DRAINAGE
          ),
        },
      ],
    },
    {
      table: {
        headerRows: 0,
        widths: ["100%"],
        body: [
          [
            {
              text: "Far Abutment - Waterway",
              fillColor: "#ebebeb",
              alignment: "center",
              style: ["sectionHeader"],
            },
          ],
        ],
      },
      layout: "noBorders",
    },
    {
      columns: [
        {
          width: "25%",
          text: "IN01 Sub Unit: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: getSubUnit(
            farAbutmentStructureUnit?.STRUNITKEY,
            inspectionStore
          ),
          margin: [0, 3, 0, 0],
        },
        {
          width: "30%",
          text: "IN12 Pier/Abutment Type: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: pierAndAbutTypes?.find((type) => {
            return type?.value == farAbutmentUnderwaterInsp?.SUBUNIT_TYPE;
          })?.title,
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "IN02 Current Inspection? ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: inspectionStore.getReportCheckbox(
            farAbutmentUnderwaterInsp?.PREVIOUS_CURRENT
          ),
          style: "customFontAwesome",
          margin: [0, 3, 0, 0],
        },
        {
          width: "30%",
          text: "IN13 Inv Foundation Type: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.PA_FOUNDATION_TYPE,
            farAbutmentUnderwaterInsp?.INV_FOUND_TYPE
          ),
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "IU27 SCBI Code: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: farAbutmentUnderwaterInsp?.SCBI_CODE,
          margin: [0, 3, 0, 0],
        },
        {
          width: "30%",
          text: "IN14 OSA Foundation Type: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.OSA_FOUNDATION_TYPE,
            farAbutmentUnderwaterInsp?.FOUND_TYPE
          ),
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "IU28 SCBI Case: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: farAbutmentUnderwaterInsp?.SCBI_CASE,
          margin: [0, 3, 0, 0],
        },
        {
          width: "30%",
          text: "IN15 Streambed Material: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.STREAM_BED_MATERIAL,
            farAbutmentUnderwaterInsp?.STREAM_BED_MATERIAL
          ),
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "IN03 Scour Rating: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.OBSERVED_SCOUR_RATING,
            farAbutmentUnderwaterInsp?.OBS_SCOUR_RATING
          ),
          margin: [0, 3, 0, 0],
        },
        {
          width: "30%",
          text: "IN16 UW Inspection Type: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.UNDERWATER_INSPECTION_TYPE,
            farAbutmentUnderwaterInsp?.UNDERWATER_INSP_TYPE
          ),
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "IN04 Change Since Last Insp: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.CHANGE_SINCE_LAST_INSP,
            farAbutmentUnderwaterInsp?.CHG_SINCE_LAST_INSP
          ),
          margin: [0, 3, 0, 0],
        },
        {
          width: "30%",
          text: "IN17 Observed Scour Depth: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: getDecimalString(farAbutmentUnderwaterInsp?.OBS_SCOUR_DEPTH, 1),
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "IN05 Scour Hole: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.SCOUR_HOLE,
            farAbutmentUnderwaterInsp?.SCOUR_HOLE
          ),
          margin: [0, 3, 0, 0],
        },
        {
          width: "30%",
          text: "IN18 Water Depth: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: getDecimalString(farAbutmentUnderwaterInsp?.MAX_WATER_DEPTH, 1),
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "IN06 Debris Potential: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.DEBRIS_POTENTIAL,
            farAbutmentUnderwaterInsp?.DEBRIS_POTENTIAL
          ),
          margin: [0, 3, 0, 0],
        },
        {
          width: "30%",
          text: "IN19 Movement Indicator: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: inspectionStore.getReportCheckbox(
            farAbutmentUnderwaterInsp?.MOVEMENT
          ),
          style: "customFontAwesome",
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "IN07 Scourability: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.SUBSTRUCTURE_SCOURABILITY,
            farAbutmentUnderwaterInsp?.SUB_SCOUR
          ),
          margin: [0, 3, 0, 0],
        },
        {
          width: "30%",
          text: "IN20 Scour/Undermine Indicator: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: inspectionStore.getReportCheckbox(
            farAbutmentUnderwaterInsp?.SCOUR_UNDERMINE
          ),
          style: "customFontAwesome",
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "IN08 Opening Adeq Channel: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.OPENING_ADEQUACY_CHANNEL,
            farAbutmentUnderwaterInsp?.OPEN_ADEQ_CHANNEL
          ),
          margin: [0, 3, 0, 0],
        },
        {
          width: "30%",
          text: "IN21 Countermeasures: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: inspectionStore.getReportCheckbox(
            farAbutmentUnderwaterInsp?.COUNTERMEASURES
          ),
          style: "customFontAwesome",
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "IN09 Sediment: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.SEDIMENT_DEPOSITS,
            farAbutmentUnderwaterInsp?.SED_DEPOSIT
          ),
          margin: [0, 3, 0, 0],
        },
        {
          width: "30%",
          text: "IN22 100yr Flood Scour Depth: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: getDecimalString(
            farAbutmentUnderwaterInsp?.CALC_SCOUR_DEPTH_100,
            1
          ),
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "IN10 Alignment: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.ALIGNMENT,
            farAbutmentUnderwaterInsp?.ALIGNMENT
          ),
          margin: [0, 3, 0, 0],
        },
        {
          width: "30%",
          text: "IN23 500yr Flood Scour Depth: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: getDecimalString(
            farAbutmentUnderwaterInsp?.CALC_SCOUR_DEPTH_500,
            1
          ),
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "IN11 Velocity Stream Slope: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.VELOCITY_STREAM_SLOPE,
            farAbutmentUnderwaterInsp?.VELO_STREAM_SLOPE
          ),
          margin: [0, 3, 0, 0],
        },
        {
          width: "30%",
          text: "IN25 In 500yr Flood Plain? ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
        {
          width: "*",
          text: inspectionStore.getReportCheckbox(
            farAbutmentStructureUnit?.IN_500YR_FP
          ),
          style: "customFontAwesome",
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "25%",
          text: "IN24 Notes: ",
          bold: true,
          margin: [0, 3, 0, 0],
        },
      ],
    },
    {
      columns: [
        {
          width: "*",
          text: farAbutmentUnderwaterInsp?.UNDERWATER_INSP_DESC,
        },
      ],
    }
  );
  if (farAbutmentFatigue) {
    docDefinition.content.push(
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Far Abutment - NSTM/Fatigue",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        table: {
          headerRows: 1,
          widths: ["5%", "15%", "15%", "20%", "*", "*"],
          body: farAbutmentFatigueTable,
        },
        layout: {
          paddingLeft: () => 0,
          paddingRight: () => 0,
          paddingTop: () => 0,
          paddingBottom: () => 0,
        },
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Piers",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      }
    );
  }
  let floodPlains = getFloodPlains(inspectionStore);
  let piers = getPiers();
  for (let pier of piers) {
    let floodPlain = floodPlains?.find(
      (a) => a?.STRUNITKEY == pier?.STRUNITKEY
    );
    docDefinition.content.push(
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Pier " + getPierNumber(pier),
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Pier " + getPierNumber(pier) + " Inspection Notes",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Condition Summary: ",
            bold: true,
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: getComment(
              pier?.STRUNITKEY,
              INSPECTION_COMMENTS.CONDITION_SUMMARY_PIER
            ),
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Bridge Seats: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: getComment(
              pier?.STRUNITKEY,
              INSPECTION_COMMENTS.BRIDGE_SEATS_PIER
            ),
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Cheekwalls: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: getComment(
              pier?.STRUNITKEY,
              INSPECTION_COMMENTS.CHEEKWALLS_PIER
            ),
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Columns/Stems: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: getComment(
              pier?.STRUNITKEY,
              INSPECTION_COMMENTS.COLUMNS_STEMS_PIER
            ),
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "Settlement: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: getComment(
              pier?.STRUNITKEY,
              INSPECTION_COMMENTS.SETTLEMENT_PIER
            ),
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "Pier " + getPierNumber(pier) + " - Waterway",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN01 Sub Unit: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: floodPlain
              ? getSubUnit(floodPlain?.STRUNITKEY, inspectionStore)
              : "",
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN12 Pier/Abutment Type: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: pierAndAbutTypes?.find((type) => {
              return type?.value == floodPlain?.SUBUNIT_TYPE;
            })?.title,
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN02 Current Inspection? ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: inspectionStore.getReportCheckbox(
              floodPlain?.PREVIOUS_CURRENT
            ),
            style: "customFontAwesome",
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN13 Inv Foundation Type: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.PA_FOUNDATION_TYPE,
              floodPlain?.INV_FOUND_TYPE
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IU27 SCBI Code: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: floodPlain?.SCBI_CODE,
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN14 OSA Foundation Type: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.OSA_FOUNDATION_TYPE,
              floodPlain?.FOUND_TYPE
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IU28 SCBI Case: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: floodPlain?.SCBI_CASE,
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN15 Streambed Material: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.STREAM_BED_MATERIAL,
              floodPlain?.STREAM_BED_MATERIAL
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN03 Scour Rating: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.OBSERVED_SCOUR_RATING,
              floodPlain?.OBS_SCOUR_RATING
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN16 UW Inspection Type: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.UNDERWATER_INSPECTION_TYPE,
              floodPlain?.UNDERWATER_INSP_TYPE
            ),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN04 Change Since Last Insp: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CHANGE_SINCE_LAST_INSP,
              floodPlain?.CHG_SINCE_LAST_INSP
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN17 Observed Scour Depth: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: getDecimalString(floodPlain?.OBS_SCOUR_DEPTH, 1),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN05 Scour Hole: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SCOUR_HOLE,
              floodPlain?.SCOUR_HOLE
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN18 Water Depth: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: getDecimalString(floodPlain?.MAX_WATER_DEPTH, 1),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN06 Debris Potential: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.DEBRIS_POTENTIAL,
              floodPlain?.DEBRIS_POTENTIAL
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN19 Movement Indicator: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: inspectionStore.getReportCheckbox(floodPlain?.MOVEMENT),
            style: "customFontAwesome",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN07 Scourability: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SUBSTRUCTURE_SCOURABILITY,
              floodPlain?.SUB_SCOUR
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN20 Scour/Undermine Indicator: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: inspectionStore.getReportCheckbox(
              floodPlain?.SCOUR_UNDERMINE
            ),
            style: "customFontAwesome",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN08 Opening Adeq Channel: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.OPENING_ADEQUACY_CHANNEL,
              floodPlain?.OPEN_ADEQ_CHANNEL
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN21 Countermeasures: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: inspectionStore.getReportCheckbox(
              floodPlain?.COUNTERMEASURES
            ),
            style: "customFontAwesome",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN09 Sediment: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.SEDIMENT_DEPOSITS,
              floodPlain?.SED_DEPOSIT
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN22 100yr Flood Scour Depth: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: getDecimalString(floodPlain?.CALC_SCOUR_DEPTH_100, 1),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN10 Alignment: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.ALIGNMENT,
              floodPlain?.ALIGNMENT
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN23 500yr Flood Scour Depth: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: getDecimalString(floodPlain?.CALC_SCOUR_DEPTH_500, 1),
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN11 Velocity Stream Slope: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.VELOCITY_STREAM_SLOPE,
              floodPlain?.VELO_STREAM_SLOPE
            ),
            margin: [0, 3, 0, 0],
          },
          {
            width: "30%",
            text: "IN25 In 500yr Flood Plain? ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
          {
            width: "*",
            text: inspectionStore.getReportCheckbox(pier?.IN_500YR_FP),
            style: "customFontAwesome",
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "IN24 Notes: ",
            bold: true,
            margin: [0, 3, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            width: "*",
            text: floodPlain?.UNDERWATER_INSP_DESC,
          },
        ],
      }
    );
    if (getNSTMFatigue(pier?.STRUNITKEY)) {
      docDefinition.content.push(
        {
          table: {
            headerRows: 0,
            widths: ["100%"],
            body: [
              [
                {
                  text: "Pier " + getPierNumber(pier) + " - NSTM/Fatigue",
                  fillColor: "#ebebeb",
                  alignment: "center",
                  style: ["sectionHeader"],
                },
              ],
            ],
          },
          layout: "noBorders",
        },
        {
          table: {
            headerRows: 1,
            widths: ["5%", "15%", "15%", "20%", "*", "*"],
            body: getPierFatigue(pier?.STRUNITKEY),
          },
          layout: {
            paddingLeft: () => 0,
            paddingRight: () => 0,
            paddingTop: () => 0,
            paddingBottom: () => 0,
          },
        }
      );
    }
  }

  return docDefinition;
}
function getLocations() {
  const inspectionStore = useInspectionStore();
  return inspectionStore
    .getFilteredStructureUnits(
      [
        STRUCTURE_UNIT_TYPE.MAIN,
        STRUCTURE_UNIT_TYPE.APPROACH,
        STRUCTURE_UNIT_TYPE.PIER,
      ],
      true
    )
    ?.map((b) => ({
      title: `${b?.STRUNITKEY} - ${b?.STRUNITLABEL}`,
      value: b?.STRUNITKEY,
    }))
    .sort((a, b) => a?.value - b?.value);
}
function findLocation(value) {
  const locations = getLocations();
  return locations?.find((a) => a.value === value)?.title;
}
function getStructureUnit(value) {
  return structure?.Structure_Unit?.find(
    (elementToFind) => elementToFind?.STRUNITKEY == value
  );
}
function getUnderwaterInsp(value) {
  return structure?.T_Underwater_Insp?.find(
    (element) => element?.STRUNITKEY == value
  );
}
function getNSTMFatigue(value) {
  return structure?.T_FC_Insp?.filter((a) => a?.STRUNITKEY === value)[0];
}
function getComment(unitKey, commentType) {
  return structure?.T_Struc_Unit_Insp_Comment?.find(
    (element) =>
      element?.STRUNITKEY == unitKey && element?.COMMENT_TYPE == commentType
  )?.NOTES;
}

function setupFatigueTables(fatigue, table, configStore, findLocation) {
  if (fatigue) {
    table.push([
      {
        text: fatigue?.FC_KEY,
        style: ["tableBody"],
      },
      {
        text: findLocation(fatigue?.STRUNITKEY),
        style: ["tableBody"],
      },
      {
        text: configStore.getReferenceValue(
          REFERENCE_TABLE.FRACTURE_CRITICAL_MEMBER_TYPE,
          fatigue?.FC_MEM_TYPE
        ),
        style: ["tableBody"],
      },
      {
        text: fatigue?.FC_DETAIL,
        style: ["tableBody"],
      },
      {
        text: configStore.getReferenceValue(
          REFERENCE_TABLE.FRACTURE_CRITICAL_FATIGUE_STRESS_CATEGORY,
          fatigue?.FATIG_STRESS_CAT,
          true
        ),
        style: ["tableBody"],
      },
      {
        text: fatigue?.NSTM_DETAIL_PRESENT,
        style: ["tableBody"],
      },
    ]);
    table.push([
      {
        colSpan: 6,
        table: {
          headerRows: 1,
          widths: ["34.7%", "*"],
          body: [
            [
              {
                text: "IF06\n Member Detail Condition",
                style: ["tableHeader"],
              },
              {
                text: "IF10\n Member Inspection Procedure",
                style: ["tableHeader"],
              },
            ],
            [
              {
                text: fatigue?.FC_DESC,
                style: ["tableBody"],
                alignment: "left",
              },
              {
                text: fatigue?.DETAIL_INSP_PROC,
                style: ["tableBody"],
                alignment: "left",
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (idx, innerNode) {
            return idx === 0 || idx === innerNode.table.body.length ? 0 : 1;
          },
          vLineWidth: function (idx, innerNode) {
            return idx == 0 || idx === innerNode.table.widths.length ? 0 : 1;
          },
        },
      },
    ]);
  }
  return table;
}

function nstmFatigueTable() {
  return [
    {
      text: "IF00\n Key",
      style: ["tableHeader"],
    },
    {
      text: "IF01\n Location",
      style: ["tableHeader"],
    },
    {
      text: "IF02\n Member Type",
      style: ["tableHeader"],
    },
    {
      text: "IF04\n Member Detail",
      style: ["tableHeader"],
    },
    {
      text: "IF05\n Member Stress Category",
      style: ["tableHeader"],
    },
    {
      text: "IF09\n Detail Type",
      style: ["tableHeader"],
    },
  ];
}

function getFloodPlains(inspectionStore) {
  let floodPlains = clone(structure.T_Underwater_Insp);

  if (!floodPlains) {
    //remove null values from array(if any)
    floodPlains = floodPlains?.filter((a) => !!a);
  }

  for (let idx = 1; idx <= floodPlains?.length; idx++) {
    floodPlains[idx - 1].UNIQUE_KEY = idx;
    floodPlains[idx - 1].SUB_UNIT = inspectionStore.getSubStructure(
      floodPlains[idx - 1]?.STRUNITKEY
    )?.STRUNITLABEL;
  }
  return floodPlains || [];
}

function getSubUnit(unitKey, store) {
  if (unitKey) {
    return unitKey + " - " + store.getSubStructure(unitKey)?.STRUNITLABEL;
  } else {
    return "";
  }
}

function getPiers() {
  let structureUnits = clone(structure?.Structure_Unit) || [];
  return sortPiers(structureUnits?.filter((p) => p.STRUNITTYPE == "P"));
}

function getPierNumber(pier) {
  if (pier?.STRUNITLABEL.includes("Pier")) {
    if (pier?.STRUNITLABEL.split(" ")[1].length == 1) {
      return "0" + pier?.STRUNITLABEL.split(" ")[1];
    } else {
      return pier?.STRUNITLABEL.split(" ")[1];
    }
  } else {
    return pier?.STRUNITLABEL.split("P")[1];
  }
}

function getPlaceholder(str) {
  let num = null;
  if (str?.includes("Pier")) {
    if (str?.split(" ")[1].length == 1) {
      num = "0" + str?.split(" ")[1];
    } else {
      num = str?.split(" ")[1];
    }
  } else {
    num = str?.split("P")[1];
  }

  return num?.charAt(0) === "0" ? parseInt(num?.charAt(1)) : parseInt(num);
}

function sortPiers(piers) {
  return piers?.sort((a, b) => {
    const aPlaceholder = getPlaceholder(a?.STRUNITLABEL);
    const bPlaceholder = getPlaceholder(b?.STRUNITLABEL);
    return aPlaceholder - bPlaceholder;
  });
}

function getDecimalString(value, decimals) {
  const configStore = useConfigStore();

  return value?.toString()?.length > 0
    ? parseFloat(
        configStore.getMetricToEnglishConversions(
          PAIR_CODE.METERS_TO_FEET,
          value
        )
      ).toFixed(decimals) + " ft."
    : "";
}

export { setSubstructureDocDefinition };
