export const CONFIG_ENDPOINT = {
  COUNTIES:
    import.meta.env.VITE_APP_APIM_CONTEXT + "/bms3-config-service/api/counties",
  DISTRICTS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/districts",
  ENV_CONFIG_VALUES:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/envConfigValues",
  MUNICIPALITIES:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/municipalities",
  REFERENCES:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/references",
  DEPARTMENT_STRUCTURE_TYPE_DESCRIPTIONS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/departmentStructureTypeDescriptions",
  METRIC_CONVERSIONS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/metricConversions",
  ELEMENT_DEFINITIONS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/elementDefinitions",
  ELEMENT_CHILDREN:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/elementChildren",
  ELEMENT_STATE_DEFINITIONS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/stateDefinitions",
  SCOUR_CRITICAL_BRIDGE_INDICATORS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/scourCriticalBridgeIndicators",
  USERS:
    import.meta.env.VITE_APP_APIM_CONTEXT + "/bms3-config-service/api/users",
  DOC_TYPES:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/documentTypes",
  PING_ACTUATOR:
    import.meta.env.VITE_APP_APIM_CONTEXT + "/bms3-config-service/actuator",
  FLEX_ACTIONS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/flexActions",
  BRIDGE_ACTIONS:
    import.meta.env.VITE_APP_APIM_CONTEXT + "/bms3-config-service/api/actions",
  CODE_FILTERS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/codeFilters",
  ORGANIZATIONS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/businessPartner",
  WEBUSERS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/bms2WebUser",
  NBIS_BRIDGE_INSPECTORS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/nbisBridgeInspectors",
  NBIS_NSTM_BRIDGE_INSPECTORS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/nbisNSTMBridgeInspectors",
  NBIS_TUNNEL_INSPECTORS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/nbisTunnelInspectors",
  INSPECTORS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/inspectors",
  UNDERWATER_DIVERS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-config-service/api/underwaterDivers",
};

export const DOCUMENT_ENDPOINT =
  import.meta.env.VITE_APP_APIM_CONTEXT +
  "/bms3-document-service/api/documents";
export const SUBMIT_PDF_REPORT_ENDPOINT =
  import.meta.env.VITE_APP_APIM_CONTEXT +
  "/bms3-document-service/api/documents/uploadD450?brkey={brkey}&inspkey={inspkey}&doclabel={doclabel}";
export const INSPECTION_ENDPOINT = {
  SUBMIT_INSPECTION:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-inspection-service/api/inspections",
  VALIDATE_INSPECTION:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-inspection-service/api/inspections/validate",
  SYNC_INSPECTION:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-inspection-service/api/inspections/data",
  LOAD_LATEST_INSPECTION:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-inspection-service/api/inspections/structures/{brkey}/latest",
  GET_INSPECTIONS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-inspection-service/api/inspections/structures/{brkey}",
  LOAD_INSPECTION:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-inspection-service/api/inspections/structures/{brkey}/inspections/{inspkey}",
  GET_INSP_KEYFIELDS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-inspection-service/api/inspections/structures/{brkey}/inspections/{inspkey}/keyfields",
};
export const STRUCTURE_ENDPOINT = {
  SEARCH_BY_BMSID:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-structure-service/api/structures/search/bmsid",
  SEARCH_BY_BRKEY:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-structure-service/api/structures/search/brkey",
  SEARCH_BY_PARAMETER:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-structure-service/api/structures/search/parameter",
  GET_ROUTES:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-structure-service/api/structures/routes",
  GET_AGREEMENT_CONTRACTS:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-structure-service/api/structures/agreementContracts",
  GET_REPORT_GROUP_BY_COUNTY:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-structure-service/api/structures/reportGroups",
};

export const ADMIN_ENDPOINT = {
  SAVE_POUCHDB_USER:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-admin-service/api/pouchDBUser",
  GET_POUCHDB_USER:
    import.meta.env.VITE_APP_APIM_CONTEXT +
    "/bms3-admin-service/api/pouchDBUser/{userId}",
};
