function buildFutureSchedule(structure) {
  return [
    {
      inspType: "1",
      show7A57Checkbox: false,
      showInterval: false,
      showInspDueDate: false,
      is7A57RequiredValue: null,
      lastInspectionDate: structure?.Snbi_Insp_Schedule?.[0]?.FT_PREVIOUS_DATE,
      inspectionInterval: 0,
      inspectionDueDate: null,
    },
    {
      inspType: "2",
      show7A57Checkbox: true,
      showInterval: true,
      showInspDueDate: true,
      is7A57RequiredValue: structure?.Snbi_Insp_Schedule?.[0]?.ROU_REQUIRED,
      lastInspectionDate: structure?.Snbi_Insp_Schedule?.[0]?.ROU_PREVIOUS_DATE,
      inspectionInterval: structure?.Snbi_Insp_Schedule?.[0]?.ROU_INTERVAL,
      inspectionDueDate: structure?.Snbi_Insp_Schedule?.[0]?.ROU_NEXT_DATE,
    },
    {
      inspType: "3",
      show7A57Checkbox: true,
      showInterval: true,
      showInspDueDate: true,
      is7A57RequiredValue: structure?.Snbi_Insp_Schedule[0]?.UW_REQUIRED,
      lastInspectionDate: structure?.Snbi_Insp_Schedule[0]?.UW_PREVIOUS_DATE,
      inspectionInterval: structure?.Snbi_Insp_Schedule[0]?.UW_INTERVAL,
      inspectionDueDate: structure?.Snbi_Insp_Schedule[0]?.UW_NEXT_DATE,
    },
    {
      inspType: "4",
      show7A57Checkbox: true,
      showInterval: true,
      showInspDueDate: true,
      is7A57RequiredValue: structure?.Snbi_Insp_Schedule[0]?.NSTM_REQUIRED,
      lastInspectionDate: structure?.Snbi_Insp_Schedule[0]?.NSTM_PREVIOUS_DATE,
      inspectionInterval: structure?.Snbi_Insp_Schedule[0]?.NSTM_INTERVAL,
      inspectionDueDate: structure?.Snbi_Insp_Schedule[0]?.NSTM_NEXT_DATE,
    },
    {
      inspType: "5",
      show7A57Checkbox: false,
      showInterval: false,
      showInspDueDate: false,
      is7A57RequiredValue: null,
      lastInspectionDate: structure?.Snbi_Insp_Schedule[0]?.DMG_PREVIOUS_DATE,
      inspectionInterval: 0,
      inspectionDueDate: null,
    },
    {
      inspType: "6",
      show7A57Checkbox: true,
      showInterval: true,
      showInspDueDate: true,
      is7A57RequiredValue: structure?.Snbi_Insp_Schedule[0]?.IND_REQUIRED,
      lastInspectionDate: structure?.Snbi_Insp_Schedule[0]?.IND_PREVIOUS_DATE,
      inspectionInterval: structure?.Snbi_Insp_Schedule[0]?.IND_INTERVAL,
      inspectionDueDate: structure?.Snbi_Insp_Schedule[0]?.IND_NEXT_DATE,
    },
    {
      inspType: "7",
      show7A57Checkbox: true,
      showInterval: true,
      showInspDueDate: true,
      is7A57RequiredValue: structure?.Snbi_Insp_Schedule[0]?.OS_REQUIRED,
      lastInspectionDate: structure?.Snbi_Insp_Schedule[0]?.OS_PREVIOUS_DATE,
      inspectionInterval: structure?.Snbi_Insp_Schedule[0]?.OS_INTERVAL,
      inspectionDueDate: structure?.Snbi_Insp_Schedule[0]?.OS_NEXT_DATE,
    },
    {
      inspType: "8",
      show7A57Checkbox: true,
      showInterval: true,
      showInspDueDate: true,
      is7A57RequiredValue: structure?.Snbi_Insp_Schedule[0]?.SERV_REQUIRED,
      lastInspectionDate: structure?.Snbi_Insp_Schedule[0]?.SERV_PREVIOUS_DATE,
      inspectionInterval: structure?.Snbi_Insp_Schedule[0]?.SERV_INTERVAL,
      inspectionDueDate: structure?.Snbi_Insp_Schedule[0]?.SERV_NEXT_DATE,
    },
    {
      inspType: "9",
      show7A57Checkbox: false,
      showInterval: false,
      showInspDueDate: false,
      is7A57RequiredValue: null,
      lastInspectionDate: structure?.Snbi_Insp_Schedule[0]?.SCM_PREVIOUS_DATE,
      inspectionInterval: 0,
      inspectionDueDate: null,
    },
    {
      inspType: "P",
      show7A57Checkbox: false,
      showInterval: false,
      showInspDueDate: false,
      is7A57RequiredValue: null,
      lastInspectionDate: structure?.Snbi_Insp_Schedule[0]?.PROB_PREVIOUS_DATE,
      inspectionInterval: 0,
      inspectionDueDate: null,
    },
    {
      inspType: "E",
      show7A57Checkbox: true,
      showInterval: true,
      showInspDueDate: true,
      is7A57RequiredValue: structure?.Snbi_Insp_Schedule[0]?.ELEM_REQUIRED,
      lastInspectionDate: structure?.Snbi_Insp_Schedule[0]?.ELEM_PREVIOUS_DATE,
      inspectionInterval: structure?.Snbi_Insp_Schedule[0]?.ELEM_INTERVAL,
      inspectionDueDate: structure?.Snbi_Insp_Schedule[0]?.ELEM_NEXT_DATE,
    },
    {
      inspType: "Q",
      show7A57Checkbox: false,
      showInterval: false,
      showInspDueDate: false,
      is7A57RequiredValue: null,
      lastInspectionDate: structure?.Snbi_Insp_Schedule[0]?.QA_PREVIOUS_DATE,
      inspectionInterval: 0,
      inspectionDueDate: null,
    },
  ];
}
export { buildFutureSchedule };
