const safetyFeature = [
  {
    BRKEY: "",
    INSPKEY: "",
    SAFETY_FEATURE_TYPE: "1",
    CONTROL_FEATURE: "0",
    LOCATION: "",
    MODTIME: "",
    SF_DESC: "",
    USERKEY: "",
  },
  {
    BRKEY: "",
    INSPKEY: "",
    SAFETY_FEATURE_TYPE: "2",
    CONTROL_FEATURE: "0",
    LOCATION: "",
    MODTIME: "",
    SF_DESC: "",
    USERKEY: "",
  },
  {
    BRKEY: "",
    INSPKEY: "",
    SAFETY_FEATURE_TYPE: "3",
    CONTROL_FEATURE: "0",
    LOCATION: "",
    MODTIME: "",
    SF_DESC: "",
    USERKEY: "",
  },
  {
    BRKEY: "",
    INSPKEY: "",
    SAFETY_FEATURE_TYPE: "4",
    CONTROL_FEATURE: "0",
    LOCATION: "",
    MODTIME: "",
    SF_DESC: "",
    USERKEY: "",
  },
];
export default safetyFeature;
