<template>
  <v-row>
    <ExpandCollapseToggle
      id="waterwayPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panels"
    />
    <v-expansion-panels v-model="panels" multiple color="#fff">
      <v-expansion-panel value="waterwayConditions" class="mt-5">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon
            v-if="panels.includes('waterwayConditions')"
            icon="fa-solid fa-minus"
          />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h2>Waterway Conditions</h2>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-form
            v-model="validWaterwayConditions"
            ref="modifyWaterwayConditions"
          >
            <v-row>
              <v-col xl="6" lg="6" md="12" sm="12" cols="12">
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="5"
                  :labelSm="6"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  showDurationIcon
                  id="select_Scour_Critical"
                  label="4A08 SCBI"
                  :refTable="REFERENCE_TABLE.SCOUR_CRITICAL_BRIDGE_INDICATOR"
                  :isEditable="isEditable && scbiSource == 'C'"
                  v-model="scourCritical"
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.SCOUTCRIT_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.SCOUTCRIT_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      scbiUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                ></LabelSelect>
                <LabelText
                  style="padding-top: 3px"
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="5"
                  :labelSm="6"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  id="select_Scour_Critical_Category"
                  label="4A08b Scour Critical Category"
                  :isEditable="false"
                  v-model="scourCriticalCategory"
                ></LabelText>
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="5"
                  :labelSm="6"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  id="select_scbi_Source"
                  label="IU03 SCBI Source"
                  :refTable="
                    REFERENCE_TABLE.SCOUR_CRITICAL_BRIDGE_INDICATOR_SOURCE
                  "
                  :isEditable="isEditable"
                  v-model="scbiSource"
                ></LabelSelect>
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="5"
                  :labelSm="6"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  id="select_Overall_SCBI"
                  label="IU04 Overall SCBI"
                  :isEditable="false"
                  v-model="overallScbi"
                  :refTable="REFERENCE_TABLE.SCOUR_CRITICAL_BRIDGE_INDICATOR"
                ></LabelSelect>
                <v-row>
                  <v-col
                    xl="5"
                    lg="6"
                    md="5"
                    sm="6"
                    cols="6"
                    class="pt-1 px-0 pb-0"
                  >
                    <v-row>
                      <v-col class="pa-0">
                        <v-btn
                          cols="9"
                          id="btn_calculate"
                          variant="outlined"
                          class="mx-4"
                          @click="calculate"
                          :disabled="!isEditable"
                          >Calculate</v-btn
                        ></v-col
                      >
                      <v-col cols="3" class="px-0 pb-0 pt-2">
                        <v-label class="bold"><span>IU04b:</span></v-label>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    xl="6"
                    lg="5"
                    md="4"
                    sm="3"
                    cols="3"
                    style="padding-top: 6px"
                  >
                    <v-row>
                      <v-col class="d-flex pa-0">
                        <v-checkbox
                          class="pl-3 pt-1"
                          id="checkbox_Calculate_No_Grid"
                          aria-describedby="checkbox_Calculate_No_Grid"
                          aria-labelledby="checkbox_Calculate_Label"
                          true-value="1"
                          hide-details
                          :disabled="true"
                          v-model="scourCalculated"
                          ><template v-slot:label>
                            <div
                              id="checkbox_Calculate_Label"
                              aria-describedby="checkbox_Calculate_Label"
                              aria-label
                              for="checkbox_Calculate_No_Grid"
                              v-show="false"
                            >
                              IU04b Checkbox label
                            </div>
                          </template></v-checkbox
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col xl="6" lg="6" md="12" sm="12" cols="12">
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="5"
                  :labelSm="6"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  :isRequired="isEditable ? true : false"
                  id="select_Channel"
                  label="1A05 Channel"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="channel"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.CHANRATE_DURATION,
                      structure?.T_Insp_Condition_Hist
                        ?.CHANRATE_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      channelConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                ></LabelSelect>
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="5"
                  :labelSm="6"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  :isRequired="isEditable ? true : false"
                  id="selected_Channel_Protection"
                  label="1A05b Channel Protection"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="channelProtectionRating"
                ></LabelSelect>
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="5"
                  :labelSm="6"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  :isRequired="isEditable ? true : false"
                  id="select_Waterway"
                  label="1A06 Waterway"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="waterwayRating"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      structure?.T_Insp_Condition_Hist?.WADEQ_DURATION,
                      structure?.T_Insp_Condition_Hist?.WADEQ_DURATION_CHANGES
                    )
                  "
                  @update:modelValue="
                    {
                      waterwayConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                ></LabelSelect>
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="5"
                  :labelSm="6"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  :isRequired="isEditable ? true : false"
                  id="select_Overall_Scour"
                  label="1A13 Overall Scour"
                  :refTable="REFERENCE_TABLE.SCOUR_CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="overallScourHoles"
                  @update:modelValue="
                    inspectionStore.setOverallScourHolesChangedByUser(true)
                  "
                ></LabelSelect>
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="5"
                  :labelSm="6"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  :isRequired="isEditable ? true : false"
                  id="select_Underwater"
                  label="1A14 Underwater"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  v-model="underwater"
                  @update:modelValue="underwaterChangedByUser = true"
                ></LabelSelect>
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="5"
                  :labelSm="6"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  id="selected_Scour_Vulnerability"
                  label="IU29 Scour Vulnerability"
                  :refTable="REFERENCE_TABLE.SCOUR_VULNERABILITY"
                  :isEditable="false"
                  v-model="scourVulnerability"
                ></LabelSelect>
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="5"
                  :labelSm="6"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  id="scour_poa_required"
                  label="IU30 Scour Plan of Action Required"
                  :refTable="REFERENCE_TABLE.SCOUR_POA_REQUIRED"
                  v-model="scourPOARequired"
                ></LabelSelect>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel value="waterwayDetails">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon
            v-if="panels.includes('waterwayDetails')"
            icon="fa-solid fa-minus"
          />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h2>Waterway Details</h2>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-form v-model="validWaterwayDetails" ref="modifyWaterwayDetails">
            <v-row>
              <v-col xl="6" lg="8" md="12" sm="12" cols="12">
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="4"
                  :labelSm="5"
                  :selectXl="7"
                  :selectLg="6"
                  :selectMd="6"
                  :selectSm="6"
                  id="select_Stream_Bed_Material_1"
                  label="IU06 Stream Bed Material"
                  :refTable="REFERENCE_TABLE.STREAM_BED_MATERIAL"
                  :isEditable="isEditable"
                  v-model="streamBedMaterialOne"
                ></LabelSelect>
              </v-col>
              <v-col
                v-if="$vuetify.display.mdAndDown"
                md="12"
                sm="12"
                cols="12"
              >
                <LabelSelect
                  :labelMd="4"
                  :labelSm="5"
                  :selectMd="6"
                  :selectSm="6"
                  label=" "
                  :showColon="false"
                  id="select_Stream_Bed_Material_2"
                  :refTable="REFERENCE_TABLE.STREAM_BED_MATERIAL"
                  :isEditable="isEditable"
                  v-model="streamBedMaterialTwo"
                ></LabelSelect>
              </v-col>
              <v-col v-else cols="4">
                <LabelSelect
                  :selectXl="12"
                  :selectLg="12"
                  id="select_Stream_Bed_Material_2"
                  :refTable="REFERENCE_TABLE.STREAM_BED_MATERIAL"
                  :isEditable="isEditable"
                  v-model="streamBedMaterialTwo"
                ></LabelSelect>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <LabelText
                  dense
                  :labelXl="12"
                  :labelLg="12"
                  :labelMd="12"
                  :labelSm="12"
                  :textXl="12"
                  :textLg="12"
                  :textMd="12"
                  :textSm="12"
                  type="textarea"
                  label="IU07 Streambed Material Description"
                  id="text_Stream_Bed_Material_Description"
                  v-model="streamBedMaterialDescription"
                  :isEditable="isEditable"
                  :counter="streambedMaterialMaxLength"
                  :maxlength="streambedMaterialMaxLength"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="6" lg="6" md="12" sm="12" cols="12">
                <LabelText
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="4"
                  :labelSm="5"
                  :textXl="2"
                  :textLg="2"
                  :textMd="2"
                  :textSm="3"
                  textStyle="width: 100%"
                  type="number"
                  maxlength="2"
                  id="text_Number_Of_Subunits"
                  label="IU02 Number of Subunits"
                  :isEditable="isEditable"
                  v-model="numberOfSubunits"
                />
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="4"
                  :labelSm="5"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  id="select_Nab_Location"
                  label="IU11 NAB Location"
                  :refTable="REFERENCE_TABLE.ABUTMENT_LOCATION"
                  :isEditable="isEditable"
                  v-model="nabLoaction"
                ></LabelSelect>
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="4"
                  :labelSm="5"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  id="select_Fab_Location"
                  label="IU12 FAB Location"
                  :refTable="REFERENCE_TABLE.ABUTMENT_LOCATION"
                  :isEditable="isEditable"
                  v-model="fabLoaction"
                ></LabelSelect>
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="4"
                  :labelSm="5"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  id="select_Us_Left_Presence"
                  label="IU13 U/S Left WW Presence"
                  :refTable="REFERENCE_TABLE.WINGWALL_PRESENCE"
                  :isEditable="isEditable"
                  v-model="leftPresence"
                ></LabelSelect>
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="4"
                  :labelSm="5"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  id="select_Left_Condition"
                  label="IU14 U/S Left WW Condition"
                  :refTable="REFERENCE_TABLE.UPSTREAM_WINGWALL_CONDITION"
                  :isEditable="isEditable"
                  v-model="leftCondition"
                ></LabelSelect>
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="4"
                  :labelSm="5"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  id="select_Right_Presence"
                  label="IU15 U/S Right WW Presence"
                  :refTable="REFERENCE_TABLE.WINGWALL_PRESENCE"
                  :isEditable="isEditable"
                  v-model="rightPresence"
                ></LabelSelect>
                <LabelSelect
                  :labelXl="5"
                  :labelLg="6"
                  :labelMd="4"
                  :labelSm="5"
                  :selectXl="6"
                  :selectLg="5"
                  :selectMd="6"
                  :selectSm="5"
                  id="select_Right_Condition"
                  label="IU16 U/S Right WW Condition"
                  :refTable="REFERENCE_TABLE.UPSTREAM_WINGWALL_CONDITION"
                  :isEditable="isEditable"
                  v-model="rightCondition"
                ></LabelSelect>
              </v-col>
              <v-col xl="6" lg="6" md="12" sm="12" cols="12">
                <LabelText
                  :labelXl="5"
                  :labelLg="4"
                  :labelMd="4"
                  :labelSm="5"
                  :textXl="2"
                  :textLg="2"
                  :textMd="2"
                  :textSm="2"
                  textStyle="width: 100%"
                  type="number"
                  id="text_Horiz_Debris_Start"
                  label="IU17 Horizontal Debris Start"
                  :isEditable="isEditable"
                  v-model="horizDebrisStart"
                  :showComma="false"
                  maxlength="3"
                  max="100"
                  min="0"
                  :roundingFactor="ROUNDING_FACTOR.ROUND"
                  :style="isEditable ? '' : 'padding-top: 2px'"
                  :alignRight="isEditable ? true : false"
                />
                <LabelText
                  :labelXl="5"
                  :labelLg="4"
                  :labelMd="4"
                  :labelSm="5"
                  :textXl="2"
                  :textLg="2"
                  :textMd="2"
                  :textSm="2"
                  afterText="(0%=LAB to 100%=RAB)"
                  type="number"
                  id="text_Horiz_Debris_End"
                  label="IU18 Horizontal Debris End"
                  :isEditable="isEditable"
                  v-model="horizDebrisEnd"
                  textStyle="width: 100%"
                  :showComma="false"
                  :roundingFactor="ROUNDING_FACTOR.ROUND"
                  maxlength="3"
                  max="100"
                  min="0"
                  :style="isEditable ? '' : 'padding-top: 2px'"
                  :alignRight="isEditable ? true : false"
                />
                <LabelText
                  :labelXl="5"
                  :labelLg="4"
                  :labelMd="4"
                  :labelSm="5"
                  :textXl="2"
                  :textLg="2"
                  :textMd="2"
                  :textSm="2"
                  type="number"
                  textStyle="width: 100%"
                  id="text_Vert_Debris_Start"
                  label="IU19 Vertical Debris Start"
                  :isEditable="isEditable"
                  v-model="vertDebrisStart"
                  :showComma="false"
                  :roundingFactor="ROUNDING_FACTOR.ROUND"
                  maxlength="3"
                  max="100"
                  min="0"
                  :style="isEditable ? '' : 'padding-top: 2px'"
                  :alignRight="isEditable ? true : false"
                />
                <LabelText
                  :labelXl="5"
                  :labelLg="4"
                  :labelMd="4"
                  :labelSm="5"
                  :textXl="2"
                  :textLg="2"
                  :textMd="2"
                  :textSm="2"
                  afterText="(0%=Streambed to 100%=Beam)"
                  type="number"
                  id="text_Vert_Debris_End"
                  label="IU20 Vertical Debris End"
                  textStyle="width: 100%"
                  :isEditable="isEditable"
                  v-model="vertDebrisEnd"
                  :showComma="false"
                  :roundingFactor="ROUNDING_FACTOR.ROUND"
                  maxlength="3"
                  max="100"
                  min="0"
                  :style="isEditable ? '' : 'padding-top: 2px'"
                  :alignRight="isEditable ? true : false"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel
        value="measuresAndCountermeasures"
        @group:selected="checkCurrentScourValidation"
      >
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon
            v-if="panels.includes('measuresAndCountermeasures')"
            icon="fa-solid fa-minus"
          />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h2>Current Scour Measures and Countermeasures</h2>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-row>
            <v-col class="px-0">
              <v-btn
                :disabled="!isEditable || expandedRowsCurrent?.length > 0"
                id="btn_addDetails"
                variant="outlined"
                class="my-2 ml-3"
                @click="addCurrent()"
                >Add</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="mb-8">
            <v-col cols="12">
              <v-data-table
                class="noScrollTable compactTable"
                :items="measuresCurrent"
                :headers="measuresCurrentHeaders"
                v-model:expanded="expandedRowsCurrent"
                hide-default-footer
                :items-per-page="-1"
                show-expand
                return-object
              >
                <template v-slot:[`column.data-table-expand`]="{ column }">
                  {{ column.title }}
                </template>
                <template v-slot:[`column.UNIQUE_KEY`]="{ column }">
                  <DataTableHeader :column="column" :lines="2" />
                </template>
                <template v-slot:[`column.CMEASURE_TYPE`]="{ column }">
                  <DataTableHeader :column="column" :lines="2" />
                </template>
                <template v-slot:[`column.CMEASURE_LOCATION`]="{ column }">
                  <DataTableHeader :column="column" :lines="2" />
                </template>
                <template v-slot:[`column.CMEASURE_COND`]="{ column }">
                  <DataTableHeader :column="column" :lines="2" />
                </template>
                <template v-slot:[`column.CMEASURE_SUBUNIT`]="{ column }">
                  <DataTableHeader :column="column" :lines="2" />
                </template>
                <template v-slot:[`column.Action`]="{ column }">
                  <DataTableHeader :column="column" :lines="2" />
                </template>
                <template v-slot:item="{ item }">
                  <tr :class="rowClassCurrent(item.raw)">
                    <td class="text-center">
                      <template v-if="!anyEditableCurrent">
                        <button
                          v-if="!isExpandedCurrent(item.raw)"
                          @click="toggleCurrentRow(item.raw)"
                        >
                          <v-icon
                            :id="`icon_Chevron_Right_${item.raw.UNIQUE_KEY}`"
                            icon="fa-solid fa-chevron-right"
                          ></v-icon>
                        </button>

                        <button
                          v-if="isExpandedCurrent(item.raw)"
                          @click="toggleCurrentRow(item.raw)"
                        >
                          <v-icon
                            :id="`icon_Chevron_Down_${item.raw.UNIQUE_KEY}`"
                            icon="fa-solid fa-chevron-down"
                          ></v-icon>
                        </button>
                      </template>
                    </td>
                    <td class="alignCenter">
                      {{ item.raw.UNIQUE_KEY }}
                    </td>
                    <td class="alignCenter">
                      {{
                        item.raw?.CMEASURE_TYPE
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.CURRENT_COUNTERMEASURE_TYPE,
                              item.raw?.CMEASURE_TYPE,
                              false
                            )
                          : ""
                      }}
                    </td>
                    <td class="alignCenter">
                      {{
                        item.raw?.CMEASURE_LOCATION
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.CURRENT_COUNTERMEASURE_LOCATION,
                              item.raw?.CMEASURE_LOCATION,
                              false
                            )
                          : ""
                      }}
                    </td>

                    <td class="alignCenter">
                      {{
                        item.raw?.CMEASURE_COND
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.CURRENT_COUNTERMEASURE_CONDITION,
                              item.raw?.CMEASURE_COND,
                              false
                            )
                          : ""
                      }}
                    </td>
                    <td class="alignCenter">
                      {{ getSubUnit(item.raw.PIER_NUM) }}
                    </td>
                    <td class="alignCenter">
                      <div
                        style="white-space: nowrap"
                        v-if="!item.raw.readOnly && isEditable"
                      >
                        <IconTooltip
                          id="icon_Current_Update"
                          icon="fa-solid fa-check"
                          :tooltip="item.raw.isAdd ? 'Add' : 'Update'"
                          @click="saveCurrent(item.raw)"
                        />
                        <IconTooltip
                          id="icon_Current_Close"
                          icon="fa-solid fa-xmark"
                          tooltip="Close"
                          @click="closeCurrent"
                        />
                      </div>
                      <div
                        style="white-space: nowrap"
                        v-else-if="isEditable && !anyEditableCurrent"
                      >
                        <IconTooltip
                          id="icon_Current_Edit"
                          icon="fa-solid fa-pencil"
                          tooltip="Edit"
                          @click="editCurrent(item.raw)"
                        />
                        <IconTooltip
                          id="icon_Current_Delete"
                          icon="fa-solid fa-trash"
                          tooltip="Delete"
                          @click="deleteCurrent(item.raw)"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-slot:expanded-row="{ item }">
                  <td :colspan="measuresCurrentHeaders.length">
                    <v-form
                      ref="currentForm"
                      v-model="validCurrent"
                      class="mt-2"
                      @change="changesDetectedCurrent = true"
                    >
                      <v-row
                        ><v-col cols="6" class="pl-3 pt-3">
                          <LabelText
                            labelXl="4"
                            labelLg="4"
                            labelMd="6"
                            labelSm="6"
                            textXl="6"
                            textLg="6"
                            textMd="6"
                            textSm="6"
                            id="currentNum"
                            label="Record Key"
                            v-model="item.raw.UNIQUE_KEY"
                            type="number"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" class="pl-3">
                          <LabelSelect
                            labelXl="4"
                            labelLg="4"
                            labelMd="6"
                            labelSm="6"
                            selectXl="6"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="currentType"
                            label="IU21 Type"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.CMEASURE_TYPE"
                            :refTable="
                              REFERENCE_TABLE.CURRENT_COUNTERMEASURE_TYPE
                            "
                            @update:modelValue="
                              {
                                changesDetectedCurrent = true;
                              }
                            "
                          />
                        </v-col>
                        <v-col cols="6">
                          <LabelSelect
                            labelXl="4"
                            labelLg="4"
                            labelMd="6"
                            labelSm="6"
                            selectXl="6"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="currentCondition"
                            label="IU23 Condition"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.CMEASURE_COND"
                            :refTable="
                              REFERENCE_TABLE.CURRENT_COUNTERMEASURE_CONDITION
                            "
                            @update:modelValue="
                              {
                                changesDetectedCurrent = true;
                              }
                            "
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" class="pl-3">
                          <LabelSelect
                            labelXl="4"
                            labelLg="4"
                            labelMd="6"
                            labelSm="6"
                            selectXl="6"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="currentLocation"
                            label="IU22 Location"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.CMEASURE_LOCATION"
                            :options="cMeasureLocations"
                            @update:modelValue="
                              {
                                changesDetectedCurrent = true;
                              }
                            "
                          />
                        </v-col>
                        <v-col cols="6">
                          <LabelSelect
                            labelXl="4"
                            labelLg="4"
                            labelMd="6"
                            labelSm="6"
                            selectXl="6"
                            selectLg="6"
                            selectMd="6"
                            selectSm="6"
                            id="currentSubunit"
                            label="IU24 Subunit"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.PIER_NUM"
                            :options="subUnits"
                            :isRequired="isEditable && !item.raw.readOnly"
                            @update:modelValue="
                              {
                                changesDetectedCurrent = true;
                              }
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </td>
                </template>
                <template v-slot:bottom> </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel value="potentialCountermeasures">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon
            v-if="panels.includes('potentialCountermeasures')"
            icon="fa-solid fa-minus"
          />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h2>Potential Countermeasures</h2>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-row>
            <v-col class="px-0">
              <v-btn
                :disabled="!isEditable || expandedRowsPotential?.length > 0"
                id="btn_addPotential"
                variant="outlined"
                class="my-2 ml-3"
                @click="addPotential()"
                >Add</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="mb-8">
            <v-col cols="12">
              <v-data-table
                class="noScrollTable compactTable"
                :items="measuresPotential"
                :headers="potentialHeaders"
                v-model:expanded="expandedRowsPotential"
                hide-default-footer
                :items-per-page="-1"
                show-expand
                return-object
              >
                <template v-slot:[`column.data-table-expand`]="{ column }">
                  {{ column.title }}
                </template>
                <template v-slot:[`column.UNIQUE_KEY`]="{ column }">
                  <DataTableHeader :column="column" :lines="2" />
                </template>
                <template v-slot:[`column.POSS_CMEASURE_LOCATION`]="{ column }">
                  <DataTableHeader :column="column" :lines="2" />
                </template>
                <template
                  v-slot:[`column.POSS_CMEASURE_COUNTERMEASURES`]="{ column }"
                >
                  <DataTableHeader :column="column" :lines="2" />
                </template>
                <template v-slot:[`column.Action`]="{ column }">
                  <DataTableHeader :column="column" :lines="2" />
                </template>
                <template v-slot:item="{ item }">
                  <tr :class="rowClassPotential(item.raw)">
                    <td class="text-center">
                      <template v-if="!anyEditablePotential">
                        <button
                          v-if="!isExpandedPotential(item.raw)"
                          @click="togglePotentialRow(item.raw)"
                        >
                          <v-icon
                            :id="`icon_Chevron_Right_${item.raw.UNIQUE_KEY}`"
                            icon="fa-solid fa-chevron-right"
                          ></v-icon>
                        </button>

                        <button
                          v-if="isExpandedPotential(item.raw)"
                          @click="togglePotentialRow(item.raw)"
                        >
                          <v-icon
                            :id="`icon_Chevron_Down_${item.raw.UNIQUE_KEY}`"
                            icon="fa-solid fa-chevron-down"
                          ></v-icon>
                        </button>
                      </template>
                    </td>
                    <td class="alignCenter">
                      {{ item.raw.UNIQUE_KEY }}
                    </td>
                    <td class="alignCenter">
                      {{
                        item.raw?.LOCATION
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.CURRENT_COUNTERMEASURE_LOCATION,
                              item.raw?.LOCATION,
                              false
                            )
                          : ""
                      }}
                    </td>
                    <td class="alignCenter">
                      {{
                        item.raw?.WCKEY
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.WORK_CANDIDATE,
                              item.raw?.WCKEY,
                              false
                            )
                          : ""
                      }}
                    </td>
                    <td class="alignCenter">
                      <div
                        style="white-space: nowrap"
                        v-if="
                          isExpandedPotential(item.raw) &&
                          anyEditablePotential &&
                          isEditable
                        "
                      >
                        <IconTooltip
                          id="icon_add"
                          icon="fa-solid fa-check"
                          :tooltip="item.raw?.isAdd ? 'Add' : 'Update'"
                          @click="savePotential(item.raw)"
                        />
                        <IconTooltip
                          id="icon_close"
                          icon="fa-solid fa-xmark"
                          tooltip="Close"
                          @click="closePotential"
                        />
                      </div>
                      <div
                        style="white-space: nowrap"
                        v-else-if="
                          isEditable &&
                          (expandedRowsPotential?.length == 0 ||
                            !anyEditablePotential)
                        "
                      >
                        <IconTooltip
                          id="icon_edit"
                          icon="fa-solid fa-pencil"
                          tooltip="Edit"
                          @click="editPotential(item.raw)"
                        />
                        <IconTooltip
                          id="icon_delete"
                          icon="fa-solid fa-trash"
                          tooltip="Delete"
                          @click="deletePotential(item.raw)"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-slot:expanded-row="{ item }">
                  <td :colspan="potentialHeaders.length">
                    <v-row
                      ><v-col
                        xl="8"
                        lg="9"
                        md="10"
                        sm="12"
                        cols="12"
                        class="pl-3 pt-3"
                      >
                        <LabelText
                          labelXl="5"
                          labelLg="5"
                          labelMd="5"
                          labelSm="5"
                          textXl="7"
                          textLg="7"
                          textMd="7"
                          textSm="7"
                          id="potentialNum"
                          label="Record Key"
                          v-model="item.raw.UNIQUE_KEY"
                          type="number"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        xl="8"
                        lg="9"
                        md="10"
                        sm="12"
                        cols="12"
                        class="pl-3"
                      >
                        <LabelSelect
                          labelXl="5"
                          labelLg="5"
                          labelMd="5"
                          labelSm="5"
                          selectXl="7"
                          selectLg="7"
                          selectMd="7"
                          selectSm="7"
                          id="potentialLocation"
                          label="IU25 Location"
                          :isEditable="isEditable && !item.raw.readOnly"
                          v-model="item.raw.LOCATION"
                          :options="cMeasureLocations"
                          @update:modelValue="
                            {
                              changesDetectedPotential = true;
                            }
                          "
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        xl="8"
                        lg="9"
                        md="10"
                        sm="12"
                        cols="12"
                        class="pl-3"
                      >
                        <LabelSelect
                          labelXl="5"
                          labelLg="5"
                          labelMd="5"
                          labelSm="5"
                          selectXl="7"
                          selectLg="7"
                          selectMd="7"
                          selectSm="7"
                          id="potentialCountermeasures"
                          label="IU26 Countermeasures"
                          :isEditable="isEditable && !item.raw.readOnly"
                          v-model="item.raw.WCKEY"
                          :refTable="REFERENCE_TABLE.WORK_CANDIDATE"
                          @update:modelValue="
                            {
                              changesDetectedPotential = true;
                            }
                          "
                        />
                      </v-col>
                    </v-row>
                  </td>
                </template>
                <template v-slot:bottom> </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel
        value="500yrFloodPlain"
        @group:selected="checkFloodPlainFormValidation"
      >
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon
            v-if="panels.includes('500yrFloodPlain')"
            icon="fa-solid fa-minus"
          />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h2>Subunits within 500yr Flood Plain</h2>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-row>
            <v-col class="px-0">
              <v-btn
                :disabled="
                  !isEditable ||
                  expandedRowsFloodPlain?.length > 0 ||
                  inspectionStore.getIDsNotInUnderwaterInsp().length == 0
                "
                id="btn_addFloodPlain"
                variant="outlined"
                class="my-2 ml-3"
                @click="addFloodPlain()"
                >Add Subunit</v-btn
              >
              <v-tooltip
                v-if="inspectionStore?.getIDsNotInUnderwaterInsp().length == 0"
              >
                <template v-slot:activator="{ props }">
                  <v-icon
                    id="icon_AddSubunitInfo"
                    class="my-1 ml-3 mr-2 infoIcon"
                    icon="fas fa-circle-info"
                    v-bind="props"
                  />
                </template>
                <div>
                  <span>No Subunits available to Add.</span>
                </div>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row class="mb-8">
            <v-col cols="12">
              <v-data-table
                ref="floodPlainTable"
                class="lessPaddingTableColumns"
                :items="floodPlains"
                :headers="floodPlainHeaders"
                v-model:expanded="expandedRowsFloodPlain"
                hide-default-footer
                show-expand
                :items-per-page="-1"
                v-model:sort-by="sortByFloodPlain"
                must-sort
                return-object
              >
                <template v-slot:[`column.data-table-expand`]="{ column }">
                  {{ column.title }}
                </template>
                <template v-slot:[`column.SUB_UNIT`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortByFloodPlain"
                  />
                </template>
                <template v-slot:[`column.OBS_SCOUR_RATING`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortByFloodPlain"
                  />
                </template>
                <template v-slot:[`column.SCOUR_HOLE`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortByFloodPlain"
                  />
                </template>
                <template v-slot:[`column.FOUND_TYPE`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortByFloodPlain"
                  />
                </template>
                <template v-slot:[`column.STREAM_BED_MATERIAL`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortByFloodPlain"
                  />
                </template>
                <template v-slot:[`column.UNDERWATER_INSP_TYPE`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortByFloodPlain"
                  />
                </template>
                <template v-slot:[`column.UW_REQ`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortByFloodPlain"
                  />
                </template>
                <template v-slot:[`column.MAX_WATER_DEPTH`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortByFloodPlain"
                  />
                </template>
                <template v-slot:[`column.SCBI_CODE`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortByFloodPlain"
                  />
                </template>
                <template v-slot:[`column.Action`]="{ column }">
                  <DataTableHeader
                    :column="column"
                    :sortBy="sortByFloodPlain"
                  />
                </template>
                <template v-slot:item="{ item }">
                  <tr :class="rowClassFloodPlain(item.raw)">
                    <td class="text-center">
                      <template v-if="!anyEditableFloodPlain">
                        <button
                          v-if="!isExpandedFloodPlain(item.raw)"
                          @click="toggleFloodPlainsRow(item.raw)"
                        >
                          <v-icon
                            :id="`icon_Chevron_Right_${item.raw.UNIQUE_KEY}`"
                            icon="fa-solid fa-chevron-right"
                          ></v-icon>
                        </button>

                        <button
                          v-if="isExpandedFloodPlain(item.raw)"
                          @click="toggleFloodPlainsRow(item.raw)"
                        >
                          <v-icon
                            :id="`icon_Chevron_Down_${item.raw.UNIQUE_KEY}`"
                            icon="fa-solid fa-chevron-down"
                          ></v-icon>
                        </button>
                      </template>
                    </td>
                    <td class="alignCenter">
                      {{ item.raw?.SUB_UNIT }}
                    </td>
                    <td class="alignCenter">
                      {{ item.raw?.OBS_SCOUR_RATING }}
                    </td>
                    <td class="alignCenter">
                      {{ item.raw?.SCOUR_HOLE }}
                    </td>
                    <td class="alignCenter">
                      {{
                        item.raw.FOUND_TYPE
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.OSA_FOUNDATION_TYPE,
                              item.raw.FOUND_TYPE
                            )
                          : ""
                      }}
                    </td>
                    <td class="alignCenter">
                      {{
                        item.raw.STREAM_BED_MATERIAL
                          ? configStore.getReferenceValue(
                              REFERENCE_TABLE.STREAM_BED_MATERIAL,
                              item.raw.STREAM_BED_MATERIAL
                            )
                          : ""
                      }}
                    </td>
                    <td class="alignCenter">
                      {{ item.raw?.UNDERWATER_INSP_TYPE }}
                    </td>
                    <td>
                      <v-row>
                        <v-col
                          xl="4"
                          lg="3"
                          md="1"
                          sm="1"
                          cols="1"
                          class="pl-0"
                        />
                        <v-col class="pl-0">
                          <v-checkbox
                            v-if="!item.raw?.isAdd"
                            class="alignCenter"
                            id="checkbox_UW_INSP__TYPE"
                            hide-details
                            :true-value="'1'"
                            disabled
                            v-model="getIsUwRequired(item.raw).isUwRequired"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </td>
                    <td class="alignCenter">
                      {{
                        configStore.getMetricToEnglishConversions(
                          PAIR_CODE.METERS_TO_FEET,
                          item.raw?.MAX_WATER_DEPTH,
                          ROUNDING_FACTOR.TENTH
                        )
                      }}
                    </td>
                    <td class="alignCenter">
                      {{ item.raw.SCBI_CODE != "" ? item.raw.SCBI_CODE : "--" }}
                    </td>
                    <td class="alignCenter">
                      <div
                        style="white-space: nowrap"
                        v-if="
                          isExpandedFloodPlain(item.raw) &&
                          anyEditableFloodPlain &&
                          isEditable
                        "
                      >
                        <IconTooltip
                          id="icon_add"
                          icon="fa-solid fa-check"
                          :tooltip="item.raw?.isAdd ? 'Add' : 'Update'"
                          @click="saveFloodPlain(item.raw)"
                        />
                        <IconTooltip
                          id="icon_close"
                          icon="fa-solid fa-xmark"
                          tooltip="Close"
                          @click="closeFloodPlain(item.raw)"
                        />
                      </div>
                      <div
                        style="white-space: nowrap"
                        v-else-if="
                          expandedRowsFloodPlain?.length == 0 ||
                          !anyEditableFloodPlain
                        "
                      >
                        <IconTooltip
                          v-if="isEditable"
                          id="icon_edit"
                          icon="fa-solid fa-pencil"
                          tooltip="Edit"
                          @click="editFloodPlain(item.raw)"
                        />
                        <IconTooltip
                          v-if="inspectionStore.isEditable"
                          id="icon_delete"
                          icon="fa-solid fa-trash"
                          tooltip="Delete"
                          @click="deleteFloodPlain(item.raw)"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-slot:expanded-row="{ item }">
                  <td :colspan="floodPlainHeaders.length">
                    <v-form v-model="validFloodPlain" ref="floodPlainForm">
                      <v-row>
                        <v-col
                          xl="6"
                          lg="6"
                          md="12"
                          sm="12"
                          cols="12"
                          class="pl-3 pt-3"
                        >
                          <v-row v-if="!item.raw?.isAdd">
                            <v-col
                              xl="5"
                              lg="6"
                              md="5"
                              sm="6"
                              cols="6"
                              class="pb-0"
                            >
                              <v-label class="bold pb-0"
                                >IN01 Sub Unit:</v-label
                              >
                            </v-col>
                            <v-col
                              xl="6"
                              lg="5"
                              md="7"
                              sm="6"
                              cols="6"
                              class="pb-0"
                            >
                              <span class="pl-4 pb-0">{{
                                inspectionStore.getSubStructure(
                                  item.raw?.STRUNITKEY
                                ).STRUNITKEY +
                                "-" +
                                inspectionStore.getSubStructure(
                                  item.raw?.STRUNITKEY
                                ).STRUNITLABEL
                              }}</span>
                            </v-col>
                          </v-row>
                          <LabelSelect
                            v-else
                            class="pt-2 pb-0"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="select_Sub_Unit_Id"
                            label="IN01 Sub Unit"
                            :options="
                              inspectionStore.getIDsNotInUnderwaterInsp()
                            "
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.STRUNITKEY"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          ></LabelSelect>
                          <LabelCheckbox
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            class="labelCheckbox"
                            id="is_current"
                            label="IN02 Current Inspection?"
                            :showColon="false"
                            editIndent
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.PREVIOUS_CURRENT"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          >
                          </LabelCheckbox>
                          <v-row>
                            <v-col
                              xl="5"
                              lg="6"
                              md="5"
                              sm="6"
                              cols="6"
                              class="mt-1 pt-1 d-flex"
                            >
                              <v-label class="bold">IU27 SCBI Code:</v-label>
                            </v-col>
                            <v-col
                              xl="6"
                              lg="5"
                              md="6"
                              sm="5"
                              cols="5"
                              class="mt-1 pt-1 d-flex"
                            >
                              <span class="pl-4">{{
                                item.raw.SCBI_CODE != ""
                                  ? item.raw.SCBI_CODE
                                  : "--"
                              }}</span>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              xl="5"
                              lg="6"
                              md="5"
                              sm="6"
                              cols="6"
                              class="pt-2 mt-1 d-flex"
                            >
                              <v-label class="bold">IU28 SCBI Case:</v-label>
                            </v-col>
                            <v-col
                              xl="6"
                              lg="5"
                              md="6"
                              sm="5"
                              cols="5"
                              class="pt-2 mt-1 d-flex"
                            >
                              <span class="pl-4">{{
                                item.raw.SCBI_CASE != ""
                                  ? item.raw.SCBI_CASE
                                  : "--"
                              }}</span>
                            </v-col>
                          </v-row>
                          <LabelSelect
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="scour_rating"
                            label="IN03 Scour Rating"
                            :refTable="REFERENCE_TABLE.OBSERVED_SCOUR_RATING"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.OBS_SCOUR_RATING"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          ></LabelSelect>
                          <LabelSelect
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="change_since_last_insp"
                            label="IN04 Change Since Last Insp"
                            :refTable="REFERENCE_TABLE.CHANGE_SINCE_LAST_INSP"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.CHG_SINCE_LAST_INSP"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          ></LabelSelect>
                          <LabelSelect
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="scour_hole"
                            label="IN05 Scour hole"
                            :refTable="REFERENCE_TABLE.SCOUR_HOLE"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.SCOUR_HOLE"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          ></LabelSelect>
                          <LabelSelect
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="debris_potn"
                            label="IN06 Debris Potential"
                            :refTable="REFERENCE_TABLE.DEBRIS_POTENTIAL"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.DEBRIS_POTENTIAL"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          ></LabelSelect>
                          <LabelSelect
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="scourability"
                            label="IN07 Scourability"
                            :refTable="
                              REFERENCE_TABLE.SUBSTRUCTURE_SCOURABILITY
                            "
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.SUB_SCOUR"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          ></LabelSelect>
                          <LabelSelect
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="opening_adeq_channel"
                            label="IN08 Opening Adeq Channel"
                            :refTable="REFERENCE_TABLE.OPENING_ADEQUACY_CHANNEL"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.OPEN_ADEQ_CHANNEL"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          ></LabelSelect>
                          <LabelSelect
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="seidment"
                            label="IN09 Sediment"
                            :refTable="REFERENCE_TABLE.SEDIMENT_DEPOSITS"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.SED_DEPOSIT"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          ></LabelSelect>
                          <LabelSelect
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="alignment"
                            label="IN10 Alignment"
                            :refTable="REFERENCE_TABLE.ALIGNMENT"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.ALIGNMENT"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          ></LabelSelect>
                          <LabelSelect
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="velocity_stream_slope"
                            label="IN11 Velocity Stream Slope"
                            :refTable="REFERENCE_TABLE.VELOCITY_STREAM_SLOPE"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.VELO_STREAM_SLOPE"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          ></LabelSelect>
                        </v-col>
                        <v-col
                          xl="6"
                          lg="6"
                          md="12"
                          sm="12"
                          cols="12"
                          :class="
                            $vuetify.display.mdAndDown ? 'pb-0 pl-3' : 'pb-0'
                          "
                        >
                          <LabelSelect
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="subunit_Type"
                            label="IN12 Pier/Abutment Type"
                            :options="pierAbutTypes"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.SUBUNIT_TYPE"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          ></LabelSelect>
                          <LabelSelect
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="inv_found_type"
                            label="IN13 Inv Foundation Type"
                            :refTable="REFERENCE_TABLE.PA_FOUNDATION_TYPE"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.INV_FOUND_TYPE"
                            @update:modelValue="
                              if (item.raw.INV_FOUND_TYPE == 'A') {
                                item.raw.FOUND_TYPE = '1';
                              } else if (
                                ['R', 'K', 'L'].includes(
                                  item.raw.INV_FOUND_TYPE
                                )
                              ) {
                                item.raw.FOUND_TYPE = '2';
                              } else if (
                                [
                                  'B',
                                  'C',
                                  'D',
                                  'E',
                                  'F',
                                  'G',
                                  'H',
                                  'I',
                                  'J',
                                  'S',
                                ].includes(item.raw.INV_FOUND_TYPE)
                              ) {
                                item.raw.FOUND_TYPE = '3';
                              } else if (item.raw.INV_FOUND_TYPE == 'O') {
                                item.raw.FOUND_TYPE = '4';
                              } else if (
                                ['P', 'X'].includes(item.raw.INV_FOUND_TYPE)
                              ) {
                                item.raw.FOUND_TYPE = '5';
                              }
                              item.raw.changesDetectedFloodPlain = true;
                            "
                          ></LabelSelect>
                          <LabelSelect
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            id="found_type"
                            :isEditable="false"
                            :refTable="REFERENCE_TABLE.OSA_FOUNDATION_TYPE"
                            label="IN14 OSA Foundation Type"
                            v-model="item.raw.FOUND_TYPE"
                          >
                          </LabelSelect>
                          <LabelSelect
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="streambed_material"
                            label="IN15 Streambed Material"
                            :refTable="REFERENCE_TABLE.STREAM_BED_MATERIAL"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.STREAM_BED_MATERIAL"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          ></LabelSelect>
                          <LabelSelect
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="uw_insp_type"
                            label="IN16 UW Inspection Type"
                            :refTable="
                              REFERENCE_TABLE.UNDERWATER_INSPECTION_TYPE
                            "
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.UNDERWATER_INSP_TYPE"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          ></LabelSelect>
                          <LabelText
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="ob_scour_depth"
                            :type="'number'"
                            appendWith=" ft"
                            :pairCode="PAIR_CODE.METERS_TO_FEET"
                            :decimalPlaces="1"
                            :roundingFactor="ROUNDING_FACTOR.TENTH"
                            :isEditable="isEditable && !item.raw.readOnly"
                            label="IN17 Observed Scour Depth"
                            v-model="item.raw.OBS_SCOUR_DEPTH"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          />
                          <LabelText
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            :pairCode="PAIR_CODE.METERS_TO_FEET"
                            :isRequired="isEditable && !item.raw.readOnly"
                            id="max_water_depth"
                            :type="'number'"
                            appendWith=" ft"
                            :decimalPlaces="1"
                            :roundingFactor="ROUNDING_FACTOR.TENTH"
                            :isEditable="isEditable && !item.raw.readOnly"
                            label="IN18 Water Depth"
                            v-model="item.raw.MAX_WATER_DEPTH"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          />
                          <LabelCheckbox
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            id="movement"
                            label="IN19 Movement Indicator"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.MOVEMENT"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          >
                          </LabelCheckbox>
                          <LabelCheckbox
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            id="scour_undermine_ind"
                            label="IN20 Scour/Undermine Indicator"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.SCOUR_UNDERMINE"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          >
                          </LabelCheckbox>
                          <LabelCheckbox
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :selectXl="6"
                            :selectLg="5"
                            :selectMd="7"
                            :selectSm="6"
                            id="countermeasures"
                            label="IN21 Countermeasures"
                            :isEditable="isEditable && !item.raw.readOnly"
                            v-model="item.raw.COUNTERMEASURES"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          >
                          </LabelCheckbox>
                          <LabelText
                            class="pt-1"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :textXl="6"
                            :textLg="5"
                            :textMd="7"
                            :textSm="6"
                            id="onehundred_year_flood_depth"
                            :type="'number'"
                            appendWith=" ft"
                            :decimalPlaces="1"
                            :roundingFactor="ROUNDING_FACTOR.TENTH"
                            :pairCode="PAIR_CODE.METERS_TO_FEET"
                            :isEditable="isEditable && !item.raw.readOnly"
                            :showPlaceholder="true"
                            label="IN22 100 YR Flood Scour Depth"
                            v-model="item.raw.CALC_SCOUR_DEPTH_100"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          />
                          <LabelText
                            class="pt-2"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            :textXl="6"
                            :textLg="5"
                            :textMd="7"
                            :textSm="6"
                            id="fivehundred_year_flood_depth"
                            :type="'number'"
                            appendWith=" ft"
                            :decimalPlaces="1"
                            :roundingFactor="ROUNDING_FACTOR.TENTH"
                            :pairCode="PAIR_CODE.METERS_TO_FEET"
                            :isEditable="isEditable && !item.raw.readOnly"
                            :showPlaceholder="true"
                            label="IN23 500 YR Flood Scour Depth"
                            v-model="item.raw.CALC_SCOUR_DEPTH_500"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          />
                          <LabelCheckbox
                            v-if="item.raw.STRUNITKEY"
                            class="pt-1"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            id="in_flood_plain"
                            label="IN25 In 500yr Flood Plain?"
                            :isEditable="false"
                            :indent="
                              isEditable && !item.raw.readOnly ? false : true
                            "
                            v-model="
                              inspectionStore.getSubStructure(
                                item.raw.STRUNITKEY
                              ).IN_500YR_FP
                            "
                            :showColon="false"
                          >
                          </LabelCheckbox>
                          <LabelCheckbox
                            v-else
                            class="pt-1"
                            :labelXl="5"
                            :labelLg="6"
                            :labelMd="5"
                            :labelSm="6"
                            id="in_flood_plain"
                            label="IN25 In 500yr Flood Plain?"
                            :isEditable="false"
                            :indent="isEditable ? false : true"
                            :showColon="false"
                          >
                          </LabelCheckbox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pl-3 pt-2">
                          <LabelText
                            class="pt-0"
                            :labelXl="12"
                            :labelLg="12"
                            :labelMd="12"
                            :labelSm="12"
                            :textXl="12"
                            :textLg="12"
                            :textMd="12"
                            :textSm="12"
                            id="textarea_notes"
                            v-model="item.raw.UNDERWATER_INSP_DESC"
                            :isEditable="isEditable && !item.raw.readOnly"
                            :counter="commentsMaxLength"
                            :maxlength="commentsMaxLength"
                            label="IN24 Notes"
                            persistent-counter
                            type="textarea"
                            auto-grow
                            rows="1"
                            @update:modelValue="
                              {
                                item.raw.changesDetectedFloodPlain = true;
                              }
                            "
                          >
                          </LabelText>
                        </v-col>
                      </v-row>
                    </v-form>
                  </td>
                </template>
                <template v-slot:bottom> </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel value="adequacyInfo">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon
            v-if="panels.includes('adequacyInfo')"
            icon="fa-solid fa-minus"
          />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h2>Channel and Waterway Adequacy Information</h2>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row>
            <v-col xl="4" lg="4" md="12" sm="12" cols="12">
              <LabelSelect
                v-if="$vuetify.display.mdAndDown"
                :labelMd="5"
                :labelSm="6"
                id="select_Channel_Rating"
                label="1A05 Channel"
                :refTable="REFERENCE_TABLE.CONDITION_RATING"
                :isEditable="false"
                v-model="channelRating"
              ></LabelSelect>
              <LabelSelect
                v-else
                noGrid
                id="select_Channel_Rating"
                label="1A05 Channel"
                :refTable="REFERENCE_TABLE.CONDITION_RATING"
                :isEditable="false"
                v-model="channelRating"
              ></LabelSelect>
            </v-col>
            <v-col xl="4" lg="4" md="12" sm="12" cols="12">
              <LabelSelect
                v-if="$vuetify.display.mdAndDown"
                :labelMd="5"
                :labelSm="6"
                id="select_Channel_Protection"
                label="1A05b Channel Protection"
                :refTable="REFERENCE_TABLE.CONDITION_RATING"
                :isEditable="false"
                v-model="channelProtectionRating"
              ></LabelSelect>
              <LabelSelect
                v-else
                noGrid
                id="select_Channel_Protection"
                label="1A05b Channel Protection"
                :refTable="REFERENCE_TABLE.CONDITION_RATING"
                :isEditable="false"
                v-model="channelProtectionRating"
              ></LabelSelect>
            </v-col>
            <v-col xl="4" lg="4" md="12" sm="12" cols="12">
              <LabelSelect
                v-if="$vuetify.display.mdAndDown"
                :labelMd="5"
                :labelSm="6"
                id="select_Waterway_Rating"
                label="1A06 Waterway"
                :refTable="REFERENCE_TABLE.CONDITION_RATING"
                :isEditable="false"
                v-model="waterwayRating"
              ></LabelSelect>
              <LabelSelect
                v-else
                noGrid
                id="select_Waterway_Rating"
                label="1A06 Waterway"
                :refTable="REFERENCE_TABLE.CONDITION_RATING"
                :isEditable="false"
                v-model="waterwayRating"
              ></LabelSelect>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <CommentTypeTextField
                label="Channel"
                id="field_Channel_Notes"
                :inspectionCommentType="INSPECTION_COMMENTS.CHANNEL"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <CommentTypeTextField
                label="Channel Protection"
                id="field_Channel_Protection_Notes"
                :inspectionCommentType="INSPECTION_COMMENTS.CHANNEL_PROTECTION"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <CommentTypeTextField
                label="Banks"
                id="field_Banks_Notes"
                :inspectionCommentType="INSPECTION_COMMENTS.BANKS"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <CommentTypeTextField
                label="Streambed Movements"
                id="field_Streambed_Movements_Notes"
                :inspectionCommentType="INSPECTION_COMMENTS.STREAMBED_MOVEMENTS"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <CommentTypeTextField
                label="Debris, Vegetation"
                id="field_Debris_Vegetation_Notes"
                :inspectionCommentType="INSPECTION_COMMENTS.DEBRIS_VEGITATION"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <CommentTypeTextField
                label="River (Stream) Control Devices"
                id="field_River_Control_Devices_Notes"
                :inspectionCommentType="
                  INSPECTION_COMMENTS.RIVER_CONTROL_DEVICES
                "
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <CommentTypeTextField
                label="Embankment/Streambed Controls"
                id="field_Embankment_Streambed_Controls_Notes"
                :inspectionCommentType="
                  INSPECTION_COMMENTS.EMBANKMENT_STREAMBED_CONTROLS
                "
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col sm="12">
              <CommentTypeTextField
                label="Drift, Other"
                id="field_Drift_Other_Notes"
                :inspectionCommentType="INSPECTION_COMMENTS.DRIFT_OTHER"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col sm="12">
              <CommentTypeTextField
                label="Waterway Adequacy"
                id="field_Waterway_Adequacy_Notes"
                :inspectionCommentType="INSPECTION_COMMENTS.WATERWAY_ADEQUACY"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="6" lg="6" md="12" sm="12" cols="12">
              <LabelSelect
                :labelXl="5"
                :labelLg="6"
                :labelMd="5"
                :labelSm="6"
                :selectXl="5"
                :selectLg="4"
                :selectMd="5"
                :selectSm="4"
                id="select_Overtopping_Likelihood"
                label="IL02 Overtopping Likelihood"
                :refTable="REFERENCE_TABLE.OVERTOPPING_RISK"
                :isEditable="isEditable"
                v-model="riskOfOvertopping"
              ></LabelSelect>
            </v-col>
            <v-col xl="6" lg="6" md="12" sm="12" cols="12">
              <LabelSelect
                :labelXl="5"
                :labelLg="6"
                :labelMd="5"
                :labelSm="6"
                :selectXl="5"
                :selectLg="4"
                :selectMd="5"
                :selectSm="4"
                id="select_Traffic_Delay"
                label="IL03 Traffic Delay"
                :refTable="REFERENCE_TABLE.TRAFFIC_DELAY"
                :isEditable="isEditable"
                v-model="trafficDelay"
              ></LabelSelect>
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="6" lg="6" md="12" sm="12" cols="12">
              <LabelSelect
                :labelXl="5"
                :labelLg="6"
                :labelMd="5"
                :labelSm="6"
                :selectXl="5"
                :selectLg="4"
                :selectMd="5"
                :selectSm="4"
                id="select_Worst_Flood_Event"
                label="IL13 Worst Flood Event"
                :refTable="REFERENCE_TABLE.WORST_FLOOD_EVENT"
                :isEditable="isEditable"
                v-model="worstFloodEvent"
              ></LabelSelect>
            </v-col>

            <v-col xl="6" lg="6" md="12" sm="12" cols="12">
              <LabelDatefield
                :labelXl="5"
                :labelLg="6"
                :labelMd="5"
                :labelSm="6"
                :textXl="5"
                :textLg="4"
                :textMd="5"
                :textSm="4"
                id="text_Worst_Flood_Event_Date"
                label="IL14 Worst Flood Event Date"
                v-model="worstFloodEventDate"
                :isEditable="isEditable"
              >
              </LabelDatefield>
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="6" lg="6" md="12" sm="12" cols="12">
              <LabelSelect
                :labelXl="5"
                :labelLg="6"
                :labelMd="5"
                :labelSm="6"
                :selectXl="5"
                :selectLg="4"
                :selectMd="5"
                :selectSm="4"
                id="select_Functional_Class"
                label="5C22 Functional Class"
                :refTable="REFERENCE_TABLE.ROADWAY_FUNCTIONAL_CLASSIFICATION"
                :isEditable="false"
                v-model="functionClass"
              ></LabelSelect>
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="4" lg="4" md="12" sm="12" cols="12">
              <LabelText
                v-if="$vuetify.display.mdAndDown"
                :labelMd="5"
                :labelSm="6"
                :isEditable="isEditable"
                id="number_Elevation_Of_Water_Level"
                label="IL05 High Water Elevation"
                v-model="elevationOfWaterLevel"
                type="number"
                maxlength="4"
                :pairCode="PAIR_CODE.METERS_TO_FEET"
                appendWith=" ft"
              >
              </LabelText>
              <LabelText
                v-else
                noGrid
                :isEditable="isEditable"
                id="number_Elevation_Of_Water_Level"
                label="IL05 High Water Elevation"
                v-model="elevationOfWaterLevel"
                type="number"
                maxlength="4"
                :pairCode="PAIR_CODE.METERS_TO_FEET"
                appendWith=" ft"
              >
              </LabelText>
            </v-col>
            <v-col xl="4" lg="4" md="12" sm="12" cols="12" class="pt-1">
              <LabelDatefield
                v-if="$vuetify.display.mdAndDown"
                :labelMd="5"
                :labelSm="6"
                addLabelPadding
                addDatePadding
                id="text_High_Water_Date"
                label="IL06 High Water Date"
                :isEditable="isEditable"
                v-model="highWaterDate"
              >
              </LabelDatefield>
              <LabelDatefield
                v-else
                :labelLg="6"
                :labelXl="5"
                :textXl="6"
                :textLg="6"
                addLabelPadding
                addDatePadding
                id="text_High_Water_Date"
                label="IL06 High Water Date"
                :isEditable="isEditable"
                v-model="highWaterDate"
              >
              </LabelDatefield>
            </v-col>
            <v-col xl="4" lg="4" md="12" sm="12" cols="12">
              <LabelCheckbox
                v-if="$vuetify.display.mdAndDown"
                :labelMd="5"
                :labelSm="6"
                class="waterwayCheckbox"
                label="IL07 New High Water Mark"
                :id="'checkbox_New_High_Water_Mark'"
                v-model="newHighWaterMark"
                :isEditable="isEditable"
              >
              </LabelCheckbox>
              <LabelCheckbox
                v-else
                noGrid
                class="waterwayCheckbox"
                label="IL07 New High Water Mark"
                :id="'checkbox_New_High_Water_Mark'"
                v-model="newHighWaterMark"
                :isEditable="isEditable"
              >
              </LabelCheckbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CommentTypeTextField
                label="IL08 High Water Notes"
                id="field_High_Water_Notes"
                :inspectionCommentType="INSPECTION_COMMENTS.HIGH_WATER_MARK"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <ConfirmDialog ref="closeConfirm" :cancelButtonText="BUTTONS.NO" />
    <v-snackbar
      v-model="inspectionStore.addingOrEditing"
      color="warning"
      position="sticky"
      location="top"
      timeout="-1"
      multi-line
    >
      <div v-if="currentSnackbarText" class="py-1">
        {{ currentSnackbarText }}
      </div>
      <div v-if="potentialSnackbarText" class="py-1">
        {{ potentialSnackbarText }}
      </div>
      <div v-if="floodPlainSnackbarText" class="py-1">
        {{ floodPlainSnackbarText }}
      </div>
    </v-snackbar>
  </v-row>
</template>
<script>
import { computed, ref, watch, onMounted, nextTick } from "vue";
import { clone } from "@/util/clone";
import {
  ENV_CONFIG_PROPERTY,
  CONFIG_SORT_BY,
} from "@/constants/EnvConfigProperties";
import { SORT_ORDER, TYPES, BUTTONS } from "@/constants/CommonWebConstants";
import { useConfigStore } from "@/stores/config";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelText from "@/components/common/LabelText.vue";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";
import IconTooltip from "@/components/common/IconTooltip.vue";
import { INSPECTION_MESSAGES } from "@/constants/InspectionConstants";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import { LOGGER } from "@/util/logger";
import underwaterInsp from "@/data/underwaterInspection";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import LabelDatefield from "@/components/common/LabelDatefield.vue";
import DataTableHeader from "@/components/common/DataTableHeader.vue";
import { getHistDuration } from "@/composables/util";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";

export default {
  name: "WaterwayComponent",
  data() {
    return {
      measuresCurrentHeaders: [
        { key: "data-table-expand" },
        {
          line1: null,
          line2: "Record Key",
          line3: null,
          sortable: false,
          key: "UNIQUE_KEY",
        },
        {
          line1: "IU21",
          line2: "Type",
          line3: null,
          sortable: false,
          key: "CMEASURE_TYPE",
        },
        {
          line1: "IU22",
          line2: "Location",
          line3: null,
          sortable: false,
          key: "CMEASURE_LOCATION",
        },
        {
          line1: "IU23",
          line2: "Condition",
          line3: null,
          sortable: false,
          key: "CMEASURE_COND",
        },
        {
          line1: "IU24",
          line2: "Subunit",
          line3: null,
          sortable: false,
          key: "CMEASURE_SUBUNIT",
        },
        {
          line1: null,
          line2: "Action",
          line3: null,
          sortable: false,
          key: "Action",
        },
      ],
      potentialHeaders: [
        { title: "", key: "data-table-expand" },
        {
          line1: null,
          line2: "Record Key",
          line3: null,
          sortable: false,
          key: "UNIQUE_KEY",
        },
        {
          line1: "IU25",
          line2: "Location",
          line3: null,
          sortable: false,
          key: "POSS_CMEASURE_LOCATION",
        },
        {
          line1: "IU26",
          line2: "Countermeasures",
          line3: null,
          sortable: false,
          key: "POSS_CMEASURE_COUNTERMEASURES",
        },
        {
          line1: null,
          line2: "Action",
          line3: null,
          sortable: false,
          key: "Action",
        },
      ],
      floodPlainHeaders: [
        {
          line1: null,
          line2: null,
          line3: null,
          key: "data-table-expand",
          width: "3%",
        },
        {
          line1: "IN01",
          line2: "Subunit",
          line3: null,
          sortable: true,
          key: "SUB_UNIT",
          width: "9%",
        },
        {
          line1: "IN03",
          line2: "Obs Scour",
          line3: "Rating",
          sortable: true,
          key: "OBS_SCOUR_RATING",
        },
        {
          title: "IN05 Scour Hole",
          line1: "IN05",
          line2: "Scour",
          line3: "Hole",
          sortable: true,
          key: "SCOUR_HOLE",
        },
        {
          line1: "IN14",
          line2: "OSA Foundation",
          line3: "Type",
          sortable: true,
          key: "FOUND_TYPE",
        },
        {
          line1: "IN15",
          line2: "Streambed",
          line3: "Material",
          sortable: true,
          key: "STREAM_BED_MATERIAL",
        },
        {
          line1: "IN16",
          line2: "UW Insp",
          line3: "Type",
          sortable: true,
          key: "UNDERWATER_INSP_TYPE",
        },
        {
          line1: null,
          line2: "Is UW",
          line3: "Req'd?",
          sortable: false,
          key: "UW_REQ",
        },
        {
          line1: "IN18",
          line2: "Water",
          line3: "Depth (ft)",
          sortable: true,
          key: "MAX_WATER_DEPTH",
        },
        {
          line1: "IU27",
          line2: "SCBI",
          line3: "Code",
          sortable: true,
          key: "SCBI_CODE",
        },
        {
          line1: null,
          line2: "Action",
          line3: null,
          sortable: false,
          key: "Action",
          width: "5%",
        },
      ],
    };
  },
  setup() {
    const configStore = useConfigStore();

    const inspectionStore = useInspectionStore();

    const isEditable = computed(() => {
      const servTypUnd = parseInt(structure.value?.Bridge.SERVTYPUND);
      if (servTypUnd >= 5 && servTypUnd <= 9) {
        return inspectionStore.isEditable;
      } else {
        return false;
      }
    });
    let currentSnackbarText = ref("");
    let potentialSnackbarText = ref("");
    let floodPlainSnackbarText = ref("");
    let structure = computed(() => inspectionStore.selectedInspection);
    const allPanels = [
      "waterwayConditions",
      "waterwayDetails",
      "measuresAndCountermeasures",
      "potentialCountermeasures",
      "500yrFloodPlain",
      "adequacyInfo",
    ];
    const panels = ref(allPanels);

    const commentsMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });

    const streambedMaterialMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.ELEM_DESC_MAX_LENGTH
      );
    });

    let sortByFloodPlain = ref([{ key: "SUB_UNIT", order: "asc" }]);

    function getSortIcon(id) {
      const item = sortByFloodPlain.value.find((item) => item.key === id);
      if (!item) {
        return { isSorted: false, sortType: "fa-sort-up" };
      } else {
        return item.order === "asc"
          ? { isSorted: true, sortType: "fa-sort-up" }
          : { isSorted: true, sortType: "fa-sort-down" };
      }
    }

    const subUnits = computed(() => {
      return inspectionStore.getSubUnits();
    });
    function getSubUnit(unitKey) {
      if (unitKey) {
        return (
          unitKey +
          " - " +
          inspectionStore.getSubStructure(unitKey)?.STRUNITLABEL
        );
      } else {
        return "";
      }
    }

    const pierAbutTypes = computed(() => {
      return configStore.getReferenceValues(
        REFERENCE_TABLE.PIER_ABUTMENT_TYPE,
        CONFIG_SORT_BY.VALUE,
        SORT_ORDER.ASC,
        false,
        TYPES.NUMBER
      );
    });

    const cMeasureLocations = computed(() => {
      return configStore.getReferenceValues(
        REFERENCE_TABLE.CURRENT_COUNTERMEASURE_LOCATION,
        CONFIG_SORT_BY.VALUE,
        SORT_ORDER.ASC,
        false,
        TYPES.NUMBER
      );
    });

    //Waterway Conditions Code

    let validWaterwayConditions = ref(true);
    let validWaterwayDetails = ref(true);
    let valid = ref(true);

    let modifyWaterwayConditions = ref(null);
    let modifyWaterwayDetails = ref(null);
    onMounted(async () => {
      await validate();
    });

    const scourCritical = computed({
      get() {
        return structure.value?.InspEvnt?.SCOURCRIT;
      },
      set(scourCriticalParam) {
        structure.value.InspEvnt.SCOURCRIT = scourCriticalParam;
      },
    });

    const scourCriticalCategory = computed({
      get() {
        if (
          !structure.value?.InspEvnt?.SCOUR_CRIT_CTGRY ||
          structure.value?.InspEvnt?.SCOUR_CRIT_CTGRY == ""
        ) {
          return "--";
        } else {
          return structure.value?.InspEvnt?.SCOUR_CRIT_CTGRY;
        }
      },
    });

    const scourPOARequired = computed({
      get() {
        if (scourCriticalCategory.value == "--") {
          return "0";
        } else if (
          scourCriticalCategory.value == "A" ||
          scourCriticalCategory.value == "B" ||
          scourCriticalCategory.value == "C" ||
          scourCriticalCategory.value == "D"
        ) {
          return "Y";
        } else {
          return "N";
        }
      },
    });

    let underwaterChangedByUser = ref(false);

    const underwater = computed({
      get() {
        if (
          !structure.value?.UserInsp?.UNDERWATER_RATING &&
          !underwaterChangedByUser.value
        ) {
          if (
            structure.value?.InspEvnt?.SUBRATING <
            structure.value?.InspEvnt?.CULVRATING
          ) {
            return structure.value?.InspEvnt?.SUBRATING;
          } else {
            return structure.value?.InspEvnt?.CULVRATING;
          }
        } else {
          return structure.value.UserInsp.UNDERWATER_RATING;
        }
      },
      set(underwaterParam) {
        structure.value.UserInsp.UNDERWATER_RATING = underwaterParam;
      },
    });

    const scbiSource = computed({
      get() {
        return structure.value?.T_Scour_Comp?.SCBI_SOURCE;
      },
      set(scbiSourceParam) {
        structure.value.T_Scour_Comp.SCBI_SOURCE = scbiSourceParam;
      },
    });

    const overallScbi = computed({
      get() {
        return structure.value?.T_Scour_Comp?.OVERALL_OSA;
      },
      set(overallScbiParam) {
        structure.value.T_Scour_Comp.OVERALL_OSA = overallScbiParam;
      },
    });

    const scourCalculated = computed({
      get() {
        return structure.value?.T_Scour_Comp?.SCOUR_CALCULATED;
      },
      set(scourCalculatedParam) {
        structure.value.T_Scour_Comp.SCOUR_CALCULATED = scourCalculatedParam;
      },
    });
    const overallScourHoles = computed({
      get() {
        return inspectionStore.getOverallScourHoles(structure.value);
      },
      set(overallScourParam) {
        structure.value.UserInsp.SCOUR_COND_RATING = overallScourParam;
      },
    });

    const scourVulnerability = computed({
      get() {
        return structure.value?.UserInsp?.SCOUR_VULNERABILITY;
      },
    });

    const channel = computed({
      get() {
        return structure.value?.InspEvnt?.CHANRATING;
      },
      set(channelParam) {
        structure.value.InspEvnt.CHANRATING = channelParam;
      },
    });

    const channelProtectionRating = computed({
      get() {
        return structure.value.UserInsp.CHAN_PROT_RATING;
      },
      set(channelProtectionRatingParam) {
        structure.value.UserInsp.CHAN_PROT_RATING =
          channelProtectionRatingParam;
      },
    });

    //Waterway Details Code

    const streamBedMaterialOne = computed({
      get() {
        return structure.value?.T_Scour_Comp?.STREAM_BED_MATERIAL_1;
      },
      set(streamBedMaterialOneParam) {
        structure.value.T_Scour_Comp.STREAM_BED_MATERIAL_1 =
          streamBedMaterialOneParam;
      },
    });

    const streamBedMaterialTwo = computed({
      get() {
        return structure.value?.T_Scour_Comp?.STREAM_BED_MATERIAL_2;
      },
      set(streamBedMaterialTwoParam) {
        structure.value.T_Scour_Comp.STREAM_BED_MATERIAL_2 =
          streamBedMaterialTwoParam;
      },
    });

    const streamBedMaterialDescription = computed({
      get() {
        return structure.value?.T_Scour_Comp?.STREAM_BED_MATERIAL_DESC;
      },
      set(streamBedMaterialDescriptionParam) {
        structure.value.T_Scour_Comp.STREAM_BED_MATERIAL_DESC =
          streamBedMaterialDescriptionParam;
      },
    });

    const numberOfSubunits = computed({
      get() {
        return structure.value?.T_Scour_Comp?.UNITS_INSPECTED_QTY;
      },
      set(numberOfSubunitsParam) {
        structure.value.T_Scour_Comp.UNITS_INSPECTED_QTY =
          numberOfSubunitsParam;
      },
    });

    const horizDebrisStart = computed({
      get() {
        return structure.value?.T_Scour_Comp?.HOR_DEBRIS_START_PCT;
      },
      set(horizDebrisStartParam) {
        structure.value.T_Scour_Comp.HOR_DEBRIS_START_PCT =
          horizDebrisStartParam;
      },
    });

    const horizDebrisEnd = computed({
      get() {
        return structure.value?.T_Scour_Comp?.HOR_DEBRIS_END_PCT;
      },
      set(horizDebrisEndParam) {
        structure.value.T_Scour_Comp.HOR_DEBRIS_END_PCT = horizDebrisEndParam;
      },
    });

    const vertDebrisStart = computed({
      get() {
        return structure.value?.T_Scour_Comp?.VERT_DEBRIS_START_PCT;
      },
      set(vertDebrisStartParam) {
        structure.value.T_Scour_Comp.VERT_DEBRIS_START_PCT =
          vertDebrisStartParam;
      },
    });

    const vertDebrisEnd = computed({
      get() {
        return structure.value?.T_Scour_Comp?.VERT_DEBRIS_END_PCT;
      },
      set(vertDebrisEndParam) {
        structure.value.T_Scour_Comp.VERT_DEBRIS_END_PCT = vertDebrisEndParam;
      },
    });

    const fabLoaction = computed({
      get() {
        return structure.value?.T_Scour_Comp?.FAB_LOCATION;
      },
      set(fabLoactionParam) {
        structure.value.T_Scour_Comp.FAB_LOCATION = fabLoactionParam;
      },
    });

    const nabLoaction = computed({
      get() {
        return structure.value?.T_Scour_Comp?.NAB_LOCATION;
      },
      set(nabLoactionParam) {
        structure.value.T_Scour_Comp.NAB_LOCATION = nabLoactionParam;
      },
    });

    const leftPresence = computed({
      get() {
        return structure.value?.T_Scour_Comp?.US_LEFT_WW_PRESENCE;
      },
      set(leftPresenceParam) {
        structure.value.T_Scour_Comp.US_LEFT_WW_PRESENCE = leftPresenceParam;
      },
    });
    const leftCondition = computed({
      get() {
        return structure.value?.T_Scour_Comp?.US_LEFT_WW_COND;
      },
      set(leftConditionParam) {
        structure.value.T_Scour_Comp.US_LEFT_WW_COND = leftConditionParam;
      },
    });
    const rightPresence = computed({
      get() {
        return structure.value?.T_Scour_Comp?.US_RIGHT_WW_PRESENCE;
      },
      set(rightPresenceParam) {
        structure.value.T_Scour_Comp.US_RIGHT_WW_PRESENCE = rightPresenceParam;
      },
    });
    const rightCondition = computed({
      get() {
        return structure.value?.T_Scour_Comp?.US_RIGHT_WW_COND;
      },
      set(rightConditionParam) {
        structure.value.T_Scour_Comp.US_RIGHT_WW_COND = rightConditionParam;
      },
    });

    //common table Code

    const onRowExpand = (event) => {
      //on clicking on row expansion icon - set the data to readonly.
      event.data.readOnly = true;
    };

    const closeConfirm = ref(null);

    //Current Scour Measures and Countermeasures Code

    let currentForm = ref(null);

    let validCurrent = ref(false);

    let expandedRowsCurrent = ref([]);

    let changesDetectedCurrent = ref(false);

    let measuresCurrent = ref([]);

    const getNewCurrent = () => {
      return {
        BRKEY: structure.value.Bridge.BRKEY,
        CMEASURE_COND: "",
        CMEASURE_KEY: structure.value.T_Scour_CMeasure.length,
        CMEASURE_LOCATION: "",
        CMEASURE_TYPE: "",
        INSPKEY: structure.value.Bridge.INSPKEY,
        PIER_NUM: null,
      };
    };

    const convertToCurrentObject = (detail) => {
      return {
        BRKEY: detail.BRKEY,
        CMEASURE_COND: detail.CMEASURE_COND,
        CMEASURE_KEY: detail.CMEASURE_KEY,
        CMEASURE_LOCATION: detail.CMEASURE_LOCATION,
        CMEASURE_TYPE: detail.CMEASURE_TYPE,
        INSPKEY: detail.INSPKEY,
        PIER_NUM: detail.PIER_NUM,
      };
    };

    const getMeasuresCurrent = () => {
      measuresCurrent.value = clone(structure.value.T_Scour_CMeasure);

      if (!measuresCurrent?.value?.[0]) {
        //remove null values from array(if any)
        measuresCurrent.value = measuresCurrent.value?.filter((a) => !!a);
      }
      for (let idx = 1; idx <= measuresCurrent.value?.length; idx++) {
        measuresCurrent.value[idx - 1].UNIQUE_KEY = idx;
        measuresCurrent.value[idx - 1].readOnly = true;
      }
    };
    getMeasuresCurrent();

    const rowClassCurrent = (data) => {
      return expandedRowsCurrent.value?.find(
        (a) => a.UNIQUE_KEY === data.UNIQUE_KEY
      )
        ? "rowSelect"
        : "rowUnselect";
    };

    const closeCurrent = () => {
      if (isEditable.value && changesDetectedCurrent.value) {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (!response) {
              getMeasuresCurrent();
              expandedRowsCurrent.value = [];
              changesDetectedCurrent.value = false;
              inspectionStore.setAddingOrEditing(false);
              currentSnackbarText.value = "";
            }
          });
      } else {
        getMeasuresCurrent();
        expandedRowsCurrent.value = [];
        inspectionStore.setAddingOrEditing(false);
        currentSnackbarText.value = "";
      }
    };

    const isExpandedCurrent = (details) => {
      return (
        expandedRowsCurrent.value?.findIndex(
          (a) => a.UNIQUE_KEY == details.UNIQUE_KEY
        ) >= 0
      );
    };

    function editCurrent(detail) {
      inspectionStore.setAddingOrEditing(true);
      detail.readOnly = false;
      expandedRowsCurrent.value = [detail];
      changesDetectedCurrent.value = false;

      currentSnackbarText.value =
        INSPECTION_MESSAGES.CURRENT_SCOUR_MEASURES_AND_COUNTERMEASURES_DATATABLE;
    }

    function deleteCurrent(detail) {
      const deleteIndex = structure.value.T_Scour_CMeasure.findIndex(
        (a) => a?.CMEASURE_KEY === detail.CMEASURE_KEY
      );
      if (deleteIndex > -1) {
        structure.value.T_Scour_CMeasure.splice(deleteIndex, 1);
        expandedRowsCurrent.value = [];
        getMeasuresCurrent();
      }
    }

    const anyEditableCurrent = computed(() => {
      return expandedRowsCurrent.value?.some((element) => !element.readOnly);
    });

    const toggleCurrentRow = (detail) => {
      if (expandedRowsCurrent.value?.includes(detail)) {
        const index = expandedRowsCurrent.value.findIndex(
          (a) => detail.UNIQUE_KEY == a.UNIQUE_KEY
        );
        expandedRowsCurrent.value.splice(index, 1);
      } else {
        expandedRowsCurrent.value.push(detail);
      }
    };

    function addCurrent() {
      inspectionStore.setAddingOrEditing(true);
      currentSnackbarText.value =
        INSPECTION_MESSAGES.CURRENT_SCOUR_MEASURES_AND_COUNTERMEASURES_DATATABLE;
      let newCurrent = getNewCurrent();
      newCurrent.isAdd = true;
      newCurrent.readOnly = false;
      newCurrent.UNIQUE_KEY = measuresCurrent.value.length + 1;
      measuresCurrent.value.unshift(newCurrent);
      expandedRowsCurrent.value = [newCurrent];
      changesDetectedCurrent.value = false;
      nextTick(() => {
        currentForm.value.validate();
      });
    }

    function saveCurrent(detail) {
      currentForm.value
        .validate()
        .then(() => {
          if (validCurrent.value) {
            inspectionStore.setShowValidationSnackbar(false);
            currentSnackbarText.value = "";
            if (!structure.value?.T_Scour_CMeasure?.[0]) {
              //remove null values from array(if any)
              structure.value.T_Scour_CMeasure =
                structure.value.T_Scour_CMeasure?.filter((a) => !!a);
            }
            if (detail.isAdd) {
              structure.value.T_Scour_CMeasure.push(
                convertToCurrentObject(detail)
              );
            } else {
              //update existing
              const updateIndex = measuresCurrent.value.findIndex(
                (a) => a?.UNIQUE_KEY === detail.UNIQUE_KEY
              );
              structure.value.T_Scour_CMeasure.splice(
                updateIndex,
                1,
                convertToCurrentObject(detail)
              );
            }
            //if all required fields entered - close the expanded row
            expandedRowsCurrent.value = [];
            getMeasuresCurrent();
            changesDetectedCurrent.value = false;
            inspectionStore.setAddingOrEditing(false);
          } else {
            inspectionStore.setShowValidationSnackbar(true);
          }
        })
        .catch((err) => LOGGER.logException(err));
    }

    //Potential Countermeasures Code

    let expandedRowsPotential = ref([]);

    let changesDetectedPotential = ref(false);

    let measuresPotential = ref([]);

    const getNewPotential = () => {
      return {
        BRKEY: structure.value.Bridge.BRKEY,
        INSPKEY: structure.value.Bridge.INSPKEY,
        LOCATION: "",
        PCM_KEY: structure.value.T_Scour_Poss_CMeasure.length,
        WCKEY: "",
      };
    };

    const convertToPotentialObject = (detail) => {
      return {
        BRKEY: detail.BRKEY,
        INSPKEY: detail.INSPKEY,
        LOCATION: detail.LOCATION,
        PCM_KEY: detail.PCM_KEY,
        WCKEY: detail.WCKEY,
      };
    };

    const getMeasuresPotential = () => {
      measuresPotential.value = clone(structure.value.T_Scour_Poss_CMeasure);

      if (!measuresPotential?.value?.[0]) {
        //remove null values from array(if any)
        measuresPotential.value = measuresPotential.value?.filter((a) => !!a);
      }
      for (let idx = 1; idx <= measuresPotential.value?.length; idx++) {
        measuresPotential.value[idx - 1].UNIQUE_KEY = idx;
        measuresPotential.value[idx - 1].readOnly = true;
      }
    };
    getMeasuresPotential();

    const rowClassPotential = (data) => {
      return expandedRowsPotential.value?.find(
        (a) => a.UNIQUE_KEY === data.UNIQUE_KEY
      )
        ? "rowSelect"
        : "rowUnselect";
    };

    const closePotential = () => {
      if (isEditable.value && changesDetectedPotential.value) {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (!response) {
              getMeasuresPotential();
              expandedRowsPotential.value = [];
              changesDetectedPotential.value = false;
              inspectionStore.setAddingOrEditing(false);
              potentialSnackbarText.value = "";
            }
          });
      } else {
        getMeasuresPotential();
        expandedRowsPotential.value = [];
        inspectionStore.setAddingOrEditing(false);
        potentialSnackbarText.value = "";
      }
    };

    const isExpandedPotential = (details) => {
      return (
        expandedRowsPotential.value?.findIndex(
          (a) => a.UNIQUE_KEY == details.UNIQUE_KEY
        ) >= 0
      );
    };

    function editPotential(detail) {
      inspectionStore.setAddingOrEditing(true);
      detail.readOnly = false;
      expandedRowsPotential.value = [detail];
      changesDetectedPotential.value = false;

      potentialSnackbarText.value =
        INSPECTION_MESSAGES.POTENTIAL_COUNTERMEASURES_DATATABLE;
    }

    function deletePotential(detail) {
      const deleteIndex = structure.value.T_Scour_Poss_CMeasure.findIndex(
        (a) => a?.PCM_KEY === detail.PCM_KEY
      );
      if (deleteIndex > -1) {
        structure.value.T_Scour_Poss_CMeasure.splice(deleteIndex, 1);
        expandedRowsPotential.value = [];
        getMeasuresPotential();
      }
    }

    const anyEditablePotential = computed(() => {
      return expandedRowsPotential.value?.find((element) => !element.readOnly);
    });

    const togglePotentialRow = (detail) => {
      if (expandedRowsPotential.value?.includes(detail)) {
        const index = expandedRowsPotential.value.findIndex(
          (a) => detail.UNIQUE_KEY == a.UNIQUE_KEY
        );
        expandedRowsPotential.value.splice(index, 1);
      } else {
        expandedRowsPotential.value.push(detail);
      }
    };

    function addPotential() {
      inspectionStore.setAddingOrEditing(true);
      potentialSnackbarText.value =
        INSPECTION_MESSAGES.POTENTIAL_COUNTERMEASURES_DATATABLE;
      let newPotential = getNewPotential();
      newPotential.isAdd = true;
      newPotential.readOnly = false;
      newPotential.UNIQUE_KEY = measuresPotential.value.length + 1;
      measuresPotential.value.unshift(newPotential);
      expandedRowsPotential.value = [newPotential];
      changesDetectedPotential.value = false;
    }

    function savePotential(detail) {
      if (!structure.value?.T_Scour_Poss_CMeasure?.[0]) {
        //remove null values from array(if any)
        structure.value.T_Scour_Poss_CMeasure =
          structure.value.T_Scour_Poss_CMeasure?.filter((a) => !!a);
      }
      if (detail.isAdd) {
        structure.value.T_Scour_Poss_CMeasure.push(
          convertToPotentialObject(detail)
        );
      } else {
        //update existing
        const updateIndex = measuresPotential.value.findIndex(
          (a) => a?.UNIQUE_KEY === detail.UNIQUE_KEY
        );
        structure.value.T_Scour_Poss_CMeasure.splice(
          updateIndex,
          1,
          convertToPotentialObject(detail)
        );
      }
      //if all required fields entered - close the expanded row
      expandedRowsPotential.value = [];
      getMeasuresPotential();
      changesDetectedPotential.value = false;
      inspectionStore.setAddingOrEditing(false);
      potentialSnackbarText.value = "";
    }

    //UnderwaterInsp Table Code

    let floodPlainTable = ref(null);

    let floodPlainForm = ref(null);

    let validFloodPlain = ref(false);

    let expandedRowsFloodPlain = ref([]);

    let floodPlains = ref([]);

    function getIsUwRequired(detail) {
      if (
        detail.UNDERWATER_INSP_TYPE == "A" ||
        detail.UNDERWATER_INSP_TYPE == "B" ||
        detail.UNDERWATER_INSP_TYPE == "C"
      ) {
        return { isUwRequired: "1" };
      } else {
        return { isUwRequired: "0" };
      }
    }

    const convertToFloodPlainObject = (detail) => {
      inspectionStore.getSubStructure(detail.STRUNITKEY).IN_500YR_FP = "1";
      return {
        ALIGNMENT: detail.ALIGNMENT,
        BRKEY: detail.BRKEY,
        CALC_SCOUR_DEPTH_100: detail.CALC_SCOUR_DEPTH_100,
        CALC_SCOUR_DEPTH_500: detail.CALC_SCOUR_DEPTH_500,
        CHG_SINCE_LAST_INSP: detail.CHG_SINCE_LAST_INSP,
        COUNTERMEASURES: detail.COUNTERMEASURES,
        DEBRIS_POTENTIAL: detail.DEBRIS_POTENTIAL,
        FOUND_TYPE: detail.FOUND_TYPE,
        INSPKEY: detail.INSPKEY,
        INV_FOUND_TYPE: detail.INV_FOUND_TYPE,
        MAX_WATER_DEPTH: detail.MAX_WATER_DEPTH,
        MODTIME: detail.MODTIME,
        MOVEMENT: detail.MOVEMENT,
        OBS_SCOUR_DEPTH: detail.OBS_SCOUR_DEPTH,
        OBS_SCOUR_RATING: detail.OBS_SCOUR_RATING,
        OPEN_ADEQ_CHANNEL: detail.OPEN_ADEQ_CHANNEL,
        PREVIOUS_CURRENT: detail.PREVIOUS_CURRENT,
        SAR: detail.SAR,
        SCBI_CASE: detail.SCBI_CASE,
        SCBI_CODE: detail.SCBI_CODE,
        SCOUR_HOLE: detail.SCOUR_HOLE,
        SCOUR_UNDERMINE: detail.SCOUR_UNDERMINE,
        SED_DEPOSIT: detail.SED_DEPOSIT,
        STREAM_BED_MATERIAL: detail.STREAM_BED_MATERIAL,
        STRUNITKEY: detail.STRUNITKEY,
        SUBUNIT_TYPE: detail.SUBUNIT_TYPE,
        SUB_SCOUR: detail.SUB_SCOUR,
        UNDERWATER_INSP_DESC: detail.UNDERWATER_INSP_DESC,
        UNDERWATER_INSP_TYPE: detail.UNDERWATER_INSP_TYPE,
        USERKEY: detail.USERKEY,
        VELO_STREAM_SLOPE: detail.VELO_STREAM_SLOPE,
      };
    };

    const getFloodPlains = () => {
      floodPlains.value = clone(structure.value.T_Underwater_Insp);

      if (!floodPlains.value) {
        //remove null values from array(if any)
        floodPlains.value = floodPlains.value?.filter((a) => !!a);
      }
      if (floodPlains.value) {
        for (let idx = 1; idx <= floodPlains.value.length; idx++) {
          floodPlains.value[idx - 1].UNIQUE_KEY = idx;
          floodPlains.value[idx - 1].SUB_UNIT = inspectionStore.getSubStructure(
            floodPlains.value[idx - 1]?.STRUNITKEY
          )?.STRUNITLABEL;

          floodPlains.value[idx - 1].readOnly = true;
        }
        nextTick(() => {
          floodPlainTable.value.d_sortOrder = 1;
          floodPlainTable.value.d_sortField = "SUB_UNIT";
        });
      }
    };
    getFloodPlains();

    const rowClassFloodPlain = (data) => {
      return expandedRowsFloodPlain.value?.find(
        (a) => a.UNIQUE_KEY === data.UNIQUE_KEY
      )
        ? "rowSelect"
        : "rowUnselect";
    };

    const closeFloodPlain = (detail) => {
      if (isEditable.value && detail.changesDetectedFloodPlain) {
        //show confirmation dialog on closing
        closeConfirm.value
          .open(
            INSPECTION_MESSAGES.DETECT_DATATABLE_CHANGE_CONFIRMATION_MESSAGE,
            BUTTONS.YES
          )
          .then((response) => {
            if (!response) {
              getFloodPlains();
              expandedRowsFloodPlain.value = [];
              detail.changesDetectedFloodPlain = false;
              floodPlainSnackbarText.value = "";
              inspectionStore.setAddingOrEditing(false);
            }
          });
      } else {
        getFloodPlains();
        expandedRowsFloodPlain.value = [];
        inspectionStore.setAddingOrEditing(false);
        floodPlainSnackbarText.value = "";
      }
    };

    const isExpandedFloodPlain = (details) => {
      return (
        expandedRowsFloodPlain.value?.findIndex(
          (a) => a.UNIQUE_KEY == details.UNIQUE_KEY
        ) >= 0
      );
    };

    function editFloodPlain(detail) {
      inspectionStore.setAddingOrEditing(true);
      detail.readOnly = false;
      expandedRowsFloodPlain.value = [detail];
      detail.changesDetectedFloodPlain = true;
      floodPlainSnackbarText.value =
        INSPECTION_MESSAGES.SUBUNITS_WITHIN_500YR_FLOOD_PLAIN_DATATABLE;
      nextTick(() => {
        floodPlainForm.value?.validate();
      });
    }

    function deleteFloodPlain(detail) {
      const deleteIndex = structure.value.T_Underwater_Insp.findIndex(
        (a) => a?.STRUNITKEY === detail?.STRUNITKEY
      );
      if (deleteIndex > -1) {
        structure.value.T_Underwater_Insp.splice(deleteIndex, 1);
        const structureUnitIndex = structure.value?.Structure_Unit?.findIndex(
          (a) => a?.STRUNITKEY === detail?.STRUNITKEY
        );
        structure.value.Structure_Unit[structureUnitIndex].IN_500YR_FP = "0";
        expandedRowsFloodPlain.value = [];
        calculateOnFloodPlainUpdate();
        getFloodPlains();
      }
    }

    const anyEditableFloodPlain = computed(() => {
      return expandedRowsFloodPlain.value?.find((element) => !element.readOnly);
    });

    const toggleFloodPlainsRow = (detail) => {
      if (expandedRowsFloodPlain.value?.includes(detail)) {
        const index = expandedRowsFloodPlain.value.findIndex(
          (a) => detail.UNIQUE_KEY == a.UNIQUE_KEY
        );
        expandedRowsFloodPlain.value.splice(index, 1);
      } else {
        expandedRowsFloodPlain.value.push(detail);
      }
    };

    function addFloodPlain() {
      inspectionStore.setAddingOrEditing(true);
      floodPlainSnackbarText.value =
        INSPECTION_MESSAGES.SUBUNITS_WITHIN_500YR_FLOOD_PLAIN_DATATABLE;
      let newFloodPlain = clone(underwaterInsp);
      newFloodPlain.BRKEY = structure.value.Bridge.BRKEY;
      newFloodPlain.INSPKEY = structure.value.InspEvnt.INSPKEY;
      newFloodPlain.MODTIME = structure.value.InspEvnt.MODTIME;
      newFloodPlain.MOVEMENT = "0";
      newFloodPlain.USERKEY = structure.value.InspEvnt.USERKEY;
      newFloodPlain.isAdd = true;
      newFloodPlain.readOnly = false;
      newFloodPlain.UNIQUE_KEY = floodPlains.value.length + 1;
      floodPlains.value.unshift(newFloodPlain);
      expandedRowsFloodPlain.value = [newFloodPlain];
      newFloodPlain.changesDetectedFloodPlain = true;
      nextTick(() => {
        floodPlainForm.value?.validate();
        floodPlainTable.value.d_sortOrder = 1;
        floodPlainTable.value.d_sortField = "SUB_UNIT";
      });
    }

    function saveFloodPlain(detail) {
      floodPlainForm.value
        .validate()
        .then(() => {
          if (validFloodPlain.value) {
            inspectionStore.setShowValidationSnackbar(false);
            floodPlainSnackbarText.value = "";
            if (!structure.value?.T_Underwater_Insp?.[0]) {
              //remove null values from array(if any)
              structure.value.T_Underwater_Insp =
                structure.value.T_Underwater_Insp?.filter((a) => !!a);
            }
            if (detail.isAdd) {
              structure.value.T_Underwater_Insp?.push(
                convertToFloodPlainObject(detail)
              );
            } else {
              //update existing
              const updateIndex = floodPlains.value.findIndex(
                (a) => a?.UNIQUE_KEY === detail.UNIQUE_KEY
              );
              structure.value.T_Underwater_Insp?.splice(
                updateIndex,
                1,
                convertToFloodPlainObject(detail)
              );
            }
            //if all required fields entered - close the expanded row
            expandedRowsFloodPlain.value = [];
            if (detail.changesDetectedFloodPlain) {
              calculateOnFloodPlainUpdate();
              detail.changesDetectedFloodPlain = false;
            }
            getFloodPlains();
            inspectionStore.setAddingOrEditing(false);
          } else {
            inspectionStore.setShowValidationSnackbar(true);
          }
        })
        .catch((err) => LOGGER.logException(err));
    }

    //Channel and Waterway Adequacy Information Code

    const channelRating = computed({
      get() {
        return structure.value?.InspEvnt?.CHANRATING;
      },
      set(channelRatingParam) {
        structure.value.InspEvnt.CHANRATING = channelRatingParam;
      },
    });
    const waterwayRating = computed({
      get() {
        return structure.value?.InspEvnt?.WATERADEQ;
      },
      set(waterwayRatingParam) {
        structure.value.InspEvnt.WATERADEQ = waterwayRatingParam;
      },
    });

    const riskOfOvertopping = computed({
      get() {
        return structure.value?.T_Scour_Comp?.OVERTOP_RISK;
      },
      set(riskOfOvertoppingParam) {
        structure.value.T_Scour_Comp.OVERTOP_RISK = riskOfOvertoppingParam;
      },
    });

    const trafficDelay = computed({
      get() {
        return structure.value?.T_Scour_Comp?.TRAFFIC_DELAY;
      },
      set(trafficDelayParam) {
        structure.value.T_Scour_Comp.TRAFFIC_DELAY = trafficDelayParam;
      },
    });

    const worstFloodEvent = computed({
      get() {
        return structure.value?.T_Scour_Comp?.OVERTOPPING;
      },
      set(worstFloodEventParam) {
        structure.value.T_Scour_Comp.OVERTOPPING = worstFloodEventParam;
      },
    });

    const worstFloodEventDate = computed({
      get() {
        return structure.value?.T_Scour_Comp?.OVERTOP_DATE;
      },
      set(worstFloodEventDateParam) {
        structure.value.T_Scour_Comp.OVERTOP_DATE = worstFloodEventDateParam;
      },
    });

    const functionClass = computed({
      get() {
        return structure.value?.Roadway?.find((a) => a.ON_UNDER === "1")
          .FUNCCLASS;
      },
    });

    const elevationOfWaterLevel = computed({
      get() {
        return structure.value?.T_Scour_Comp?.HIGH_WATER_ELEV;
      },
      set(elevationOfWaterLevelParam) {
        structure.value.T_Scour_Comp.HIGH_WATER_ELEV =
          elevationOfWaterLevelParam;
      },
    });

    const highWaterDate = computed({
      get() {
        return structure.value?.T_Scour_Comp?.HIGH_WATER_DATE;
      },
      set(highWaterDateParam) {
        structure.value.T_Scour_Comp.HIGH_WATER_DATE = highWaterDateParam;
      },
    });

    const newHighWaterMark = computed({
      get() {
        return structure.value?.T_Scour_Comp?.NEW_HIGH_WATER;
      },
      set(newHighWaterMarkParam) {
        structure.value.T_Scour_Comp.NEW_HIGH_WATER = newHighWaterMarkParam;
      },
    });

    let subScourRating = computed({
      get() {
        let obsScourRatings = [];
        if (structure.value?.T_Underwater_Insp) {
          for (let underwaterInspection of structure.value.T_Underwater_Insp) {
            obsScourRatings.push(
              parseInt(underwaterInspection?.OBS_SCOUR_RATING)
            );
          }
        }

        if (obsScourRatings == []) {
          return -1;
        } else {
          return Math.min(...obsScourRatings);
        }
      },
    });

    function calculateScourCriticalCategory() {
      if (subScourRating.value == -1) {
        structure.value.InspEvnt.SCOUR_CRIT_CTGRY = "--";
      } else if (
        parseInt(structure.value?.InspEvnt?.SCOURCRIT) <= 2 ||
        parseInt(structure.value?.InspEvnt?.SCOURCRIT) == 6 ||
        subScourRating.value <= 3
      ) {
        structure.value.InspEvnt.SCOUR_CRIT_CTGRY = "A";
      } else if (
        parseInt(structure.value?.InspEvnt?.SCOURCRIT) == 3 &&
        subScourRating.value == 4
      ) {
        structure.value.InspEvnt.SCOUR_CRIT_CTGRY = "B";
      } else if (
        parseInt(structure.value?.InspEvnt?.SCOURCRIT) == 3 &&
        subScourRating.value <= 9 &&
        subScourRating.value >= 5
      ) {
        structure.value.InspEvnt.SCOUR_CRIT_CTGRY = "C";
      } else if (
        parseInt(structure.value?.InspEvnt?.SCOURCRIT) == 4 ||
        parseInt(structure.value?.InspEvnt?.SCOURCRIT) == 7
      ) {
        structure.value.InspEvnt.SCOUR_CRIT_CTGRY = "D";
      } else {
        structure.value.InspEvnt.SCOUR_CRIT_CTGRY = "--";
      }
    }

    function calculateOverallSCBI() {
      configStore.getScourCriticalBridgeIndicator(structure.value);
    }

    function calculate() {
      if (structure.value?.T_Scour_Comp?.SCOUR_CALCULATED == "0") {
        structure.value.T_Scour_Comp.SCOUR_CALCULATED = "1";
      }
      calculateOverallSCBI();
      calculateScourCriticalCategory();
      if (expandedRowsFloodPlain.value.length == 0) {
        getFloodPlains();
      }
      inspectionStore.setSaved(false);
      inspectionStore.setSavingSnackbar(true);
      inspectionStore.setShowCalculate(true);
    }

    function calculateOnFloodPlainUpdate() {
      if (structure.value?.T_Scour_Comp?.SCOUR_CALCULATED == "1") {
        calculateOverallSCBI();
        calculateScourCriticalCategory();
        if (expandedRowsFloodPlain.value.length == 0) {
          getFloodPlains();
        }
        inspectionStore.setSaved(false);
        inspectionStore.setSavingSnackbar(true);
        inspectionStore.setShowCalculate(true);
      }
    }

    //Validation

    async function validate() {
      await modifyWaterwayConditions.value.validate();
      await modifyWaterwayDetails.value.validate();
      if (validWaterwayConditions.value && validWaterwayDetails.value) {
        valid.value = true;
      } else {
        valid.value = false;
      }
    }

    const checkCurrentScourValidation = (value) => {
      //add validation only when the expansion panel is expanded(value = true)
      if (value) {
        nextTick(() => {
          currentForm.value?.validate();
        });
      }
    };

    const resetChangedByUser = async () => {
      underwaterChangedByUser.value = false;
      inspectionStore.setOverallScourHolesChangedByUser(false);
    };

    const checkFloodPlainFormValidation = (value) => {
      //add validation only when the expansion panel is expanded(value = true)
      if (value) {
        nextTick(() => {
          floodPlainForm.value?.validate();
        });
      }
    };
    let channelConditionUpdated = ref(false);
    let waterwayConditionUpdated = ref(false);
    let conditionUpdated = ref(false);
    let scbiUpdated = ref(false);
    const updateDuration = () => {
      if (!structure.value.T_Insp_Condition_Hist) {
        structure.value.T_Insp_Condition_Hist = {};
      }
      if (scbiUpdated.value) {
        structure.value.T_Insp_Condition_Hist.SCOUTCRIT_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.SCOUTCRIT_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.SCOUTCRIT_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.SCOUTCRIT_DURATION,
            structure.value.T_Insp_Condition_Hist.SCOUTCRIT_DURATION_CHANGES
          );
      }
      if (channelConditionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.CHANRATE_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.CHANRATE_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.CHANRATE_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.CHANRATE_DURATION,
            structure.value.T_Insp_Condition_Hist.CHANRATE_DURATION_CHANGES
          );
      }
      if (waterwayConditionUpdated.value) {
        structure.value.T_Insp_Condition_Hist.WADEQ_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            structure.value.T_Insp_Condition_Hist.WADEQ_DURATION_CHANGES
          );
        structure.value.T_Insp_Condition_Hist.WADEQ_DURATION =
          inspectionStore.setDuration(
            structure.value.T_Insp_Condition_Hist.WADEQ_DURATION,
            structure.value.T_Insp_Condition_Hist.WADEQ_DURATION_CHANGES
          );
      }
      channelConditionUpdated.value = false;
      waterwayConditionUpdated.value = false;
      scbiUpdated.value = false;
      conditionUpdated.value = false;
    };
    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panels.value = allPanels;
      } else {
        panels.value = [];
      }
    };

    watch(
      () => [
        structure.value.T_Underwater_Insp,
        structure.value.T_Scour_Poss_CMeasure,
        structure.value.T_Scour_Comp,
        structure.value.InspEvnt,
        structure.value.T_Scour_CMeasure,
        structure.value.UserInsp,
        structure.value.T_Insp_Comment,
      ],
      () => {
        inspectionStore.setDirtyFlag(true);
      },
      { deep: true }
    );

    return {
      getMeasuresCurrent,
      getMeasuresPotential,
      getFloodPlains,
      scbiUpdated,
      channelConditionUpdated,
      waterwayConditionUpdated,
      conditionUpdated,
      updateDuration,
      resetChangedByUser,
      underwaterChangedByUser,
      modifyWaterwayDetails,
      validWaterwayDetails,
      getSortIcon,
      sortByFloodPlain,
      toggleFloodPlainsRow,
      togglePotentialRow,
      toggleCurrentRow,
      streambedMaterialMaxLength,
      cMeasureLocations,
      pierAbutTypes,
      PAIR_CODE,
      floodPlainTable,
      calculateOverallSCBI,
      getIsUwRequired,
      calculateScourCriticalCategory,
      newHighWaterMark,
      highWaterDate,
      elevationOfWaterLevel,
      getHistDuration,
      functionClass,
      worstFloodEventDate,
      worstFloodEvent,
      trafficDelay,
      riskOfOvertopping,
      waterwayRating,
      channelProtectionRating,
      ROUNDING_FACTOR,
      channelRating,
      validFloodPlain,
      floodPlainForm,
      inspectionStore,
      saveFloodPlain,
      deleteFloodPlain,
      closeFloodPlain,
      editFloodPlain,
      isExpandedFloodPlain,
      anyEditableFloodPlain,
      rowClassFloodPlain,
      onRowExpand,
      floodPlains,
      addFloodPlain,
      expandedRowsFloodPlain,
      changesDetectedPotential,
      getNewPotential,
      savePotential,
      deletePotential,
      closePotential,
      editPotential,
      isExpandedPotential,
      anyEditablePotential,
      rowClassPotential,
      measuresPotential,
      addPotential,
      expandedRowsPotential,
      closeConfirm,
      changesDetectedCurrent,
      getNewCurrent,
      getSubUnit,
      subUnits,
      configStore,
      validCurrent,
      currentForm,
      saveCurrent,
      deleteCurrent,
      BUTTONS,
      closeCurrent,
      editCurrent,
      isExpandedCurrent,
      anyEditableCurrent,
      rowClassCurrent,
      measuresCurrent,
      addCurrent,
      expandedRowsCurrent,
      rightCondition,
      rightPresence,
      leftCondition,
      leftPresence,
      nabLoaction,
      fabLoaction,
      vertDebrisEnd,
      vertDebrisStart,
      horizDebrisEnd,
      horizDebrisStart,
      numberOfSubunits,
      streamBedMaterialDescription,
      streamBedMaterialTwo,
      streamBedMaterialOne,
      channel,
      scourCalculated,
      overallScbi,
      scbiSource,
      scourCriticalCategory,
      scourCritical,
      scourVulnerability,
      overallScourHoles,
      underwater,
      calculate,
      modifyWaterwayConditions,
      valid,
      validWaterwayConditions,
      validate,
      REFERENCE_TABLE,
      panels,
      isEditable,
      structure,
      commentsMaxLength,
      INSPECTION_COMMENTS,
      currentSnackbarText,
      potentialSnackbarText,
      floodPlainSnackbarText,
      checkCurrentScourValidation,
      checkFloodPlainFormValidation,
      scourPOARequired,
      toggleAllPanels,
    };
  },
  components: {
    DataTableHeader,
    IconTooltip,
    LabelCheckbox,
    LabelText,
    LabelSelect,
    CommentTypeTextField,
    ConfirmDialog,
    LabelDatefield,
    ExpandCollapseToggle,
  },
};
</script>

<style scoped lang="scss">
@use "@/styles/colors" as c;

h2 {
  order: 1;
  color: c.$p-white !important;
}

.v-expansion-panel-title__icon {
  order: 0;
}

.tableHeader {
  text-align: center !important;
  background: c.$p-pa-light-blue !important;
}

.v-label.v-field-label {
  color: c.$p-black !important;
  opacity: 1 !important;
  font-weight: bold !important;
}
.alignCenter {
  text-align: center;
}
.alignEnd {
  text-align: end;
}
.v-data-table :deep(button:hover) {
  background-color: inherit !important;
  color: inherit !important;
}

.v-data-table :deep(button:focus) {
  outline: none !important;
}
</style>
