<template>
  <v-row dense>
    <v-col sm="6" md="6" lg="4" class="d-flex align-content-center flex-wrap">
      <label
        >Parameter Search:
        <v-tooltip>
          <template v-slot:activator="{ props }">
            <v-icon
              id="icon_SearchByParamsInfo"
              class="ml-2 infoIcon"
              icon="fas fa-circle-info"
              v-bind="props"
            />
          </template>
          <span
            >Options available in the County, Municipality, Route Number,
            Inspection Agreement Number and Report group are filtered by
            selection(s) in District and/or County.
          </span>
        </v-tooltip>
      </label>
    </v-col>
    <v-col>
      <v-alert v-model="message" type="warning" icon="false" closable>
        Some previously selected parameters have been removed to align with the
        parameters selected above.
      </v-alert></v-col
    >
  </v-row>

  <v-row dense>
    <v-col md="12" lg="10" xl="6">
      <label>5A04 District:</label>
      <v-autocomplete
        id="sel_district"
        :items="districts"
        v-model="selectedDistricts"
        dense
        chips
        small-chips
        closable-chips
        multiple
        label="District"
        single-line
      />
    </v-col>
  </v-row>
  <v-row dense>
    <v-col md="12" lg="10" xl="6">
      <label>5A05 County:</label>
      <v-autocomplete
        id="sel_county"
        :items="counties"
        v-model="selectedCounties"
        dense
        chips
        small-chips
        closable-chips
        multiple
        label="County"
        single-line
      />
    </v-col>
  </v-row>

  <v-row dense>
    <v-col md="12" lg="10" xl="6">
      <label>5A06 Municipality:</label>
      <v-autocomplete
        id="sel_municipality"
        :items="municipalities"
        v-model="selectedMunicipalities"
        dense
        chips
        small-chips
        closable-chips
        multiple
        label="Municipality"
        single-line
      >
        <template v-slot:append-item>
          <div v-intersect="onIntersect" />
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>

  <v-row dense>
    <v-col md="12" lg="10" xl="6">
      <label>Route Number - Digits 3 through 6 of BMS ID:</label>
      <v-autocomplete
        id="sel_routeNumber"
        :items="routes"
        v-model="selectedRoutes"
        dense
        chips
        small-chips
        closable-chips
        multiple
        label="Route Number"
        single-line
      /> </v-col
  ></v-row>

  <v-row dense>
    <v-col md="12" lg="10" xl="6">
      <label>6B25 Inspection Agreement Number:</label>

      <v-autocomplete
        id="sel_agreementContractNumber"
        :items="contractNumbers"
        v-model="selectedAgreementContracts"
        dense
        chips
        small-chips
        closable-chips
        multiple
        label="Agreement Contract Number"
        single-line
      /> </v-col
  ></v-row>

  <v-row dense>
    <v-col md="12" lg="10" xl="6">
      <label>5A24 Report Group:</label>

      <v-autocomplete
        id="sel_reportGroup"
        :items="reportingGroups"
        v-model="selectedReportGroups"
        dense
        chips
        closable-chips
        multiple
        label="Report Group"
        single-line
      />
    </v-col>
  </v-row>

  <v-row dense>
    <v-col cols="12">
      <v-btn
        id="btn_retrieveList"
        variant="outlined"
        size="large"
        @click="search()"
      >
        Retrieve List
      </v-btn>
      <v-btn
        id="btn_clearAll"
        variant="outlined"
        size="large"
        class="ml-4"
        @click="resetForm"
      >
        Clear All
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  watchEffect,
  watchPostEffect,
} from "vue";
import { useConfigStore } from "@/stores/config";
import { useStructureSearchStore } from "@/stores/structureSearch";
import { STRUCTURE_SEARCH_MESSAGES } from "@/constants/StructureSearches";
import { scrollToElement } from "@/util/scrollToElement";

export default defineComponent({
  emits: ["showMessage"],
  setup(_, { emit }) {
    const configStore = useConfigStore();
    const structureSearchStore = useStructureSearchStore();
    const selectedDistricts = ref([]);
    const selectedCounties = ref([]);
    const selectedMunicipalities = ref([]);
    const selectedRoutes = ref([]);
    const selectedAgreementContracts = ref([]);
    const selectedReportGroups = ref([]);
    const message = ref(false);
    const districts = computed(() =>
      Array.from(configStore.districts, ([value, title]) => ({
        value,
        title,
      }))
    );
    const counties = ref([]);
    const countyLookup = ref(null);
    const countyLookupForMunicipalities = ref("01");
    const municipalities = ref(
      configStore.getMunicipalitiesByCounty(countyLookupForMunicipalities.value)
    );
    let intersecting = false;
    const routes = ref([]);
    const contractNumbers = ref([]);
    const reportingGroups = ref([]);

    function onIntersect() {
      if (selectedCounties.value.length == 0 && selectedDistricts.value == 0) {
        intersecting = true;
        let currentIndex = counties.value.findIndex((a) => {
          return a.value == countyLookupForMunicipalities.value;
        });
        countyLookupForMunicipalities.value =
          counties.value[currentIndex + 1].value;
        municipalities.value = [
          ...municipalities.value,
          ...configStore.getMunicipalitiesByCounty(
            countyLookupForMunicipalities.value
          ),
        ];
      }
    }

    watchPostEffect(() => {
      //Check for filtered counties
      if (
        !selectedCounties.value.every((county) =>
          counties.value.find((c) => c.value === county)
        )
      ) {
        message.value = true;
        selectedCounties.value = selectedCounties.value.filter((x) =>
          counties.value.includes(x)
        );
      }
    });
    watchPostEffect(() => {
      //Check for filtered municipalities
      if (
        !selectedMunicipalities.value.every((municipality) =>
          municipalities.value.find((m) => m.value === municipality)
        )
      ) {
        message.value = true;
        selectedMunicipalities.value = selectedMunicipalities.value.filter(
          (s) => municipalities.value.find((m) => m.value === s)
        );
      }
    });
    watchPostEffect(() => {
      //Check for filtered routes
      if (
        !selectedRoutes.value.every((route) => routes.value.includes(route))
      ) {
        message.value = true;
        selectedRoutes.value = selectedRoutes.value.filter((x) =>
          routes.value.includes(x)
        );
      }
    });
    watchPostEffect(() => {
      //Check for filtered agreement contracts
      if (
        !selectedAgreementContracts.value.every((contract) =>
          contractNumbers.value.includes(contract)
        )
      ) {
        message.value = true;
        selectedAgreementContracts.value =
          selectedAgreementContracts.value.filter((x) =>
            contractNumbers.value.includes(x)
          );
      }
    });
    watchPostEffect(() => {
      //Check for filtered reporting groups
      if (
        !selectedReportGroups.value.every((reportGroup) =>
          reportingGroups.value.find((r) => r.value === reportGroup)
        )
      ) {
        message.value = true;
        selectedReportGroups.value = selectedReportGroups.value.filter((x) =>
          reportingGroups.value.includes(x)
        );
      }
    });

    watchEffect(async () => {
      counties.value = configStore.getCountiesByDistrict(
        selectedDistricts.value
      );
      countyLookup.value =
        selectedCounties.value.length > 0
          ? selectedCounties.value
          : counties.value.map((i) => {
              return i.value;
            });
      if (selectedCounties.value.length > 0 || selectedDistricts.value > 0) {
        countyLookupForMunicipalities.value = countyLookup.value;
        municipalities.value = configStore.getMunicipalitiesByCounty(
          countyLookupForMunicipalities.value
        );
        intersecting = false;
      } else if (
        selectedCounties.value.length == 0 &&
        selectedDistricts.value == 0 &&
        !intersecting
      ) {
        countyLookupForMunicipalities.value = "01";
        municipalities.value = configStore.getMunicipalitiesByCounty(
          countyLookupForMunicipalities.value
        );
      }
      routes.value = await structureSearchStore.getRouteNumber(
        countyLookup.value
      );
      contractNumbers.value =
        await structureSearchStore.getAgreementContractNumber(
          countyLookup.value
        );
      reportingGroups.value =
        await structureSearchStore.getReportingGroupByCounty(
          countyLookup.value
        );
    });

    const search = async () => {
      structureSearchStore.resetSelectedStructures();
      await structureSearchStore.getStructureSearchByParameters(
        selectedDistricts.value,
        selectedCounties.value,
        selectedMunicipalities.value,
        selectedRoutes.value,
        selectedAgreementContracts.value,
        selectedReportGroups.value
      );
      message.value = false;
      showMessage();
    };

    const resetForm = () => {
      message.value = false;
      selectedDistricts.value = [];
      selectedCounties.value = [];
      selectedMunicipalities.value = [];
      selectedRoutes.value = [];
      selectedAgreementContracts.value = [];
      selectedReportGroups.value = [];
    };
    function showMessage() {
      let dialogMsg = {
        value: null,
        type: null,
        show: true,
      };

      if (structureSearchStore.getStructureSearchResults.length > 0) {
        dialogMsg.show = false;
        scrollToElement("search_results_header");
      } else {
        dialogMsg.value = STRUCTURE_SEARCH_MESSAGES.PARAMS_SEARCH_NO_RESULT;
        dialogMsg.type = "error-dialog";
      }
      emit("showMessage", dialogMsg);
    }

    return {
      countyLookupForMunicipalities,
      onIntersect,
      showMessage,
      districts,
      counties,
      municipalities,
      reportingGroups,
      selectedDistricts,
      selectedCounties,
      selectedMunicipalities,
      selectedRoutes,
      selectedAgreementContracts,
      selectedReportGroups,
      search,
      resetForm,
      structureSearchStore,
      countyLookup,
      message,
      routes,
      contractNumbers,
    };
  },
});
</script>
