<template>
  <v-form v-model="valid" ref="modifyUnderwaterInspValidation">
    <v-row class="pt-3">
      <v-col xl="6" lg="6" md="12" sm="12" cols="12">
        <LabelText
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :id="`sub_unit_Id_${id}`"
          :isEditable="false"
          label="IN01 Sub Unit"
          v-model="subUnitId"
        >
        </LabelText>
        <LabelCheckbox
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          class="labelCheckbox"
          :id="`is_current_${id}`"
          label="IN02 Current Inspection?"
          :showColon="false"
          editIndent
          :isEditable="isEditable"
          v-model="underwaterInspection.PREVIOUS_CURRENT"
          @update:modelValue="updateStructureObject()"
        >
        </LabelCheckbox>

        <LabelText
          class="pt-1"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :id="`scbi_code_${id}`"
          :isEditable="false"
          label="IU27 SCBI Code"
          v-model="underwaterInspection.SCBI_CODE"
        >
        </LabelText>
        <LabelText
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :id="`scbi_case_${id}`"
          :isEditable="false"
          label="IU28 SCBI Case"
          v-model="underwaterInspection.SCBI_CASE"
        >
        </LabelText>
        <LabelSelect
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :isRequired="isEditable ? true : false"
          :id="`scour_rating_${id}`"
          label="IN03 Scour Rating"
          :refTable="REFERENCE_TABLE.OBSERVED_SCOUR_RATING"
          :isEditable="isEditable"
          v-model="underwaterInspection.OBS_SCOUR_RATING"
          @update:modelValue="updateStructureObject()"
        ></LabelSelect>
        <LabelSelect
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :isRequired="isEditable ? true : false"
          :id="`change_since_last_insp_${id}`"
          label="IN04 Change Since Last Insp"
          :refTable="REFERENCE_TABLE.CHANGE_SINCE_LAST_INSP"
          :isEditable="isEditable"
          v-model="underwaterInspection.CHG_SINCE_LAST_INSP"
          @update:modelValue="updateStructureObject()"
        ></LabelSelect>
        <LabelSelect
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :isRequired="isEditable ? true : false"
          :id="`scour_hole_${id}`"
          label="IN05 Scour hole"
          :refTable="REFERENCE_TABLE.SCOUR_HOLE"
          :isEditable="isEditable"
          v-model="underwaterInspection.SCOUR_HOLE"
          @update:modelValue="updateStructureObject()"
        ></LabelSelect>
        <LabelSelect
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :isRequired="isEditable ? true : false"
          :id="`debris_potn_${id}`"
          label="IN06 Debris Potential"
          :refTable="REFERENCE_TABLE.DEBRIS_POTENTIAL"
          :isEditable="isEditable"
          v-model="underwaterInspection.DEBRIS_POTENTIAL"
          @update:modelValue="updateStructureObject()"
        ></LabelSelect>
        <LabelSelect
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :isRequired="isEditable ? true : false"
          :id="`scourability_${id}`"
          label="IN07 Scourability"
          :refTable="REFERENCE_TABLE.SUBSTRUCTURE_SCOURABILITY"
          :isEditable="isEditable"
          v-model="underwaterInspection.SUB_SCOUR"
          @update:modelValue="updateStructureObject()"
        ></LabelSelect>
        <LabelSelect
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :isRequired="isEditable ? true : false"
          :id="`opening_adeq_channel_${id}`"
          label="IN08 Opening Adeq Channel"
          :refTable="REFERENCE_TABLE.OPENING_ADEQUACY_CHANNEL"
          :isEditable="isEditable"
          v-model="underwaterInspection.OPEN_ADEQ_CHANNEL"
          @update:modelValue="updateStructureObject()"
        ></LabelSelect>
        <LabelSelect
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :isRequired="isEditable ? true : false"
          :id="`seidment_${id}`"
          label="IN09 Sediment"
          :refTable="REFERENCE_TABLE.SEDIMENT_DEPOSITS"
          :isEditable="isEditable"
          v-model="underwaterInspection.SED_DEPOSIT"
          @update:modelValue="updateStructureObject()"
        ></LabelSelect>
        <LabelSelect
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :isRequired="isEditable ? true : false"
          :id="`alignment_${id}`"
          label="IN10 Alignment"
          :refTable="REFERENCE_TABLE.ALIGNMENT"
          :isEditable="isEditable"
          v-model="underwaterInspection.ALIGNMENT"
          @update:modelValue="updateStructureObject()"
        ></LabelSelect>
        <LabelSelect
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :isRequired="isEditable ? true : false"
          :id="`velocity_stream_slope_${id}`"
          label="IN11 Velocity Stream Slope"
          :refTable="REFERENCE_TABLE.VELOCITY_STREAM_SLOPE"
          :isEditable="isEditable"
          v-model="underwaterInspection.VELO_STREAM_SLOPE"
          @update:modelValue="updateStructureObject()"
        ></LabelSelect>
      </v-col>
      <v-col xl="6" lg="6" md="12" sm="12" cols="12">
        <LabelSelect
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :isRequired="isEditable ? true : false"
          :id="`subunit_Type_${id}`"
          label="IN12 Pier/Abutment Type"
          :options="pierAbutTypes"
          :isEditable="isEditable"
          v-model="underwaterInspection.SUBUNIT_TYPE"
          @update:modelValue="updateStructureObject()"
        ></LabelSelect>
        <LabelSelect
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :isRequired="isEditable ? true : false"
          :id="`inv_found_type_${id}`"
          label="IN13 Inv Foundation Type"
          :refTable="REFERENCE_TABLE.PA_FOUNDATION_TYPE"
          :isEditable="isEditable"
          v-model="paFoundationType"
        ></LabelSelect>
        <LabelSelect
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :id="`found_type_${id}`"
          :isEditable="false"
          :refTable="REFERENCE_TABLE.OSA_FOUNDATION_TYPE"
          label="IN14 OSA Foundation Type"
          v-model="foundType"
        >
        </LabelSelect>
        <LabelSelect
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :isRequired="isEditable ? true : false"
          :id="`streambed_material_${id}`"
          label="IN15 Streambed Material"
          :refTable="REFERENCE_TABLE.STREAM_BED_MATERIAL"
          :isEditable="isEditable"
          v-model="underwaterInspection.STREAM_BED_MATERIAL"
          @update:modelValue="updateStructureObject()"
        ></LabelSelect>
        <LabelSelect
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :isRequired="isEditable ? true : false"
          :id="`uw_insp_type_${id}`"
          label="IN16 UW Inspection Type"
          :refTable="REFERENCE_TABLE.UNDERWATER_INSPECTION_TYPE"
          :isEditable="isEditable"
          v-model="underwaterInspection.UNDERWATER_INSP_TYPE"
          @update:modelValue="updateStructureObject()"
        ></LabelSelect>
        <LabelText
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :isRequired="isEditable ? true : false"
          :id="`ob_scour_depth_${id}`"
          :type="'number'"
          appendWith=" ft"
          :pairCode="PAIR_CODE.METERS_TO_FEET"
          :decimalPlaces="1"
          :isEditable="isEditable"
          label="IN17 Observed Scour Depth"
          v-model="underwaterInspection.OBS_SCOUR_DEPTH"
          @update:modelValue="updateStructureObject()"
        />
        <LabelText
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :isRequired="isEditable ? true : false"
          :id="`max_water_depth_${id}`"
          :type="'number'"
          appendWith=" ft"
          :decimalPlaces="1"
          :pairCode="PAIR_CODE.METERS_TO_FEET"
          :isEditable="isEditable"
          label="IN18 Water Depth"
          v-model="underwaterInspection.MAX_WATER_DEPTH"
          @update:modelValue="updateStructureObject()"
        />
        <LabelCheckbox
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          class="labelCheckbox"
          :id="`movement_${id}`"
          label="IN19 Movement Indicator"
          :isEditable="isEditable"
          v-model="underwaterInspection.MOVEMENT"
          @update:modelValue="updateStructureObject()"
        >
        </LabelCheckbox>
        <LabelCheckbox
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          class="labelCheckbox"
          :id="`scour_undermine_ind_${id}`"
          label="IN20 Scour/Undermine Indicator"
          :isEditable="isEditable"
          v-model="underwaterInspection.SCOUR_UNDERMINE"
          @update:modelValue="updateStructureObject()"
        >
        </LabelCheckbox>
        <LabelCheckbox
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          class="labelCheckbox"
          :id="`countermeasures_${id}`"
          label="IN21 Countermeasures"
          :isEditable="isEditable"
          v-model="underwaterInspection.COUNTERMEASURES"
          @update:modelValue="updateStructureObject()"
        >
        </LabelCheckbox>
        <LabelText
          class="pt-1"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :id="`onehundred_year_flood_depth_${id}`"
          :type="'number'"
          appendWith=" ft"
          :decimalPlaces="1"
          :isEditable="isEditable"
          :showPlaceholder="true"
          label="IN22 100 YR Flood Scour Depth"
          v-model="calcScourDepthOneHundred"
        />
        <LabelText
          class="pt-2"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :textXl="6"
          :textLg="5"
          :textMd="6"
          :textSm="6"
          :id="`fivehundred_year_flood_depth_${id}`"
          :type="'number'"
          appendWith=" ft"
          :decimalPlaces="1"
          :isEditable="isEditable"
          :showPlaceholder="true"
          label="IN23 500 YR Flood Scour Depth"
          v-model="calcScourDepthFiveHundred"
        />
        <LabelCheckbox
          class="pt-1 labelCheckbox"
          :labelXl="5"
          :labelLg="6"
          :labelMd="6"
          :labelSm="6"
          :id="`in_flood_plain_${id}`"
          label="IN25 In 500yr Flood Plain?"
          :showColon="false"
          :isEditable="false"
          :indent="isEditable ? false : true"
          v-model="getSubstructure().IN_500YR_FP"
        >
        </LabelCheckbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-3">
        <LabelText
          :labelXl="12"
          :labelLg="12"
          :labelMd="12"
          :labelSm="12"
          :textXl="12"
          :textLg="12"
          :textMd="12"
          :textSm="12"
          :id="`textarea_notes_${id}`"
          v-model="underwaterInspection.UNDERWATER_INSP_DESC"
          @update:modelValue="updateStructureObject()"
          :isEditable="isEditable"
          :counter="commentsMaxLength"
          :maxlength="commentsMaxLength"
          persistent-counter
          label="IN24 Notes"
          auto-grow
          rows="1"
          type="textarea"
        >
        </LabelText>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import {
  ENV_CONFIG_PROPERTY,
  CONFIG_SORT_BY,
} from "@/constants/EnvConfigProperties";
import { useConfigStore } from "@/stores/config";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelText from "@/components/common/LabelText.vue";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";
import underwaterInsp from "@/data/underwaterInspection";
import { clone } from "@/util/clone";
import { SORT_ORDER, TYPES } from "@/constants/CommonWebConstants";

export default {
  name: "UnderwaterInspection",
  props: {
    id: {},
    subStructure: { default: null },
  },
  setup(props) {
    const configStore = useConfigStore();
    const inspectionStore = useInspectionStore();

    const isEditable = computed(() => {
      return inspectionStore.isEditable;
    });

    const commentsMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });

    const pierAbutTypes = computed(() => {
      return configStore.getReferenceValues(
        REFERENCE_TABLE.PIER_ABUTMENT_TYPE,
        CONFIG_SORT_BY.VALUE,
        SORT_ORDER.ASC,
        false,
        TYPES.NUMBER
      );
    });

    let structure = computed(() => inspectionStore.selectedInspection);

    let valid = ref(true);
    onMounted(async () => {
      await validate();
    });
    let modifyUnderwaterInspValidation = ref(null);

    async function validate() {
      await modifyUnderwaterInspValidation.value.validate();
    }

    function getMetricToEnglish(value) {
      return configStore.getMetricToEnglishConversions(
        PAIR_CODE.METERS_TO_FEET,
        value
      );
    }

    function getEnglishToMetric(value) {
      return configStore.getEnglishToMetricConversions(
        PAIR_CODE.METERS_TO_FEET,
        value
      );
    }

    function getSubstructure() {
      return props.subStructure;
    }

    let underwaterInspToAdd = ref();

    underwaterInspToAdd.value = clone(underwaterInsp);

    underwaterInspToAdd.value.BRKEY = structure.value.Bridge.BRKEY;

    underwaterInspToAdd.value.INSPKEY = structure.value.InspEvnt.INSPKEY;

    underwaterInspToAdd.value.MODTIME = structure.value.InspEvnt.MODTIME;

    underwaterInspToAdd.value.STRUNITKEY = getSubstructure().STRUNITKEY;

    underwaterInspToAdd.value.USERKEY = structure.value.InspEvnt.USERKEY;

    let subUnitId = ref();

    subUnitId.value =
      props.subStructure.STRUNITKEY + "-" + props.subStructure.STRUNITLABEL;

    let underwaterInspections = computed(() => {
      return clone(structure.value?.T_Underwater_Insp);
    });

    function getUnderwaterInspection() {
      let underwaterInsp = underwaterInspections.value?.find(
        (element) => element?.STRUNITKEY == props.subStructure.STRUNITKEY
      );
      if (underwaterInsp) {
        return underwaterInsp;
      } else {
        underwaterInspections.value.push(underwaterInspToAdd.value);
        return underwaterInspections?.value[
          underwaterInspections.value.length - 1
        ];
      }
    }

    function updateStructureObject() {
      structure.value.T_Underwater_Insp = underwaterInspections.value;
    }

    const underwaterInspection = computed(() => {
      return getUnderwaterInspection();
    });

    const paFoundationType = computed({
      get() {
        return underwaterInspection.value.INV_FOUND_TYPE;
      },
      set(paFoundationTypeParam) {
        let osaFoundType = "";
        let paFoundType = paFoundationTypeParam?.charAt(0);
        if (paFoundType == "A") {
          osaFoundType = "1";
        } else if (["R", "K", "L"].includes(paFoundType)) {
          osaFoundType = "2";
        } else if (
          ["B", "C", "D", "E", "F", "G", "H", "I", "J", "S"].includes(
            paFoundType
          )
        ) {
          osaFoundType = "3";
        } else if (paFoundType == "O") {
          osaFoundType = "4";
        } else if (["P", "X"].includes(paFoundType)) {
          osaFoundType = "5";
        }
        underwaterInspection.value.INV_FOUND_TYPE = paFoundationTypeParam;
        underwaterInspection.value.FOUND_TYPE = osaFoundType;
        updateStructureObject();
      },
    });

    const foundType = computed({
      get() {
        return underwaterInspection.value
          ? underwaterInspection.value?.FOUND_TYPE
          : [];
      },
    });

    const calcScourDepthOneHundred = computed({
      get() {
        return getMetricToEnglish(
          underwaterInspection.value?.CALC_SCOUR_DEPTH_100
        );
      },
      set(calcScourDepthOneHundredParam) {
        underwaterInspection.value.CALC_SCOUR_DEPTH_100 = getEnglishToMetric(
          calcScourDepthOneHundredParam
        );
        updateStructureObject();
      },
    });
    const calcScourDepthFiveHundred = computed({
      get() {
        return getMetricToEnglish(
          underwaterInspection.value?.CALC_SCOUR_DEPTH_500
        );
      },
      set(calcScourDepthFiveHundredParam) {
        underwaterInspection.value.CALC_SCOUR_DEPTH_500 = getEnglishToMetric(
          calcScourDepthFiveHundredParam
        );
        updateStructureObject();
      },
    });

    return {
      pierAbutTypes,
      updateStructureObject,
      underwaterInspection,
      getSubstructure,
      PAIR_CODE,
      modifyUnderwaterInspValidation,
      valid,
      validate,
      subUnitId,
      structure,
      commentsMaxLength,
      isEditable,
      REFERENCE_TABLE,
      calcScourDepthOneHundred,
      calcScourDepthFiveHundred,
      foundType,
      paFoundationType,
    };
  },
  components: {
    LabelSelect,
    LabelText,
    LabelCheckbox,
  },
};
</script>
