<template>
  <div>Logged in successfully</div>
</template>

<script>
import router from "@/router";
import { LOGGER } from "@/util/logger";

export default {
  name: "CallbackPage",
  async setup() {
    const originalUrl = localStorage.getItem("originalUrl");
    let url = "";
    if (originalUrl) {
      try {
        url = new URL(originalUrl);
        if (url.search) {
          const urlParams = new URLSearchParams(url.search);
          let params = {};
          if (urlParams) {
            for (const [key, value] of urlParams) {
              params[key] = value;
            }
          }

          router.push({
            path: url.pathname,
            query: params,
          });
        } else if (
          url.pathname.indexOf("callback") >= 0 ||
          url.pathname == "/"
        ) {
          router.push({
            name: "StructureSearch",
          });
        } else {
          router.push({
            path: url.pathname,
          });
        }
      } catch (e) {
        LOGGER.logException(e);
        router.push({
          name: "StructureSearch",
        });
      }
      localStorage.removeItem("originalUrl");
    } else {
      router.push({
        name: "StructureSearch",
      });
    }
  },
};
</script>
