export const SORT_ORDER = {
  ASC: "asc",
  DESC: "desc",
};

export const TYPES = {
  NUMBER: "number",
  STRING: "string",
};

export const WORKLIST_DELETE_CONFIRM_MESSAGES = {
  UNSUBMITTED_STRUCTURE_DELETE_CONFIRM_MESSAGE:
    "Records with unsubmitted changes are marked for deletion and those changes will be lost. Cancel and click Submit All to avoid losing data before deleting again. Are you sure you want to delete this record?",
  SUBMITTED_STRUCTURE_DELETE_CONFIRM_MESSAGE:
    "Are you sure you want to delete this record?",
};

export const BUTTONS = {
  DELETE: "Delete",
  PLACEMENT: {
    LEFT: "justify-start",
    CENTER: "justify-center",
    RIGHT: "justify-end",
  },
  YES: "Yes",
  NO: "No",
};
export const ELEMENTS = {
  QUANTITY: "quantity",
  PERCENTAGE: "percentage",
};

export const DOWNLOAD_ERROR_MESSAGES = {
  UNAUTHORIZED_MESSAGE:
    "User account does not have access to at least one bridge/structure or the structure has been Demolished. The following BRKey's were not downloaded:",
  INVALID_CREDENTIALS_MESSAGE:
    "The Username/Password combination is not found or unauthorized. Please Try again.",
  UNPROCESSABLE_ENTITY_MESSAGE:
    "Data Exception has occurred, Structure unable to be downloaded.",
};

export const ERROR_MESSAGES = {
  GREATER_THAN_ZERO: "must be greater than 0",
  ATLEAST_ONE_GREATER_THAN_ZERO:
    "at least one quantity needs to be greater than 0",
  TOTAL_PERCENT_EQUAL_TO_100: "CS2-4 must add up to 100",
};

export const RESPONSE_ERROR_MESSAGE = {
  INVALID_JWT: "Invalid JWT",
};
export const SUBMISSION_SUCCESS = "Submission successful";

export const CREATE_SUCCESS = "Document successfully created.";

export const UPLOAD_SUCCESS = "Upload Successful";

export const CREATE_AND_UPLOAD_SUCCESS =
  "Document successfully created and uploaded.";

export const SUBMISSION_FAILURE = {
  PDF_FAILURE:
    "Submission Failure - Upload failed. Please attempt to resubmit and contact the Help Desk at 717-783-8330 and email ra-pdBMS3Support@pa.gov to report the issue.",
  INSPECTION_FAILURE:
    "Submission Failure - Upload failed. Inspection data may have been lost. Please attempt to resubmit and contact the Help Desk at 717-783-8330 and email ra-pdBMS3Support@pa.gov to report the issue.",
};
