<template>
  <td>
    {{ element.ELEM_KEY }}-
    {{ configStore.getElementDefinition(element.ELEM_KEY)?.elemLongName }}
  </td>
  <td v-if="structureType === 'T'">
    {{ element.ELEM_TAG }}
  </td>
  <td class="align-center">
    {{ getQuantityRounded(element.ELEM_KEY, element.ELEM_QUANTITY) }}
  </td>
  <td class="align-center">
    <template
      v-if="isDefect(configStore.getElementDefinition(element.ELEM_KEY))"
    >
      <!--if defect set parent uom-->
      {{
        configStore.getMetricConversionObject(
          configStore.getElementDefinition(element.ELEM_PARENT_KEY)
            ?.elemPairCode
        )?.englishUnit
      }}
    </template>
    <template v-else>
      {{
        configStore.getMetricConversionObject(
          configStore.getElementDefinition(element.ELEM_KEY)?.elemPairCode
        )?.englishUnit
      }}
    </template>
  </td>
  <td class="align-center">
    {{
      elementQtyGroup === ELEMENTS.QUANTITY
        ? getQuantityRounded(element.ELEM_KEY, element.ELEM_QTYSTATE1)
        : convertToDecimalString(
            convertToDecimalString(element.ELEM_PCTSTATE1, 2),
            2
          )
    }}
  </td>
  <td class="align-center">
    {{
      elementQtyGroup === ELEMENTS.QUANTITY
        ? getQuantityRounded(element.ELEM_KEY, element.ELEM_QTYSTATE2)
        : convertToDecimalString(
            convertToDecimalString(element.ELEM_PCTSTATE2, 2),
            2
          )
    }}
  </td>
  <td class="align-center">
    {{
      elementQtyGroup === ELEMENTS.QUANTITY
        ? getQuantityRounded(element.ELEM_KEY, element.ELEM_QTYSTATE3)
        : convertToDecimalString(
            convertToDecimalString(element.ELEM_PCTSTATE3, 2),
            2
          )
    }}
  </td>
  <td class="align-center">
    {{
      elementQtyGroup === ELEMENTS.QUANTITY
        ? getQuantityRounded(element.ELEM_KEY, element.ELEM_QTYSTATE4)
        : convertToDecimalString(
            convertToDecimalString(element.ELEM_PCTSTATE4, 2),
            2
          )
    }}
  </td>
</template>

<script>
import { convertToDecimalString } from "@/composables/util";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { useConfigStore } from "@/stores/config";
import { ELEMENTS } from "@/constants/CommonWebConstants";

export default {
  name: "InspectionElementRow",
  props: ["element", "structureType", "elementQtyGroup"],
  setup() {
    const configStore = useConfigStore();

    function getQuantityRounded(elemKey, value) {
      let quantity = configStore.getMetricToEnglishConversions(
        configStore.getElementDefinition(elemKey)?.elemPairCode,
        value,
        ROUNDING_FACTOR.ROUND
      );
      if (quantity === "") {
        return "0";
      }
      return quantity;
    }
    const isDefect = (elemDef) => {
      if (elemDef?.elemProtectSys === "Y") {
        return false;
      } else if (elemDef?.elemSubComp === "Y") {
        return false;
      } else if (elemDef?.elemSmartFlag === "Y") {
        return true;
      }
    };
    return {
      getQuantityRounded,
      convertToDecimalString,
      configStore,
      ELEMENTS,
      isDefect,
    };
  },
};
</script>
