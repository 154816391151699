<template>
  <v-row>
    <v-col cols="12" sm="12" md="7" lg="8">
      <label
        >BRKEY Search:<v-tooltip>
          <template v-slot:activator="{ props }">
            <v-icon
              id="icon_brkeysInfo"
              class="ml-2 infoIcon"
              icon="fas fa-circle-info"
              v-bind="props"
            />
          </template>
          <span
            >For multiple entries, separate the numbers by a comma or space
          </span>
        </v-tooltip></label
      >
      <v-text-field
        id="input_searchBrkeys"
        v-model="brKeys"
        autofocus
        single-line
        v-maska="MASK.NUMBER_COMMA_SPACE_REPEATING"
        label="Enter BRKEY"
        persistent-hint
        clearable
        @keyup.enter="enterKeyHandler"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="auto"
      lg="auto"
      class="d-flex align-content-center flex-wrap"
    >
      <v-btn
        id="btn_retrieveBrkeys"
        variant="outlined"
        @click="readDataFromAPI(brKeys)"
      >
        Retrieve List
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { useStructureSearchStore } from "@/stores/structureSearch";
import { STRUCTURE_SEARCH_MESSAGES } from "@/constants/StructureSearches";
import { MASK } from "@/constants/Maskas";
import useFieldEntry from "@/composables/fieldEntry";
import { scrollToElement } from "@/util/scrollToElement";
import { nextTick } from "vue";

export default {
  name: "BRKEY",
  emits: ["showMessage"],
  data: () => ({
    brKeys: null,
  }),
  setup(_, ctx) {
    const structureSearchStore = useStructureSearchStore();
    const { isNumber } = useFieldEntry();
    async function readDataFromAPI(payload) {
      if (payload) {
        structureSearchStore.resetSelectedStructures();
        let cleanedPayload = payload.trim().split(/[ ,]+/);
        await structureSearchStore.getStructuresByBrKey(cleanedPayload);
        showMessage(payload);
      }
    }
    async function showMessage(brKeys) {
      let missingResults;
      let data;
      let cleanedBrkey;

      data = JSON.parse(
        JSON.stringify(structureSearchStore.getStructureSearchResults)
      ).map(function (elem) {
        return elem.brkey;
      });
      cleanedBrkey = brKeys.trim().split(/[, ]+/).filter(Boolean);
      missingResults = structureSearchStore.getMissingResults(
        data,
        cleanedBrkey
      );
      ctx.emit(
        "showMessage",
        setMessageValue(cleanedBrkey, missingResults, data)
      );
    }
    const enterKeyHandler = () => {
      nextTick(() => document.getElementById("btn_retrieveBrkeys").focus());
      document.getElementById("btn_retrieveBrkeys").click();
    };
    return {
      MASK,
      structureSearchStore,
      readDataFromAPI,
      showMessage,
      isNumber,
      enterKeyHandler,
    };
  },
};
function setMessageValue(cleanedBrkey, missingResults, data) {
  let message = {
    header: "BRKEY Missing Results",
    value: null,
    type: null,
    keys: null,
    missingResults: missingResults,
    show: true,
  };

  if (data?.length === 0) {
    message.value = STRUCTURE_SEARCH_MESSAGES.SEARCH_NO_RESULTS;
    message.type = "error-dialog";
  } else if (missingResults?.length === 0) {
    message.show = false;
    scrollToElement("search_results_header");
  } else if (missingResults?.length !== 0) {
    message.value = STRUCTURE_SEARCH_MESSAGES.BRKEY_SEARCH_SOME_RESULTS;
    message.type = "warning-dialog";
    message.keys = cleanedBrkey;
    scrollToElement("search_results_header");
  }
  return message;
}
</script>
