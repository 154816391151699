import { getReportFooter, getReportHeader } from "@/reports/ReportUtil";
import { styles } from "@/reports/reportStyles";
import { useConfigStore } from "@/stores/config";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useInspectionStore } from "@/stores/inspection";
import useNSTMFatigueData from "@/composables/NSTMFatigueData";

function setNSTMFatigueDocDefinition(structure) {
  const { fatigueDetail } = useNSTMFatigueData();

  const configStore = useConfigStore();

  const SpanSet = [];
  SpanSet.push(getSpanSetHeader());
  getSpanSetDetails(SpanSet, structure);

  const NSTMFatigueTable = [];
  NSTMFatigueTable.push(getNSTMFatigueTableHeader());
  getNSTMFatigueTableDetails(NSTMFatigueTable);

  let docDefinition = {
    header: function () {
      return getReportHeader("NSTM/Fatigue Page", structure);
    },
    footer: function (currentPage, pageCount) {
      return getReportFooter(currentPage, pageCount);
    },
    pageMargins: [20, 70, 20, 50],
    content: [
      { text: "", pageHeaderText: "NSTM/Fatigue Page" },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "NSTM Conditions",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
                margin: [0, 3, 0, 2],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            text: "1A15 NSTM Inspection Condition: ",
            bold: true,
            width: "30%",
            margin: [5, 5, 0, 0],
          },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.CONDITION_RATING,
              getNSTMInspectionCondition(structure)
            ),
            width: "*",
            margin: [0, 5, 0, 0],
          },
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "General Information",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
                margin: [0, 3, 0, 2],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          [
            {
              table: {
                headerRows: 1,
                widths: ["*", "99%", "*"],
                body: [
                  [
                    {},
                    {
                      colSpan: 1,
                      text: "Plug Weld Indicator",
                      fillColor: "#ebebeb",
                      alignment: "center",
                      style: ["sectionHeader"],
                    },
                    {},
                  ],
                ],
              },
              layout: "noBorders",
              margin: [0, 5, 0, 0],
            },
          ],
          [
            {
              table: {
                headerRows: 1,
                widths: ["*", "99%", "*"],
                body: [
                  [
                    {},
                    {
                      colSpan: 1,
                      text: "Estimated Truck Traffic",
                      fillColor: "#ebebeb",
                      alignment: "center",
                      style: ["sectionHeader"],
                    },
                    {},
                  ],
                ],
              },
              layout: "noBorders",
              margin: [0, 5, 0, 0],
            },
          ],
        ],
      },
      {
        columns: [
          [
            [
              {
                table: {
                  headerRows: 0,
                  widths: ["*", "60%", "39%", "*"],
                  body: [
                    [
                      {},
                      {
                        colSpan: 1,
                        bold: true,
                        text: "6A50 Superstructure: ",
                        margin: [0, 2, 0, 2],
                      },
                      {
                        colSpan: 1,
                        text: configStore.getReferenceValue(
                          REFERENCE_TABLE.PLUG_WELD_PROBLEM_TYPE,
                          structure?.UserBrdg?.SUP_PROBLEM_TYPE
                        ),
                        margin: [0, 2, 0, 2],
                      },
                      {},
                    ],
                    [
                      {},
                      {
                        colSpan: 1,
                        bold: true,
                        text: "6A51 Substructure: ",
                        margin: [0, 2, 0, 2],
                      },
                      {
                        colSpan: 1,
                        text: configStore.getReferenceValue(
                          REFERENCE_TABLE.PLUG_WELD_PROBLEM_TYPE,
                          structure?.UserBrdg?.SUB_PROBLEM_TYPE
                        ),
                        margin: [0, 2, 0, 2],
                      },
                      {},
                    ],
                  ],
                },
                layout: "noBorders",
              },
            ],
          ],
          [
            [
              {
                table: {
                  headerRows: 0,
                  widths: ["*", "60%", "39%", "*"],
                  body: [
                    [
                      {},
                      {
                        colSpan: 1,
                        bold: true,
                        text: "6A52 Cumulative Truck Traffic: ",
                        margin: [0, 2, 10, 2],
                      },
                      {
                        colSpan: 1,
                        text: structure?.UserBrdg?.EST_TRUCK_TRAFFIC,
                        margin: [10, 2, 0, 2],
                      },
                      {},
                    ],
                    [
                      {},
                      {
                        colSpan: 1,
                        bold: true,
                        text: "6A53 Cumulative Truck Traffic for Fatigue Damage: ",
                        margin: [0, 2, 10, 2],
                      },
                      {
                        colSpan: 1,
                        text: structure?.UserBrdg?.EST_CUM_FATIG_LIFE,
                        margin: [10, 2, 0, 2],
                      },
                      {},
                    ],
                    [
                      {},
                      {
                        colSpan: 1,
                        bold: true,
                        text: "6A54 Year of Estimate: ",
                        margin: [0, 2, 10, 2],
                      },
                      {
                        colSpan: 1,
                        text: structure?.UserBrdg?.EST_TRUCK_TRAFFIC_YEAR,
                        margin: [10, 2, 0, 2],
                      },
                      {},
                    ],
                  ],
                },
                layout: "noBorders",
                margin: [0, 5, 0, 0],
              },
            ],
          ],
        ],
      },
      {
        table: {
          headerRows: 0,
          widths: ["100%"],
          body: [
            [
              {
                text: "NSTM Details",
                fillColor: "#ebebeb",
                alignment: "center",
                style: ["sectionHeader"],
                margin: [0, 3, 0, 2],
              },
            ],
          ],
        },
        layout: "noBorders",
        margin: [0, 5, 0, 0],
      },
      {
        table: {
          headerRows: 1,
          widths: ["9%", "7%", "8%", "9%", "16%", "17%", "17%", "17%"],
          body: SpanSet,
        },
        margin: [0, 5, 0, 5],
      },
      {
        columns: [
          {
            text: "IF07 Fatigue Details: ",
            bold: true,
            width: "17%",
            margin: [0, 2, 0, 2],
          },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.FATIGUE_DETAILS,
              fatigueDetail.value
            ),
            width: "15%",
            margin: [0, 2, 0, 2],
          },
          {
            text: "IF08 NSTM's? ",
            bold: true,
            width: "12%",
            margin: [1, 2, 0, 2],
          },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.NSTM_PRESENT,
              structure?.UserInsp?.NSTM_PRESENT
            ),
            width: "20%",
            margin: [0, 2, 0, 2],
          },
          {
            text: "B.IR.04 Complex Feature? ",
            bold: true,
            width: "15%",
            margin: [1, 2, 0, 2],
          },
          {
            text: configStore.getReferenceValue(
              REFERENCE_TABLE.COMPLEX_FEATURE,
              structure?.UserInsp?.COMPLEX_FEATURE_PRESENT
            ),
            width: "*",
            margin: [0, 2, 0, 2],
          },
        ],
      },
      {
        table: {
          headerRows: 1,
          widths: ["8%", "12%", "15%", "20%", "20%", "15%", "10%"],
          body: NSTMFatigueTable,
        },
        layout: {
          paddingLeft: function () {
            return 0;
          },
          paddingRight: function () {
            return 0;
          },
          paddingTop: function () {
            return 0;
          },
          paddingBottom: function () {
            return 0;
          },
        },
      },
    ],
    styles: styles,
    defaultStyle: {
      fontSize: 10,
    },
  };
  return docDefinition;
}

function getSpanSetHeader() {
  return [
    {
      text: "\nSpan Set",
      style: ["tableHeader"],
    },
    {
      text: "6A44\n Group",
      style: ["tableHeader"],
    },
    {
      text: "6A45-48\n CRF",
      style: ["tableHeader"],
    },
    {
      text: "6A49\n Total CRF",
      style: ["tableHeader"],
    },
    {
      text: "6A26\n Material Makeup",
      style: ["tableHeader"],
    },
    {
      text: "6A27\n Physical Makeup",
      style: ["tableHeader"],
    },
    {
      text: "6A28\n Span Interaction",
      style: ["tableHeader"],
    },
    {
      text: "6A29\n Structural Config",
      style: ["tableHeader"],
    },
  ];
}

function getNSTMFatigueTableHeader() {
  return [
    {
      text: "IF00\n Key",
      style: ["tableHeader"],
    },
    {
      text: "IF01\n Location",
      style: ["tableHeader"],
    },
    {
      text: "IF02\n Member\n Type",
      style: ["tableHeader"],
    },
    {
      text: "IF03\n Member",
      style: ["tableHeader"],
    },
    {
      text: "IF04\n Member Detail",
      style: ["tableHeader"],
    },
    {
      text: "IF05\n Member Stress\n Category",
      style: ["tableHeader"],
    },
    {
      text: "IF09\n Detail Type",
      style: ["tableHeader"],
    },
  ];
}

function getSpanSetDetails(SpanSet, structure) {
  const configStore = useConfigStore();

  const {
    fctotalCrfMainString,
    fctotalCrfMain,
    fctotalCrfApproachString,
    fctotalCrfApproach,
  } = useNSTMFatigueData();

  SpanSet.push(
    [
      {
        text: "Main",
        style: ["tableBody"],
      },
      {
        text: structure?.UserBrdg?.MAIN_FC_GROUP_NUM,
        style: ["tableBody"],
      },
      {
        text: fctotalCrfMainString,
        style: ["tableBody"],
      },
      {
        text: fctotalCrfMain,
        style: ["tableBody"],
      },
      {
        text: configStore.getReferenceValue(
          REFERENCE_TABLE.MATERIAL_MAKEUP,
          structure?.UserBrdg?.DEPT_MAIN_MATERIAL_TYPE
        ),
        style: ["tableBody"],
      },
      {
        text: configStore.getReferenceValue(
          REFERENCE_TABLE.PHYSICAL_MAKEUP,
          structure?.UserBrdg?.DEPT_MAIN_PHYSICAL_TYPE
        ),
        style: ["tableBody"],
      },
      {
        text: configStore.getReferenceValue(
          REFERENCE_TABLE.SPAN_INTERACTION,
          structure?.UserBrdg?.DEPT_MAIN_SPAN_INTERACTION
        ),
        style: ["tableBody"],
      },
      {
        text: configStore.getReferenceValue(
          REFERENCE_TABLE.STRUCTURAL_CONFIGURATION,
          structure?.UserBrdg?.DEPT_MAIN_STRUC_CONFIG
        ),
        style: ["tableBody"],
      },
    ],
    [
      {
        text: "Approach",
        style: ["tableBody"],
      },
      {
        text: structure?.UserBrdg?.APPR_FC_GROUP_NUM,
        style: ["tableBody"],
      },
      {
        text: fctotalCrfApproachString,
        style: ["tableBody"],
      },
      {
        text: fctotalCrfApproach,
        style: ["tableBody"],
      },
      {
        text: configStore.getReferenceValue(
          REFERENCE_TABLE.MATERIAL_MAKEUP,
          structure?.UserBrdg?.DEPT_APPR_MATERIAL_TYPE
        ),
        style: ["tableBody"],
      },
      {
        text: configStore.getReferenceValue(
          REFERENCE_TABLE.PHYSICAL_MAKEUP,
          structure?.UserBrdg?.DEPT_APPR_PHYSICAL_TYPE
        ),
        style: ["tableBody"],
      },
      {
        text: configStore.getReferenceValue(
          REFERENCE_TABLE.SPAN_INTERACTION,
          structure?.UserBrdg?.DEPT_APPR_SPAN_INTERACTION
        ),
        style: ["tableBody"],
      },
      {
        text: configStore.getReferenceValue(
          REFERENCE_TABLE.STRUCTURAL_CONFIGURATION,
          structure?.UserBrdg?.DEPT_APPR_STRUC_CONFIG
        ),
        style: ["tableBody"],
      },
    ]
  );
}

function getNSTMFatigueTableDetails(NSTMFatigueTable) {
  const configStore = useConfigStore();

  const { locationOptions, getFcDetails } = useNSTMFatigueData();
  const fcDetails = getFcDetails();

  for (const detail of fcDetails) {
    NSTMFatigueTable.push(
      [
        detail?.FC_KEY,
        {
          text: locationOptions(detail?.STRUNITKEY),
          style: ["tableBody"],
        },
        {
          text: configStore.getReferenceValue(
            REFERENCE_TABLE.FRACTURE_CRITICAL_MEMBER_TYPE,
            detail?.FC_MEM_TYPE
          ),
          style: ["tableBody"],
        },
        detail?.FC_MEM,
        detail?.FC_DETAIL,
        detail?.FATIG_STRESS_CAT,
        detail?.NSTM_DETAIL_PRESENT,
      ].map((d) => {
        return {
          text: d,
          alignment: "center",
        };
      })
    );
    NSTMFatigueTable.push([
      {
        colSpan: 7,
        margin: [3, 3, 0, 10],
        text: [
          {
            text: "IF06 Member Detail Condition: ",
            bold: true,
          },
          {
            text: detail?.FC_DESC,
          },
        ],
      },
    ]);
    NSTMFatigueTable.push([
      {
        colSpan: 7,
        margin: [3, 3, 0, 10],
        text: [
          {
            text: "IF10 Member Inspection Procedure: ",
            bold: true,
          },
          {
            text: detail?.DETAIL_INSP_PROC,
          },
        ],
      },
    ]);
  }
}

function getNSTMInspectionCondition(structure) {
  const inspectionStore = useInspectionStore();
  if (
    !structure.UserInsp.NSTM_CONDITION &&
    !inspectionStore.getNstmInspectionConditionChangedByUser
  ) {
    if (
      structure.UserBrdg.MAIN_FC_GROUP_NUM < 5 ||
      structure.UserBrdg.APPR_FC_GROUP_NUM < 5
    ) {
      return structure.InspEvnt.SUPRATING;
    } else {
      return "N";
    }
  } else {
    return structure.UserInsp.NSTM_CONDITION;
  }
}
export { setNSTMFatigueDocDefinition };
