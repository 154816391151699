import { INSPECTION_STATUSES } from "@/constants/InspectionStatuses";

export const INSPECTION_PAGES = {
  GENERAL: "General",
  SCHEDULE: "Schedule",
  RATINGS: "Ratings",
  APPROACH: "Approach",
  DECK: "Deck",
  JOINTS: "Joints",
  SUPERSTRUCTURE: "Superstructure",
  BEARINGS: "Bearings",
  SUBSTRUCTURE: "Substructure",
  CULVERT: "Culvert",
  WATERWAY: "Waterway",
  LOAD_RATING: "Load Rating",
  NSTM_FATIGUE: "NSTM/Fatigue",
  ELEMENTS: "Elements",
  FEATURES: "Features",
  MAINTENANCE_ITEMS: "Maintenance",
  MAINTENANCE_INCLUDE: "Include Completed Maintenance Candidates",
  NOTES: "Notes",
  SIGNS_AND_LIGHTS: "Signs & Lights",
  WALLS: "Walls",
  TUNNELS: "Tunnels",
  EDOCS: "E-Docs",
};
export const BRIDGE_PAGES = [
  INSPECTION_PAGES.GENERAL,
  INSPECTION_PAGES.SCHEDULE,
  INSPECTION_PAGES.RATINGS,
  INSPECTION_PAGES.APPROACH,
  INSPECTION_PAGES.DECK,
  INSPECTION_PAGES.JOINTS,
  INSPECTION_PAGES.SUPERSTRUCTURE,
  INSPECTION_PAGES.BEARINGS,
  INSPECTION_PAGES.SUBSTRUCTURE,
  INSPECTION_PAGES.CULVERT,
  INSPECTION_PAGES.WATERWAY,
  INSPECTION_PAGES.LOAD_RATING,
  INSPECTION_PAGES.NSTM_FATIGUE,
  INSPECTION_PAGES.ELEMENTS,
  INSPECTION_PAGES.FEATURES,
  INSPECTION_PAGES.MAINTENANCE_ITEMS,
  INSPECTION_PAGES.NOTES,
  INSPECTION_PAGES.EDOCS,
];
export const SIGN_PAGES = [
  INSPECTION_PAGES.GENERAL,
  INSPECTION_PAGES.SCHEDULE,
  INSPECTION_PAGES.RATINGS,
  INSPECTION_PAGES.APPROACH,
  INSPECTION_PAGES.FEATURES,
  INSPECTION_PAGES.MAINTENANCE_ITEMS,
  INSPECTION_PAGES.NOTES,
  INSPECTION_PAGES.SIGNS_AND_LIGHTS,
  INSPECTION_PAGES.EDOCS,
];
export const WALL_PAGES = [
  INSPECTION_PAGES.GENERAL,
  INSPECTION_PAGES.SCHEDULE,
  INSPECTION_PAGES.RATINGS,
  INSPECTION_PAGES.APPROACH,
  INSPECTION_PAGES.FEATURES,
  INSPECTION_PAGES.MAINTENANCE_ITEMS,
  INSPECTION_PAGES.NOTES,
  INSPECTION_PAGES.WALLS,
  INSPECTION_PAGES.EDOCS,
];
export const TUNNEL_PAGES = [
  INSPECTION_PAGES.GENERAL,
  INSPECTION_PAGES.SCHEDULE,
  INSPECTION_PAGES.RATINGS,
  INSPECTION_PAGES.APPROACH,
  INSPECTION_PAGES.LOAD_RATING,
  INSPECTION_PAGES.ELEMENTS,
  INSPECTION_PAGES.FEATURES,
  INSPECTION_PAGES.MAINTENANCE_ITEMS,
  INSPECTION_PAGES.NOTES,
  INSPECTION_PAGES.TUNNELS,
  INSPECTION_PAGES.EDOCS,
];

export const EDOCS_PAGES = [INSPECTION_PAGES.EDOCS];

//Streambed materials
export const STREAM_BED_MATERIALS = [
  { material: "A3", rank: 1 },
  { material: "A4", rank: 2 },
  { material: "R4", rank: 3 },
  { material: "C7", rank: 4 },
  { material: "C8", rank: 5 },
  { material: "A5", rank: 6 },
  { material: "A6", rank: 7 },
  { material: "R7", rank: 8 },
  { material: "R8", rank: 9 },
  { material: "R9", rank: 10 },
  { material: "D9", rank: 11 },
];

export const INSPECTION_COMMENTS = {
  RAIL_END: "10",
  GUIDE_RAIL: "9",
  TRANSITION: "8",
  BRIDGE_RAILING: "7",
  APPROACH_ALIGNMENT: "1",
  APPROACH_PAVEMENT: "2",
  APPROACH_DRAINAGE: "3",
  APPROACH_SHOULDERS: "4",
  APPROACH_SLAB: "5",
  BUMP_AT_BRIDGE_OBJECT: "69",
  PAVEMENT_RELIEF_JOINTS: "6",
  SUPERSTRUCTURE: "15",
  SUBSTRUCTURE: "19",
  BACKWALL: "20",
  BANKS: "46",
  BRIDGE_SEATS: "21",
  CHEEKWALLS: "22",
  CHANNEL: "45",
  CHANNEL_PROTECTION: "80",
  DEBRIS_VEGITATION: "48",
  STEM: "23",
  WINGS: "24",
  FOOTING: "25",
  PILES: "26",
  EMBANKMENT_STREAMBED_CONTROLS: "50",
  DRIFT_OTHER: "51",
  SETTLEMENT: "27",
  EMBANK_SLOPE_WALL: "28",
  WALL_DRAINAGE: "29",
  COMBUSTIBLE_MATERIAL: "38",
  CONDITION_SUMMARY_PIER: "40",
  BRIDGE_SEATS_PIER: "41",
  CHEEKWALLS_PIER: "42",
  COLUMNS_STEMS_PIER: "43",
  SETTLEMENT_PIER: "44",
  COMBUSTIBLE_MATERIAL_PIER: "39",
  OVERALL: "109",
  INTERIOR_BEAM_GIRDER: "53",
  HIGH_WATER_MARK: "52",
  PAINT_FASCIAS: "54",
  PAINT_SPLASH_ZONE: "55",
  PAINT_TRUSS: "56",
  PAINT_BEARINGS: "57",
  PAINT_OTHER: "58",
  SUPER_GIRDERS: "30",
  SUPER_FLOORBEAMS: "31",
  SUPER_STRINGERS: "32",
  SUPER_DIAPHRAGMS: "16",
  SUPER_TRUSS_MEMBERS: "33",
  SUPER_PORTALS: "17",
  SUPER_DRAINAGE: "18",
  OVERALL_DECK_COMMENTS: "12",
  OVERALL_WALL: "138",
  OVERALL_WEARING_SURFACE_COMMENTS: "14",
  DECK_TOP_WEARING_SURFACE: "35",
  DECK_UNDERSIDE: "36",
  DECK_DRAINAGE: "13",
  JOINTS_TINSP_COMMENTS: "70",
  BEARING_OVERALL_COMMENTS: "34",
  REVIEW_RECOMMENDED_DUE_TO_COMMENTS: "67",
  RIVER_CONTROL_DEVICES: "49",
  CULVERT: "71",
  CULVERT_TOP_SLAB: "59",
  CULVERT_BARREL: "60",
  CULVERT_FLOOR_PAVE: "61",
  CULVERT_HEADWALL: "62",
  CULVERT_WINGS: "63",
  CULVERT_SETTLEMENT: "64",
  CULVERT_DEBRIS: "65",
  CULVERT_FOOTING: "78",
  PAINT_OVERALL: "77",
  SIGN_COLUMN_BASE: "100",
  SIGN_GUIDE_RAIL: "102",
  SIGN_COLUMN: "101",
  SIGN_ACCESS_METH: "103",
  SIGN_SIGN: "106",
  SIGN_LIGHTS: "104",
  SIGN_SURFACE_PAINT: "108",
  SIGN_FRAMEWORK: "107",
  SIGN_ASSET_TAGS: "110",
  STREAMBED_MOVEMENTS: "47",
  WALL_ANCHORAGE: "130",
  WALL_BACKFLIP_DAMPING: "131",
  WALL: "132",
  WALL_PANEL: "137",
  WALL_POST: "136",
  WALL_PAGE_DRAINAGE: "133",
  WALL_FOUNDATION: "134",
  WALL_PARAPETS: "135",
  WATERWAY_ADEQUACY: "66",
  COMBUSTIBLE_MATERIALS_COMMENTS: "74",
  LOAD_RATING_REVIEW: "79",
  SUBMISSION_VALIDATION_COMMENT: "478",
};

export const SUB_STRUCTURE_UNIT_KEY = {
  NEAR_ABUTMENT: "NAB",
  FAR_ABUTMENT: "FAB",
};

export const STRUCTURE_UNIT_TYPE = {
  APRAS_SPAN: "X",
  ABUTMENT: "B",
  PIER: "P",
  MAIN: "M",
  APPROACH: "A",
};
export const DOWNLOAD_PROGRESS = {
  START: "start",
  END: "end",
  SUCCESS: "success",
  FAIL: "fail",
};
export const EDOC_CATEGORY = {
  INVENTORY: "Inventory",
  INSPECTION: "Inspection",
  OTHER: "Other",
};
export const EDOC_ERROR_DESC = {
  NOT_FOUND: "Document not found",
  SERVICE_UNAVAILABLE:
    "Server Unavailable, email ra-pdBMS3Support@pa.gov to report the issue.",
  NO_CONTENT: "No Content",
  DOC_SERVICE_ERROR: "Document Service Error",
};
export const CHECKBOX_VALUE = {
  CHECKED: "1",
  UNCHECKED: "0",
};
export const INSPECTION_TYPE_PERFORMED = {
  INITIAL: "1",
  ROUTINE: "2",
  UNDERWATER: "3",
  NSTM: "4",
  DAMAGE: "5",
  IN_DEPTH: "6",
  SPECIAL: "7",
  SERVICE: "8",
  SCOUR_MONITORING: "9",
  PROBLEM_AREA: "P",
  ELEMENT: "E",
  QA: "Q",
};
export const STATUSES_CAN_SUBMIT = [
  INSPECTION_STATUSES.READY_TO_SUBMIT,
  INSPECTION_STATUSES.NEW,
  INSPECTION_STATUSES.SUBMITTED,
  INSPECTION_STATUSES.VALIDATION_ERROR,
];

export const DISABLE_REPORT_SUBMIT = [
  INSPECTION_STATUSES.NEW,
  INSPECTION_STATUSES.VALIDATION_ERROR,
];
