<template>
  <v-container fluid>
    <h3 class="withBlueBackground px-2 py-1">Worklist</h3>
    <v-spacer style="height: 25px"></v-spacer>
    <WorkList />
  </v-container>
</template>

<script>
import WorkList from "@/components/shared/WorkList.vue";
import { onMounted } from "vue";
import { useInspectionStore } from "@/stores/inspection";
export default {
  components: {
    WorkList,
  },
  name: "ViewWorkList",
  setup() {
    const inspectionStore = useInspectionStore();
    onMounted(async () => {
      await inspectionStore.setUnsavedChangesWarning(false);
      await inspectionStore.setDirtyFlag(false);
    });
  },
};
</script>
