export const STRUCTURE_SEARCH = {
  BRKEY: "BRKEY",
  BMSID: "BMSID",
  UPLOAD_BRKEY: "UploadBRKEY",
  PARAMETER_SEARCH: "ParameterSearch",
};
export const STRUCTURE_SEARCH_MESSAGES = {
  SEARCH_NO_RESULTS: "No Results Found. Please try again.",
  BRKEY_SEARCH_SOME_RESULTS:
    "The BRKEYs listed below were not found during the search.",
  BMSID_SEARCH_SOME_RESULTS:
    "The following BMS IDs were not found during the search.",
  PARAMS_SEARCH_NO_RESULT: "The parameters selected returned no search results",
  INVALID_FILE_TYPE_ERROR: "Your file failed to upload. Please try again.",
  INVALID_BRKEY_UPLOAD: "The BRKEYs listed below are invalid in the file.",
};
export const RECORD_MANAGEMENT_TYPES = {
  INSPECTION: "Inspection",
  DOCUMENTS: "E-Docs",
  BOTH: "Inspection, E-Docs",
};
