<template>
  <v-row>
    <v-col xs="12" sm="12" md="12" lg="6" class="pa-0">
      <LabelSelect
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :selectXl="6"
        :selectLg="6"
        :selectMd="6"
        :selectSm="6"
        :id="`label_Waterway_On/Under_${rowId}`"
        label="5C03 On/Under"
        :refTable="REFERENCE_TABLE.ON_UNDER"
        v-model="selectedRow.ON_UNDER"
      >
      </LabelSelect>
      <LabelSelect
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :selectXl="6"
        :selectLg="6"
        :selectMd="6"
        :selectSm="6"
        :id="`label_Waterway_Feature_Type_${rowId}`"
        label="6C34 Feature Type"
        v-if="$vuetify.display.mdAndDown"
        :refTable="REFERENCE_TABLE.FEATURE_TYPE"
        v-model="selectedRow.FEATURE_TYPE"
      >
      </LabelSelect>

      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Stream_Name_${rowId}`"
        label="FW01 Stream Name"
        v-model="selectedRow.ROADWAY_NAME"
        maxlength="30"
      >
      </LabelText>

      <LabelSelect
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :selectXl="6"
        :selectLg="6"
        :selectMd="6"
        :selectSm="6"
        :id="`label_Stream_Classification_1_${rowId}`"
        label="FW02 Stream Classification 1"
        :refTable="REFERENCE_TABLE.STREAM_CLASSIFICATION"
        v-model="selectedRow.DEP_CLASS_1"
      >
      </LabelSelect>
      <LabelSelect
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :selectXl="6"
        :selectLg="6"
        :selectMd="6"
        :selectSm="6"
        :id="`label_Stream_Classification_2_${rowId}`"
        label="FW03 Stream Classification 2"
        :refTable="REFERENCE_TABLE.STREAM_CLASSIFICATION"
        v-model="selectedRow.DEP_CLASS_2"
      >
      </LabelSelect>

      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Timeframe_${rowId}`"
        label="FW04 Timeframe"
        v-model="selectedRow.DEP_TIMEFRAME"
        maxlength="25"
      >
      </LabelText>

      <LabelSelect
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :selectXl="6"
        :selectLg="6"
        :selectMd="6"
        :selectSm="6"
        :id="`label_Stream_Classification_3_${rowId}`"
        label="FW05 Stream Classification 3"
        :refTable="REFERENCE_TABLE.STREAM_CLASSIFICATION"
        v-model="selectedRow.DEP_CLASS_3"
      >
      </LabelSelect>

      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Permit_Type_${rowId}`"
        label="FW06 Permit Type"
        v-model="selectedRow.DEP_PERMIT_TYPE"
        maxlength="10"
      >
      </LabelText>

      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Drainage_Area_${rowId}`"
        label="FW07 Drainage Area"
        appendWith=" sq mi."
        type="number"
        :decimalPlaces="1"
        v-model="selectedRow.STREAM_DRAIN_AREA"
        :pairCode="PAIR_CODE.SQUAREKILOMETERS_TO_SQUAREMILES"
      >
      </LabelText>

      <LabelCheckbox
        labelXl="6"
        labelLg="6"
        labelMd="6"
        labelSm="6"
        :id="`txt_Fishable_${rowId}`"
        label="FW08 Fishable"
        v-model="selectedRow.FISHABLE"
      >
      </LabelCheckbox>

      <LabelSelect
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :selectXl="6"
        :selectLg="6"
        :selectMd="6"
        :selectSm="6"
        :id="`label_Waterflow_Direction_${rowId}`"
        label="FW09 Waterflow Direction"
        :refTable="REFERENCE_TABLE.WATERFLOW_DIRECTION"
        v-model="selectedRow.WATERFLOW_DIR"
      >
      </LabelSelect>
    </v-col>
    <v-col class="pa-0">
      <LabelSelect
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :selectXl="6"
        :selectLg="6"
        :selectMd="6"
        :selectSm="6"
        :id="`label_Waterway_Feature_Type_${rowId}`"
        label="6C34 Feature Type"
        v-if="$vuetify.display.lgAndUp"
        :refTable="REFERENCE_TABLE.FEATURE_TYPE"
        v-model="selectedRow.FEATURE_TYPE"
      >
      </LabelSelect>

      <LabelCheckbox
        labelXl="6"
        labelLg="6"
        labelMd="6"
        labelSm="6"
        :id="`txt_Primary_Waterway_${rowId}`"
        label="FW10 Primary Waterway"
        v-model="selectedRow.PRIMARY_WATERWAY"
      >
      </LabelCheckbox>

      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Vertical_Clearance_${rowId}`"
        label="FW11 Vertical Clearance"
        appendWith=" ft"
        :pairCode="PAIR_CODE.METERS_TO_FEET"
        type="number"
        :decimalPlaces="1"
        v-model="selectedRow.MIN_OVER_VERT_CLEAR_RIGHT"
        :conversionTypes="[METRIC_CONVERSION_TYPES.L3]"
      >
      </LabelText>
      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Max_W_S_Elevation_${rowId}`"
        label="FW12 Max W.S. Elevation"
        appendWith=" ft"
        v-model="selectedRow.MAX_WATER_SURF_ELEV"
        type="number"
        :decimalPlaces="1"
        :pairCode="PAIR_CODE.METERS_TO_FEET"
      >
      </LabelText>
      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Max_W_S_Elevation_Year_${rowId}`"
        label="FW13 Max W.S. Elevation Year"
        v-model="selectedRow.MAX_WATER_SURF_YEAR"
      >
      </LabelText>
      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Design_Flood_Magnitude_${rowId}`"
        label="FW14 Design Flood Magnitude"
        appendWith=" cf/s"
        v-model="selectedRow.DESIGN_FLOOD_MAGNITUDE"
        :pairCode="PAIR_CODE.CUBICMETERSPERSECOND_TO_CUBICFEETPERSECOND"
        type="number"
      >
      </LabelText>
      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Design_Flood_Elevation_${rowId}`"
        label="FW15 Design Flood Elevation"
        appendWith=" ft"
        v-model="selectedRow.DESIGN_FLOOD_ELEV"
        :pairCode="PAIR_CODE.METERS_TO_FEET"
        type="number"
        :decimalPlaces="1"
      >
      </LabelText>
      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Design_Flood_Frequency_${rowId}`"
        label="FW16 Design Flood Frequency"
        appendWith=" yrs"
        v-model="selectedRow.DESIGN_FLOOD_FREQ"
      >
      </LabelText>
      <LabelText
        :labelXl="6"
        :labelLg="6"
        :labelMd="6"
        :labelSm="6"
        :textXl="6"
        :textLg="6"
        :textMd="6"
        :textSm="6"
        :id="`txt_Design_Flood_Velocity_${rowId}`"
        label="FW17 Design Flood Velocity"
        appendWith=" fps"
        v-model="selectedRow.DESIGN_FLOOD_VEL"
        :pairCode="PAIR_CODE.METERSPERSECOND_TO_FEETPERSECOND"
        type="number"
        :decimalPlaces="1"
      >
      </LabelText>
    </v-col>
  </v-row>
  <v-row>
    <v-col sm="12" class="pa-0">
      <LabelText
        labelXl="3"
        labelLg="3"
        labelMd="3"
        labelSm="3"
        textXl="9"
        textLg="9"
        textMd="9"
        textSm="9"
        :id="`txt_Pollutant_Description_${rowId}`"
        label="FW18 Pollutant Description"
        v-model="selectedRow.POLLUTANT_DESC"
        maxlength="200"
        type="textarea"
        counter
        persistentCounter
        class="pb-0 customLabelAlignment"
      >
      </LabelText>

      <LabelText
        labelXl="3"
        labelLg="3"
        labelMd="3"
        labelSm="3"
        textXl="9"
        textLg="9"
        textMd="9"
        textSm="9"
        :id="`txt_Stream_Restrict_Description_${rowId}`"
        label="FW19 Stream Restrict Description"
        v-model="selectedRow.STREAM_RESTRICT_DESC"
        maxlength="200"
        type="textarea"
        counter
        persistentCounter
        class="pb-0 customLabelAlignment"
      >
      </LabelText>

      <LabelText
        labelXl="3"
        labelLg="3"
        labelMd="3"
        labelSm="3"
        textXl="9"
        textLg="9"
        textMd="9"
        textSm="9"
        :id="`txt_FW20_Notes_${rowId}`"
        label="FW20 Notes"
        v-model="selectedRow.NOTES"
        maxlength="2000"
        type="textarea"
        counter
        persistentCounter
        class="pb-0 customLabelAlignment"
      >
      </LabelText>
    </v-col>
  </v-row>
</template>

<script>
import { computed } from "vue";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useInspectionStore } from "@/stores/inspection";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelCheckbox from "@/components/common/LabelCheckbox.vue";
import LabelText from "@/components/common/LabelText.vue";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { METRIC_CONVERSION_TYPES } from "@/constants/InspectionConstants";

export default {
  name: "InspectionFeaturesWaterway",
  props: {
    rowId: { default: 0 },
  },
  setup(props) {
    const inspectionStore = useInspectionStore();

    let selectedRow = computed(() =>
      inspectionStore.getFeatureIntersectionSelectedRow(props.rowId)
    );

    return {
      REFERENCE_TABLE,
      selectedRow,
      PAIR_CODE,
      METRIC_CONVERSION_TYPES,
    };
  },
  components: {
    LabelSelect,
    LabelCheckbox,
    LabelText,
  },
};
</script>
