<template>
  <v-label v-if="label" class="bold"
    >{{ label }}:
    <span lass="pl-1" v-if="labelValue">{{ labelValue }}</span></v-label
  >

  <v-text-field
    v-if="singleLine"
    :id="id"
    :aria-describedby="id"
    :aria-label="ariaLabel"
    v-model="modelValue"
    dense
    :readonly="readOnly"
    :counter="counter"
    :maxlength="maxlength"
    persistent-counter
    single-line
    :variant="readOnly ? 'outlined' : 'filled'"
  />
  <v-textarea
    v-else
    :id="id"
    :aria-describedby="id"
    :aria-label="ariaLabel"
    v-model="modelValue"
    dense
    :readonly="readOnly"
    :counter="counter"
    :maxlength="maxlength"
    persistent-counter
    auto-grow
    rows="1"
    :variant="readOnly ? 'outlined' : 'filled'"
    @keypress="handler"
    @input="truncate"
    @blur="trim"
  />
</template>
<script>
import { computed } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import { truncateString, encodeString } from "@/composables/util";

export default {
  name: "StructureUnitCommentTypeTextField",
  props: {
    id: {},
    inspectionCommentType: { default: "" },
    readOnly: { default: false },
    counter: { default: "" },
    maxlength: { maxlength: "" },
    singleLine: { default: false },
    structureUnitKey: { default: "" },
    label: { default: "" },
    labelValue: { default: "" },
  },
  setup(props) {
    const inspectionStore = useInspectionStore();

    let structure = computed(() => inspectionStore.selectedInspection);

    function getInspCommentObjectByUnitKeyAndCommentType(commentType, unitKey) {
      return computed(() =>
        structure.value?.T_Struc_Unit_Insp_Comment?.find(
          (element) =>
            element?.COMMENT_TYPE == commentType &&
            element?.STRUNITKEY == unitKey
        )
      );
    }

    function elseCase(commentType, notes, unitKey) {
      return {
        BRKEY: structure.value.Bridge.BRKEY,
        COMMENT_TYPE: commentType,
        INSPKEY: structure.value.InspEvnt.INSPKEY,
        MODTIME: new Date().toISOString(),
        NOTES: notes,
        STRUNITKEY: unitKey,
        USERKEY: structure.value.InspEvnt.USERKEY,
      };
    }
    const ariaLabel = computed({
      get() {
        if (props.label == "" || !props.label) {
          return props.id + " has no label";
        } else {
          return props.label + "_" + props.id;
        }
      },
    });

    const modelValue = computed({
      get() {
        return getInspCommentObjectByUnitKeyAndCommentType(
          props.inspectionCommentType,
          props.structureUnitKey
        )?.value?.NOTES;
      },
      set(comment) {
        let commentToSet = getInspCommentObjectByUnitKeyAndCommentType(
          props.inspectionCommentType,
          props.structureUnitKey
        );
        if (commentToSet.value) {
          commentToSet.value.NOTES = encodeString(comment);
          commentToSet.value.USERKEY = structure.value.InspEvnt.USERKEY;
          commentToSet.value.MODTIME = new Date().toISOString();
        } else {
          structure.value.T_Struc_Unit_Insp_Comment.push(
            elseCase(
              props.inspectionCommentType,
              encodeString(comment),
              props.structureUnitKey
            )
          );
        }
      },
    });

    const handler = (event) => {
      if (event.which < 0x20 && event.which != 13) {
        // e.which < 0x20, then it's not a printable character like backspace etc.
        // e.which!=13 , enter key
        return; // Do nothing
      }

      if (modelValue.value?.length >= parseInt(props.maxlength)) {
        event.preventDefault();
        return false;
      }
    };

    const truncate = () => {
      modelValue.value = truncateString(
        modelValue.value?.replace(/\r\n|\r|\n/g, "\r\n"),
        parseInt(props.maxlength)
      );
    };
    const trim = () => {
      const trimmedString = modelValue.value?.trim();
      if (modelValue.value != trimmedString) {
        modelValue.value = modelValue.value?.trim();
      }
    };

    return {
      ariaLabel,
      modelValue,
      handler,
      truncate,
      trim,
    };
  },
};
</script>
