export default () => {
  function isNumber(event) {
    if (isLeftUpRightDownBackspaceDeleteTab(event)) return;
    const regex = /^[0-9,\s]+$/i;
    let key = event.key ? event.key : String.fromCharCode(event.code);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  function allowAlphanumericOnly(event) {
    const blockSpecialCharacterRegex = /^[a-z0-9\s]+$/i;
    let key = event.key ? event.key : String.fromCharCode(event.code);
    if (!blockSpecialCharacterRegex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  function isLeftUpRightDownBackspaceDeleteTab(event) {
    let key = event.key || event.code;

    return (
      key === "ArrowLeft" ||
      key === 37 ||
      key === "ArrowUp" ||
      key === 38 ||
      key === "ArrowRight" ||
      key === 39 ||
      key === "ArrowDown" ||
      key === 40 ||
      key === "Backspace" ||
      key === 8 ||
      key === "Delete" ||
      key === 46 ||
      key === "Tab" ||
      key === 9
    );
  }
  function isDecimalNumber(event) {
    if (isLeftUpRightDownBackspaceDeleteTab(event)) return;
    const regex = /^\d*\.?\d*$/;
    let key = event.key ? event.key : String.fromCharCode(event.code);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  function isNumberWithNegative(event) {
    if (isLeftUpRightDownBackspaceDeleteTab(event)) return;
    const regex = /^[-,0-9\s]+$/i;
    let key = event.key ? event.key : String.fromCharCode(event.code);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  function isDecimalNumberWithNegative(event) {
    if (isLeftUpRightDownBackspaceDeleteTab(event)) return;
    const regex = /^-?\d*\.?\d*$/;
    let key = event.key ? event.key : String.fromCharCode(event.code);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

  return {
    isNumber,
    allowAlphanumericOnly,
    isDecimalNumber,
    isNumberWithNegative,
    isDecimalNumberWithNegative,
  };
};
