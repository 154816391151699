import { defineStore } from "pinia";
import { ADMIN_ENDPOINT } from "@/constants/Endpoints";
import axios from "axios";
import { LOGGER } from "@/util/logger";

export const useAdminStore = defineStore("admin", () => {
  const saveUser = async (user) => {
    return axios
      .put(ADMIN_ENDPOINT.SAVE_POUCHDB_USER, user)
      .then((response) => {
        return response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
  };

  const getUserInfo = async (userId) => {
    let url = ADMIN_ENDPOINT.GET_POUCHDB_USER.replace("{userId}", userId);
    return await axios
      .get(url)
      .then((response) => {
        return response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
  };

  return { saveUser, getUserInfo };
});
