import { SYMBOLS } from "@/constants/Unicode";

export default () => {
  function parseBMSLatLongToDMS(locationBMS, direction) {
    let coordinates = {
      seconds: null,
      minutes: null,
      degrees: null,
      direction: null,
      decimal: "",
    };
    if (locationBMS) {
      let indexOfDecimal = locationBMS.toString().indexOf(".");

      const locationBMSLength = locationBMS.toString().length;

      if (locationBMSLength > 5) {
        if (indexOfDecimal != -1) {
          coordinates.seconds = locationBMS
            .toString()
            .substring(indexOfDecimal - 2, locationBMSLength);
          coordinates.minutes = locationBMS
            .toString()
            .substring(indexOfDecimal - 4, indexOfDecimal - 2);
          coordinates.degress = locationBMS
            .toString()
            .substring(0, indexOfDecimal - 4);
          coordinates.direction = direction;
          coordinates.decimal = convertDMStoDecimalLatLong(
            coordinates.degress,
            coordinates.minutes,
            coordinates.seconds,
            coordinates.direction
          );
        } else {
          coordinates.seconds = locationBMS
            .toString()
            .substring(locationBMSLength - 2, locationBMSLength);
          coordinates.minutes = locationBMS
            .toString()
            .substring(locationBMSLength - 4, locationBMSLength - 2);
          coordinates.degress = locationBMS
            .toString()
            .substring(0, locationBMSLength - 4);
          coordinates.direction = direction;
          coordinates.decimal = convertDMStoDecimalLatLong(
            coordinates.degress,
            coordinates.minutes,
            coordinates.seconds,
            coordinates.direction
          );
        }
      }
      coordinates.decimal = coordinates.decimal + SYMBOLS.DEGREE;
    }
    return coordinates;
  }
  function convertDMStoDecimalLatLong(degrees, minutes, seconds, direction) {
    let coordinatesDecmial =
      parseFloat(degrees) +
      parseFloat(minutes) / 60 +
      parseFloat(seconds) / 3600;

    if ("s" == direction.toLowerCase() || "w" == direction.toLowerCase()) {
      coordinatesDecmial = coordinatesDecmial * -1;
    }

    return parseFloat(coordinatesDecmial).toFixed(6);
  }
  return { parseBMSLatLongToDMS };
};
