<template>
  <v-row>
    <ExpandCollapseToggle
      id="tunnelsPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panel"
    />
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel value="tunnelDetails" class="mt-5">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon
            v-if="panel.includes('tunnelDetails')"
            icon="fa-solid fa-minus"
          />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h3>Tunnel Details</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <h3 class="withBackground px-2 my-2">
            Structure Type and Material Items
          </h3>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <LabelText
                labelXl="8"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                textXl="4"
                textLg="4"
                textMd="4"
                textSm="4"
                id="number_number_of_bores"
                label="S.1 Number of Bores"
                v-model="numBores"
                type="number"
                maxlength="22"
              >
              </LabelText>
              <LabelSelect
                labelXl="8"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="4"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="S.2 Tunnel Shape"
                id="select_Tunnel_Shape"
                :refTable="REFERENCE_TABLE.TUNNEL_SHAPE"
                v-model="tunnelShape"
              />
              <LabelSelect
                labelXl="8"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="4"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="S.3 Portal Shape"
                id="select_Portal_Shape"
                :refTable="REFERENCE_TABLE.PORTAL_SHAPE"
                v-model="portalShape"
              />
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <LabelSelect
                labelXl="8"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="4"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="S.4 Ground Conditions"
                id="select_Ground_Conditions"
                :refTable="REFERENCE_TABLE.GROUND_CONDITION"
                v-model="groundCondition"
              />
              <LabelSelect
                labelXl="8"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="4"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="S.5 Complex"
                id="select_Complex"
                :refTable="REFERENCE_TABLE.COMPLEX"
                v-model="complex"
              />
            </v-col>
          </v-row>

          <h3 class="withBackground px-2 my-2">Age and Service Items</h3>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <LabelSelect
                labelXl="8"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="4"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="A.8 Service in Tunnel"
                id="select_Service_in_Tunnel"
                :refTable="REFERENCE_TABLE.SERVICE_IN_TUNNEL"
                v-model="svcInTunnel"
              />
            </v-col>
          </v-row>
          <h3 class="withBackground px-2 my-2">Classification Items</h3>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <LabelSelect
                labelXl="8"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="4"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="C.3 Direction of Traffic"
                id="select_Direction_of_Traffic"
                :refTable="REFERENCE_TABLE.DIRECTION_OF_TRAVEL"
                v-model="dirOfYTraffic"
              />
              <LabelSelect
                labelXl="8"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="4"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="C.4 Toll"
                id="select_Toll"
                :refTable="REFERENCE_TABLE.TOLL"
                v-model="toll"
              />
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <LabelSelect
                labelXl="8"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="4"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="C.7 Functional Classification"
                id="select_Functional_Classification"
                :refTable="REFERENCE_TABLE.TUNNEL_FUNCTIONAL_CLASSIFICATION"
                v-model="functionalClass"
              />
              <LabelSelect
                labelXl="8"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="4"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="C.8 Urban Code"
                id="select_Urban_Code"
                :refTable="REFERENCE_TABLE.URBAN_CODE"
                v-model="urbanCode"
              />
            </v-col>
          </v-row>
          <h3 class="withBackground px-2 my-2">Inspection Items</h3>

          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <LabelDatefield
                labelXl="8"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                textXl="4"
                textLg="4"
                textMd="4"
                textSm="4"
                id="txt_Routine_Inspection_Target_Date"
                label="D.1 Routine Inspection Target Date"
                v-model="inspTargetDate"
              >
              </LabelDatefield>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <LabelSelect
                labelXl="8"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="4"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="D.4 In-Depth Inspection Schedule?"
                id="select_In-Depth_Inspection_Schedule"
                :refTable="REFERENCE_TABLE.IN_DEPTH"
                v-model="indepthInspScheduled"
                :showColon="false"
              />
            </v-col>
          </v-row>
          <h3 class="withBackground px-2 my-2">Posting Restriction Items</h3>

          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <LabelSelect
                labelXl="8"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="4"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="L.10 Height Restriction"
                id="select_Height_Restriction"
                :refTable="REFERENCE_TABLE.YES_NO"
                v-model="heightRestriction"
              />
              <LabelSelect
                labelXl="8"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="4"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="L.11 Hazardous Material Restriction"
                id="select_Hazardous_Material_Restriction"
                :refTable="REFERENCE_TABLE.YES_NO"
                v-model="hazMatRestr"
              />
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <LabelSelect
                labelXl="8"
                labelLg="8"
                labelMd="8"
                labelSm="8"
                selectXl="4"
                selectLg="4"
                selectMd="4"
                selectSm="4"
                label="L.12 Other Restrictions"
                id="select_Other_Restrictions"
                :refTable="REFERENCE_TABLE.YES_NO"
                v-model="otherRestr"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel value="elements">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="panel.includes('elements')" icon="fa-solid fa-minus" />
          <v-icon v-else icon="fa-solid fa-plus" />
          <h3>Elements</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <InspectionElements :inspectionPage="INSPECTION_PAGES.TUNNELS" />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>
<script>
import { ref, computed } from "vue";
import InspectionElements from "@/components/inspection/InspectionElements.vue";
import { INSPECTION_PAGES } from "@/constants/Inspections";
import LabelDatefield from "@/components/common/LabelDatefield.vue";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import LabelText from "@/components/common/LabelText.vue";
import LabelSelect from "@/components/common/LabelSelect.vue";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";

export default {
  name: "InspectionTunnels",
  setup() {
    const allPanels = ["tunnelDetails", "elements"];
    const panel = ref(allPanels);
    const inspectionStore = useInspectionStore();

    let structure = computed(() => inspectionStore.selectedInspection);

    let numBores = computed(
      () => structure.value?.T_Tunnel_Structmat?.NUM_BORES
    );

    let tunnelShape = computed(
      () => structure.value?.T_Tunnel_Structmat?.TUNNEL_SHAPE
    );
    let portalShape = computed(
      () => structure.value?.T_Tunnel_Structmat?.PORTAL_SHAPE
    );
    let groundCondition = computed(
      () => structure.value?.T_Tunnel_Structmat?.GROUND_CONDTN
    );
    let complex = computed(() => structure.value?.T_Tunnel_Structmat?.COMPLEX);
    let svcInTunnel = computed(
      () => structure.value?.T_Tunnel_Service?.SVC_IN_TUNNEL
    );
    let dirOfYTraffic = computed(
      () => structure.value?.T_Tunnel_Service?.DIR_OF_TRAFFIC
    );
    let toll = computed(() => structure.value?.T_Tunnel_Service?.TOLL);
    let functionalClass = computed(
      () => structure.value?.T_Tunnel_Service?.FUNCTIONAL_CLASS
    );
    let urbanCode = computed(
      () => structure.value?.T_Tunnel_Service?.URBAN_CODE
    );
    let inspTargetDate = computed(
      () => structure.value?.T_Tunnel_Service?.INSP_TARGET_DATE
    );
    let indepthInspScheduled = computed(
      () => structure.value?.T_Tunnel_Service?.IN_DEPTH_INSP_SCHEDULED
    );
    let heightRestriction = computed(
      () => structure.value?.T_Tunnel_Load_Rating?.HEIGHT_RESTR
    );
    let hazMatRestr = computed(
      () => structure.value?.T_Tunnel_Load_Rating?.HAZMAT_RESTR
    );
    let otherRestr = computed(
      () => structure.value?.T_Tunnel_Load_Rating?.OTHER_RESTR
    );

    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    return {
      panel,
      INSPECTION_PAGES,
      structure,
      REFERENCE_TABLE,
      numBores,
      tunnelShape,
      portalShape,
      groundCondition,
      complex,
      svcInTunnel,
      dirOfYTraffic,
      toll,
      functionalClass,
      urbanCode,
      inspTargetDate,
      indepthInspScheduled,
      heightRestriction,
      hazMatRestr,
      otherRestr,
      toggleAllPanels,
    };
  },
  components: {
    LabelText,
    LabelSelect,
    InspectionElements,
    LabelDatefield,
    ExpandCollapseToggle,
  },
};
</script>
<style scoped lang="scss">
@use "@/styles/colors" as c;

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.compactCell {
  width: 10%;
  padding: 0px !important;
}
</style>
