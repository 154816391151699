import { defineStore } from "pinia";
import { setupSyncInterval } from "@/util/syncInterval";
import axios from "axios";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { LOGGER } from "@/util/logger";
import auth from "@/auth";
import router from "@/router";

export const useConnectivityStore = defineStore("connectivity", {
  state: () => ({
    toggledOnline: true,
    serviceStatus: false,
    offlineAt: window.navigator.onLine ? null : new Date(),
    isOnline: window.navigator.onLine
  }),
  getters: {
    getOnlineServiceStatus() {
      return this.isOnline && this.serviceStatus && this.toggledOnline;
    },
    getisOnline() {
      return this.isOnline && this.serviceStatus;
    },
    getToggledOnline() {
      return this.toggledOnline;
    }, 
  },
  actions: {
    async toggleOnline(value) {
      this.toggledOnline = value;
      if (!value) {
        router.push({ name: "OfflineLogin" });
      }
      this.offlineAt = this.getisOnline ? null : new Date();
    },
    toggleOnlineNoRedirect(value){
      this.toggledOnline = value;     
      this.offlineAt = this.getisOnline ? null : new Date();
    },
    async getServiceStatus() {
      await axios
        .get(CONFIG_ENDPOINT.PING_ACTUATOR)
        .then((response) => {
          if (
            (response.status >= 200 && response.status < 300) ||
            response.status === 304 ||
            response.status === 401
          ) {
            this.serviceStatus = true;
          } else {
            this.serviceStatus = false;
          }
        })
        .catch((e) => {
          //401 means the server is reached but failed to authorize
          if (e.response?.data?.statusCode === 401) {
            this.serviceStatus = true;
          } else {
            this.serviceStatus = false;
          }

          let error = Object.assign(e, {
            method: "pingActuator",
          });
          LOGGER.logException(error);
        });
    },
    addListeners() {
      const { onOffline, onOnline } = callbacks(this);
      window.addEventListener("offline", onOffline);
      window.addEventListener("online", onOnline);
      if ("connection" in window.navigator) {
        this.offlineAt = this.getisOnline ? null : new Date();
      }
    },
    removeListeners() {
      const { onOffline, onOnline } = callbacks(this);
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
    }, 
  },
});

function callbacks(instance) {
  let timer;
  const onOffline = () => {
    instance.isOnline = window.navigator.onLine;
    instance.serviceStatus = false;
    instance.offlineAt = new Date();
    clearTimeout(timer);

  };
  const onOnline = async () => {
    instance.isOnline = window.navigator.onLine;
    timer = setTimeout(setOnline, 5 * 60 * 1000);
  };
  const setOnline = async () => {
    await instance.getServiceStatus();
    instance.offlineAt = null;
    await auth.silentLogin();
    setupSyncInterval();
    clearTimeout(timer);
  };
  return { onOffline, onOnline };
}
