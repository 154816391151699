import pouchdb from "pouchdb";
import { defineStore } from "pinia";
import { LOGGER } from "@/util/logger";
import pouchdbFind from "pouchdb-find";
import { RECORD_MANAGEMENT_TYPES } from "@/constants/StructureSearches";
import { useUserStore } from "@/stores/userStore";
import { ref, computed } from "vue";
import cryptoPouch from "crypto-pouch";
import { useEDocsIDBStore } from "@/stores/eDocsIDB";

export const useStructureIDBStore = defineStore("structureIDB", () => {
  const userStore = useUserStore();
  pouchdb.plugin(pouchdbFind);
  pouchdb.plugin(cryptoPouch);

  let localStructures = ref([]);
  let totalStructuresInIDB = ref(0);
  let dbInitialized = ref(false);
  const dbName = computed(() => {
    const userName = userStore.loggedInUser?.sub;
    return userName ? `bms-${userName.toLowerCase()}` : "";
  });

  const getLocalStructures = computed(() => {
    return localStructures.value;
  });

  let structuredb = ref(null);

  const initDB = async (isLoginAttempt) => {
    if (!dbInitialized.value || isLoginAttempt) {
      try {
        const db = pouchdb(dbName.value, {
          revs_limit: 0,
          auto_compaction: true,
        });

        const password = localStorage.getItem("hashedPass");
        if (password) {
          await db.crypto({
            password: password,
            ignore: "_attachments",
          });
        }
        await new Promise((resolve) => db.info(resolve));
        structuredb.value = db;
        dbInitialized.value = true;
      } catch (error) {
        LOGGER.logException(error);
      }
    }
  };

  const saveStructure = async (payload) => {
    LOGGER.logEvent(
      LOGGER.EventAction.Save,
      LOGGER.EventCat.Button,
      "IDB",
      payload
    );
    // eslint-disable-next-line no-unused-vars
    const { _attachments, EDocs, ...payloadWithNoAttachments } = payload;
    const eDocsIDBStore = useEDocsIDBStore();
    await eDocsIDBStore.initDB();

    try {
      let doc = await structuredb.value.get(
        payloadWithNoAttachments.Bridge.BRKEY
      );

      const savedRecordType = doc.recordType;
      const updatedStructure = updateRelatedData(doc, payloadWithNoAttachments);
      updateIDBRecordType(savedRecordType, updatedStructure);
      if (doc.submittedTimestamp) {
        updatedStructure.submittedTimestamp = null;
      }
      await structuredb.value.put({
        ...updatedStructure,
      });
      eDocsIDBStore.saveAttachments(payload);
    } catch (e) {
      let error = Object.assign(e, {
        method: "saveStructure",
        payload,
      });
      LOGGER.logException(error);
      updateIDBRecordType(null, payloadWithNoAttachments);

      await structuredb.value.put({
        _id: payload.Bridge.BRKEY,
        ...payloadWithNoAttachments,
      });
      eDocsIDBStore.saveAttachments(payload);
    }
    getStructuresFromIDB();
  };

  const updateRelatedData = (savedStructure, payload) => {
    if (payload.recordType === RECORD_MANAGEMENT_TYPES.BOTH) {
      //update structure/EDocs with payload data.
      return {
        ...savedStructure,
        ...payload,
        _rev: savedStructure._rev,
      };
    } else if (payload.recordType === RECORD_MANAGEMENT_TYPES.DOCUMENTS) {
      return {
        ...payload,
        ...savedStructure,
        EDocs: payload?.EDocs ?? [],
        _rev: savedStructure._rev,
        recordType: RECORD_MANAGEMENT_TYPES.DOCUMENTS,
      };
    } else {
      return {
        ...savedStructure,
        ...payload,
        EDocs: savedStructure?.EDocs ?? [],
        _rev: savedStructure._rev,
      };
    }
  };
  const deleteStructureFromIDB = async (payload) => {
    LOGGER.logEvent(
      LOGGER.EventAction.Delete,
      LOGGER.EventCat.Button,
      "IDB",
      payload
    );
    const eDocsIDBStore = useEDocsIDBStore();
    await eDocsIDBStore.initDB();
    for (const brKey of payload) {
      structuredb.value
        .get(brKey)
        .then(async function (doc) {
          await eDocsIDBStore.deleteEDocsFromIDB(brKey);
          return structuredb.value.remove(doc);
        })
        .then(() => {
          getStructuresFromIDB();
        })
        .catch((e) => {
          let error = Object.assign(e, {
            method: "deleteStructureFromIDB",
            payload,
          });
          LOGGER.logException(error);
        });
    }
  };
  const getStructuresFromIDB = async () => {
    clearStructuresFromState();
    await structuredb.value
      .allDocs({
        include_docs: true,
        attachments: false,
      })
      .then(function (result) {
        //filter user records and get structure records only
        localStructures.value = result?.rows
          ?.filter((doc) => !doc.doc.sub)
          ?.map((x) => x.doc);
        totalStructuresInIDB.value = localStructures.value?.length;
      })
      .catch(function (e) {
        let error = Object.assign(e, {
          method: "getStructuresFromIDB",
        });
        LOGGER.logException(error);
      });
  };

  const getDirtyStructuresFromIDB = async () => {
    return structuredb.value.find({
      selector: { backedUp: "N" },
    });
  };

  const getLatestInspectionFromIDB = async (brkey) => {
    try {
      let structures = await structuredb.value.find({
        selector: {
          _id: brkey,
        },
      });
      structures = structures.docs.sort((a, b) => {
        return new Date(b.InspEvnt.INSPDATE) - new Date(a.InspEvnt.INSPDATE);
      });
      return structures[0];
    } catch (e) {
      let error = Object.assign(e, {
        method: "getLatestInspectionFromIDB",
      });
      LOGGER.logException(error);
    }
  };
  const clearStructuresFromState = () => {
    localStructures.value = [];
  };
  const updateIDBRecordType = (savedRecordType, document) => {
    if (savedRecordType) {
      document.recordType =
        savedRecordType != document.recordType
          ? RECORD_MANAGEMENT_TYPES.BOTH
          : document.recordType;
    }
  };

  const getLatestInspectionKeyFromIDB = async (brkey) => {
    try {
      let structures = await structuredb.value.find({
        selector: {
          _id: brkey,
        },
        fields: ["InspEvnt.INSPKEY"],
      });
      return structures?.docs[0]?.InspEvnt?.INSPKEY;
    } catch (e) {
      let error = Object.assign(e, {
        method: "getLatestInspectionKeyFromIDB",
      });
      LOGGER.logException(error);
    }
  };
  const saveUserInfo = async (userInfo) => {
    LOGGER.logEvent(
      LOGGER.EventAction.Save,
      LOGGER.EventCat.Button,
      "SaveUserInfo",
      userInfo
    );

    try {
      let savedUser = await structuredb.value.get(userInfo?.sub);
      if (savedUser) {
        await structuredb.value.put({
          ...savedUser,
          ...userInfo,
        });
      }
    } catch (e) {
      let error = Object.assign(e, {
        method: "saveUserInfo",
        userInfo,
      });
      LOGGER.logException(error);

      await structuredb.value.put({
        _id: userInfo?.sub,
        ...userInfo,
      });
    }
  };

  const getUserInfo = async (userId) => {
    LOGGER.logEvent(
      LOGGER.EventAction.Search,
      LOGGER.EventCat.Button,
      "GetUserInfo",
      userId
    );
    try {
      const savedUser = await structuredb.value.get(userId);
      return savedUser;
    } catch (e) {
      let error = Object.assign(e, {
        method: "getUserInfo",
        userId,
      });
      LOGGER.logException(error);
    }
  };

  const updateStructures = async (structures) => {
    LOGGER.logEvent(
      LOGGER.EventAction.Update,
      LOGGER.EventCat.Button,
      "IDB",
      structures
    );

    try {
      await structuredb.value.bulkDocs(structures);
    } catch (e) {
      let error = Object.assign(e, {
        method: "updateStructures",
        structures,
      });
      LOGGER.logException(error);
    }
    getStructuresFromIDB();
  };

  const checkIfDBExists = async () => {
    try {
      const info = await structuredb.value.info();
      return info?.doc_count > 0;
    } catch (e) {
      LOGGER.logException(e);
      return false;
    }
  };

  const deleteDB = async (userId) => {
    await pouchdb(`bms-${userId}`)
      .destroy()
      .catch((err) => {
        LOGGER.logException(err);
      });
  };
  const updatePassword = async (newPassword) => {
    try {
      let newDB = await pouchdb(`${dbName.value}-new`);
      LOGGER.logEvent(
        LOGGER.EventAction.OfflinePassword,
        LOGGER.EventCat.Button,
        "OfflinePassword",
        `New DB created, ${dbName.value}-new`
      );
      const docs = await structuredb.value.allDocs({
        include_docs: true,
        attachments: true,
      });

      // extract the documents from the response
      const docsData = docs?.rows.map((row) => {
        const doc = row.doc;
        const attachments = doc._attachments;
        // check if the document has any attachments
        if (attachments) {
          // add the attachment data to the document
          Object.keys(attachments).forEach((name) => {
            structuredb.value.getAttachment(doc._id, name).then((blob) => {
              doc._attachments[name].data = blob;
            });
          });
        }
        delete doc._rev;
        return doc;
      });
      LOGGER.logEvent(
        LOGGER.EventAction.OfflinePassword,
        LOGGER.EventCat.Button,
        "OfflinePassword",
        "All docs extracted from old DB"
      );
      await newDB.bulkDocs(docsData);
      LOGGER.logEvent(
        LOGGER.EventAction.OfflinePassword,
        LOGGER.EventCat.Button,
        "OfflinePassword",
        "All docs loaded into new DB"
      );
      //delete olddb
      await structuredb.value.destroy();
      LOGGER.logEvent(
        LOGGER.EventAction.OfflinePassword,
        LOGGER.EventCat.Button,
        "OfflinePassword",
        "Destroyed old DB"
      );
      localStorage.setItem("hashedPass", newPassword);
      //initialize DB with new password
      dbInitialized.value = false;
      await initDB(true);
      LOGGER.logEvent(
        LOGGER.EventAction.OfflinePassword,
        LOGGER.EventCat.Button,
        "OfflinePassword",
        "DB created with original name"
      );
      LOGGER.logEvent(
        LOGGER.EventAction.OfflinePassword,
        LOGGER.EventCat.Button,
        "OfflinePassword",
        "Before loading all Docs from new DB to original named DB"
      );
      await newDB.replicate.to(structuredb.value);
      LOGGER.logEvent(
        LOGGER.EventAction.OfflinePassword,
        LOGGER.EventCat.Button,
        "OfflinePassword",
        "After loading all docs to original named DB"
      );
      newDB.destroy();
      LOGGER.logEvent(
        LOGGER.EventAction.OfflinePassword,
        LOGGER.EventCat.Button,
        "OfflinePassword",
        "Destroyed temp DB"
      );
    } catch (e) {
      LOGGER.logException(e);
    }
  };

  return {
    localStructures,
    totalStructuresInIDB,
    getLocalStructures,
    saveStructure,
    updateRelatedData,
    deleteStructureFromIDB,
    getStructuresFromIDB,
    getDirtyStructuresFromIDB,
    getLatestInspectionFromIDB,
    clearStructuresFromState,
    updateIDBRecordType,
    getLatestInspectionKeyFromIDB,
    saveUserInfo,
    getUserInfo,
    updateStructures,
    checkIfDBExists,
    deleteDB,
    updatePassword,
    initDB,
  };
});
